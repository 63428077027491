export function LanguageCheck(English: any, French: any, German: any, Dutch: any, Spanish: any, Italian: any, Romanian: any, Portuguese: any, language?: string) {
  switch (language?.toLowerCase()) {
    case "english":
    case "en":
    case "en-us":
      return English;
    case "french":
    case "fr":
      return French;
    case "german":
    case "de":
      return German;
    case "dutch":
    case "nl":
      return Dutch;
    case "spanish":
    case "es":
    case "es-es":
      return Spanish;
    case "italian":
    case "it":
      return Italian;
    case "romanian":
    case "ro":
      return Romanian;
    case "portuguese":
    case "pt":
    case "pt-pt":
      return Portuguese;
    default:
      return English;
  }
}
