import { useEffect, useState } from "react";
import { ChartScale, MarkerData } from "..";
import _ from "lodash";
import { ChartType } from "..";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../../../dictionary/ChartsText";
import { LanguageCheck } from "../../../../utils/LanguageCheck";
import { useAppSelector } from "../../../../app/hooks";
import { selectLanguage } from "../../../../features/user/userSlice";
import { useParams } from "react-router";
import { Device, selectDevices } from "../../../../features/devices/deviceSlice";
import { Dashboards, selectDashboards } from "../../../../features/dashboards/dashboardSlice";
import { MdAddLocation } from "react-icons/md";
import { EditChart, EditChartProps } from "../EditChart";
import { MarkerBox } from "./NewMarkerMenu";
import { Button } from "../../../Button";
import { FormInput } from "../../../FormInput";

type Props = {
  id: string;
  data: ChartType;
  remove: (id: string) => void;
  updateChart: (data: ChartType) => void;
  setShowChartEdit: (isOpen: boolean) => void;
};

function EditMap({ id, remove, updateChart, data, setShowChartEdit }: Props) {
  type FormInputData = {
    optionValue: string;
    optionName: string;
  };

  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  const [markerList, setMarkerList] = useState<MarkerData[]>(
    data.chartData ?? []
  );
  const [title, setTitle] = useState("");
  const [userDeviceData, setUserDeviceData] = useState<FormInputData[]>([]);
  const [loading, setLoading] = useState(false);

  let { dashboardid } = useParams();
  let { devices } = useAppSelector(selectDevices);
  let { dashboards } = useAppSelector(selectDashboards);

  function populateDeviceSelect() {
    var tempUserDeviceData: FormInputData[] = [{
      optionValue: "new",
      optionName: language.charts.gauge.inputDefaults.selectDevice,
    }];
    const dashboardDevices: Device[] = _.filter(devices, (device: Device) => {
      return device.group_id === dashboards[dashboardid as keyof Dashboards].group_id;
    });
    _.map(dashboardDevices, (device: Device) => {
      if (device.eui !== null && device.name !== null) {
        tempUserDeviceData.push({
          optionValue: device.eui,
          optionName: device.name,
        });
      }
    });
    setUserDeviceData(tempUserDeviceData);
  }

  function getTitleAndMarkerListFromParams() {
    setTitle(data.title.en);
    setMarkerList(data.chartData ?? []);
  }

  useEffect(() => {
    populateDeviceSelect();
  }, [devices, dashboardid, dashboards]);

  useEffect(() => {
    getTitleAndMarkerListFromParams();
  }, [data]);

  const makeSelected = (index: number) => {
    let newMarkerList = [...markerList];
    newMarkerList.slice(0, newMarkerList.length).map((itemT, indexT) => {
      newMarkerList[indexT] = { ...itemT, selected: false };
      if (index === indexT) {
        newMarkerList[indexT] = { ...itemT, selected: true };
      }
    });
    setMarkerList(newMarkerList);
    saveAndCloseChartSettings(newMarkerList);
  };

  const toggleShowUnit = (index: number) => {
    let newMarkerList = [...markerList];
    newMarkerList.slice(0, newMarkerList.length).map((itemT, indexT) => {
      if (index === indexT) {
        newMarkerList[indexT] = { ...itemT, showUnit: !itemT.showUnit };
      }
    });
    setMarkerList(newMarkerList);
  };

  const updateMarker = ({ deviceID, elemID, lng, lat, label, phenomena, colour, draggable, selected, }: MarkerData, index: number) => {
    let newMarkerList = [...markerList];
    newMarkerList.slice(0, newMarkerList.length).map((itemT, indexT) => {
      newMarkerList[indexT] = { ...itemT, selected: false };
      if (index === indexT) {
        newMarkerList[indexT] = { ...itemT, deviceID, elemID, lng, lat, label, phenomena, colour, draggable, selected };
      }
    });
    setMarkerList(newMarkerList);
  };

  const addNewMarker = ({ deviceID, elemID, lng, lat, label, phenomena, colour, draggable, selected, }: MarkerData) => {
    setMarkerList(_.concat(markerList, { deviceID, elemID, lng, lat, label, phenomena, colour, draggable, selected, }));
  };

  function setCenter() {
    let newData = { ...data, chartScale: { ...data.chartScale, y_axis: { ...data?.chartScale?.y_axis ?? { max_auto: false, min: 0, max: 0 }, min_auto: true } } };

    saveAndCloseChartSettings(newData.chartData, newData.chartScale);
  }

  function removeMarker(index: number) {
    setMarkerList(markerList.filter((_, i) => i !== index));
  };

  const itemList = (
    markerList.slice(0, markerList.length).map((item, index) => {
      if (markerList.length > 0) {
        return (
          <MarkerBox
            marker={item}
            index={index}
            userDeviceData={userDeviceData}
            showCoords={true}
            mapType="land"
            updateMarker={updateMarker}
            removeMarker={removeMarker}
            toggleShowUnit={toggleShowUnit}
            makeSelected={makeSelected}
          />
        );
      }
    })
  );

  const settingsBox = (
    <div className="p-2 my-1 space-y-1 flex w-full border-4 bg-gray-200 scrollbar-none rounded-lg flex-col border-gray-200">
      <FormInput
        htmlFor="name"
        label={"Title"}
        value={title}
        onChange={(event) => {
          setTitle(event.target.value);
        }}
      />
    </div>
  )

  const newButton = {
    text: language.charts.map.landMap.buttons.newMarker,
    onClick: () =>
      addNewMarker({
        deviceID: "new",
        elemID: "0",
        lat: 0,
        lng: 0,
        phenomena: "new",
        colour: "#" + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0').toUpperCase(),
        draggable: false,
        selected: false,
      }),
    icon: <MdAddLocation size={40} className="bg-white opacity-0 group-hover:opacity-70 absolute -ml-[6px] -mt-5 h-16 w-[calc(100%+12px)]" />
  }

  function onDelete() {
    remove(id);
    setShowChartEdit(false);
  }

  function saveChartSettings(chartData: MarkerData[], chartScale: ChartScale | undefined) {
    updateChart({
      i: id,
      type: "map",
      title: { en: title },
      chartData: chartData ? chartData : _.filter(markerList, (item) => item.deviceID !== "new"),
      chartScale: chartScale ? chartScale : data.chartScale,
    });
  };

  function saveAndCloseChartSettings(chartData?: MarkerData[], chartScale?: ChartScale) {
    updateChart({
      i: id,
      type: "map",
      title: { en: title },
      chartData: chartData ? chartData : _.filter(markerList, (item) => item.deviceID !== "new"),
      chartScale: chartScale ? chartScale : data.chartScale,
    });
    if (chartData) {
      setShowChartEdit(false);
    } else {
      setLoading(true)
      setTimeout(function () {
        setShowChartEdit(false);
        setLoading(false)
      }, 300)
    }
  };

  const EditChartObjects: EditChartProps = ({
    editMapType: language.charts.map.landMap.editMap,
    settingsBox: settingsBox,
    itemList: itemList,
    newButton: newButton,
    onDelete: onDelete,
    onSave: saveAndCloseChartSettings,
    loading: loading,
  })

  return (
    <EditChart {...EditChartObjects} />
  );
}

export default EditMap;
