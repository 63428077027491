import _ from "lodash";
import { useEffect, useState } from "react";
import { ChartScale, ChartType, GaugeData, Scale } from "..";
import { v4 as uuidv4 } from "uuid";
import { FormInput } from "../../../FormInput";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../../../dictionary/ChartsText";
import { LanguageCheck } from "../../../../utils/LanguageCheck";
import { useAppSelector } from "../../../../app/hooks";
import { selectLanguage } from "../../../../features/user/userSlice";
import { EditChart, EditChartProps } from "../EditChart";
import { RiInsertColumnRight, RiInsertRowBottom } from "react-icons/ri";
import { Device, selectDevices } from "../../../../features/devices/deviceSlice";
import { Dashboards, selectDashboards } from "../../../../features/dashboards/dashboardSlice";
import { useParams } from "react-router-dom";
import NewSingleDeviceTable from "./NewSingleDeviceTableMenu";
import NewComparisonTableMenu from "./NewComparisonTableMenu";

type Props = {
  id: string;
  data: ChartType;
  remove: (id: string) => void;
  updateChart: (data: ChartType) => void;
  setShowChartEdit: (isOpen: boolean) => void;
};

type FormInputData = {
  optionValue: string;
  optionName: string;
};

function EditTable({ id, remove, updateChart, data, setShowChartEdit }: Props) {
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  const defaultYAxis: Scale = { min_auto: true, max_auto: true, max: 0, min: 0 };

  const [title, setTitle] = useState<string>(data.title.en);
  const [tableType, setTableType] = useState<string>("singleDevice");
  const [maxRows, setMaxRows] = useState<number>(2000);
  const [deviceID, setDeviceID] = useState<string>("new");
  const [rings, setRings] = useState<GaugeData[]>(data.chartData);
  const [loading, setLoading] = useState<boolean>(false);
  const [userDeviceData, setUserDeviceData] = useState<FormInputData[]>([]);

  let { devices } = useAppSelector(selectDevices);
  let { dashboards } = useAppSelector(selectDashboards);
  let { dashboardid } = useParams();

  const [chartScale, setChartScale] = useState<ChartScale>(
    (data.chartScale === undefined || (data.chartScale.y_axis === defaultYAxis)) ?
      { y_axis: { min_auto: true, max_auto: true, max: 10, min: 0 } }
      : data.chartScale
  );

  const tableTypes: FormInputData[] = (
    [
      {
        optionName: "Single Device",
        optionValue: "singleDevice",
      },
      {
        optionName: "Device Comparison",
        optionValue: "comparison",
      },
    ]
  );

  function GetIcon() {
    if (tableType === "singleDevice") {
      return <RiInsertColumnRight size={40} className="bg-white opacity-0 group-hover:opacity-70 absolute -ml-[6px] -mt-5 h-16 w-[calc(100%+12px)]" />
    } else {
      return <RiInsertRowBottom size={40} className="bg-white opacity-0 group-hover:opacity-70 absolute -ml-[6px] -mt-5 h-16 w-[calc(100%+12px)]" />
    }
  }

  function GetDeviceIDFromRings() {
    if (rings) {
      if (rings[0]) {
        if (rings[0].deviceID !== undefined && rings[0].deviceID !== null && rings[0].deviceID !== "new") {
          setDeviceID(rings[0].deviceID)
        } else { setDeviceID("new") }
      } else { setDeviceID("new") }
    } else { setDeviceID("new") }
  }

  function getBlankRing() {
    return {
      ringID: uuidv4(),
      deviceID: tableType === "singleDevice" ? deviceID : "new",
      phenomena: "new",
      unit: "",
      elemID: "",
      colour: "",
      data: [],
      label: "",
      min: 0,
      max: 0,
    }
  }

  function GetChartTypeAndMaxRingsFromParams() {
    if (chartScale.y_axis.min_auto === true) {
      setTableType("singleDevice")
    } else {
      setTableType("comparison")
    }
    if (chartScale.y_axis.max) {
      setMaxRows(chartScale.y_axis.max)
    }
  }

  function GetTitleAndRingsFromParams() {
    setTitle(data.title.en);
    if (data.chartData !== undefined && data.chartData !== null && data.chartData.length > 0) {
      setRings(data.chartData);
    } else {
      setRings([
        getBlankRing()
      ]);
    }
  }

  function getChartScaleFromParams() {
    if (data.chartScale !== undefined && data.chartScale !== null) {
      setChartScale(data.chartScale)
    }
  }

  function populateDeviceSelect() {
    var tempUserDeviceData: FormInputData[] = [{
      optionValue: "new",
      optionName: language.charts.gauge.inputDefaults.selectDevice,
    }];
    const dashboardDevices: Device[] = _.filter(devices, (device: Device) => {
      return device.group_id === dashboards[dashboardid as keyof Dashboards].group_id;
    });
    _.map(dashboardDevices, (device: Device) => {
      if (device.eui !== null && device.name !== null) {
        tempUserDeviceData.push({
          optionValue: device.eui,
          optionName: device.name,
        });
      }
    });
    setUserDeviceData(tempUserDeviceData);
  }

  function CatchBlankData() {
    if (rings === undefined || rings === null) {
      setRings([
        getBlankRing()
      ]);
    } else {
      if (!(rings.length > 0)) {
        setRings([
          getBlankRing()
        ]);
      }
    }
  }

  useEffect(() => {
    GetDeviceIDFromRings();
  }, [rings])

  useEffect(() => {
    GetChartTypeAndMaxRingsFromParams();
  }, [chartScale])

  useEffect(() => {
    GetTitleAndRingsFromParams();
  }, [data]);

  useEffect(() => {
    getChartScaleFromParams();
  }, [data])

  useEffect(() => {
    populateDeviceSelect();
  }, [devices]);

  useEffect(() => {
    CatchBlankData()
  }, [rings])

  const onRemoveRing = (ringID: string | undefined) => {
    setRings(_.reject(rings, { ringID: ringID }));
  };

  const addNewRing = ({ ringID, deviceID, phenomena, unit, elemID, colour, data, label, min, max, }: GaugeData) => {
    setRings(_.concat(rings, { ringID, deviceID, phenomena, unit, elemID, colour, data, label, min, max, }));
  };

  var settingsBox = (
    <div className="p-2 bg-gray-200 overflow-y-scroll scrollbar-none rounded-lg flex flex-col">
      <FormInput
        type="select"
        label={"Table Type"}
        htmlFor="type"
        data={tableTypes}
        value={tableType}
        onChange={(event) => {
          setTableType(event.target.value);
          setDeviceID("new");
          setRings([])
        }}
      />
      <FormInput
        label={language.charts.gauge.labels.title}
        htmlFor="title"
        value={title !== null ? title : ""}
        onChange={(event) => {
          setTitle(event.target.value);
        }}
      />
      {tableType === "singleDevice" && (
        <>
          <FormInput
            value={deviceID ?? "new"}
            type="select"
            label={language.charts.gauge.labels.device}
            htmlFor="device"
            data={userDeviceData}
            onChange={(event) => {
              setDeviceID(event.target.value);
              setRings([]);
            }} />
          <div className="w-full flex flex-row justify-between mt-1">
            <div className="text-blue-600 font-semibold flex items-center justify-center">
              {"Max Rows:"}
            </div>
            <div className="w-[50%] rounded-xl">
              <FormInput
                label={""}
                autoComplete="off"
                inputmode="numeric"
                htmlFor="maxRows"
                type="text"
                value={maxRows}
                onKeyDown={(evt) => /^-?\d|-+$/.test(evt.key) ? null : (evt.key === "Backspace") ? null : evt.preventDefault()}
                onChange={(event) => {
                  if (/^-?\d+$/.test(event.target.value)) {
                    setMaxRows(parseInt(event.target.value));
                  }
                }} />
            </div>
          </div>
        </>
      )}
    </div >
  );

  function SaveRingData(ring: GaugeData) {
    setRings(
      _.map(rings, (currentRing: GaugeData) => {
        if (currentRing.ringID === ring.ringID) {
          return ring;
        } else {
          return currentRing;
        }
      })
    );
  }

  const itemList = (rings ?? [getBlankRing()]).map((ring, index) => (
    tableType === "singleDevice" ? (
      <NewSingleDeviceTable
        index={index}
        ring={ring}
        key={index}
        remove={rings ? rings.length !== 1 : false}
        removeRing={onRemoveRing}
        chartScale={chartScale}
        setChartScale={setChartScale}
        saveRingData={SaveRingData}
      />
    ) : (
      <NewComparisonTableMenu
        index={index}
        ring={ring}
        key={index}
        remove={rings ? rings.length !== 1 : false}
        removeRing={onRemoveRing}
        chartScale={chartScale}
        setChartScale={setChartScale}
        saveRingData={SaveRingData}
      />
    ))
  );

  var newButton = {
    text: tableType === "singleDevice" ? "Add new Column" : "Add new Row",
    onClick: () => addNewRing(getBlankRing()),
    icon: GetIcon(),
  }

  function onDelete() {
    remove(id);
    setShowChartEdit(false);
  }

  const saveChartSettings = () => {
    var newRings = rings.map((ring) => {
      var newRing: GaugeData = {
        ...ring,
        max: 0,
        min: 0,
      };
      return newRing;
    });

    setRings(newRings);

    var newChartScale: ChartScale = {
      ...chartScale,
      y_axis: {
        ...chartScale.y_axis,
        min_auto: tableType === "singleDevice" ? true : false,
        max: tableType === "singleDevice" ? maxRows : chartScale.y_axis.max,
      },
    };
    setChartScale(newChartScale);
    setLoading(true)
    setTimeout(function () {
      setShowChartEdit(false);
      setLoading(false)
    }, 300);
    updateChart({
      i: id,
      type: "table",
      title: { en: title },
      chartData: newRings,
      chartScale: newChartScale,
    });
  };

  const EditChartObjects: EditChartProps = ({
    editMapType: "Edit Table",
    settingsBox: settingsBox,
    itemList: itemList,
    newButton: newButton,
    onDelete: onDelete,
    onSave: saveChartSettings,
    loading: loading,
  })

  return (
    <>
      <EditChart {...EditChartObjects} />
    </>
  );
};

export default EditTable;
