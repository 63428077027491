import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import { ChartScale, ChartType, GaugeData, Language, Scale } from "..";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { DataType, selectData } from "../../../../features/data/dataSlice";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
} from "../../../../dictionary/ChartsText";
import { LanguageCheck } from "../../../../utils/LanguageCheck";
import { selectLanguage } from "../../../../features/user/userSlice";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import Spinner from "../../../Spinner";

type Props = {
  ChartData: ChartType;
  FetchSuccess: boolean;
  FetchError: boolean;
};

function Value({ ChartData, FetchSuccess, FetchError }: Props) {

  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  const defaultYAxis: Scale = { min_auto: true, max_auto: true, max: 0, min: 0 };

  const [dataValue, setDataValue] = useState<number>();
  const [dataUnit, setDataUnit] = useState<string>();
  const [readingTime, setReadingTime] = useState<string>();

  var [boxHeight, setBoxHeight] = useState<number>(0);
  var [textHeight, setTextHeight] = useState<number>(0);

  const [chartData, setChartData] = useState<GaugeData[]>(ChartData.chartData ?? []);
  const [chartScale, setChartScale] = useState<ChartScale>(
    (ChartData.chartScale === undefined || (ChartData.chartScale.y_axis === defaultYAxis)) ?
      { y_axis: { min_auto: true, max_auto: true, max: 10, min: 0 } }
      : ChartData.chartScale
  );

  const nodeData = useAppSelector(selectData);

  function getBlankRing() {
    return {
      label: "",
      colour: "#000000",
      phenomena: "new",
      ringID: uuidv4(),
      deviceID: "new",
      unit: "",
      min: 0,
      max: 10,
    };
  }

  function CatchBlankData() {
    if (chartData.length === 0) {
      setChartData(() => [
        ...ChartData.chartData,
        getBlankRing(),
      ]);
    } else {
      setChartData(ChartData.chartData ?? []);
    }
  }

  function GetDataAndReadingTime() {
    if (nodeData) {
      if (chartData !== undefined && chartData.length > 0) {
        if (
          nodeData?.data?.[chartData[0].deviceID as keyof DataType] !== undefined
        ) {
          var deviceNodeData = nodeData.data[chartData[0].deviceID as keyof DataType];
          let latestValue = deviceNodeData[deviceNodeData.length - 1]
          if (chartData[0].phenomena !== undefined && chartData[0].elemID !== undefined) {
            if (latestValue.data_points?.[chartData[0].phenomena]?.[chartData[0].elemID] !== undefined) {
              setDataValue(
                latestValue.data_points[chartData[0].phenomena][chartData[0].elemID]
              );
            }
          }
          setDataUnit(chartData[0].unit)
          setReadingTime(
            moment(new Date(latestValue.ts))
              .local()
              .format(
                "[" +
                language.charts.gauge.timeFormat.on +
                "] DD/MM/YYYY [" +
                language.charts.gauge.timeFormat.at +
                "] HH:mm:ss"
              )
          );
        }
      }
    }
  }

  function GetChartScaleFromParams() {
    if (ChartData.chartScale) {
      setChartScale(ChartData.chartScale);
    }
  }

  useEffect(() => {
    CatchBlankData();
  }, [ChartData.chartData]);

  useEffect(() => {
    GetDataAndReadingTime();
  }, [ChartData, chartData, nodeData]);

  useEffect(() => {
    GetChartScaleFromParams();
  }, [ChartData.chartScale]);

  function dataUnitFilter(dataUnit: string | undefined) {
    switch (dataUnit) {
      case undefined:
        return "";
      case "℃":
        return "°C";
      case "V":
        return "";
      case "mm/tip":
        return "mm";
      default:
        return dataUnit;
    }
  }

  return (
    <>
      {chartData && (
        chartData[0] && (
          (!(chartData[0].deviceID && chartData[0].deviceID !== "new")) ? (
            <div className="w-full h-full flex items-center justify-center">
              Select a Device And Parameter to show data
            </div>
          ) : (
            (!(chartData[0].phenomena && chartData[0].elemID)) ? (
              <div className="w-full h-full flex items-center justify-center">
                Select a Parameter to show data
              </div>
            ) : (
              (!(dataValue || dataValue === 0)) ? (
                <div className="w-full h-full flex items-center justify-center">
                  <Spinner colour="fill-blue-600" />
                </div>
              ) : (
                <div className="h-full" ref={(ref) => { if (ref) { setBoxHeight(ref.getBoundingClientRect().height); } }}>
                  <div className="truncate" ref={(ref) => { if (ref) { setTextHeight(ref.getBoundingClientRect().height); } }}>
                    <p className="text-center font-semibold">{ChartData.title.en}</p>
                    <p className="text-center font-semibold">{chartScale.y_axis.max_auto === true && readingTime}</p>
                  </div>
                  <div key={textHeight} className={`w-full flex overflow-clip items-center justify-center bg-transparent`}>
                    <svg className="w-full h-full" height={boxHeight - textHeight}>
                      <text className="w-full h-full" textLength="80%" lengthAdjust="spacingAndGlyphs" fontFamily="sans-serif" fontSize={boxHeight - textHeight} fontStyle="normal" fontWeight="700" textAnchor="middle" dominantBaseline="middle" >
                        <tspan x="50%" y="60%" fill={chartData[0].colour} >
                          {!isNaN(Number(dataValue)) && Math.round((Number(dataValue) * 10)) / 10}
                          {(chartScale.y_axis.min_auto === true && dataUnitFilter(dataUnit))}
                        </tspan>
                      </text>
                    </svg>
                  </div>
                </div>
              )
            )
          )
        )
      )}
    </>
  );
}

export default Value;
