import { useEffect, useRef, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { NavHeader } from "../components/nav/NavHeader";
import { SideBar } from "../components/nav/SideBar";
import {
  clearUser,
  selectLanguage,
  selectUser,
  setTermsAndConditionsUpdated,
} from "../features/user/userSlice";
import {
  useGetUserInfoQuery,
  useUpdateUserSettingsMutation,
} from "../services/userAPI";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectAuth } from "../features/auth/authSlice";
import useWindowDimensions, { mdBreakPoint } from "../utils/ScreenDimensions";
import { doLogout, invalidateAllCaches } from "../app/store";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetDashboardsQuery } from "../services/dashboardAPI";
import { useGetDevicesQuery } from "../services/deviceAPI";
import { useGetGroupsQuery } from "../services/groupAPI";
import { handleError } from "../utils/ErrorHandling";
import TermsAndConditions from "../components/TermsAndConditions";
import AcceptModal from "../components/modals/AcceptModal";
import modal from "flowbite/lib/esm/components/modal";
import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
} from "../dictionary/InfoHelpText";
import { LanguageCheck } from "../utils/LanguageCheck";
import { useAcceptTermsAndConditionsMutation } from "../services/authAPI";

function Home() {
  const { width } = useWindowDimensions();
  const [sideBarIsOpen, setIsSidebarOpen] = useState(width > mdBreakPoint);
  const user = useAppSelector(selectUser);
  const auth = useAppSelector(selectAuth);
  const location = useLocation();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const sidebarIconRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  //If the user info hasn't been loaded, load the user info
  const { error: getUserInfoError, isError: isGetUserInfoError } =
    useGetUserInfoQuery(auth.token ? "" : skipToken);
  const { error: getDashboardInfoError, isError: isGetDashboardInfoError } =
    useGetDashboardsQuery("", { pollingInterval: 300000 });
  const { error: getDeviceInfoError, isError: isGetDeviceInfoError } =
    useGetDevicesQuery("", { pollingInterval: 300000 });
  const { error: getGroupInfoError, isError: isGetUserGroupError } =
    useGetGroupsQuery("", { pollingInterval: 300000 });
  const [
    acceptTermsAndConditions,
    {
      isError: isAcceptTermsAndConditionsError,
      error: acceptTermsAndConditionsError,
      isSuccess: isAcceptTermsAndConditionsSuccess,
      data: AcceptTermsAndConditionsData,
    },
  ] = useAcceptTermsAndConditionsMutation();
  const [tsCsModal, setTsCsModal] = useState(user.latest_terms_accepted);

  useEffect(() => {
    if (user) {
      setTsCsModal(!user.latest_terms_accepted); //open the modal if the user has not accepted the latest terms and conditions
    }
  }, [user]);

  useEffect(() => {
    if (isAcceptTermsAndConditionsSuccess) {
      window.location.reload(); //Not really want to do this but it is the only way i can see to prevent the TsCs modal from popping up again
    }
  }, [isAcceptTermsAndConditionsSuccess]);

  useEffect(() => {
    if (isAcceptTermsAndConditionsError) {
      handleError(acceptTermsAndConditionsError);
    }
  }, [isAcceptTermsAndConditionsError]);

  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(
    LanguageCheck(
      English,
      French,
      German,
      Dutch,
      Spanish,
      Italian,
      Romanian,
      Portuguese,
      stateLang
    )
  );
  useEffect(() => {
    setLanguage(
      LanguageCheck(
        English,
        French,
        German,
        Dutch,
        Spanish,
        Italian,
        Romanian,
        Portuguese,
        stateLang
      )
    );
  }, [stateLang]);

  const changeSidebarIfSmallScreen = (value: boolean) => {
    if (width < mdBreakPoint) {
      setIsSidebarOpen(value);
    }
  };

  if (
    localStorage.getItem("redirectURL") != null &&
    localStorage.getItem("redirectURL") != undefined
  ) {
    if (
      localStorage.getItem("redirectURL") ===
      location.pathname + location.search
    ) {
      localStorage.removeItem("redirectURL");
    }
  }

  useEffect(() => {
    //If there is an error when getting dashboard info, the error is handled appropriately
    if (isGetDashboardInfoError) {
      handleError(getDashboardInfoError);
    }
  }, [getDashboardInfoError]);

  useEffect(() => {
    //If there is an error when getting device info, the error is handled appropriately
    if (isGetDeviceInfoError) {
      handleError(getDeviceInfoError);
    }
  }, [getDeviceInfoError]);

  useEffect(() => {
    //If there is an error when getting group info, the error is handled appropriately
    if (isGetUserGroupError) {
      handleError(getGroupInfoError);
    }
  }, [getGroupInfoError]);

  //When the sidebar is opened or closed a resize event is triggered to trigger a resize of any components in the outlet
  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, [sideBarIsOpen]);
  //When the user clicks outside the sidebar it will close if the screen width is less than mdBreakPoint
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        sidebarRef?.current !== null &&
        sidebarIconRef?.current !== null &&
        width < mdBreakPoint
      ) {
        if (
          !sidebarRef.current.contains(event.target) &&
          !sidebarIconRef.current.contains(event.target)
        ) {
          setIsSidebarOpen(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [sidebarRef, sidebarIconRef, width]);

  //Sidebar, header, and main outlet (Container for all routes)
  return (
    <>
      {tsCsModal ? (
        <AcceptModal
          onAccept={() => {
            acceptTermsAndConditions({ accept: true, type: "general" });
          }}
          onCancel={() => {
            setTsCsModal(false);
            doLogout();
          }}
          showCancel={true}
          Title={
            "The terms and conditions have changed. Please re-read them and confirm your acceptance."
          }
          Body={language.help.termsAndConditions.body}
          AcceptButton={"Accept"}
          CancelButton={"Logout"}
        />
      ) : null}
      <div className="flex flex-col h-screen py-4 px-3 w-full bg-rpr-blue select-none">
        <div className="max-h-14 z-10">
          <NavHeader
            setIsSideBarOpen={setIsSidebarOpen}
            sideBarIsOpen={sideBarIsOpen}
            ref={sidebarIconRef}
          />
        </div>
        <div className="flex flex-grow flex-shrink h-main-body">
          <div
            className={`basis-64 z-10 md:static absolute mr-6 top-0 bottom-0 md:mb-0 md:mt-4 mb-4 mt-22 ${
              sideBarIsOpen ? "" : "hidden"
            }`}
          >
            <SideBar
              setIsSideBarOpen={changeSidebarIfSmallScreen}
              sideBarIsOpen={sideBarIsOpen}
              ref={sidebarRef}
            />
          </div>
          <div className="mt-4 w-full overflow-y-auto overflow-x-hidden scrollbar-thin">
            <Outlet />
          </div>
        </div>
        <div
          className={`h-full w-full absolute top-0 left-0 z-[1]  ${
            sideBarIsOpen && width < mdBreakPoint ? "" : "invisible"
          }`}
          onClick={() => {
            setIsSidebarOpen(false);
          }}
        />
      </div>
    </>
  );
}

export default Home;
