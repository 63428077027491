import ReactECharts from "echarts-for-react";
import _ from "lodash";
import { ChartType, LineChartData } from "..";
import { useAppSelector } from "../../../../app/hooks";
import { DataType, selectData } from "../../../../features/data/dataSlice";
import { UplinkData } from "../../../../services/dataAPI";
import { useEffect, useRef, useState } from "react";
const colour: string = "#000099";

type Props = {
  ChartData: ChartType;
  FetchSuccess: boolean;
  FetchError: boolean;
};

type CalendarDataType = [{
  xPos: number;
  yPos: number;
  pointData: number;
  date: string;
  readings: number;
}];

type DisplayDataType = [number, number, number]

function Calendar({ ChartData, FetchSuccess, FetchError }: Props) {


  const hours = [
    '00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
    '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
    '20', '21', '22', '23'
  ];

  const [lines, setLines] = useState<LineChartData[]>(ChartData.chartData ?? []);
  const [lowColour, setLowColour] = useState("f6efa6")
  const [highColour, setHighColour] = useState("bf444c")
  const [lowestData, setLowestData] = useState(0)
  const [highestData, setHighestData] = useState(0)
  const [readingCounts, setReadingCounts] = useState<{ [key: string]: number }>({})
  const [calendarDates, setCalendarDates] = useState<string[]>([])
  const [boxWidth, setBoxWidth] = useState(0);
  const [dataAvailable, setDataAvailable] = useState(false);

  const nodeData = useAppSelector(selectData)
  const chartRef = useRef<ReactECharts>(null);

  const getCalendarData = (
    phenomena: string | undefined,
    elemID: string | undefined,
    deviceid: string | undefined
  ) => {
    if (nodeData.data[deviceid as keyof DataType] !== undefined) {
      /* setDataAvailable(true); */
      var arr = _.values(nodeData.data[deviceid as keyof DataType]).slice(-4000);
      var dateInfo: { hours: number; days: number; months: number; years: number } = { hours: 0, days: 0, months: 0, years: 0 }
      var hourCount = 0
      var hourTotal = 0
      var uniqueDays = 0
      var data = arr.map((element: UplinkData, index: number) => {
        if (typeof element.ts === "string" && phenomena !== undefined) {
          const date = new Date(element.ts);
          var newData: CalendarDataType = [{ xPos: 0, yPos: 0, pointData: 0, date: "", readings: 0 }];
          if (
            element.data_points !== undefined &&
            element?.data_points !== null
          ) {
            if (
              element?.data_points[phenomena] !== undefined &&
              element?.data_points[phenomena] !== null
            ) {
              if (elemID !== undefined) {
                if (
                  element?.data_points[phenomena][elemID] !== undefined &&
                  element?.data_points[phenomena][elemID] !== null &&
                  !isNaN(Number(element?.data_points[phenomena][elemID]))
                ) {
                  if (index === 0) {
                    dateInfo = { hours: date.getHours(), days: date.getDate(), months: date.getMonth(), years: date.getFullYear() }
                  }
                  if (_.isEqual(dateInfo, { hours: date.getHours(), days: date.getDate(), months: date.getMonth(), years: date.getFullYear() })) {
                    hourCount++
                    hourTotal += Number(element?.data_points[phenomena][elemID])
                  } else {
                    newData = [{
                      xPos: dateInfo.hours,
                      yPos: uniqueDays,
                      pointData: (ChartData.chartScale?.y_axis.max_auto ? _.round((hourTotal / hourCount), 1) : _.round(hourTotal)),
                      date: dateInfo.days + "/" + (dateInfo.months + 1) + "/" + dateInfo.years,
                      readings: hourCount
                    }]
                    hourCount = 1
                    hourTotal = Number(element?.data_points[phenomena][elemID])
                  }
                  if (date.getDate() !== dateInfo.days || date.getMonth() !== dateInfo.months || date.getFullYear() !== dateInfo.years) {
                    uniqueDays++
                  }
                  dateInfo = {
                    hours: date.getHours(),
                    days: date.getDate(),
                    months: date.getMonth(),
                    years: date.getFullYear()
                  }
                }
              }
            }
            return newData;
          }
        }
      });
      data.push([{
        xPos: dateInfo.hours,
        yPos: uniqueDays,
        pointData: (ChartData.chartScale?.y_axis.max_auto ? _.round((hourTotal / hourCount), 1) : _.round(hourTotal)),
        date: dateInfo.days + "/" + (dateInfo.months + 1) + "/" + dateInfo.years,
        readings: hourCount,
      }])
      return data.filter((n) => !_.isEqual(n, [{ xPos: 0, yPos: 0, pointData: 0, date: "", readings: 0 }]));
    }
  };

  const getDisplayData = (
    phenomena: string | undefined,
    elemID: string | undefined,
    deviceid: string | undefined
  ) => {
    var calData: (CalendarDataType | undefined)[] | undefined = []
    var displayData: (DisplayDataType | undefined)[] = []
    var datesArray: string[] = []
    var pointDataArray: number[] = []
    var tempReadingCounts: { [key: string]: number } = {}

    calData = (getCalendarData(phenomena, elemID, deviceid))
    if (calData !== undefined) {
      displayData = calData.map(function (calDataPoints: CalendarDataType | undefined) {
        if (calDataPoints !== undefined) {
          var calDataPoint = calDataPoints[0]
          pointDataArray.push(calDataPoint["pointData"])
          datesArray.push(calDataPoint["date"])
          tempReadingCounts[`${calDataPoint["xPos"]}, ${calDataPoint["yPos"]}`] = calDataPoint["readings"]
          return [calDataPoint["xPos"], calDataPoint["yPos"], calDataPoint["pointData"]];
        }
      });
    }
    datesArray = _.uniq(datesArray)
    if (_.min(pointDataArray) !== undefined && _.max(pointDataArray) !== undefined) {
      setLowestData(_.floor(_.min(pointDataArray)!))
      setHighestData(_.ceil(_.max(pointDataArray)!))
    }
    setCalendarDates(datesArray)
    setReadingCounts(tempReadingCounts)
    return displayData
  }

  function GetLinesAndSetInitialLoading() {
    setLines(ChartData.chartData ?? []);
    if (chartRef && chartRef.current) {
      chartRef.current?.getEchartsInstance().showLoading("default", {
        text: (lines[0] ? lines[0].deviceID !== "new" : false)
          ? "language.charts.loadingText.retrievingData" + "..."
          : "language.charts.loadingText.editThisChart",
        color: "#000099",
        textColor: "#000",
        maskColor: "rgba(255, 255, 255, 0.8)",
        showSpinner: (lines[0] ? lines[0].deviceID !== "new" : false)
          ? true
          : false,
      });
    }
  }

  function GetHighAndLowColourFromParams() {
    if (ChartData.chartData !== undefined && ChartData.chartData !== null) {
      if (lines[0] !== undefined) {
        if (lines[0].colour !== undefined) {
          setLowColour(lines[0].colour.split(",")[0])
          setHighColour(lines[0].colour.split(",")[1])
        }
      }
    }
  }

  function DataAvailableLoading() {
    if (chartRef && chartRef.current && nodeData) {
      if (!dataAvailable) {
        chartRef.current?.getEchartsInstance().showLoading("default", {
          text: (lines[0] ? lines[0].deviceID !== "new" : false)
            ? "retrieving data" + "..."
            : "edit this chart to show data",
          color: "#000099",
          textColor: "#000",
          maskColor: "rgba(255, 255, 255, 0.8)",
          showSpinner: (lines[0] ? lines[0].deviceID !== "new" : false)
            ? true
            : false,
        });
      }
    }
  }

  function SetSeriesAndDataFetchErrorLoading() {
    if (chartRef && chartRef.current && nodeData) {
      const series = [
        {
          name: '',
          type: 'heatmap',
          data: getDisplayData(lines[0]?.phenomena, lines[0]?.elemID, lines[0]?.deviceID),
          label: {
            show: true
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
      chartRef.current?.getEchartsInstance().setOption({
        ...chartRef.current?.getEchartsInstance().getOption(), series: series,
      }, true);
      if (nodeData.data[lines[0].deviceID as keyof DataType] !== undefined) {
        chartRef.current?.getEchartsInstance().hideLoading();
      } else {
        if (FetchSuccess && lines[0].deviceID !== "new") {
          chartRef.current?.getEchartsInstance().showLoading("default", {
            text: "language.charts.loadingText.noDataAvailable",
            textColor: "#000",
            maskColor: "rgba(255, 255, 255, 0.8)",
            showSpinner: false,
          });
        } else if (FetchError && lines[0].deviceID !== "new") {
          chartRef.current?.getEchartsInstance().showLoading("default", {
            text: "Error retrieving data",
            textColor: "#000",
            maskColor: "rgba(255, 255, 255, 0.8)",
            showSpinner: false,
          });
        }
      }
    }
  }

  function SetVisualMap() {
    if (chartRef && chartRef.current && nodeData) {
      const visualMap = {
        min: lowestData,
        max: highestData,
        calculable: true,
        orient: 'horizontal',
        left: 'center',
        bottom: 1,
        inRange: {
          color: [lowColour, highColour]
        },
      }
      chartRef.current?.getEchartsInstance().setOption({
        ...chartRef.current?.getEchartsInstance().getOption(), visualMap: visualMap,
      }, true);
    }
  }

  useEffect(() => {
    GetLinesAndSetInitialLoading();
  }, [ChartData.chartData]);

  useEffect(() => {
    GetHighAndLowColourFromParams()
  }, [ChartData.chartData])

  useEffect(() => {
    DataAvailableLoading()
  }, [dataAvailable, chartRef, nodeData, ChartData.chartData]);

  useEffect(() => {
    SetSeriesAndDataFetchErrorLoading()
  }, [nodeData, ChartData.chartData, chartRef, lines, dataAvailable]);

  useEffect(() => {
    SetVisualMap()
  }, [nodeData, ChartData.chartData, chartRef, lines, dataAvailable, lowestData, highestData, lowColour, highColour]);

  const options = {
    title: {
      top: 1,
      left: "center",
      text: ChartData.title.en ?? "",
    },
    tooltip: {
      position: 'inside',
      formatter: function (params: any) {
        return `${calendarDates[params.value[1]]} ${params.value[0]}:00 - ${params.value[0] + 1}:00 <br />
          ${lines[0].label} <br />
          ${ChartData.chartScale?.y_axis.max_auto ? "Average: " : "Total: "} ${params.value[2]} <br />
          (${readingCounts[`${params.value[0]}, ${params.value[1]}`]} readings)`
      }
    },
    color:
    {
      type: 'linear',
      x: 0,
      y: 0,
      x2: 0,
      y2: 1,
      colorStops: [{
        offset: 0, color: 'red'
      }, {
        offset: 1, color: 'blue'
      }],
      global: false
    },
    grid: {
      height: '80%',
      width: '97%',
      top: 25,
      left: 10,
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: hours,
      splitArea: {
        show: true
      }
    },
    yAxis: {
      type: 'category',
      data: calendarDates,
      splitArea: {
        show: true
      }
    },
    visualMap: {
      min: lowestData,
      max: highestData,
      calculable: true,
      orient: 'horizontal',
      left: 'center',
      bottom: 1,
      inRange: {
        color: [lowColour, highColour]
      },
    },
  }

  return (
    <div className="w-full h-full overflow-x-scroll scrollbar-none" ref={(ref) => { if (ref) { setBoxWidth(ref.getBoundingClientRect().width) } }}>
      {!lines[0] ? (
        <div>
          Edit this panel to show data
        </div>
      ) : (
        lines[0].deviceID === "new" ? (
          <div className="w-full h-full flex items-center justify-center">
            Select a Device to display data
          </div>
        ) : (
          lines[0].phenomena === "new" ? (
            <div className="w-full h-full flex items-center justify-center">
              Select a Parameter to display data
            </div>
          ) : (
            <div className="h-full w-fit">
              <ReactECharts
                style={{ height: "100%", width: (boxWidth > 860 ? boxWidth : 860) }}
                opts={{ renderer: "svg" }}
                option={options}
                ref={chartRef}
              />
            </div>
          )
        )
      )}
    </div>
  )
}

export default Calendar;
