import TermsAndConditions from "../components/TermsAndConditions";

export type LanguageText = {
  help: {
    termsAndConditions: {
      title: string;
      body: React.ReactNode;
      accept: string;
      cancel: string;
      close: string;
    };
    titles: {
      help: string;
      contactUs: string;
    };
  };
  info: {
    quickAccess: string;
    dashboards: string;
    devices: string;
    noGroups: {
      title: string;
      subtitle: string;
      createBox: {
        title: string;
        description: string;
      };
      joinBox: {
        title: string;
        description: string;
      };
    };
    hasGroup: {
      title: string;
      noDashboards: {
        createBox: {
          title: string;
          description: string;
        };
      };
      noDevices: {
        claimBox: {
          title: string;
          description: string;
        };
      };
    };
  };
};
export const English: LanguageText = {
  help: {
    termsAndConditions: {
      title: "Terms and Conditions",
      body: (
        <>
          <TermsAndConditions language={"english"} />
        </>
      ),
      accept: "Accept",
      cancel: "Cancel",
      close: "Close",
    },
    titles: {
      help: "Help",
      contactUs: "Contact Us",
    },
  },
  info: {
    quickAccess: "Quick Access",
    dashboards: "Dashboards",
    devices: "Devices",
    noGroups: {
      title: "Welcome to rpr-IoT",
      subtitle: "Create or join a group to get started",
      createBox: {
        title: "Create",
        description:
          "Create a group to claim devices, create dashboards and invite others to join.",
      },
      joinBox: {
        title: "Join",
        description:
          "You'll need to be invited to a group to join one. If you have been invited, click the link in the email you recieved.",
      },
    },
    hasGroup: {
      title: "rpr-IoT Quick Setup",
      noDashboards: {
        createBox: {
          title: "Create",
          description:
            "Create a dashboard to start showing data from claimed devices.",
        },
      },
      noDevices: {
        claimBox: {
          title: "Claim",
          description:
            "Claim a device to your group. You'll need the device ID and claim code.",
        },
      },
    },
  },
};
export const French: LanguageText = {
  help: {
    termsAndConditions: {
      title: "Modalités et conditions",
      body: (
        <>
          <TermsAndConditions language={"french"} />
        </>
      ),
      accept: "Accepter",
      cancel: "Annuler",
      close: "Fermer",
    },
    titles: {
      help: "Aide",
      contactUs: "Contactez nous",
    },
  },
  info: {
    quickAccess: "Accès rapide",
    dashboards: "Tableaux de bord",
    devices: "Dispositifs",
    noGroups: {
      title: "Bienvenue sur rpr-IoT",
      subtitle: "Créez ou rejoignez un groupe pour commencer",
      createBox: {
        title: "Créer",
        description:
          "Créez un groupe pour revendiquer des appareils, créer des tableaux de bord et inviter d'autres personnes à vous rejoindre.",
      },
      joinBox: {
        title: "Rejoindre",
        description:
          "Vous devrez être invité à un groupe pour vous joindre. Si vous avez été invité, cliquez sur le lien dans l'e-mail que vous avez reçu.",
      },
    },
    hasGroup: {
      title: "Configuration rapide de rpr-IoT",
      noDashboards: {
        createBox: {
          title: "Créer",
          description:
            "Créez un tableau de bord pour commencer à afficher des données provenant d'appareils revendiqués.",
        },
      },
      noDevices: {
        claimBox: {
          title: "Revendiquer",
          description:
            "Revendiquez un appareil à votre groupe. Vous aurez besoin de l'identifiant de l'appareil et du code de revendication.",
        },
      },
    },
  },
};
export const German: LanguageText = {
  help: {
    termsAndConditions: {
      title: "Bedingungen und Konditionen",
      body: (
        <>
          <TermsAndConditions language={"german"} />
        </>
      ),
      accept: "Akzeptieren",
      cancel: "Abbrechen",
      close: "Schließen",
    },
    titles: {
      help: "Hilfe",
      contactUs: "Kontaktiere uns",
    },
  },
  info: {
    quickAccess: "Schneller Zugriff",
    dashboards: "Dashboards",
    devices: "Geräte",
    noGroups: {
      title: "Willkommen bei rpr-IoT",
      subtitle: "Erstellen oder beitreten Sie einer Gruppe, um loszulegen",
      createBox: {
        title: "Erstellen",
        description:
          "Erstellen Sie eine Gruppe, um Geräte zu beanspruchen, Dashboards zu erstellen und andere einzuladen, sich anzuschließen.",
      },
      joinBox: {
        title: "Beitreten",
        description:
          "Sie müssen zu einer Gruppe eingeladen werden, um einer beizutreten. Wenn Sie eingeladen wurden, klicken Sie auf den Link in der E-Mail, die Sie erhalten haben.",
      },
    },
    hasGroup: {
      title: "Schnelle Einrichtung von rpr-IoT",
      noDashboards: {
        createBox: {
          title: "Erstellen",
          description:
            "Erstellen Sie ein Dashboard, um mit der Anzeige von Daten von beanspruchten Geräten zu beginnen.",
        },
      },
      noDevices: {
        claimBox: {
          title: "Beanspruchen",
          description:
            "Beanspruchen Sie ein Gerät für Ihre Gruppe. Sie benötigen die Geräte-ID und den Anspruchscode.",
        },
      },
    },
  },
};
export const Dutch: LanguageText = {
  help: {
    termsAndConditions: {
      title: "Voorwaarden",
      body: (
        <>
          <TermsAndConditions language={"dutch"} />
        </>
      ),
      accept: "Accepteren",
      cancel: "Annuleren",
      close: "Sluiten",
    },
    titles: {
      help: "Help",
      contactUs: "Neem contact op met ons",
    },
  },
  info: {
    quickAccess: "Snelle toegang",
    dashboards: "Dashboards",
    devices: "Apparaten",
    noGroups: {
      title: "Welkom bij rpr-IoT",
      subtitle: "Maak of neem deel aan een groep om te beginnen",
      createBox: {
        title: "Maken",
        description:
          "Maak een groep om apparaten te claimen, dashboards te maken en anderen uit te nodigen om deel te nemen.",
      },
      joinBox: {
        title: "Deelnemen",
        description:
          "U moet worden uitgenodigd voor een groep om deel te nemen. Als u bent uitgenodigd, klikt u op de link in de e-mail die u hebt ontvangen.",
      },
    },
    hasGroup: {
      title: "Snelle installatie van rpr-IoT",
      noDashboards: {
        createBox: {
          title: "Maken",
          description:
            "Maak een dashboard om te beginnen met het weergeven van gegevens van geclaimde apparaten.",
        },
      },
      noDevices: {
        claimBox: {
          title: "Claimen",
          description:
            "Claim een apparaat voor uw groep. U hebt de apparaat-ID en de claimcode nodig.",
        },
      },
    },
  },
};
export const Spanish: LanguageText = {
  help: {
    termsAndConditions: {
      title: "Condiciones generales",
      body: (
        <>
          <TermsAndConditions language={"spanish"} />
        </>
      ),
      accept: "Aceptar",
      cancel: "Cancelar",
      close: "Cerrar",
    },
    titles: {
      help: "Ayuda",
      contactUs: "Contáctenos",
    },
  },
  info: {
    quickAccess: "Acceso rápido",
    dashboards: "Cuadros de mando",
    devices: "Dispositivos",
    noGroups: {
      title: "Bienvenido a rpr-IoT",
      subtitle: "Crea o únete a un grupo para comenzar",
      createBox: {
        title: "Crear",
        description:
          "Crea un grupo para reclamar dispositivos, crear paneles y invitar a otras personas a unirse.",
      },
      joinBox: {
        title: "Unirse",
        description:
          "Deberás ser invitado a un grupo para unirte. Si has sido invitado, haz clic en el enlace en el correo electrónico que recibiste.",
      },
    },
    hasGroup: {
      title: "Configuración rápida de rpr-IoT",
      noDashboards: {
        createBox: {
          title: "Crear",
          description:
            "Crea un panel para comenzar a mostrar datos de dispositivos reclamados.",
        },
      },
      noDevices: {
        claimBox: {
          title: "Reclamar",
          description:
            "Reclama un dispositivo para tu grupo. Necesitarás el ID del dispositivo y el código de reclamación.",
        },
      },
    },
  },
};
export const Italian: LanguageText = {
  help: {
    termsAndConditions: {
      title: "Termini e condizioni",
      body: (
        <>
          <TermsAndConditions language={"italian"} />
        </>
      ),
      accept: "Accetta",
      cancel: "Annulla",
      close: "Chiudere",
    },
    titles: {
      help: "Aiuto",
      contactUs: "Contattaci",
    },
  },
  info: {
    quickAccess: "Accesso rapido",
    dashboards: "Cruscotti",
    devices: "Dispositivi",
    noGroups: {
      title: "Benvenuto in rpr-IoT",
      subtitle: "Crea o unisciti a un gruppo per iniziare",
      createBox: {
        title: "Creare",
        description:
          "Crea un gruppo per reclamare dispositivi, creare dashboard e invitare altre persone a unirsi.",
      },
      joinBox: {
        title: "Unirsi",
        description:
          "Devi essere invitato a un gruppo per unirti. Se sei stato invitato, fai clic sul link nella e-mail che hai ricevuto.",
      },
    },
    hasGroup: {
      title: "Configurazione rapida di rpr-IoT",
      noDashboards: {
        createBox: {
          title: "Creare",
          description:
            "Crea un dashboard per iniziare a visualizzare i dati dei dispositivi reclamati.",
        },
      },
      noDevices: {
        claimBox: {
          title: "Reclamare",
          description:
            "Reclama un dispositivo per il tuo gruppo. Avrai bisogno dell'ID del dispositivo e del codice di reclamo.",
        },
      },
    },
  },
};
export const Romanian: LanguageText = {
  help: {
    termsAndConditions: {
      title: "Termeni și condiții",
      body: (
        <>
          <TermsAndConditions language={"romanian"} />
        </>
      ),
      accept: "Accept",
      cancel: "Anulează",
      close: "Închide",
    },
    titles: {
      help: "Ajutor",
      contactUs: "Contactează-ne",
    },
  },
  info: {
    quickAccess: "Acces rapid",
    dashboards: "Tablouri de bord",
    devices: "Dispozitive",
    noGroups: {
      title: "Bine ați venit la rpr-IoT",
      subtitle: "Creați sau alăturați-vă unui grup pentru a începe",
      createBox: {
        title: "Crea",
        description:
          "Creați un grup pentru a revendica dispozitive, creați tablouri de bord și invitați alte persoane să se alăture.",
      },
      joinBox: {
        title: "Alăturați-vă",
        description:
          "Trebuie să fiți invitat într-un grup pentru a vă alătura. Dacă ați fost invitat, faceți clic pe link-ul din e-mailul pe care l-ați primit.",
      },
    },
    hasGroup: {
      title: "Configurare rapidă a rpr-IoT",
      noDashboards: {
        createBox: {
          title: "Crea",
          description:
            "Creați un tablou de bord pentru a începe să afișați datele dispozitivelor reclamate.",
        },
      },
      noDevices: {
        claimBox: {
          title: "Revendică",
          description:
            "Revendicați un dispozitiv pentru grupul dvs. Aveți nevoie de ID-ul dispozitivului și de codul de revendicare.",
        },
      },
    },
  },
};
export const Portuguese: LanguageText = {
  help: {
    termsAndConditions: {
      title: "Termos e Condições",
      body: (
        <>
          <TermsAndConditions language={"portuguese"} />
        </>
      ),
      accept: "Aceitar",
      cancel: "Cancelar",
      close: "Fechar",
    },
    titles: {
      help: "Ajuda",
      contactUs: "Contate-Nos",
    },
  },
  info: {
    quickAccess: "Acesso Rápido",
    dashboards: "Painéis de instrumentos",
    devices: "Dispositivos",
    noGroups: {
      title: "Bem-vindo ao rpr-IoT",
      subtitle: "Crie ou junte-se a um grupo para começar",
      createBox: {
        title: "Criar",
        description:
          "Crie um grupo para reclamar dispositivos, criar painéis e convidar outras pessoas a se juntar.",
      },
      joinBox: {
        title: "Junte-se",
        description:
          "Você deve ser convidado a um grupo para se juntar. Se você foi convidado, clique no link no e-mail que você recebeu.",
      },
    },
    hasGroup: {
      title: "Configuração rápida do rpr-IoT",
      noDashboards: {
        createBox: {
          title: "Criar",
          description:
            "Crie um painel para começar a exibir dados de dispositivos reclamados.",
        },
      },
      noDevices: {
        claimBox: {
          title: "Reclamar",
          description:
            "Reivindique um dispositivo para o seu grupo. Você precisará do ID do dispositivo e do código de reivindicação.",
        },
      },
    },
  },
};
