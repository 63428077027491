import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { updateDeviceData } from "../features/data/dataSlice";
import moment from "moment";
import { updateDashboardData } from "../features/data/dataSlice";
import { DashboardPhenomData } from "./dashboardAPI";

export type UplinkData = {
  data_points: DataPoint;
  meta: MetaData;
  time: string;
  ts: Date;
  port: number;
  gateways: Gateway[];
};

type MetaData = {
  date: string;
  eui: string;
};

type Gateway = {
  name: string;
  rssi: number;
  snr: number;
};

export type DataPoint = {
  [key: string]: PhenomenaObject;
};

export type PhenomenaObject = {
  [key: string]: number;
};

/*
API requests for data related requests
*/
export const dataAPI = createApi({
  reducerPath: "dataAPI",
  //base query for all requests: token used in all requests added to header
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers: any, { getState }: any) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("token", `${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Data"],
  endpoints: (builder) => ({
    //get data - Takes token and node MAC address and returns array of data
    getData: builder.query({
      query: (body: { eui: string }) => {
        return {
          url: "/data/" + body.eui,
        };
      },
      transformResponse: (result: UplinkData[]) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateDeviceData(data));
        } catch (error) { }
      },
      providesTags: ["Data"],
    }),
    getLatestData: builder.query({
      query: (body: { eui: string }) => {
        return {
          url: "/data/" + body.eui + "/latest",
        };
      },
      transformResponse: (result: UplinkData) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          /* dispatch(updateDeviceData(data)); */
        } catch (error) { }
      },
    }),
    getDataWithinRange: builder.query({
      query: (body: { eui: string[]; start: number; end: number }) => {
        return {
          url: `/data/${body.eui}/range?start=${moment(body.start).utc().format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")}&end=${moment(body.end).utc().format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")}`,
        };
      },
      transformResponse: (result: { "dashboard_data_points": DashboardPhenomData[], end_time: string, start_time: string }) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateDashboardData(data.dashboard_data_points));
        } catch (error) { console.log(error) }
      },
      providesTags: ["Data"],
    }),
    deleteDataWithinRange: builder.mutation({
      query: (body: { eui: string; start: string; end: string }) => {
        return {
          url: `/data/${body.eui}/delete`,
          method: "DELETE",
        };
      },
      transformResponse: (result: any) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          console.log(data)
          /* dispatch(updateDeviceData(data)); */
        } catch (error) { console.log(error) }
      },
    }),
  }),
});

export const {
  useLazyGetDataQuery,
  useGetLatestDataQuery,
  useLazyGetDataWithinRangeQuery,
  useDeleteDataWithinRangeMutation,
} = dataAPI;
