import { Dispatch } from "@reduxjs/toolkit";
import { Avatar } from "flowbite-react";
import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { doLogout } from "../../app/store";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../dictionary/NavText";
import { clearUser, selectLanguage, selectUser } from "../../features/user/userSlice";
import useWindowDimensions, {
  mdBreakPoint,
} from "../../utils/ScreenDimensions";
import { LanguageCheck } from "../../utils/LanguageCheck";
import DropdownNotification from "./DropdownNotification";

type Props = {
  setIsSideBarOpen: (isOpen: boolean) => void;
  sideBarIsOpen: boolean;
};

export const NavHeader = forwardRef(
  (
    { setIsSideBarOpen, sideBarIsOpen }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { email, username, notifications } = useAppSelector(selectUser);
    const dispatch = useAppDispatch();
    const [userDropdownIsOpen, setUserDropdownIsOpen] = useState(false);
     const avatarRef = useRef<HTMLDivElement>(null);
    const userSettingsRef = useRef<HTMLDivElement>(null);
    const { width } = useWindowDimensions();

    

    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (userSettingsRef?.current !== null && avatarRef?.current !== null) {
          if (
            !userSettingsRef.current.contains(event.target) &&
            !avatarRef.current.contains(event.target)
          ) {
            setUserDropdownIsOpen(false);
          }
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
    }, [userSettingsRef, avatarRef]);

    return (
      <div className="flex h-fit overflow-y-auto overflow-x-hidden rounded bg-white shadow-md select-none">
        <div className="flex justify-between items-center w-full">
          <div className="flex flex-row items-center m-2" ref={ref}>
            <svg
              onClick={() => {
                setIsSideBarOpen(!sideBarIsOpen);
                if (!sideBarIsOpen && width < mdBreakPoint) {
                  setUserDropdownIsOpen(false);
                }
              }}
              strokeWidth="0"
              viewBox="0 0 20 20"
              className={`h-8 w-8 cursor-pointer text-gray-600 dark:text-gray-400 ${
                !sideBarIsOpen
                  ? "fill-gray-600 hover:fill-rpr-blue"
                  : "fill-rpr-blue hover:fill-gray-600"
              }`}
              height="1em"
              width="1em"
              xmlns="http:www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            <Link
              className="flex items-center pl-2.5 self-center whitespace-nowrap text-xl font-semibold dark:text-white"
              to=""
            >
              <img
                src={require("../../assets/rpr-logo.png")}
                alt="RPR logo"
                className="mr-3 h-8 sm:h-8"
              ></img>
              rpr-IoT
              {/* <span className="text-xs inline-block py-1 px-2.5 mx-2 leading-none text-center whitespace-nowrap align-baseline font-bold bg-rpr-blue text-white rounded-full">
                BETA
              </span> */}
            </Link>
          </div>
          <div
            className={`absolute mr-3 top-0 bottom-0 right-0 mb-4 mt-22 min-w-settings z-20 ${
              userDropdownIsOpen ? "" : "hidden"
            }`}
          >
            <div
              className="flex h-fit overflow-y-auto overflow-x-hidden rounded bg-white shadow-md"
              ref={userSettingsRef}
            >
              <DropdownItem
                username={username}
                email={email}
                dispatch={dispatch}
              />
            </div>
          </div>

          
          <div className="m-2 flex flex-row gap-2 items-center">
            

            <div className="cursor-pointer">
              <DropdownNotification notifications={notifications} />
            </div>
            
          
            <div className="cursor-pointer" ref={avatarRef}>
              <Avatar
                alt="User settings"
                rounded={true}
                onClick={() => {
                  setUserDropdownIsOpen(!userDropdownIsOpen);
                  if (!userDropdownIsOpen && width < mdBreakPoint) {
                    setIsSideBarOpen(false);
                  }
                }}
              />
            </div>
          </div>

        </div>
      </div>
    );
  }
);

type DropdownItemProps = {
  username: string | null;
  email: string | null;
  dispatch: Dispatch<any>;
};

const DropdownItem = ({ username, email, dispatch }: DropdownItemProps) => {
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));

  
  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);
  
  return (
    <div className="flex justify-between w-full flex-col">
      <div className="relative z-20">
        <ul className="py-1">
          <div className="block py-2 px-4 text-sm text-gray-700 ">
            <span className="block text-md font-bold">
              {username ? username : "username"}
            </span>
            <span className="block truncate text-md font-bold">
              {email ? email : "email"}
            </span>
          </div>
          <div className="my-1 h-px bg-gray-100 "></div>
          <Link
            to="/user/settings"
            className="block cursor-pointer py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 "
          >
            {language.navHeader.settings}
          </Link>
          <Link
            to="/help"
            className="block cursor-pointer py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 "
          >
            {language.navHeader.help}
          </Link>
          <div className="my-1 h-px bg-gray-100 "></div>
          <li
            className="block cursor-pointer py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 "
            onClick={() => {
              doLogout();
            }}
          >
            {language.navHeader.signOut}
          </li>
        </ul>
      </div>
    </div>
  );
};
