import React from "react";
import TermsAndConditionsComponent from "../components/TermsAndConditions";
import AcceptModal from "../components/modals/AcceptModal";
import { useNavigate } from "react-router-dom";

function TermsAndConditions() {
  const navigate = useNavigate();
  return (
    <AcceptModal
      onAccept={() => navigate("/")}
      Title={"Terms and Conditions"}
      Body={<TermsAndConditionsComponent language={"english"} />}
      AcceptButton={"Home"}
      CancelButton={""}
      showCancel={false}
      closeButton={true}
      onClose={() => navigate("/")}
    />
  );
}

export default TermsAndConditions;
