import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import AcceptModal from "../../components/modals/AcceptModal";
import GroupObjectModal from "../../components/modals/GroupObjectModal";
import {
  useRemoveUserFromGroupMutation,
  useUpdateUserInGroupMutation,
  useTransferGroupOwnerMutation,
} from "../../services/groupAPI";
import { handleError } from "../../utils/ErrorHandling";
import { Group, selectGroups, UserPermissions } from "../../features/groups/groupSlice";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage, selectUser } from "../../features/user/userSlice";
import { toast } from "react-toastify";
import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
} from "../../dictionary/GroupsText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import UserPermissionsEdit from "./EditPermissions";
import { memberPreset } from "../../utils/Permissions";
import { FaLock } from "react-icons/fa";
import InputModal from "../../components/modals/InputModal";

function GroupEditUser() {
  let [name, setName] = useState("");
  const navigate = useNavigate();
  const { groupid } = useParams();
  const { memberid } = useParams();
  const [removeConfirm, setRemoveConfirm] = useState(false);
  const [showMakeOwnerConfirmModal, setShowMakeOwnerConfirmModal] = useState(false);
  const [existingUserPerms, setExistingUserPerms] = useState<UserPermissions>();
  const [isGroupOwner, setIsGroupOwner] = useState(false);
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(
    LanguageCheck(
      English,
      French,
      German,
      Dutch,
      Spanish,
      Italian,
      Romanian,
      Portuguese,
      stateLang
    )
  );
  const { groups } = useAppSelector(selectGroups);
  const [groupInfo, setGroupInfo] = useState<Group>(
    groups[groupid as keyof typeof groups]
  );

  useEffect(() => {
    setLanguage(
      LanguageCheck(
        English,
        French,
        German,
        Dutch,
        Spanish,
        Italian,
        Romanian,
        Portuguese,
        stateLang
      )
    );
  }, [stateLang]);

  useEffect(() => {
    if (groups[groupid as keyof typeof groups] && memberid) {
      setExistingUserPerms(
        groups[groupid as keyof typeof groups].users?.[
          memberid as keyof typeof groups.users
        ].permissions ?? memberPreset
      );
    }
  }, [groups, groupid, memberid]);

  useEffect(() => {
    if (groupInfo?.owner) {
      setIsGroupOwner(groupInfo.owner);
    }
  }, [groupInfo]);

  const [
    editUserPerms,
    {
      isSuccess: isEditUserPermsSuccess,
      isError: isEditUserPermsError,
      error: editUserPermsError,
    },
  ] = useUpdateUserInGroupMutation();

  const [
    removeUser,
    {
      isSuccess: isRemoveUserSuccess,
      isError: isRemoveUserError,
      error: removeUserError,
    },
  ] = useRemoveUserFromGroupMutation();

  const [
    transferGroupOwner,
    {
      isSuccess: isTransferGroupOwnerSuccess,
      isError: isTransferGroupOwnerError,
      error: transferGroupOwnerError,
      data: transferGroupOwnerData,
    },
  ] = useTransferGroupOwnerMutation();

  useEffect(() => {
    if (isEditUserPermsSuccess) {
      toast.success(language.groupEditUser.toasts.permsEdited);
      navigate(-1);
    }
  }, [isEditUserPermsSuccess]);

  useEffect(() => {
    if (isEditUserPermsError) {
      handleError(editUserPermsError);
    }
  }, [editUserPermsError, isEditUserPermsError]);

  useEffect(() => {
    if (isRemoveUserSuccess) {
      toast.success(language.groupEditUser.toasts.removed);
      navigate(-1);
    }
  }, [isRemoveUserSuccess]);

  useEffect(() => {
    if (isRemoveUserError) {
      handleError(removeUserError);
    }
  }, [removeUserError, isRemoveUserError]);

  useEffect(() => {
    if (isTransferGroupOwnerSuccess) {
      toast.success("Verification email sent to " + name + ".");
      navigate(-1);
    }
  }, [isTransferGroupOwnerSuccess]);

  useEffect(() => {
    if (isTransferGroupOwnerError) {
      handleError(transferGroupOwnerError);
    }
  }, [transferGroupOwnerError, isTransferGroupOwnerError]);

  useEffect(() => {
    if (groupInfo?.users) {
      setName(groupInfo.users[memberid as keyof typeof groupInfo.users].username);
    }
  }, [groupInfo?.users, groupInfo, memberid]);

  return (
    <>
      {existingUserPerms && (
        <>
          {removeConfirm ? (
            <AcceptModal
              onAccept={() => {
                removeUser({ groupID: groupid ?? "", userID: memberid ?? "" });
              }}
              onCancel={() => navigate(-1)}
              Title={language.groupEditUser.modals.removeUser.title}
              Body={
                <div>
                  {language.groupEditUser.modals.removeUser.body.phrase1}{" "}
                  <b>{name ?? language.groupEditUser.modals.removeUser.body.thisUser} </b>
                  {language.groupEditUser.modals.removeUser.body.phrase2}{" "}
                  <b>
                    {groupInfo?.name ??
                      language.groupEditUser.modals.removeUser.body.thisGroup}
                  </b>
                  {language.groupEditUser.modals.removeUser.body.phrase3}
                </div>
              }
              AcceptButton={language.groupEditUser.modals.removeUser.buttons.accept}
              CancelButton={language.groupEditUser.modals.removeUser.buttons.cancel}
            />
          ) : showMakeOwnerConfirmModal ? (
            <InputModal
              onSubmit={(e) => {
                e.preventDefault();
                var { modalPassword } = document.forms[0];
                transferGroupOwner({
                  group_id: groupid ?? "",
                  user_id: memberid ?? "",
                  password: modalPassword.value,
                });
                navigate(-1);
              }}
              onCancel={() => {
                navigate(-1);
              }}
              Title={
                language.groupEditUser.modals.transferOwner.title.phrase1 +
                " " +
                (name ?? language.groupEditUser.modals.editPerms.title.thisUser) +
                " " +
                language.groupEditUser.modals.transferOwner.title.phrase2
              }
              Loading={false}
              Body={
                <>
                  <div>
                    {language.groupEditUser.modals.transferOwner.body.phrase1}{" "}
                    {groupInfo?.name ??
                      language.groupEditUser.modals.transferOwner.body.thisGroup}{" "}
                    {language.groupEditUser.modals.transferOwner.body.phrase2}{" "}
                    {name ?? language.groupEditUser.modals.transferOwner.body.thisUser}
                    {language.groupEditUser.modals.transferOwner.body.phrase3}
                  </div>
                </>
              }
              Form={[
                {
                  htmlFor: "modalPassword",
                  label: language.groupEditUser.modals.transferOwner.labels.password,
                  type: "password",
                  value: "",
                  error: "",
                  autoComplete: "current-password",
                  onChange: () => {
                    /* clearFormErrors(); */
                  },
                },
              ]}
              AcceptButton={language.groupEditUser.modals.transferOwner.buttons.accept}
              CancelButton={language.groupEditUser.modals.transferOwner.buttons.cancel}
            />
          ) : (
            <GroupObjectModal
              onRemove={() => {
                setRemoveConfirm(true);
              }}
              onAccept={() => {
                editUserPerms({
                  groupID: groupid ?? "",
                  userID: memberid ?? "",
                  permissions: existingUserPerms,
                });
              }}
              onCancel={() => {
                navigate(-1);
              }}
              Title={
                language.groupEditUser.modals.editPerms.title.phrase1 +
                " " +
                (name ?? language.groupEditUser.modals.editPerms.title.thisUser) +
                language.groupEditUser.modals.editPerms.title.phrase2
              }
              Body={
                <>
                  <UserPermissionsEdit
                    editeesPerms={existingUserPerms}
                    getNewPerms={(perms) => setExistingUserPerms(perms)}
                    presetButtons={true}
                  />
                  {isGroupOwner && (
                    <>
                      <hr className="py-1" />
                      <div>
                        <button onClick={() => setShowMakeOwnerConfirmModal(true)}>
                          <div className="inline-flex items-center p-2 bg-gray-200 rounded-md border border-gray-300 text-black">
                            <div>
                              {
                                language.groupEditUser.modals.editPerms.buttons.makeOwner
                                  .phrase1
                              }{" "}
                              {name ??
                                language.groupEditUser.modals.editPerms.buttons.makeOwner
                                  .thisUser}{" "}
                              {
                                language.groupEditUser.modals.editPerms.buttons.makeOwner
                                  .phrase2
                              }
                            </div>
                            <FaLock size={14} color="black" className="ml-1" />
                          </div>
                        </button>
                      </div>
                    </>
                  )}
                </>
              }
              AcceptButton={language.groupEditUser.modals.editPerms.buttons.accept}
              CancelButton={language.groupEditUser.modals.editPerms.buttons.cancel}
              RemoveButton={language.groupEditUser.modals.editPerms.buttons.remove}
            />
          )}
        </>
      )}
    </>
  );
}

export default GroupEditUser;
