import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GroupSubscription } from "../features/groups/groupSlice";
import { Consumables } from "../routes/groups/KeepOnSubscriptionDowngrade";

/*
API requests for group related requests
*/
export const subscriptionAPI = createApi({
  reducerPath: "subscriptionAPI",
  //Set up base query for the API. URL from env. Token taken from state.
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/subscription",
    prepareHeaders: (headers: any, { getState }: any) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("token", `${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    startSubscription: builder.mutation({
      query: (args: { price_id: string; group_id: string }) => {
        return {
          url: `/start`,
          method: "POST",
          body: args,
        };
      },
    }),
    getSubscriptionPlans: builder.query({
      query: () => {
        return {
          url: `/plans/available`,
          method: "GET",
        };
      },
    }),
    getPaymentConfirmation: builder.query({
      query: (args: { subscription_id: string }) => {
        return {
          url: `/payment/confirm`,
          method: "POST",
          body: args,
        };
      },
      transformResponse: (result: {
        group_name: string;
        group_id: string;
        subscription: GroupSubscription;
      }) => result,
    }),
    editSubscription: builder.query({
      query: (args: { group_id: string }) => {
        return {
          url: `/${args.group_id}/update`,
          method: "GET",
        };
      },
      transformResponse: (result: { url: string }) => result,
    }),
    keepOnDowngrade: builder.mutation({
      query: (args: { group_id: string; consumables: Consumables }) => {
        return {
          url: `/${args.group_id}/keepondowngrade`,
          method: "PATCH",
          body: args.consumables,
        };
      },
    }),
  }),
});

export const {
  useStartSubscriptionMutation,
  useGetSubscriptionPlansQuery,
  useGetPaymentConfirmationQuery,
  useLazyEditSubscriptionQuery,
  useKeepOnDowngradeMutation,
} = subscriptionAPI;
