export type CustomError = {
  error: CustomErrorInternal;
};

export type CustomErrorInternal = {
  type: string;
  code: string;
  severity: string;
  action: string;
  message: string;
};

export const ERROR_TYPES = {
  TOKEN: "token",
  AUTHENTICATION: "auth",
  VERIFICATION: "verification",
  DATABASE: "database",
  REQUEST: "request",
  SERVER: "server",
  DUPLICATE: "duplicate",
  PERMISSIONS: "permission",
};

export const ERROR_ACTIONS = {
  LOGOUT: "logout",
  TOAST: "toast",
  FORM_ERROR: "form error",
  INPUT_ERROR: "input error",
  NONE: "na",
};
