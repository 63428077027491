import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useTermsAndConditionsQuery } from "../services/authAPI";

type props = {
  language: string;
};

function TermsAndConditions({ language }: props) {
  const [terms, setTerms] = React.useState("# Loading...");

  const { error, isError, data, isSuccess } = useTermsAndConditionsQuery({
    language,
    type: "general",
  });

  useEffect(() => {
    if (isSuccess) {
      setTerms(data?.content as string);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setTerms("## There has been an error loading the terms and conditions.");
    }
  }, [isError]);

  return (
    <div>
      {data?.version && <h3 className="pb-2">Version {data?.version}</h3>}
      <ReactMarkdown children={terms} className="prose prose-blue prose-sm" />
    </div>
  );
}

export default TermsAndConditions;
