import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../app/store";

export interface ImageState {
    images: Images;
}

type Images = {
    [x: string]: GroupImages;
}

export type GroupImages = {
    [x: string]: ImageType
}

export type ImageType = {
    id: string;
    name: string;
    description: string;
    url: string;
}

const initialState: ImageState = {
    images: {},
};

const imageSlice = createSlice({
    name: "images",
    initialState,
    reducers: {
        addImages: (state: any, action: PayloadAction<{ "images": GroupImages; "group_id": string }>) => {
            if (state.images[action.payload.group_id] === undefined) {
                state.images[action.payload.group_id] = [];
            }
            state.images[action.payload.group_id] = action.payload.images;
        },
        clearImages: (state: any) => {
            state.groups = {};
        },
    },
});

export const { addImages, clearImages } = imageSlice.actions;

export const selectImages = (state: RootState) => state.images;

export default imageSlice.reducer;
