import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { Icon, LatLng, LatLngTuple } from "leaflet";
import _ from "lodash";
import { useState, useEffect, useRef } from "react";
import { BsFillUnlockFill, BsFillLockFill } from "react-icons/bs";
import { MapContainer, LayersControl, Pane, TileLayer, Marker, Popup, useMapEvents, useMap } from "react-leaflet";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FormInput } from "../../components/FormInput";
import Spinner from "../../components/Spinner";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../dictionary/DeviceText";
import { selectLanguage, selectUser } from "../../features/user/userSlice";
import { deviceAPI, SaveDevice, useEditDeviceMutation, useGetDeviceQuery } from "../../services/deviceAPI";
import { DataTimeToLocale } from "../../utils/DateFormating";
import { handleError } from "../../utils/ErrorHandling";
import { LanguageCheck } from "../../utils/LanguageCheck";


/* gauge imports */
import { UplinkData, useGetLatestDataQuery, useLazyGetDataQuery } from "../../services/dataAPI";
/* ********************** */
import { Button } from "../../components/Button";


function EditDeviceGateways() {
    const { deviceid } = useParams();
    const {
        data: device,
        isSuccess,
        isError,
        error,
        isFetching,
    } = useGetDeviceQuery(deviceid ?? skipToken);
    const configurations = device?.configuration;
    const gateways = device?.gateways;
    const user = useAppSelector(selectUser);
    const [editPermission, setEditPermission] = useState(false);
    const { BaseLayer } = LayersControl;
    const [lockDragging, setLockDragging] = useState(true);
    const [seed, setSeed] = useState(0);
    const [viewMode, setViewMode] = useState(true);
    const [selectedGateway, setSelectedGateway] = useState("");
    const [deviceCoordinates, setDeviceCoordinates] = useState<LatLng>(
        new LatLng(0, 0)
    );
    const dispatch = useAppDispatch();
    const stateLang = useAppSelector(selectLanguage);
    let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    useEffect(() => {
        setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    }, [stateLang]);

    const { data: latestData } = useGetLatestDataQuery({ "eui": device?.eui ?? "" }, { pollingInterval: 60000 });

    const [
        saveDevice,
        {
            data: saveDeviceResponse,
            isSuccess: isSaveDeviceSuccess,
            isLoading: isSaveDeviceLoading,
            isError: isSaveDeviceError,
            error: saveDeviceError,
        },
    ] = useEditDeviceMutation();

    var gatewayIcon = new Icon({
        iconUrl:
            "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
        shadowUrl:
            "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });

    useEffect(() => {
        if (isSuccess) {
            if (user.groups[device.group_id]) {
                setEditPermission(
                    user.groups[device.group_id].permissions.can_edit_devices
                        ?.can_edit_all ??
                    user.groups[device.group_id].permissions.can_edit_devices
                        ?.can_edit_devices?.[device.id] ??
                    false
                );
            } else {
                setEditPermission(false);
            }
        }
    }, [device, user, isSuccess]);

    const LockDraggingButton: React.FC = () => {
        return (
            <button
                className="bg-white pl-[6px] ring-opacity-20 ring-2 ring-gray-900 top-[5rem] left-3 border rounded absolute w-[1.9rem] h-8 z-[700]"
                onClick={() => {
                    setSeed(Math.random());
                    setLockDragging(!lockDragging);
                }}
            >
                {lockDragging ? (
                    <BsFillUnlockFill size={16} />
                ) : (
                    <BsFillLockFill size={16} />
                )}
            </button>
        );
    };

    function MapEvents() {
        const map = useMapEvents({
            baselayerchange: (e) => {
                e.name === "Map" ? setViewMode(true) : setViewMode(false);
            },
            movestart: () => {
                setSelectedGateway("");
            },
        });
        return null;
    }

    async function accept(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (device) {
            const newUpdatedDevice: SaveDevice = {
                id: device.id,
                updates: [],
            };
            _.map(Object.values(document.forms[2]), (value, index) => {
                if ((document.forms[2][index])) {
                    if ((document.forms[2][index] as HTMLInputElement).hasOwnProperty("value")) {
                        newUpdatedDevice.updates?.push({
                            "phenom": (JSON.parse((document.forms[2][index] as HTMLInputElement).id).config),
                            "element": (JSON.parse((document.forms[2][index] as HTMLInputElement).id).ident),
                            "field": "description",
                            "value": (document.forms[2][index] as HTMLInputElement).value
                        });
                    }
                }
            })
            saveDevice(newUpdatedDevice);
        }
    }

    const phenomenaList: JSX.Element[] = [];
    var phenoList: { [key: string]: { [key: string]: any } } = {};

    if (configurations !== undefined && gateways !== undefined) {
        for (let config in configurations) {
            _.map(configurations[config], (sensor: { elemID: string | number; }, index: number) => {
                if (gateways[sensor.elemID] !== undefined) {
                    if (phenoList[sensor.elemID] === undefined) {
                        phenoList[sensor.elemID] = {};
                    }
                    phenoList[sensor.elemID][config] = ({ ...sensor, ident: index, config: config });
                    phenoList[sensor.elemID]["ts"] = (new Date(gateways[sensor.elemID].last_contact_ts ?? 0))
                }
            })
        }
        phenoList = Object.fromEntries(
            Object.entries(phenoList).sort(([, a], [, b]) => b.ts - a.ts)
        );
    }

    if (gateways !== undefined) {
        _.map(Object.keys(phenoList), (gateway, index) => {
            phenomenaList.push(
                <div className="text-black bg-gray-200 rounded-md p-2" onClick={() => { if (gateways[gateway].lat !== 0 && gateways[gateway].lng !== 0) { setSelectedGateway(gateway) } }}>
                    <div className="inline-flex w-full justify-between">
                        <h1 className="text-2xl font-bold"> {gateways[gateway].name} </h1>
                        {gateways[gateway].lat === 0 && gateways[gateway].lng === 0 &&
                            <div className="text-red-500">(0,0)</div>
                        }
                    </div>
                    <h3 className="text-l"> {language.editDevice.editDeviceGateways.lastContact}: {DataTimeToLocale(new Date(gateways[gateway].last_contact_ts ?? 0))} </h3>
                    {_.map(Object.values(phenoList[gateway]), (sensor) => {
                        if (gateways[sensor.elemID] !== undefined) {
                            const id = { ident: sensor.ident, config: sensor.config };
                            return (
                                <div className="inline-flex justify-between w-full" onClick={(e) => e.stopPropagation()}>
                                    <div className="w-full">
                                        <FormInput
                                            loading={isFetching}
                                            htmlFor={JSON.stringify(id)}
                                            label={
                                                sensor?.sensor_sn.toLocaleUpperCase()
                                            }
                                            value={sensor?.description}
                                            disabled={!editPermission}
                                        />
                                    </div>
                                    <NumberDisplay latestData={latestData} ChartData={{ elemID: sensor.elemID, phenom: sensor.config }} />
                                </div>
                            )
                        }
                    })}
                </div >
            );
        })
    }

    useEffect(() => {
        if (isSuccess) {
            if (device.gateways !== undefined) {
                Object.keys(phenoList).reverse().map(d => (device.gateways[d].lat !== 0 && device.gateways[d].lng !== 0) &&
                    setDeviceCoordinates(new LatLng(device.gateways[d].lat, device.gateways[d].lng))
                );
            }
        }
    }, [device?.gateways, isSuccess]);

    useEffect(() => {
        if (isSaveDeviceError) {
            handleError(saveDeviceError);
        }
    }, [saveDeviceError, isSaveDeviceError]);

    useEffect(() => {
        if (isSaveDeviceSuccess) {
            toast.success(language.editDevice.viewEditDevice.toasts.deviceSaved)
        }
    }, [saveDeviceResponse, isSaveDeviceSuccess, language.editDevice.viewEditDevice.toasts.deviceSaved]);

    const CenterableMarker = ({ center, content, selected }: { center: LatLngTuple, content: JSX.Element, selected: boolean }) => {
        const map = useMap();
        const markerRef = useRef<L.Marker<any>>(null);

        useEffect(() => {
            if (selected) {
                map.setView(center);
                if (markerRef.current !== null) {
                    markerRef.current.openPopup();
                }
            }
        }, [map, center, selected]);

        return (
            center[0] !== 0 && center[1] !== 0 ? (
                <Marker
                    icon={gatewayIcon}
                    position={center}
                    ref={markerRef}
                >
                    <Popup autoPan={false}>
                        {content}
                    </Popup>
                </Marker>
            ) : <> </>
        );
    };


    const markers = (
        _.map(device?.gateways, (gateway, gatewayid) => {
            const content: JSX.Element = (<>
                {gateway.name}<br />{DataTimeToLocale(new Date(gateway.last_contact_ts ?? 0))}
            </>)
            return (
                <CenterableMarker
                    center={[gateway.lat, gateway.lng]}
                    content={content}
                    selected={gatewayid === selectedGateway}
                />
            );
        })
    )

    const map: JSX.Element = (
        <div className="h-[200px] lg:h-[73vh] w-full lg:w-[49%] bg-gray-100 text-center">
            <MapContainer
                key={seed}
                center={deviceCoordinates}
                minZoom={0}
                maxZoom={18}
                zoom={15}
                style={{ height: "100%", width: "100%", zIndex: "0" }}
                dragging={lockDragging}
                scrollWheelZoom={lockDragging}
            >
                <LayersControl position="topright">
                    <BaseLayer checked={viewMode} name="Map">
                        <Pane name="map" style={{ zIndex: 0 }}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </Pane>
                    </BaseLayer>
                    <BaseLayer checked={!viewMode} name="Satellite">
                        <Pane name="satellite" style={{ zIndex: 0 }}>
                            <TileLayer
                                attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                            />
                        </Pane>
                    </BaseLayer>
                </LayersControl>
                <LockDraggingButton />
                {markers}
                <MapEvents />
            </MapContainer>
        </div>
    )

    return (
        <div className="p-4 pt-1 bg-white rounded-md">
            <form
                className="flex flex-col justify-between"
                onSubmit={accept}
            >
                <div className="flex-wrap flex space-x-1">
                    <div className="h-full w-full lg:w-[49%]">

                        {configurations !== undefined && gateways !== undefined
                            ?
                            <div>
                                <h1 className="font-bold text-xl">
                                    {language.editDevice.editDeviceGateways.gateways}
                                </h1>
                                <div className="overflow-y-scroll space-y-3 scrollbar-none max-h-[47vh] lg:max-h-[70vh] mb-2 rounded-md">
                                    {phenomenaList}
                                </div>
                            </div>
                            : <div className="text-black">
                                <div className="text-black bg-gray-200 rounded-md p-2">
                                    <div className="inline-flex w-full justify-between">
                                        <h1 className="text-xl"> {language.editDevice.editDeviceGateways.noGateways} </h1>
                                    </div>
                                </div>
                            </div>}
                    </div>
                    {deviceCoordinates.lat !== 0 && deviceCoordinates.lng !== 0 && (
                        map
                    )}
                </div>
                <div className="inline-flex w-full lg:pb-0 justify-between">
                    {editPermission && (
                        <div className="inline-flex w-full flex-row-reverse space-x-2">
                            <div className="ml-2 w-auto mt-2">
                                <Button
                                    label={isSaveDeviceLoading ? <Spinner colour="fill-blue-600" /> : language.editDevice.buttons.save}
                                    type="submit"
                                    colour="green"
                                />
                            </div>
                            <div className="w-auto mt-2">
                                <Button
                                    label={language.editDevice.buttons.cancel}
                                    onClick={() => {
                                        dispatch(deviceAPI.util.invalidateTags(["Device"]));
                                        setSeed(Math.random());
                                    }}
                                    colour="white"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </form>
        </div>
    );
}

export default EditDeviceGateways;

type Props = {
    latestData: UplinkData | undefined;
    ChartData: { elemID: string, phenom: string };
};

function NumberDisplay({ latestData, ChartData }: Props) {

    const [dataValue, setDataValue] = useState<number>();
    useEffect(() => {
        if (latestData) {
            if (latestData.data_points) {
                setDataValue(
                    latestData.data_points[ChartData.phenom][ChartData.elemID]
                );
            }
        }
    }, [ChartData, latestData]);

    return (
        <>
            {dataValue !== undefined &&
                <div className="mt-auto px-1 h-11 w-fit min-w-[44px] text-xl flex flex-row-reverse items-center rounded-md border-4 border-gray-300 bg-gray-200">
                    <div className="text-xl flex flex-row-reverse w-full text-center items-center justify-center">
                        {dataValue}
                    </div>
                </div>
            }
        </>
    );
}

