import { configureStore } from "@reduxjs/toolkit";
import { authAPI } from "../services/authAPI";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import authReducer, { clearAuth } from "../features/auth/authSlice";
import userReducer, {
  clearUser,
  setTermsAndConditionsUpdated,
} from "../features/user/userSlice";
import deviceReducer, { clearDevices } from "../features/devices/deviceSlice";
import dataReducer, { clearDataData } from "../features/data/dataSlice";
import groupReducer, { clearGroups } from "../features/groups/groupSlice";
import imageReducer, { clearImages } from "../features/images/imageSlice";
import dashboardReducer, { clearDashboards } from "../features/dashboards/dashboardSlice";
import notificationReducer from "../features/notifications/notificationSlice";
import { userAPI } from "../services/userAPI";
import { deviceAPI } from "../services/deviceAPI";
import { dataAPI } from "../services/dataAPI";
import { groupAPI } from "../services/groupAPI";
import { dashboardAPI } from "../services/dashboardAPI";
import { subscriptionAPI } from "../services/subscriptionAPI";
import { groupNotificationAPI } from "../services/groupNotificationAPI";
export const store = configureStore({
  reducer: {
    auth: authReducer,
    [authAPI.reducerPath]: authAPI.reducer,
    user: userReducer,
    [userAPI.reducerPath]: userAPI.reducer,
    devices: deviceReducer,
    [deviceAPI.reducerPath]: deviceAPI.reducer,
    data: dataReducer,
    [dataAPI.reducerPath]: dataAPI.reducer,
    groups: groupReducer,
    [groupAPI.reducerPath]: groupAPI.reducer,
    images: imageReducer,
    dashboards: dashboardReducer,
    [dashboardAPI.reducerPath]: dashboardAPI.reducer,
    [subscriptionAPI.reducerPath]: subscriptionAPI.reducer,
    notifications: notificationReducer,
    [groupNotificationAPI.reducerPath]: groupNotificationAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(authAPI.middleware)
      .concat(userAPI.middleware)
      .concat(deviceAPI.middleware)
      .concat(dataAPI.middleware)
      .concat(groupAPI.middleware)
      .concat(dashboardAPI.middleware)
      .concat(subscriptionAPI.middleware)
      .concat(groupNotificationAPI.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
setupListeners(store.dispatch);

export const doLogout = () => {
  // do other logout stuff here, for example logging out user with backend, etc..
  store.dispatch(clearAuth());
  store.dispatch(clearUser());
  store.dispatch(clearDevices());
  store.dispatch(clearDataData());
  store.dispatch(clearGroups());
  store.dispatch(clearDashboards());
  localStorage.removeItem("redirectURL");
  // Let every one of your reducers reset here.
};

export const AcceptedLatestTsCs = (newVal: boolean) => {
  store.dispatch(setTermsAndConditionsUpdated(newVal));
};

//invalidate all caches
export const invalidateAllCaches = () => {
  store.dispatch(groupAPI.util.invalidateTags(["Group", "Groups"]));
  store.dispatch(userAPI.util.invalidateTags(["User"]));
  store.dispatch(deviceAPI.util.invalidateTags(["Device", "Devices"]));
  store.dispatch(dataAPI.util.invalidateTags(["Data"]));
  store.dispatch(
    dashboardAPI.util.invalidateTags(["Dashboard", "Dashboards", "DashboardData"])
  );
};
