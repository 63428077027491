import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import AcceptModal from "../../components/modals/AcceptModal";
import { FormInput } from "../../components/FormInput";
import Spinner from "../../components/Spinner";
import { selectLanguage, selectUser } from "../../features/user/userSlice";
import { doLogout } from "../../app/store";
import {
  useGroupIDtoNameQuery,
  useJoinGroupMutation,
} from "../../services/groupAPI";
import { toast } from "react-toastify";
import { CustomError } from "../../utils/Types";
import { userAPI } from "../../services/userAPI";
import { dashboardAPI } from "../../services/dashboardAPI";
import { deviceAPI } from "../../services/deviceAPI";
import { handleError } from "../../utils/ErrorHandling";
import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
} from "../../dictionary/GroupsText";
import { LanguageCheck } from "../../utils/LanguageCheck";

export default function JoinGroup() {
  let [searchParams, setSearchParams] = useSearchParams();
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(
    LanguageCheck(
      English,
      French,
      German,
      Dutch,
      Spanish,
      Italian,
      Romanian,
      Portuguese,
      stateLang
    )
  );

  useEffect(() => {
    setLanguage(
      LanguageCheck(
        English,
        French,
        German,
        Dutch,
        Spanish,
        Italian,
        Romanian,
        Portuguese,
        stateLang
      )
    );
  }, [stateLang]);

  const { username } = useAppSelector(selectUser);
  const [body, setBody] = useState<ReactNode>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [
    joinGroup,
    {
      data: joinGroupData,
      isSuccess: isJoinGroupSuccess,
      isError: isJoinGroupError,
      error: joinGroupError,
    },
  ] = useJoinGroupMutation();

  const { isLoading, isError, isSuccess, data, error, isUninitialized } =
    useGroupIDtoNameQuery(
      {
        id: searchParams.get("groupID") ?? "",
      },
      { skip: searchParams.get("groupID") === null }
    );

  useEffect(() => {
    if (isJoinGroupSuccess) {
      toast.success(
        language.joinGroup.toast.joined +
          " " +
          data?.name +
          " " +
          language.joinGroup.toast.successfully +
          "!"
      );
      navigate("/group/" + searchParams.get("groupID"));
    }
  }, [joinGroupData]);

  useEffect(() => {
    if (isJoinGroupError) {
      handleError(joinGroupError);
    }
  }, [joinGroupError]);

  async function accept(): Promise<any> {
    joinGroup({
      group_id: searchParams.get("groupID") ?? "",
      join_token: searchParams.get("token") ?? "",
    });
  }

  useEffect(() => {
    if (isSuccess) {
      setBody(
        <div>
          {language.joinGroup.phrase1} <b>{username}</b>
          {language.joinGroup.phrase2} <b>{data?.name}</b>
          {language.joinGroup.phrase3}
        </div>
      );
    } else if (isError) {
      handleError(error);
      setBody(
        <div>
          {language.joinGroup.error.thereWasAProblem}
          <br />
          {language.joinGroup.error.ifTheProblemPersists}
          <br />
          <b>sales@r-p-r.co.uk</b>.
        </div>
      );
    }
  }, [data, error, username]);

  useEffect(() => {
    if (isUninitialized) {
      setBody(<div>{language.joinGroup.error.pleaseClickEmailLink}</div>);
    }
  }, []);

  function cancel() {
    doLogout();
  }

  return (
    <div className="h-full rounded bg-transparent flex justify-center flex-col items-center w-full">
      <AcceptModal
        closeButton={true}
        onClose={() => navigate("/")}
        onAccept={accept}
        onCancel={cancel}
        Title={language.joinGroup.modalElements.title}
        loading={isLoading}
        Body={body}
        AcceptButton={language.joinGroup.modalElements.accept}
        CancelButton={language.joinGroup.modalElements.cancel}
      />
    </div>
  );
}
