import React from "react";
import { Outlet } from "react-router-dom";
import { useVerifyEmailMutation } from "../../services/authAPI";

function UserIndex() {
  return (
    <div className="h-full w-full">
      <Outlet />
    </div>
  );
}

export default UserIndex;
