import React, { ReactNode, useEffect, useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { mdBreakPoint } from "../../utils/ScreenDimensions";
type Props = {
  setShowChartEdit: (isOpen: boolean) => void;
  showChartEdit: boolean;
  chartEditInfo: ReactNode;
};

function EditChartSettings({
  showChartEdit,
  setShowChartEdit,
  chartEditInfo,
}: Props) {
  const ChartEditRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ChartEditRef?.current !== null) {
        if (!ChartEditRef.current.contains(event.target)) {
          setShowChartEdit(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ChartEditRef]);
  return (
    <div ref={ChartEditRef}>
      <div
        className={`top-0 right-0 sm:w-96 w-10/12 p-2 rounded bg-white shadow-md flex flex-col justify-between select-none overflow-y-scroll scrollbar-thin fixed h-full z-[1000] ease-in-out duration-300 ${
          showChartEdit ? "translate-x-0 " : "translate-x-full"
        }`}
      >
        <button
          className="flex text-4xl text-black items-center cursor-pointer right-2 fixed z-[1001]"
          onClick={() => setShowChartEdit(!showChartEdit)}
        >
          <IoMdClose size={28} />
        </button>
        {chartEditInfo}
      </div>
    </div>
  );
}

export default EditChartSettings;
