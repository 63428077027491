import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  AuthState,
  login,
  setToken,
  setVerifiedEmail,
} from "../features/auth/authSlice";
import {
  setTermsAndConditionsUpdated,
  setUser,
} from "../features/user/userSlice";
import { invalidateAllCaches, store } from "../app/store";
import { toast } from "react-toastify";
import { userAPI } from "./userAPI";

type LoginResponse = {
  token: string;
  verifiedEmail: boolean;
};

/*
API requests for authentication related requests
*/
export const authAPI = createApi({
  reducerPath: "authApi",
  //Set up base query for the API. URL from env. Token taken from state (should only be present on the verify email mutation).
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/auth",
    prepareHeaders: (headers: any, { getState }: any) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("token", `${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Auth"],
  endpoints: (builder) => ({
    //Login mutation - takes username and password and returns a token and a boolean for verified email
    loginUser: builder.mutation({
      query: (args: {
        body: { username: string; password: string };
        rememberMe: boolean;
      }) => {
        return {
          url: "/login",
          method: "post",
          body: args.body,
        };
      },
      transformResponse: (result: LoginResponse, meta, arg) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            login({
              token: data.token,
              rememberMe: args.rememberMe,
              verifiedEmail: data.verifiedEmail,
            })
          );
        } catch (error) {
          //console.log(error);
        }
      },
    }),
    //Verify email mutation - takes data required to register a user returns a token and a boolean for verified email
    registerUser: builder.mutation({
      query: (args: {
        body: {
          username: string;
          email: string;
          password: string;
          recaptcha_key: string;
          language: string;
        };
        rememberMe: boolean;
      }) => {
        return {
          url: "/signup",
          method: "post",
          body: args.body,
        };
      },
      transformResponse: (
        result: { token: string; message: string },
        meta,
        arg
      ) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          //toast.success(data.message);
          dispatch(
            login({
              token: data.token,
              rememberMe: args.rememberMe,
              verifiedEmail: false,
            })
          );
        } catch (error) {
          //console.log(error);
        }
      },
    }),
    //Verify email mutation - takes token and a verification code, returns a boolean for verified email
    verifyEmail: builder.mutation({
      query: (verificationCode: string) => {
        return {
          url: "/emailverified",
          method: "post",
          body: { token_email_verification: verificationCode },
        };
      },
      transformResponse: (
        result: { token: string; verifiedEmail: boolean; message: string },
        meta,
        arg
      ) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data, meta } = await queryFulfilled;
          //toast.success(data.message);
          dispatch(
            login({
              token: data.token,
              rememberMe:
                (JSON.parse(localStorage.getItem("auth") ?? "") as AuthState)
                  ?.token != undefined,
              verifiedEmail: data.verifiedEmail,
            })
          );
          dispatch(userAPI.util.invalidateTags(["User"]));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    //Resend verification email mutation - takes token and returns a boolean for verified email
    resendVerificationEmail: builder.mutation({
      query: () => {
        return {
          url: "/reverifyemail",
          method: "post",
        };
      },
      transformResponse: (result: { message: string }, meta, arg) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data, meta } = await queryFulfilled;
          //toast.success(data.message);
        } catch (error) {}
      },
    }),
    //forgotten password mutation - takes email/username and sends a reset password email
    forgottenPassword: builder.mutation({
      query: (username: string) => {
        return {
          url: "/forgotpassword",
          method: "post",
          body: { username: username },
        };
      },
      transformResponse: (result: { message: string }, meta, arg) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          //toast.success(data.message);
        } catch (error) {
          console.log(error);
        }
      },
    }),
    //reset password mutation - takes token and new password and returns a token
    resetPassword: builder.mutation({
      query: (args: { resetPasswordToken: string; password: string }) => {
        return {
          url: "/resetpassword",
          method: "post",
          body: {
            token_forgot_password: args.resetPasswordToken,
            password_new: args.password,
          },
        };
      },
      transformResponse: (result: { message: string }, meta, arg) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          //toast.success(data.message);
        } catch (error) {
          console.log(error);
        }
      },
    }),
    changePassword: builder.mutation({
      query: (args: { oldPassword: string; newPassword: string }) => {
        return {
          url: "/changepassword",
          method: "post",
          body: {
            password_old: args.oldPassword,
            password_new: args.newPassword,
          },
        };
      },
      transformResponse: (
        result: { message: string; token: string },
        meta,
        arg
      ) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data, meta } = await queryFulfilled;
          //toast.success(data.message);
          dispatch(
            login({
              token: data.token,
              rememberMe:
                (JSON.parse(localStorage.getItem("auth") ?? "") as AuthState)
                  ?.token != undefined,
              verifiedEmail: true,
            })
          );
        } catch (error) {
          console.log(error);
        }
      },
    }),
    termsAndConditions: builder.query({
      query: (args: { language: string; type: string }) => {
        return {
          url: "/termsandconditions/" + args.type + "/" + args.language,
          method: "get",
        };
      },
      transformResponse: (
        result: {
          content: string;
          active_date: Date;
          active: boolean;
          type: string;
          id: string;
          version: number;
        },
        meta,
        arg
      ) => result,
    }),
    acceptTermsAndConditions: builder.mutation({
      query: (args: { accept: boolean; type: string }) => {
        return {
          url: "/termsandconditions/" + args.type + "/accept",
          method: "PATCH",
          body: { ...args, date: new Date() },
        };
      },
      transformResponse: (
        result: { message: string; token: string },
        meta,
        arg
      ) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data, meta } = await queryFulfilled;
          console.log(data.message);
          dispatch(setToken(data.token));
          dispatch(setTermsAndConditionsUpdated(true));
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
});

export const {
  useLoginUserMutation,
  useRegisterUserMutation,
  useForgottenPasswordMutation,
  useVerifyEmailMutation,
  useResendVerificationEmailMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useTermsAndConditionsQuery,
  useAcceptTermsAndConditionsMutation,
} = authAPI;
