import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetSubscriptionPlansQuery } from "../../services/subscriptionAPI";
import useWindowDimensions, {
  mdBreakPoint,
} from "../../utils/ScreenDimensions";
import { useStartSubscriptionMutation } from "../../services/subscriptionAPI";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectGroups } from "../../features/groups/groupSlice";
import { SubscriptionSelectionRedirect } from "../../utils/SubscriptionRedirect";
import { Button } from "../../components/Button";
import Spinner from "../../components/Spinner";
import { handleError } from "../../utils/ErrorHandling";
import { groupAPI, useDeleteGroupMutation } from "../../services/groupAPI";
import { selectLanguage } from "../../features/user/userSlice";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../dictionary/GroupsText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import InputModal from "../../components/modals/InputModal";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";

function Subscription() {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [error, setError] = useState("");
  let { groupid } = useParams();
  const dispatch = useAppDispatch();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const group = useAppSelector(selectGroups).groups[groupid ?? ""];

  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));

  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  useEffect(() => {
    const redirect = SubscriptionSelectionRedirect(group);
    if (redirect) {
      navigate(redirect);
    }
  }, [group, navigate]);

  const [billingPeriod, setBillingPeriod] = useState(false); // false = monthly, true = yearly

  function formatDate(d: number, m: number, y: number) {
    var r = ""
    r += (
      d === 0
        ? ""
        : d === 1
          ? d + " day"
          : d + " days"
    );
    r += (
      m === 0
        ? ""
        : m === 1
          ? m + " month"
          : m + " months"
    );
    r += (
      y === 0
        ? ""
        : y === 1
          ? y + " year"
          : y + " years"
    );
    return r;
  }

  const [
    deleteGroup,
    {
      isLoading: isDeleteGroupLoading,
      isSuccess: isDeleteGroupSuccess,
      isError: isDeleteGroupError,
      error: deleteGroupError,
    },
  ] = useDeleteGroupMutation();

  const { isSuccess: isSubscriptionsSuccess, data: plans } =
    useGetSubscriptionPlansQuery("");

  const [
    startSubscription,
    {
      isLoading: isStartSubscriptionLoading,
      isSuccess: isStartSubscriptionSuccess,
      isError: isStartSubscriptionError,
      error: startSubscriptionError,
      data: startSubscriptionData,
    },
  ] = useStartSubscriptionMutation();

  useEffect(() => {
    if (isStartSubscriptionError) {
      setError(handleError(startSubscriptionError));
    }
  }, [isStartSubscriptionError, startSubscriptionError]);

  useEffect(() => {
    if (isStartSubscriptionSuccess) {
      //invalidate group cache
      dispatch(groupAPI.util.invalidateTags(["Group"]));
      //send user to the url returned by the api
      if (startSubscriptionData?.url)
        window.location.href = startSubscriptionData?.url;
      else navigate("/group/" + groupid + "/subscription");
    }
  }, [isStartSubscriptionSuccess, startSubscriptionData]);

  async function GroupDelete(event: React.FormEvent<HTMLFormElement>) {
    var { modalPassword } = document.forms[0];
    event.preventDefault();
    deleteGroup({ id: groupid ?? "", password: modalPassword.value ?? "" });
  }

  useEffect(() => {
    if (isDeleteGroupSuccess) {
      toast.success("Group Deleted.")
      navigate("/group");
    }
  }, [isDeleteGroupSuccess])

  const modals = (
    <>
      {showDeleteModal && (
        <InputModal
          onSubmit={GroupDelete}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          Title={language.viewEditGroup.modals.deleteModal.title}
          Loading={isDeleteGroupLoading}
          Body={
            <>
              {language.viewEditGroup.modals.deleteModal.body.phrase1}{" "}
              <b>{group.name}</b>
              {language.viewEditGroup.modals.deleteModal.body.phrase2}
            </>
          }
          Form={[
            {
              htmlFor: "modalPassword",
              label: language.viewEditGroup.modals.deleteModal.labels.password,
              type: "password",
              value: "",
              error: "",
              autoComplete: "current-password",
              onChange: () => { },
            },
          ]}
          AcceptButton={
            language.viewEditGroup.modals.deleteModal.buttons.accept
          }
          CancelButton={
            language.viewEditGroup.modals.deleteModal.buttons.cancel
          }
        />
      )}
    </>
  )

  return (
    <div className="w-full h-full rounded-md bg-white">
      <div className="p-4 bg-white rounded-md">
        {modals}
        {isSubscriptionsSuccess &&
          <div>
            <div className="pb-2">
              <div className="font-bold text-xl h-full w-full text-black bg-white rounded-lg py-2">
                {!group?.name ? (
                  <Skeleton width={100} height={27} />
                ) : (
                  group.name
                )}
              </div>
              <div className="w-full font-semibold text-lg rounded-md bg-gray-200 border-2 border-gray-700 p-4 flex items-center">
                <span className="text-blue-700 text-3xl pr-1">ⓘ</span>You can only be a member of one free group at a time. To set up a new group, you must choose a subscription.
              </div>
            </div>
            {width > mdBreakPoint ? (
              <div className="h-fit w-full overflow-x-scroll scrollbar-thin flex items-center justify-center bg-white">
                <div className="w-full h-full">
                  {/* <div>{error}</div> */}
                  <div className="text-center mx-auto">
                    <div
                      className="inline-flex space-x-1 text-2xl mb-1 border-2 rounded-md"
                      onClick={() => {
                        setBillingPeriod(!billingPeriod);
                      }}
                    >
                      <div
                        className={`select-none ${!billingPeriod
                          ? "bg-gray-200 opacity-100"
                          : "bg-white opacity-50"
                          }`}
                      >
                        Monthly
                      </div>
                      <div className="select-none">|</div>
                      <div
                        className={`select-none ${billingPeriod
                          ? "bg-gray-200 opacity-100"
                          : "bg-white opacity-50"
                          }`}
                      >
                        Yearly
                      </div>
                    </div>
                  </div>
                  <div className="min-w-fit w-full h-full overflow-x-scroll scrollbar-thin">
                    <table className="h-full w-full">
                      <colgroup>
                        <col span={1} className="min-w-[10rem] bg-white rounded-lg relative z-10 h-full" />
                        {plans.map(() => {
                          return (
                            <>
                              <col
                                span={1}
                                className="min-w-[10rem] bg-white rounded-lg relative z-10 border-2 border-opacity-20 h-full"
                              />
                              <col span={1} className="w-1.5" />
                            </>
                          );
                        })}
                      </colgroup>
                      <tbody>
                        <tr className="group">
                          <th></th>
                          {plans.map((subscription: any) => {
                            return (
                              <>
                                <td>
                                  <h1 className="group-hover:bg-gray-100 text-lg">
                                    {subscription.name}
                                  </h1>
                                </td>
                                <td className="group-hover:bg-white w-1.5"></td>
                              </>
                            );
                          })}
                        </tr>
                        <tr className="group">
                          <th className="group-hover:bg-gray-100">Price</th>
                          {plans.map((subscription: any) => {
                            return (
                              <>
                                <h2 className="font-bold group-hover:bg-gray-100 text-[42px]">
                                  {typeof subscription.price.annually.amount ===
                                    "number" ? (
                                    <>
                                      {billingPeriod
                                        ? "£" + (subscription.price.annually.amount / 100).toFixed(2)
                                        : "£" + (subscription.price.monthly.amount / 100).toFixed(2)}
                                      <span className="text-base font-medium">
                                        {billingPeriod ? "/ year" : "/ month"}
                                      </span>
                                    </>
                                  ) : (
                                    <>{subscription.price.annually.amount / 100}</>
                                  )}
                                </h2>
                                <td className="w-1.5"></td>
                              </>
                            );
                          })}
                        </tr>
                        <tr className="group">
                          <th></th>
                          {plans.map((subscription: any) => {
                            return (
                              <>
                                <td className="group-hover:bg-gray-100">
                                  <Button
                                    onClick={() => {
                                      startSubscription({
                                        price_id: billingPeriod
                                          ? subscription.price.annually
                                            .stripe_price_id
                                          : subscription.price.monthly
                                            .stripe_price_id,
                                        group_id: groupid ?? "",
                                      });
                                    }}
                                    label={
                                      isStartSubscriptionLoading ? (
                                        <Spinner colour="fill-green-600" />
                                      ) : (
                                        `Get ${subscription.name}`
                                      )
                                    }
                                    bgColour="white"
                                    hoverColour="green-500"
                                    focusColour="green-500"
                                    textColour="black"
                                    border="border border-gray-300"
                                  />
                                </td>
                                <td className="w-1.5"></td>
                              </>
                            );
                          })}
                        </tr>
                        {/* <tr className="group">
                      <th className="group-hover:bg-gray-100 border-b-2">
                        Extra Features
                      </th>
                      {plans.map((subscription: any) => {
                        return (
                          <>
                            <td className="border-b-2 group-hover:bg-gray-100">
                              <div className="h-full">
                                {subscription.extra_features?.map(
                                  (feature: any) => {
                                    return (
                                      <p className="h-max text-lg text-center group-hover:bg-gray-100">
                                        {feature}
                                      </p>
                                    );
                                  }
                                )}
                              </div>
                            </td>
                            <td className="w-1.5 border-b-0"></td>
                          </>
                        );
                      })}
                    </tr> */}
                        <tr className="group">
                          <th className="group-hover:bg-gray-100 border-b-2">
                            Devices
                          </th>
                          {plans.map((subscription: any) => {
                            return (
                              <>
                                <td className="text-lg text-center border-b-2 group-hover:bg-gray-100">
                                  {subscription.perks.devices}
                                </td>
                                <td className="w-1.5"></td>
                              </>
                            );
                          })}
                        </tr>
                        <tr className="group">
                          <th className="group-hover:bg-gray-100 border-b-2">
                            Users
                          </th>
                          {plans.map((subscription: any) => {
                            return (
                              <>
                                <td className="text-lg text-center border-b-2 group-hover:bg-gray-100">
                                  {subscription.perks.users}
                                </td>
                                <td className="w-1.5"></td>
                              </>
                            );
                          })}
                        </tr>
                        <tr className="group">
                          <th className="group-hover:bg-gray-100 border-b-2">
                            Dashboards
                          </th>
                          {plans.map((subscription: any) => {
                            return (
                              <>
                                <td className="text-lg text-center border-b-2 group-hover:bg-gray-100">
                                  {subscription.perks.dashboards}
                                </td>
                                <td className="w-1.5"></td>
                              </>
                            );
                          })}
                        </tr>
                        <tr className="group">
                          <th className="group-hover:bg-gray-100 border-b-2">
                            Max Dashboard Tiles
                          </th>
                          {plans.map((subscription: any) => {
                            return (
                              <>
                                <td className="text-lg text-center border-b-2 group-hover:bg-gray-100">
                                  {subscription.perks.dashboard_elements === -1 ? "Unlimited" : subscription.perks.dashboard_elements}
                                </td>
                                <td className="w-1.5"></td>
                              </>
                            );
                          })}
                        </tr>
                        <tr className="group">
                          <th className="group-hover:bg-gray-100">
                            Data Retention
                          </th>
                          {plans.map((subscription: any) => {
                            return (
                              <>
                                <td className="text-lg text-center group-hover:bg-gray-100">
                                  {formatDate(subscription.perks.data_retention.days, subscription.perks.data_retention.months, subscription.perks.data_retention.years)}
                                </td>
                                <td className="w-1.5"></td>
                              </>
                            );
                          })}
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                    <div className="w-full flex items-start">
                      <div className="w-auto mt-4 pr-2">
                        <Button
                          onClick={() => setShowDeleteModal(true)}
                          label={language.viewEditGroup.buttons.deleteGroup}
                          colour="red"
                          lock={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="h-full overflow-scroll scrollbar-none bg-white p-2 rounded-md">
                <div className="text-center mx-auto">
                  <div
                    className="inline-flex space-x-1 text-2xl mb-1 border-2 rounded-md"
                    onClick={() => {
                      setBillingPeriod(!billingPeriod);
                    }}
                  >
                    <div
                      className={`select-none ${!billingPeriod
                        ? "bg-gray-200 opacity-100"
                        : "bg-white opacity-50"
                        }`}
                    >
                      Monthly
                    </div>
                    <div className="select-none">|</div>
                    <div
                      className={`select-none ${billingPeriod
                        ? "bg-gray-200 opacity-100"
                        : "bg-white opacity-50"
                        }`}
                    >
                      Yearly
                    </div>
                  </div>
                </div>
                {plans.map((subscription: any) => {
                  return (
                    <div className="w-full h-fit pb-2">
                      <div className="bg-white rounded-xl relative z-5 overflow-hidden border border-primary border-opacity-20 shadow-pricing py-4 px-4 h-full">
                        <div>
                          <span className="text-primary font-semibold text-lg block">
                            {subscription.name}
                          </span>
                          <h2 className="font-bold text-dark mb-5 text-[42px]">
                            {typeof subscription.price.annually.amount ===
                              "number" ? (
                              <>
                                {billingPeriod
                                  ? "£" + subscription.price.annually.amount / 100
                                  : "£" + subscription.price.monthly.amount / 100}
                                <span className="text-base font-medium">
                                  {billingPeriod ? "/ year" : "/ month"}
                                </span>
                              </>
                            ) : (
                              <>{subscription.price.annually.amount / 100}</>
                            )}
                          </h2>
                        </div>
                        <div className="flex justify-between pb-4 w-full">
                          {/* <div>
                        {subscription.extra_features?.length > 0 && (
                          <div className="min-w-[6rem]">
                            {subscription.extra_features?.map(
                              (feature: any) => {
                                return <p className="text-lg">{feature}</p>;
                              }
                            )}
                          </div>
                        )}
                      </div> */}
                          <div className="w-full">
                            <div className="grid grid-cols-2 gap-2 mb-2">
                              {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 auto-cols-min"> */}
                              <div className="inline-flex w-fit space-x-1">
                                <h1 className="text-xl font-bold">
                                  {subscription.perks.devices}
                                </h1>
                                <h2 className="text-xl">{" Devices"}</h2>
                              </div>
                              <div className="inline-flex w-fit space-x-1">
                                <h1 className="text-xl font-bold">
                                  {subscription.perks.users}
                                </h1>
                                <h2 className="text-xl">{" Users"}</h2>
                              </div>
                              <div className="inline-flex w-fit space-x-1">
                                <h1 className="text-xl font-bold">
                                  {subscription.perks.dashboards}
                                </h1>
                                <h2 className="text-xl">{" Dashboards"}</h2>
                              </div>
                              <div className="flex flex-wrap w-fit space-x-1">
                                <h1 className="text-xl font-bold">
                                  {subscription.perks.dashboard_elements === -1 ? "Unlimited" : subscription.perks.dashboard_elements}
                                </h1>
                                <h2 className="text-xl">{" Dashboard Tiles"}</h2>
                              </div>
                            </div>
                            <div className="inline-flex items-center justify-center w-full space-x-1">
                              <h1 className="text-xl font-bold">
                                {formatDate(subscription.perks.data_retention.days, subscription.perks.data_retention.months, subscription.perks.data_retention.years)}
                              </h1>
                              <h2 className="text-xl">{" Data Retention"}</h2>
                            </div>
                          </div>
                        </div>
                        <button
                          onClick={() => {
                            startSubscription({
                              price_id: billingPeriod
                                ? subscription.price.annually.stripe_price_id
                                : subscription.price.monthly.stripe_price_id,
                              group_id: groupid ?? "",
                            });
                          }}
                          className="w-full block text-base font-semibold text-primary border border-[#D4DEFF] rounded-md text-center p-4 transition hover:bg-green-500 hover:text-white"
                        >
                          Get {subscription.name}
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        }
      </div>
    </div>
  );
}

export default Subscription;
