import React, { ReactElement, useEffect, useState } from "react";
import { useGetDevicesQuery } from "../../services/deviceAPI";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { IoMdSettings } from "react-icons/io";
import {
  MdLastPage,
  MdFirstPage,
  MdNavigateNext,
  MdNavigateBefore,
  MdOutlineDelete,
  MdSwitchLeft,
  MdSwitchRight,
} from "react-icons/md";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnFiltersState,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  FilterFn,
} from "@tanstack/react-table";

import { rankItem } from "@tanstack/match-sorter-utils";

import QuickEditModal from "../../components/modals/QuickEditModal";
import _ from "lodash";
import {
  GroupUser,
  InvitedUser,
  UserPermissions,
} from "../../features/groups/groupSlice";
import { Navigate, Outlet, useNavigate, useParams } from "react-router-dom";
import {
  useCancelInvitationMutation,
  useGetGroupQuery,
  useUpdateGroupMutation,
} from "../../services/groupAPI";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { handleError } from "../../utils/ErrorHandling";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage, selectUser } from "../../features/user/userSlice";
import { toast } from "react-toastify";
import { UserGroup } from "../../services/userAPI";
import AcceptModal from "../../components/modals/AcceptModal";
import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
} from "../../dictionary/GroupsText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import GroupObjectModal from "../../components/modals/GroupObjectModal";
import { Table } from "../../utils/Table";

const defaultData: GroupUser[] | InvitedUser[] = [];

export default function DeviceTable() {
  //const { data: devInfo, isSuccess, isLoading } = useGetDevicesQuery("");
  const user = useAppSelector(selectUser);
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(
    LanguageCheck(
      English,
      French,
      German,
      Dutch,
      Spanish,
      Italian,
      Romanian,
      Portuguese,
      stateLang
    )
  );

  useEffect(() => {
    setLanguage(
      LanguageCheck(
        English,
        French,
        German,
        Dutch,
        Spanish,
        Italian,
        Romanian,
        Portuguese,
        stateLang
      )
    );
  }, [stateLang]);

  const [editUserPermission, setEditUserPermission] = useState(false);
  const [viewUserPermission, setViewUserPermission] = useState(false);
  const [removeUserPermission, setRemoveUserPermission] = useState(false);
  const [showCancelInviteModal, setShowCancelInviteModal] = useState(false);
  const navigation = useNavigate();
  const { groupid } = useParams();
  const {
    data: groupInfo,
    isSuccess,
    isError,
    error,
  } = useGetGroupQuery(groupid ? { id: groupid } : skipToken);

  // } = useGetGroupQuery({ id: groupid ?? "" } ?? skipToken);

  useEffect(() => {
    if (user.groups) {
      if (user.groups[groupid as keyof UserGroup]) {
        const editPerms =
          user.groups[groupid as keyof UserGroup].permissions
            .can_edit_users_permissions !== undefined &&
          user.groups[groupid as keyof UserGroup].permissions
            .can_edit_users_permissions !== false;
        setEditUserPermission(editPerms ?? false);
        const viewPerms =
          user.groups[groupid as keyof UserGroup].permissions.can_view_users !==
            undefined &&
          user.groups[groupid as keyof UserGroup].permissions.can_view_users !== false;
        setViewUserPermission(viewPerms ?? false);
        const removePerms =
          user.groups[groupid as keyof UserGroup].permissions.can_remove_users !==
            undefined &&
          user.groups[groupid as keyof UserGroup].permissions.can_remove_users !== false;
        setRemoveUserPermission(removePerms ?? false);
      }
    }
  }, [user]);

  const [
    cancelInvitation,
    {
      isLoading: isCancelInvitationLoading,
      isSuccess: isCancelInvitationSuccess,
      isError: isCancelInvitationError,
      error: cancelInvitationGroupError,
    },
  ] = useCancelInvitationMutation();

  useEffect(() => {
    if (isCancelInvitationSuccess) {
      setShowCancelInviteModal(false);
      toast.success(language.inviteTable.toast.success);
    }
  }, [isCancelInvitationSuccess]);

  useEffect(() => {
    if (isCancelInvitationError) {
      toast.error(language.inviteTable.toast.error);
    }
  }, [isCancelInvitationError]);

  const [inviteTable, setInviteTable] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      const members = _.values(groupInfo?.users);
      const invMembers = _.values(groupInfo?.invited_users);
      inviteTable ? setData([...invMembers]) : setData([...members]);
    }
    return () => {};
  }, [isSuccess, groupInfo, inviteTable]);

  useEffect(() => {
    if (isError) {
      handleError(error);
    }
  }, [error]);

  const [data, setData] = React.useState([...defaultData]);
  const [cancelInviteEmail, setCancelInviteEmail] = useState("");
  const [showEditInviteModal, setShowEditInviteModal] = useState(false);
  const [invPermissions, setInvPermissions] = useState<UserPermissions>();
  var sliders: ReactElement = <></>;

  const columns: ColumnDef<any>[] = [
    {
      accessorFn: (row) => (inviteTable ? row.invited_email : row.username),
      id: "DeviceName",
      cell: ({ row, getValue }) => (
        <div className="flex ">
          {editUserPermission && (
            <IoMdSettings
              title={language.memberTable.tableElements.quickEdit}
              size={16}
              className={`hover:fill-black ${
                row.original?.user_id === user.id
                  ? "cursor-not-allowed"
                  : "cursor-pointer"
              }`}
              onClick={() => {
                if (row.original?.user_id === user.id) {
                  toast.error(language.memberTable.toast.cantEditOwnPerms);
                } else if (!inviteTable) {
                  navigation(`/group/${groupid}/members/${row.original?.user_id}/edit`);
                } else {
                  navigation(
                    `/group/${groupid}/members/${row.original.invited_email}/editinvite`,
                    {
                      state: {
                        id: 1,
                        invitePerms: row.original.permissions,
                        name: row.original.invited_email,
                      },
                    }
                  );
                }
              }}
            />
          )}
          <div className="ml-2">{getValue() as string}</div>
        </div>
      ),
      header: inviteTable
        ? language.inviteTable.tableElements.headers.email
        : language.memberTable.tableElements.headers.userName,
    },
    {
      accessorFn: (row) => (inviteTable ? row.invited_by : row.user_id),
      id: "DeviceID",
      cell: ({ row, getValue }) =>
        inviteTable
          ? _.values(groupInfo?.users).filter((d) => d.user_id === getValue())[0].username
          : getValue(),
      header: inviteTable
        ? language.inviteTable.tableElements.headers.invitedBy
        : language.memberTable.tableElements.headers.memberID,
    },
    {
      accessorFn: (row) => (inviteTable ? row.invite_date : row.join_date),
      id: "JoinDate",
      cell: ({ row, getValue }) =>
        inviteTable
          ? new Date(row.original?.invite_date ?? 0).toLocaleString("en-GB")
          : new Date(row.original?.join_date ?? 0).toLocaleString("en-GB"),
      header: inviteTable
        ? language.inviteTable.tableElements.headers.inviteDate
        : language.memberTable.tableElements.headers.joinDate,
    },
  ];

  if (inviteTable) {
    {
      columns.push({
        accessorFn: (row) => (
          <button
            className="inline-flex justify-center ml-3 w-auto text-sm"
            type="button"
            onClick={() => {
              setCancelInviteEmail(row.invited_email);
              setShowCancelInviteModal(true);
            }}
          >
            <MdOutlineDelete color="red" size={32} />
          </button>
        ),
        id: "delete",
        cell: ({ row, getValue }) => getValue(),
        header: language.inviteTable.tableElements.headers.cancelInvitation,
      });
    }
  }

  const modals = (
    <>
      {showCancelInviteModal && (
        <AcceptModal
          onAccept={() => {
            cancelInvitation({ id: groupid!, user_email: cancelInviteEmail });
          }}
          onCancel={() => {
            setShowCancelInviteModal(false);
          }}
          Title={language.inviteTable.modals.cancelInvite.title}
          Body={
            <>
              {language.inviteTable.modals.cancelInvite.body.phrase1}
              {cancelInviteEmail ? (
                <b>{cancelInviteEmail}</b>
              ) : (
                language.inviteTable.modals.cancelInvite.body.thisUser
              )}
              {language.inviteTable.modals.cancelInvite.body.phrase2}
              {cancelInviteEmail ? (
                <b>{cancelInviteEmail}</b>
              ) : (
                language.inviteTable.modals.cancelInvite.body.thisUser
              )}
              {language.inviteTable.modals.cancelInvite.body.phrase3}
            </>
          }
          AcceptButton={language.inviteTable.modals.cancelInvite.buttons.accept}
          AcceptButtonColour={"red"}
          CancelButton={language.inviteTable.modals.cancelInvite.buttons.cancel}
        />
      )}
      {showEditInviteModal && (
        <GroupObjectModal
          onRemove={() => {
            setShowCancelInviteModal(true);
          }}
          onAccept={() => {
            //console.log("d")
          }}
          onCancel={() => {
            setShowEditInviteModal(false);
          }}
          Title={"Edit Invitation to "}
          Body={sliders}
          AcceptButton="Save"
          CancelButton="Cancel"
          RemoveButton="Remove"
        />
      )}
    </>
  );

  const tableTitle = (
    <div className="flex">
      <button
        className={`py-2 px-4 mr-4 text-xs font-bold rounded size-2 ${
          inviteTable ? "bg-gray-300 text-gray-700" : "bg-blue-500 text-white"
        }`}
        disabled={editUserPermission && viewUserPermission ? false : true}
        onClick={() => {
          setInviteTable(false);
        }}
      >
        {language.memberTable.members}
      </button>
      <button
        className={`py-2 px-4 mr-4 text-xs font-bold rounded ${
          inviteTable ? "bg-blue-500 text-white" : "bg-gray-300 text-gray-700"
        }`}
        disabled={editUserPermission && viewUserPermission ? false : true}
        onClick={() => {
          setInviteTable(true);
        }}
      >
        {language.inviteTable.outstandingInvitations}
      </button>
    </div>
  );

  var table: JSX.Element = <></>;

  table = Table(
    data,
    columns,
    tableTitle,
    language.memberTable.tableElements.noMembersFound,
    true
  );

  return (
    <>
      <Outlet />
      {modals}
      {table}
    </>
  );
}
