import React, { Fragment, useState } from "react";
import { FiSettings } from "react-icons/fi";
import { FaTrash } from "react-icons/fa";
import { Menu, Transition } from "@headlessui/react";
import { BiGridVertical } from "react-icons/bi";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type MenuItemProps = {
  active: boolean;
};

type ChartSelectProps = {
  onSelect: () => void;
  showEdit: boolean;
  setShowChartEdit: (show: boolean) => void;
};

function DashboardBlockEdit({ onSelect, showEdit, setShowChartEdit }: ChartSelectProps) {
  const [editItem, setEditItem] = useState(true);

  return (
    <>
      {showEdit ? (
        <span className="absolute right-0 top-0 cursor-pointer z-over-map bg-white p-1 rounded-lg flex flex-row">
          <button
            className="px-1 py-1 rounded-lg text-gray-900 cursor-pointer"
            onClick={() => {
              onSelect();
              setShowChartEdit(true);
            }}
          >
            <FiSettings size={24} />
          </button>
          <div className="px-1 py-1 rounded-lg text-gray-900 draggable cursor-move">
            <BiGridVertical size={24} />
          </div>
        </span>
      ) : null}
    </>
  );
}

export default DashboardBlockEdit;

type NewItemProps = {
  name: string;
  onClick: () => void;
  icon: React.ReactNode;
};
const NewItem = ({ name, icon, onClick }: NewItemProps) => {
  return (
    <Menu.Item>
      {({ active }: MenuItemProps) => (
        <div
          onClick={onClick}
          className={classNames(
            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
            "px-4 py-2 text-sm cursor-pointer flex flex-row items-center"
          )}
        >
          {icon}
          <div className="ml-2 font-bold">{name}</div>
        </div>
      )}
    </Menu.Item>
  );
};
