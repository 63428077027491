import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  MdDashboard,
  MdSensors,
  MdGroups,
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
  MdFolderOpen,
  MdThermostat,
  MdAdd,
  MdGroup,
} from "react-icons/md";
import { GrBeacon } from "react-icons/gr";
import { BsGraphUp } from "react-icons/bs";
import useWindowDimensions, {
  mdBreakPoint,
} from "../../utils/ScreenDimensions";
import { Device, selectDevices } from "../../features/devices/deviceSlice";
import { useAppSelector } from "../../app/hooks";
import AnimateHeight from "react-animate-height";
import { useGetDashboardsQuery } from "../../services/dashboardAPI";
import _ from "lodash";
import { Group, selectGroups } from "../../features/groups/groupSlice";
import { Dashboard, selectDashboards } from "../../features/dashboards/dashboardSlice";
import { useGetDevicesQuery } from "../../services/deviceAPI";
import { useGetGroupQuery, useGetGroupsQuery } from "../../services/groupAPI";
import { Tooltip } from "flowbite-react";
import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
} from "../../dictionary/NavText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { selectLanguage } from "../../features/user/userSlice";

type Props = {
  sideBarIsOpen: boolean;
  setIsSideBarOpen: (isOpen: boolean) => void;
};

export const SideBar = forwardRef(
  (
    { sideBarIsOpen, setIsSideBarOpen }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { devices } = useAppSelector(selectDevices);
    const { groups } = useAppSelector(selectGroups);
    const { dashboards } = useAppSelector(selectDashboards);
    const stateLang = useAppSelector(selectLanguage);
    let [language, setLanguage] = useState(
      LanguageCheck(
        English,
        French,
        German,
        Dutch,
        Spanish,
        Italian,
        Romanian,
        Portuguese,
        stateLang
      )
    );

    useEffect(() => {
      setLanguage(
        LanguageCheck(
          English,
          French,
          German,
          Dutch,
          Spanish,
          Italian,
          Romanian,
          Portuguese,
          stateLang
        )
      );
    }, [stateLang]);

    const navigate = useNavigate();

    var groupedDevices: { [key: string]: Device[] } = {};
    var groupedDashboards: { [key: string]: Dashboard[] } = {};
    var groupedGroups: { [key: string]: Group[] } = {};

    _.map(dashboards, (dashboard) => {
      if (dashboard.group_id in groupedDashboards) {
        groupedDashboards[dashboard.group_id].push(dashboard);
      } else {
        groupedDashboards[dashboard.group_id] = [dashboard];
      }
    });
    groupedDevices = Object.keys(groupedDevices).sort().reduce((r: typeof groupedDevices, k) => ((r[k] = groupedDevices[k]), r), {});

    _.map(devices, (device) => {
      if (device.group_id in groupedDevices) {
        groupedDevices[device.group_id].push(device);
      } else {
        groupedDevices[device.group_id] = [device];
      }
    });
    groupedDashboards = Object.keys(groupedDashboards).sort().reduce((r: typeof groupedDashboards, k) => ((r[k] = groupedDashboards[k]), r), {});

    _.map(groups, (group) => {
      if (group.id in groupedGroups) {
        groupedGroups[group.id].push(group);
      } else {
        groupedGroups[group.id] = [group];
      }
    })
    groupedGroups = Object.keys(groupedGroups).sort().reduce((r: typeof groupedGroups, k) => ((r[k] = groupedGroups[k]), r), {});
    
    return (
      <div
        className={`h-full p-3 w-64 rounded bg-white shadow-md flex flex-col justify-between py-6 select-none overflow-y-scroll scrollbar-thin`}
        ref={ref}
      >
        <div>
          <ul>
            <li>
              <Dropdown
                title={language.sideBar.dashboards}
                icon={<MdDashboard size={24} />}
                path="/dashboard"
                data={groupedDashboards}
                newText={language.sideBar.createDashboard}
                onNew={() => {
                  setIsSideBarOpen(false);
                  navigate("/dashboard/create");
                }}
                setSideBarOpen={setIsSideBarOpen}
              />
            </li>
            <li>
              <Dropdown
                title={language.sideBar.devices}
                icon={<GrBeacon size={24} />}
                data={groupedDevices}
                path="/device"
                newText={language.sideBar.claimDevice}
                onNew={() => {
                  setIsSideBarOpen(false);
                  navigate("/device/claim");
                }}
                setSideBarOpen={setIsSideBarOpen}
              />
            </li>
            <li>
              <Dropdown
                title={language.sideBar.groups}
                icon={<MdGroups size={24} />}
                path="/group"
                data={groupedGroups}
                newText={language.sideBar.createGroup}
                onNew={() => {
                  setIsSideBarOpen(false);
                  navigate("/group/create");
                }}
                setSideBarOpen={setIsSideBarOpen}
              />
            </li>
          </ul>
        </div>
      </div>
    );
  }
);

type DropdownProps = {
  title: string;
  icon: React.ReactNode;
  data?: { [key: string]: (Dashboard[] | Device[] | Group[]) };
  path: string;
  newText: string;
  onNew: (...args: any) => any;
  setSideBarOpen: (isOpen: boolean) => void;
};

const Dropdown = ({
  title,
  icon,
  data,
  path,
  newText,
  onNew,
  setSideBarOpen,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row">
          <button
            className="flex items-center rounded-lg p-2 text-base font-normal text-gray-900 transition duration-75 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
            type="button"
            onClick={
              () => {
                navigate(path);
                setSideBarOpen(false);
              } /* setIsOpen(!isOpen)*/
            }
          >
            {icon}
            <span className="ml-3 flex-1 whitespace-nowrap text-left font-bold">
              {title}
            </span>
          </button>
          <button
            className="flex items-center rounded-lg p-2 text-base font-normal text-gray-900 transition duration-75 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
            type="button"
            onClick={() => /*navigate(path)*/ setIsOpen(!isOpen)}
          >
            {isOpen ? (
              <MdKeyboardArrowDown size={24} />
            ) : (
              <MdKeyboardArrowRight size={24} />
            )}
          </button>
        </div>
        <button
          className="flex items-center rounded-lg p-2 text-base font-normal text-gray-900 transition duration-75 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
          type="button"
          onClick={() => onNew()}
        >
          <MdAdd size={24} />
        </button>
      </div>
      <AnimateHeight
        duration={500}
        height={isOpen ? "auto" : 0}
        className="rounded-lg ml-4 "
      >
        {Object.entries(data!).map((groupItems, i) => {
          return (
            <ul key={i}>
              {i !== 0 && path !== "/group" && <hr />}
              {groupItems[1].map((listing, index) => {
                return (
                  <li key={index}>
                    <Link
                      className="flex flex-row py-2 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 rounded-lg duration-75"
                      to={`${path}/${listing.id}`}
                      onClick={() => setSideBarOpen(false)}
                    >
                      {{
                        "/dashboard": <BsGraphUp size={20} />,
                        "/group": <MdGroup size={22} />,
                        "/device": <MdSensors size={24} />
                      }[path] || <MdThermostat size={24} />
                      }
                      <span
                        className="ml-3 flex-1 truncate relative overflow-hidden whitespace-nowrap text-ellipsis"
                      >
                        {listing.name}
                      </span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          )
        }
        )}
      </AnimateHeight>
    </>
  );
};

type DeviceListProps = {
  device: Device;
};

const DeviceListing = ({ device }: DeviceListProps) => {
  return (
    <li className="">
      <MdThermostat size={24} />
      <span className="ml-3 flex-1 truncate relative" title={device.eui ?? ""}>
        {device.eui}
      </span>
    </li>
  );
};

const CollectionListing = ({ device }: DeviceListProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <button
        className={`group flex w-full items-center rounded-lg p-2 text-base font-normal text-gray-900 transition duration-75 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700`}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <MdFolderOpen size={24} />
        <span className="ml-3 flex-1 whitespace-nowrap text-left">
          {device.eui}
        </span>
        {isOpen ? (
          <MdKeyboardArrowDown size={24} />
        ) : (
          <MdKeyboardArrowRight size={24} />
        )}
      </button>
      <AnimateHeight
        duration={500}
        height={isOpen ? "auto" : 0}
        className="rounded-lg ml-4 "
      >
        <ul className="space-y-2">
          <DeviceListing device={device} />
        </ul>
      </AnimateHeight>
    </>
  );
};
