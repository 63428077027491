import React, { useEffect } from "react";
import {
  Navigate,
  NavigateFunction,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import {
  GroupSubscription,
  selectGroups,
} from "../../features/groups/groupSlice";
import { useGetGroupsQuery } from "../../services/groupAPI";
import moment from "moment";
import useWindowDimensions from "../../utils/ScreenDimensions";
import _ from "lodash";
import { useGetPaymentConfirmationQuery } from "../../services/subscriptionAPI";
import { handleError } from "../../utils/ErrorHandling";
import { Button } from "../../components/Button";
import Spinner from "../../components/Spinner";
import { MdEmail, MdPhone } from "react-icons/md";

function SubscriptionConfirmation() {
  const { width } = useWindowDimensions();
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { isError, error, isLoading, isSuccess, data } =
    useGetPaymentConfirmationQuery(
      {
        subscription_id: searchParams.get("token") ?? "",
      },
      { pollingInterval: 10000 }
    );

  //check if there is a token in the url
  if (
    !searchParams.get("token") ||
    (searchParams.get("token") ?? "").length !== 24
  ) {
    return (
      <div className="h-screen w-full flex items-center justify-center overflow-auto">
        <div className="w-full max-w-3xl">
          <SubscriptionConfirmationError
            error={
              "The token provided is not valid. Please contact support if you have completed a payment."
            }
            navigate={navigate}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen w-full flex items-center justify-center overflow-auto">
      <div className="w-full max-w-3xl">
        {isLoading && (
          <div className="bg-white shadow-md rounded px-2 pt-6 pb-2 mb-4">
            <div className="mb-2">
              <h1 className="text-center text-2xl font-bold text-gray-900">
                Loading...
              </h1>
              <div className="w-full flex justify-center my-5">
                <Spinner colour="fill-rpr-blue" />
              </div>
            </div>
          </div>
        )}
        {isSuccess && (
          <SubscriptionConfirmationSuccess
            data={data}
            navigate={navigate}
            width={width}
          />
        )}
        {isError && error && (
          <SubscriptionConfirmationError error={error} navigate={navigate} />
        )}
      </div>
    </div>
  );
}

export default SubscriptionConfirmation;

type SubscriptionConfirmationSuccessProps = {
  data: {
    group_name: string;
    group_id: string;
    subscription: GroupSubscription;
  };
  navigate: NavigateFunction;
  width: number;
};

const SubscriptionConfirmationSuccess = ({
  data,
  navigate,
  width,
}: SubscriptionConfirmationSuccessProps) => {
  return (
    <div className="bg-white shadow-md rounded px-2 pt-6 pb-2 mb-4">
      <div className="mb-2">
        <h1 className="text-center text-2xl font-bold text-gray-900">
          Subscription Purchased Successfully
        </h1>
      </div>
      <div className="mb-4">
        <p className="text-center text-gray-700 font-semibold text-md">
          {data.subscription.plan_name} bought for {data.group_name}
        </p>
      </div>
      <hr className="p-2 mt-2" />
      <div className="sm:flex">
        <div className="w-full sm:w-1/3">
          <p className="text-center text-gray-700 font-semibold text-lg">
            Subscription
          </p>
          <div className="h-full text-center text-gray-700 font-semibold text-sm space-y-2">
            <p>Tier: {data.subscription.plan_name}</p>
            <p>
              Start Date:{" "}
              {moment(data.subscription.start_date).format("DD/MM/YYYY HH:mm")}
            </p>
            <p>
              Renewal Date:{" "}
              {moment(data.subscription.renewal_date).format(
                "DD/MM/YYYY HH:mm"
              )}
            </p>
            <p>
              Renewal Period:{" "}
              {data.subscription.renewal_period === "month"
                ? "Monthly"
                : "Annually"}
            </p>
            <p>
              Cost:{" "}
              {data.subscription.price === 0
                ? data.subscription.price / 10
                : "Free"}
            </p>
          </div>
        </div>
        {width < 640 && <hr className="p-2 mt-2" />}
        <div className="w-full sm:w-2/3">
          <p className="text-center text-gray-700 font-semibold text-lg">
            Perks
          </p>
          <div>
            <div className="sm:flex flex-row justify-between">
              <div className="flex flex-col">
                <div className="inline-flex w-fit space-x-1">
                  <h1 className="text-5xl font-bold">
                    {data.subscription.perks.devices}
                  </h1>
                  <h2 className="text-lg flex items-end">{" Devices"}</h2>
                </div>
                <div className="inline-flex w-fit space-x-1">
                  <h1 className="text-5xl font-bold">
                    {data.subscription.perks.users}
                  </h1>
                  <h2 className="text-lg flex items-end">{" Users"}</h2>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="inline-flex w-fit space-x-1">
                  <h1 className="text-5xl font-bold">
                    {data.subscription.perks.dashboards}
                  </h1>
                  <h2 className="text-lg flex items-end">{" Dashboards"}</h2>
                </div>
                <div className="inline-flex w-fit space-x-1">
                  <h1 className="text-5xl font-bold">
                    {data.subscription.perks.data_retention.days === 0
                      ? ""
                      : data.subscription.perks.data_retention.days === 1
                      ? data.subscription.perks.data_retention.days + " day"
                      : data.subscription.perks.data_retention.days + " days"}
                    {data.subscription.perks.data_retention.months === 0
                      ? ""
                      : data.subscription.perks.data_retention.months === 1
                      ? data.subscription.perks.data_retention.months + " month"
                      : data.subscription.perks.data_retention.months +
                        " months"}
                    {data.subscription.perks.data_retention.years === 0
                      ? ""
                      : data.subscription.perks.data_retention.years === 1
                      ? data.subscription.perks.data_retention.years + " year"
                      : data.subscription.perks.data_retention.years + " years"}
                  </h1>
                  <h2 className="text-lg flex items-end">
                    {" Data Retention"}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          {/* <div>
                  {subscription.extra_features?.length! > 0 && (
                    <ol>
                      {subscription.extra_features?.map((feature: any) => {
                        return <li className="text-lg">{feature}</li>;
                      })}
                    </ol>
                  )}
                </div> */}
        </div>
      </div>
      <hr className="p-2 pb-1 mt-2" />
      <div className="px-4">
        <p className="text-center text-gray-700 text-md">
          Unless canceled, your subscription will automatically renew on{" "}
          {moment(data.subscription.renewal_date).format("DD/MM/YYYY HH:mm")}.
        </p>
      </div>
      <hr className="p-2 mt-2" />
      <div>
        <div className="w-full flex justify-between">
          <div>
            <button
              className="mr-2 inline-flex justify-center rounded-md border shadow-sm px-4 py-2 bg-gray-200 text-base font-medium text-black hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100 w-auto sm:text-sm "
              onClick={() => {}}
            >
              View Invoice
            </button>
          </div>
          <div>
            <button
              className="mr-2 inline-flex justify-center rounded-md border shadow-sm px-4 py-2 bg-gray-200 text-base font-medium text-black hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100 w-auto sm:text-sm "
              onClick={() => {
                navigate("/group/" + data.group_id);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

type SubscriptionConfirmationErrorProps = {
  error: any;
  navigate: NavigateFunction;
};

const SubscriptionConfirmationError = ({
  error,
  navigate,
}: SubscriptionConfirmationErrorProps) => {
  const errorType = typeof error;
  if (errorType === "string") {
    return (
      <div className="bg-white shadow-md rounded px-2 pt-6 pb-2 mb-4">
        <div className="mb-2">
          <h1 className="text-center text-2xl font-bold text-gray-900">
            Error
          </h1>
        </div>
        <div className="mb-4">
          <p className="text-center text-gray-700 font-semibold text-md">
            {error}
          </p>
        </div>
        <div className="m-4 text-gray-500 font-semibold text-md">
          <p>
            Richard Paul Russell Ltd <br />
            The Lodge <br />
            Unit 1 Barnes Farm Business Park <br />
            Barnes Lane, Milford on Sea <br />
            SO41 0AP
          </p>
          <div>
            <div className="w-full inline-flex space-x-2">
              <MdEmail size={18} className="mt-1" />
              <div>
                <a href="mailto:sales@r-p-r.co.uk">sales@r-p-r.co.uk</a>
              </div>
            </div>
            <div className="w-full inline-flex space-x-2">
              <MdPhone size={18} className="mt-1" />
              <div>
                <a href="tel:+4401590641223">+44 (0) 1590 641223</a>
              </div>
            </div>
          </div>
        </div>
        <Button
          label={"Home"}
          onClick={() => {
            navigate("/");
          }}
          colour="blue"
        />
      </div>
    );
  } else {
    return (
      <div className="bg-white shadow-md rounded px-2 pt-6 pb-2 mb-4">
        <div className="mb-2">
          <h1 className="text-center text-2xl font-bold text-gray-900">
            Error
          </h1>
        </div>
        <div className="mb-4">
          <p className="text-center text-gray-700 font-semibold text-md">
            {handleError(error)}
          </p>
          <div className="w-full flex justify-center my-5">
            <Spinner colour="fill-rpr-blue" />
          </div>
          <p className="text-center text-gray-400 font-semibold text-md">
            We are waiting for confirmation from the payment provider. This page
            will update automatically when the payment has been confirmed.
          </p>
        </div>
        <Button
          label={"Home"}
          onClick={() => {
            navigate("/");
          }}
          colour="blue"
        />
      </div>
    );
  }
};
