import { useAppSelector } from "../../../../app/hooks";
import { useEffect, useState } from "react";
import { ChartType, LineChartData } from ".././index";
import _ from "lodash";
import { DataType, selectData } from "../../../../features/data/dataSlice";
import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
} from "../../../../dictionary/ChartsText";
import { selectLanguage } from "../../../../features/user/userSlice";
import { LanguageCheck } from "../../../../utils/LanguageCheck";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import moment from "moment";
import { selectDevices } from "../../../../features/devices/deviceSlice";
import Spinner from "../../../Spinner";

type Props = {
  ChartData: ChartType;
  FetchSuccess: boolean;
  FetchError: boolean;
};

type SingleDeviceDataType = {
  [key: string]: {
    [key: string]: number | string
  }
};

type SeriesType = {
  [key: string]: SingleDeviceDataType
};

type HeaderTypes = ("Date" | "Device" | "Parameter" | "Value");

function ComparisonTable({ ChartData, FetchSuccess, FetchError }: Props) {

  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  const nodeData = useAppSelector(selectData);
  let { devices } = useAppSelector(selectDevices);

  const [dataAvailable, setDataAvailable] = useState(false);
  const [lines, setLines] = useState<LineChartData[]>(ChartData.chartData ?? []);

  const [tableWidth, setTableWidth] = useState(0);

  const [groupedSeries, setGroupedSeries] = useState<SeriesType>({});
  const [sortHeader, setSortHeader] = useState<HeaderTypes>("Date");
  const [sortDirection, setSortDirection] = useState<string>("desc");

  const getLatestPoints = (
    lines: LineChartData[]
  ) => {
    var tempSeries: SeriesType = {};
    lines.map((line) => {
      var singleDeviceData: SingleDeviceDataType = {}
      if (line.deviceID !== undefined && tempSeries[line.deviceID] !== undefined) (
        Object.assign(singleDeviceData, tempSeries[line.deviceID])
      )
      if (nodeData) {
        if (line !== undefined && lines.length > 0) {
          if (line.deviceID !== undefined && line.phenomena !== undefined && line.elemID !== undefined) {
            if (
              nodeData.data[line.deviceID as keyof DataType] !== undefined
            ) {
              let latestValue =
                nodeData.data[line.deviceID as keyof DataType][
                nodeData.data[line.deviceID as keyof DataType].length - 1
                ];
              if (latestValue.data_points[line.phenomena] !== undefined) {
                setDataAvailable(true);
                if (singleDeviceData[line.phenomena] === undefined) (
                  singleDeviceData[line.phenomena] = ({} as { [key: string]: number | string })
                )
                singleDeviceData[line.phenomena][line.elemID] = (
                  latestValue.data_points[line.phenomena][line.elemID]
                );
                singleDeviceData[line.phenomena]["ts"] = (
                  moment(new Date(latestValue.ts))
                    .local()
                    .format(
                      "DD/MM/YYYY HH:mm:ss"
                    )
                );
              }
            }
          }
        }
        if (line.deviceID !== undefined) {
          tempSeries[line.deviceID] = singleDeviceData;
        }
      }
    });
    return (tempSeries);
  };

  function GetLinesFromParams() {
    setLines(ChartData.chartData)
  }

  function GetGroupedSeriesFromParams() {
    if (nodeData) {
      if (lines.length > 0) {
        var tempseries: SeriesType = getLatestPoints(lines);
        if (tempseries) {
          setGroupedSeries(tempseries);
        }
      }
    }
  }

  useEffect(() => {
    GetLinesFromParams()
  }, [ChartData.chartData]);

  useEffect(() => {
    GetGroupedSeriesFromParams()
  }, [nodeData, lines, FetchSuccess, FetchError]);

  function flipSortDirection() {
    if (sortDirection === "asc") {
      return ("desc");
    } else {
      return ("asc")
    }
  }

  function SetSelectedSort({ header }: { header: HeaderTypes }) {
    setSortDirection(flipSortDirection());
    setSortHeader(header);
    setLines(sortLinesByHeader(groupedSeries, lines, header, flipSortDirection()))
  }

  const TableHeader = (headerType: HeaderTypes): JSX.Element => (
    <th scope="col">
      <div className="flex items-center justify-center" onClick={() => { SetSelectedSort({ header: headerType }) }}>
        {headerType}
        {sortHeader === headerType && (
          sortDirection === "asc" ? <FaSortDown size={16} /> : <FaSortUp size={16} />
        )}
      </div>
    </th>
  )

  const TableBox = (value: JSX.Element): JSX.Element => (
    <td className="border border-x-0 w-max sm:w-fit px-0.5">
      <div className="flex items-center justify-center text-center">
        {value}
      </div>
    </td>
  );

  return (
    <>
      {
        groupedSeries && (
          lines[0].deviceID === "new" ? (
            <div className="w-full h-full flex items-center justify-center">
              Select a Device to Show Data</div>
          ) : (
            lines[0]?.phenomena === "new" ? (
              <div className="w-full h-full flex items-center justify-center">
                Select a Parameter to Show Data
              </div>
            ) : (
              FetchSuccess ? (
                dataAvailable ? (
                  <div className="w-full h-full flex flex-wrap overflow-auto scrollbar-none">
                    <div className="w-full h-full block overflow-scroll scrollbar-none border-collapse">
                      <table className="table table-fixed w-max sm:w-full h-full border-collapse" key={lines.length} ref={(ref) => { if (ref) { setTableWidth(ref.getBoundingClientRect().width) } }}>
                        <thead className="sticky top-0 bg-white">
                          <tr>
                            <td colSpan={4}>
                              {ChartData.title.en !== undefined && ChartData.title.en !== "" &&
                                <div className="w-full h-min">
                                  <div className="font-semibold text-xl w-full">
                                    {ChartData.title.en}
                                  </div>
                                  <hr />
                                </div>}
                            </td>
                          </tr>
                          <tr>
                            {
                              ["Date" as HeaderTypes, "Device" as HeaderTypes, "Parameter" as HeaderTypes, "Value" as HeaderTypes].map((header) => (
                                TableHeader(header)
                              ))
                            }
                          </tr>
                        </thead>

                        <tbody>
                          {
                            lines.map((line, index) => {
                              if (line !== undefined && line.phenomena !== undefined && line.elemID !== undefined && line.deviceID !== undefined) {
                                return (
                                  <tr key={index} className={`w-full border border-x-0 bg-white hover:bg-gray-300 ${index % 2 === 0 && "bg-gray-100"}`}>
                                    <td className="border border-x-0">
                                      <div className={`flex items-center justify-center text-left ${tableWidth > 600 ? "w-max" : "w-min"}`}>
                                        {
                                          groupedSeries[line.deviceID] !== undefined ? (
                                            groupedSeries[line.deviceID][line.phenomena] !== undefined ? (
                                              groupedSeries[line.deviceID][line.phenomena]["ts"].toString()
                                            ) : "-"
                                          ) : "-"
                                        }
                                      </div>
                                    </td>
                                    {TableBox(_.values(devices).filter(n => n.eui === line.deviceID)[0] !== undefined ? <>{_.values(devices).filter(n => n.eui === line.deviceID)[0].name}</> : <>{line.deviceID}</>)}
                                    {TableBox(<>{getParamFieldFromMarker({ deviceID: line.deviceID, phenomena: line.phenomena, elemID: line.elemID }, "description")}</>)}
                                    {TableBox(<>{
                                      groupedSeries[line.deviceID] !== undefined ? (
                                        groupedSeries[line.deviceID][line.phenomena] !== undefined ? (
                                          groupedSeries[line.deviceID][line.phenomena][line.elemID] !== undefined ? (
                                            !isNaN(Number(groupedSeries[line.deviceID][line.phenomena][line.elemID])) && Math.round((Number(groupedSeries[line.deviceID][line.phenomena][line.elemID]) * 10)) / 10
                                          ) : "-"
                                        ) : "-"
                                      ) : "-"
                                    }</>)}
                                  </tr>
                                )
                              }
                            })
                          }
                        </tbody>
                      </table >
                    </div>
                  </div >
                ) : (
                  <div className="w-full h-full flex items-center justify-center">No Data Within Range</div>
                )
              ) : (
                <div className="w-full h-full flex items-center justify-center"><Spinner colour="fill-blue-600" /></div>
              )
            )
          )
        )
      }
    </>
  );

  function getParamFieldFromMarker(m: { deviceID: string; phenomena: string; elemID: string; }, field: "description" | "unit"): string {
    var returnField = "";
    _.map(_.values(devices).filter(d => d.eui === m.deviceID), (device) => {
      _.map(_.entries(device.configuration).filter(c => c[0] === m.phenomena), (phenomena) => {
        _.map(_.values(phenomena[1]).filter(p => p.elemID === m.elemID), (elem) => {
          field === "description" ? returnField = elem.description : returnField = elem.unit;
        })
      })
    })
    return returnField;
  }

  function sortLinesByHeader(groupedSeries: SeriesType, sortedLines: LineChartData[], header: HeaderTypes, sortDirection: string) {
    var tempSortedSeries: SeriesType = groupedSeries;
    var tempSortedLines: LineChartData[] = [...sortedLines];

    var validSortedLines = tempSortedLines.filter((line) => {
      if (line.deviceID !== undefined && line.phenomena !== undefined && line.elemID !== undefined) {
        if (tempSortedSeries?.[line.deviceID]?.[line.phenomena]?.[line.elemID] !== undefined) {
          return line;
        }
      }
    });

    var invalidSortedLines = tempSortedLines.filter((line) => {
      if (line.deviceID !== undefined && line.phenomena !== undefined && line.elemID !== undefined) {
        if (tempSortedSeries?.[line.deviceID]?.[line.phenomena]?.[line.elemID] === undefined) {
          return line;
        }
      } else return line;
    });

    tempSortedLines = validSortedLines;

    if (tempSortedLines[0].deviceID !== undefined && tempSortedLines[0].phenomena !== undefined && tempSortedLines[0].elemID !== undefined) {
      if (tempSortedSeries?.[tempSortedLines[0].deviceID]?.[tempSortedLines[0].phenomena] !== undefined) {
        switch (header) {
          case "Date":
            if (sortDirection === "asc") {
              tempSortedLines = tempSortedLines.sort((a, b) => {
                return (
                  moment(tempSortedSeries[a.deviceID!][a.phenomena!]["ts"] as string, "DD/MM/YYYY HH:mm:ss").unix() -
                  moment(tempSortedSeries[b.deviceID!][b.phenomena!]["ts"] as string, "DD/MM/YYYY HH:mm:ss").unix()
                );
              });
            } else {
              tempSortedLines = tempSortedLines.sort((a, b) => {
                return (
                  moment(tempSortedSeries[b.deviceID!][b.phenomena!]["ts"] as string, "DD/MM/YYYY HH:mm:ss").unix() -
                  moment(tempSortedSeries[a.deviceID!][a.phenomena!]["ts"] as string, "DD/MM/YYYY HH:mm:ss").unix()
                );
              });
            }
            break;
          case "Device":
            if (sortDirection === "asc") {
              tempSortedLines = tempSortedLines.sort((a, b) => {
                return (
                  _.values(devices).filter(n => n.eui === a.deviceID)[0] !== undefined ? _.values(devices).filter(n => n.eui === a.deviceID)[0].name : a.deviceID as string).localeCompare(
                    _.values(devices).filter(n => n.eui === b.deviceID)[0] !== undefined ? _.values(devices).filter(n => n.eui === b.deviceID)[0].name : b.deviceID as string
                  );
              });
            } else {
              tempSortedLines = tempSortedLines.sort((a, b) => {
                return (
                  _.values(devices).filter(n => n.eui === b.deviceID)[0] !== undefined ? _.values(devices).filter(n => n.eui === b.deviceID)[0].name : b.deviceID as string).localeCompare(
                    _.values(devices).filter(n => n.eui === a.deviceID)[0] !== undefined ? _.values(devices).filter(n => n.eui === a.deviceID)[0].name : a.deviceID as string
                  );
              });
            }
            break;
          case "Parameter":
            if (sortDirection === "asc") {
              tempSortedLines = tempSortedLines.sort((a, b) => {
                return (
                  getParamFieldFromMarker({ deviceID: a.deviceID!, phenomena: a.phenomena!, elemID: a.elemID! }, "description")).localeCompare(
                    getParamFieldFromMarker({ deviceID: b.deviceID!, phenomena: b.phenomena!, elemID: b.elemID! }, "description")
                  );
              });
            } else {
              tempSortedLines = tempSortedLines.sort((a, b) => {
                return (
                  getParamFieldFromMarker({ deviceID: b.deviceID!, phenomena: b.phenomena!, elemID: b.elemID! }, "description")).localeCompare(
                    getParamFieldFromMarker({ deviceID: a.deviceID!, phenomena: a.phenomena!, elemID: a.elemID! }, "description")
                  );
              });
            }
            break;
          case "Value":
            if (sortDirection === "asc") {
              tempSortedLines = tempSortedLines.sort((a, b) => {
                return (
                  tempSortedSeries[a.deviceID!][a.phenomena!][a.elemID!] as number) -
                  (tempSortedSeries[b.deviceID!][b.phenomena!][b.elemID!] as number)
              });
            } else {
              tempSortedLines = tempSortedLines.sort((a, b) => {
                return (
                  tempSortedSeries[b.deviceID!][b.phenomena!][b.elemID!] as number) -
                  (tempSortedSeries[a.deviceID!][a.phenomena!][a.elemID!] as number)
              });
            }
            break;
          default:
            break;
        }
      }
    }

    tempSortedLines = tempSortedLines.concat(invalidSortedLines);

    return tempSortedLines;
  }
}

export default ComparisonTable;
