interface PromptProps {
  onClose: (result: boolean) => void;
  title?: string;
  confirmText: string;
  confirmBtnColor?: string;
  cancelText: string;
  cancelBtnColor?: string;
}

const Prompt = ({
  onClose,
  title,
  confirmText,
  confirmBtnColor = "red",
  cancelText,
  cancelBtnColor = "gray",
}: PromptProps): React.JSX.Element => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6 shadow-lg max-w-sm w-full">
        <h2 className="text-lg font-semibold text-gray-800 mb-4">{title}</h2>
        <div className="flex justify-end space-x-3">
          <button
            onClick={() => onClose(true)}
            className={`bg-${confirmBtnColor}-500 text-white px-4 py-2 rounded hover:bg-${confirmBtnColor}-600 focus:outline-none focus:ring-2 focus:ring-${confirmBtnColor}-500`}
          >
            {confirmText}
          </button>
          <button
            onClick={() => onClose(false)}
            className={`bg-${cancelBtnColor}-300 text-${cancelBtnColor}-800 px-4 py-2 rounded hover:bg-${cancelBtnColor}-400 focus:outline-none focus:ring-2 focus:ring-${cancelBtnColor}-500`}
          >
            {cancelText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Prompt;
