import _, { forEach, set } from "lodash";
import React, { useEffect, useState } from "react";
import { ChartScale, ChartType, GaugeData, Scale } from "..";

import { v4 as uuidv4 } from "uuid";
import NewCalendarMenu from "./NewCalendarMenu";
import { FormInput } from "../../../FormInput";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../../../dictionary/ChartsText";
import { LanguageCheck } from "../../../../utils/LanguageCheck";
import { useAppSelector } from "../../../../app/hooks";
import { selectLanguage } from "../../../../features/user/userSlice";
import Spinner from "../../../Spinner";
import { EditChart, EditChartProps } from "../EditChart";
import { Button } from "../../../Button";

type Props = {
  id: string;
  data: ChartType;
  remove: (id: string) => void;
  updateChart: (data: ChartType) => void;
  setShowChartEdit: (isOpen: boolean) => void;
};

function EditCalendar({ id, remove, updateChart, data, setShowChartEdit }: Props) {

  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  const defaultYAxis: Scale = { min_auto: true, max_auto: true, max: 0, min: 0 };

  
  const [title, setTitle] = useState(data.title.en);
  const [rings, setRings] = useState<GaugeData[]>(data.chartData);
  const [loading, setLoading] = useState(false);

  const [chartScale, setChartScale] = useState<ChartScale>(
    (data.chartScale === undefined || (data.chartScale.y_axis === defaultYAxis)) ?
      { y_axis: { min_auto: true, max_auto: true, max: 10, min: 0 } }
      : data.chartScale
  );

  function getBlankRing() {
    return {
      ringID: uuidv4(),
      deviceID: "",
      phenomena: "",
      unit: "",
      elemID: "",
      colour: "#f6efa6,#bf444c",
      data: [],
      label: "",
      min: 0,
      max: 0,
    }
  }

  const onRemoveRing = (ringID: string | undefined) => {
    setRings(_.reject(rings, { ringID: ringID }));
  };

  function GetTitleAndRingsFromParams() {
    setTitle(data.title.en);
    if (data.chartData !== undefined && data.chartData !== null && data.chartData.length > 0) {
      setRings(data.chartData);
    } else {
      setRings([getBlankRing()]);
    }
  }

  function GetChartScaleFromParams() {
    if (data.chartScale !== undefined && data.chartScale !== null) {
      setChartScale(data.chartScale)
    }
  }

  function CatchBlankRings() {
    if (rings === undefined || rings === null) {
      setRings([getBlankRing()]);
    } else {
      if (!(rings.length > 0)) {
        setRings([getBlankRing()]);
      }
    }
  }

  useEffect(() => {
    GetTitleAndRingsFromParams();
    GetChartScaleFromParams()
  }, [data])

  useEffect(() => {
    CatchBlankRings();
  }, [rings]);

  function ChangeLowColour(event: React.ChangeEvent<HTMLInputElement>) {
    if (rings[0]?.colour !== undefined) {
      var colourCombination = [rings[0].colour?.split(",")[0], rings[0].colour?.split(",")[1]];
      setRings(rings.map((ring, index) => ({ ...ring, colour: (index === 0 ? `${event.target.value},${colourCombination[1]}` : ring.colour) })));
    } else {
      setRings(rings.map((ring, index) => ({ ...ring, colour: "#f6efa6,#bf444c" })));
    }
  }

  function ChangeHighColour(event: React.ChangeEvent<HTMLInputElement>) {
    if (rings[0]?.colour !== undefined) {
      var colourCombination = [rings[0].colour?.split(",")[0], rings[0].colour?.split(",")[1]];
      setRings(rings.map((ring, index) => ({ ...ring, colour: (index === 0 ? `${colourCombination[0]},${event.target.value}` : ring.colour) })));
    } else {
      setRings(rings.map((ring, index) => ({ ...ring, colour: "#f6efa6,#bf444c" })));
    }
  }

  var settingsBox = (
    <div className="p-2 bg-gray-200 overflow-y-scroll scrollbar-none rounded-lg flex flex-col">
      <FormInput
        label={language.charts.gauge.labels.title}
        htmlFor="title"
        value={title !== null ? title : ""}
        onChange={(event) => {
          setTitle(event.target.value);
        }}
      />
      {rings !== null && (
        <>
          <FormInput
            type="color"
            label={"Low Colour"}
            htmlFor="lowColour"
            value={rings[0].colour?.split(",")[0]}
            onChange={ChangeLowColour}
          />
          <FormInput
            type="color"
            label={"High Colour"}
            htmlFor="highColour"
            value={rings[0].colour?.split(",")[1]}
            onChange={ChangeHighColour}
          />
        </>
      )}
    </div>
  );

  function SaveRingData(ring: GaugeData) {
    setRings(
      _.map(rings, (currentRing: GaugeData) => {
        if (currentRing.ringID === ring.ringID) {
          return ring;
        } else {
          return currentRing;
        }
      })
    );
  }

  var itemList = (rings ?? [
    {
      ringID: uuidv4(),
      deviceID: "new",
      phenomena: "new",
      unit: "",
      elemID: "",
      colour: "#f6efa6,#bf444c",
      data: [],
      label: "",
      min: 0,
      max: 0,
    },
  ]).map((ring, index) => (
    <NewCalendarMenu
      index={index}
      ring={ring}
      key={index}
      removeRing={onRemoveRing}
      chartScale={chartScale}
      setChartScale={setChartScale}
      saveRingData={SaveRingData}
    />
  ))

  function onDelete() {
    remove(id);
    setShowChartEdit(false);
  }

  function saveChartSettings() {
    var newRings = rings.map((ring) => {
      var newRing: GaugeData = {
        ...ring,
        max: 0,
        min: 0,
      };
      return newRing;
    });

    setRings(newRings);

    setLoading(true)
    setTimeout(function () {
      setShowChartEdit(false);
      setLoading(false)
    }, 300);
    updateChart({
      i: id,
      type: "calendar",
      title: { en: title },
      chartData: newRings,
      chartScale: chartScale,
    });
  };

  const EditChartObjects: EditChartProps = ({
    editMapType: "Edit Calendar",
    settingsBox: settingsBox,
    itemList: itemList,
    newButton: undefined,
    onDelete: onDelete,
    onSave: saveChartSettings,
    loading: loading,
  })

  return (
    <EditChart {...EditChartObjects} />
  );
};

export default EditCalendar;
