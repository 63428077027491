import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import AcceptModal from "../../components/modals/AcceptModal";
import GroupObjectModal from "../../components/modals/GroupObjectModal";
import {
  useCancelInvitationMutation,
  useUpdateGroupInviteMutation,
} from "../../services/groupAPI";
import { handleError } from "../../utils/ErrorHandling";
import {
  Group,
  InvitedUser,
  selectGroups,
  UserPermissions,
} from "../../features/groups/groupSlice";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../features/user/userSlice";
import { toast } from "react-toastify";
import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
  LanguageText,
} from "../../dictionary/GroupsText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import UserPermissionsEdit from "./EditPermissions";

function GroupEditInvite() {
  const navigate = useNavigate();
  const { groupid } = useParams();
  const { memberid } = useParams();
  const { groups } = useAppSelector(selectGroups);
  const [removeConfirm, setRemoveConfirm] = useState(false);
  const [invitedUser, setInvitedUser] = useState<InvitedUser>();
  const [invitedUsersNewPermissions, setInvitedUsersNewPermissions] =
    useState<UserPermissions>();

  useEffect(() => {
    if (groups[groupid as keyof typeof groups] && memberid) {
      setInvitedUser(
        groups[groupid as keyof typeof groups].invited_users?.[
          getInvitedUsersElement(
            memberid,
            groups[groupid as keyof typeof groups]
          )
        ]
      );
    }
  }, [groups, groupid, memberid]);

  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState<LanguageText>(
    LanguageCheck(
      English,
      French,
      German,
      Dutch,
      Spanish,
      Italian,
      Romanian,
      Portuguese,
      stateLang
    )
  );

  useEffect(() => {
    setLanguage(
      LanguageCheck(
        English,
        French,
        German,
        Dutch,
        Spanish,
        Italian,
        Romanian,
        Portuguese,
        stateLang
      )
    );
  }, [stateLang]);

  const [
    cancelInvitation,
    {
      isLoading: isCancelInvitationLoading,
      isSuccess: isCancelInvitationSuccess,
      isError: isCancelInvitationError,
      error: cancelInvitationGroupError,
    },
  ] = useCancelInvitationMutation();

  const [
    updateGroupInvite,
    {
      isLoading: isUpdateGroupInviteLoading,
      isSuccess: isUpdateGroupInviteSuccess,
      isError: isUpdateGroupInviteError,
      error: updateGroupInviteError,
    },
  ] = useUpdateGroupInviteMutation();

  useEffect(() => {
    if (isCancelInvitationSuccess) {
      toast.success(language.inviteTable.toast.cancelSuccess);
      navigate("../../");
    }
    if (isCancelInvitationError) {
      handleError(cancelInvitationGroupError);
    }
  }, [isCancelInvitationSuccess, isCancelInvitationError]);

  useEffect(() => {
    if (isUpdateGroupInviteSuccess) {
      toast.success(language.inviteTable.toast.updateSuccess);
      navigate("../../");
    }
    if (isUpdateGroupInviteError) {
      handleError(updateGroupInviteError);
    }
  }, [isUpdateGroupInviteSuccess, isUpdateGroupInviteError]);

  return (
    <>
      {invitedUser && (
        <>
          {removeConfirm ? (
            <AcceptModal
              onAccept={() => {
                cancelInvitation({
                  id: groupid!,
                  user_email: invitedUser?.invited_email ?? "",
                });
              }}
              onCancel={() => navigate(-1)}
              Title={language.inviteTable.modals.cancelInvite.title}
              Body={
                <>
                  {language.inviteTable.modals.cancelInvite.body.phrase1}
                  {invitedUser?.invited_email ? (
                    <b>{invitedUser?.invited_email}</b>
                  ) : (
                    language.inviteTable.modals.cancelInvite.body.thisUser
                  )}
                  {language.inviteTable.modals.cancelInvite.body.phrase2}
                  {invitedUser?.invited_email ? (
                    <b>{invitedUser?.invited_email}</b>
                  ) : (
                    language.inviteTable.modals.cancelInvite.body.thisUser
                  )}
                  {language.inviteTable.modals.cancelInvite.body.phrase3}
                </>
              }
              AcceptButton={
                language.inviteTable.modals.cancelInvite.buttons.accept
              }
              AcceptButtonColour={"red"}
              CancelButton={
                language.inviteTable.modals.cancelInvite.buttons.cancel
              }
              loading={isCancelInvitationLoading}
            />
          ) : (
            <GroupObjectModal
              onRemove={() => {
                setRemoveConfirm(true);
              }}
              onAccept={() => {
                updateGroupInvite({
                  group_id: groupid ?? "",
                  user_email: invitedUser?.invited_email ?? "",
                  permissions: invitedUsersNewPermissions ?? {},
                });
                console.log({
                  id: groupid,
                  user_email: invitedUser?.invited_email ?? "",
                  permissions: invitedUsersNewPermissions,
                });
              }}
              onCancel={() => {
                navigate("../../");
              }}
              Title={"Edit Invite sent to " + invitedUser?.invited_email}
              Body={
                invitedUser?.permissions && (
                  <UserPermissionsEdit
                    editeesPerms={invitedUser?.permissions}
                    getNewPerms={(newPerms) =>
                      setInvitedUsersNewPermissions(newPerms)
                    }
                  />
                )
              }
              AcceptButton="Save"
              CancelButton="Cancel"
              RemoveButton="Remove"
              loading={isCancelInvitationLoading || isUpdateGroupInviteLoading}
            />
          )}
        </>
      )}
    </>
  );
}

export default GroupEditInvite;

function getInvitedUsersElement(email: string, group: Group) {
  //convert group.invited_users to an array of objects
  const invitedUsersArray = Object.values(group.invited_users ?? {});
  //return the array index of the invited user using a lodash function
  return _.findIndex(invitedUsersArray, function (o) {
    return o.invited_email == email;
  });
}
