import _ from "lodash";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { InputActionMeta } from "react-select";
import Select from "react-select";
import { useAppSelector } from "../../app/hooks";
import {
  DashboardState,
  selectDashboards,
} from "../../features/dashboards/dashboardSlice";
import {
  Device,
  Devices,
  DeviceState,
  selectDevices,
} from "../../features/devices/deviceSlice";
import {
  UserPermissions,
  selectGroups,
} from "../../features/groups/groupSlice";
import { selectLanguage, selectUser } from "../../features/user/userSlice";
import { Switch } from "@headlessui/react";
import { adminPreset, memberPreset } from "../../utils/Permissions";
import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
  LanguageText,
} from "../../dictionary/PermissionText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { Button } from "../../components/Button";

type selectFormat = { value: string; label: string }[];

type UserPermissionsEditProps = {
  editeesPerms: UserPermissions;
  presetButtons?: boolean;
  getNewPerms: (newPerms: UserPermissions) => void;
};
//Only requires the permissions from the user that is being edited and a callback function to get the new permissions
export default function UserPermissionsEdit({
  editeesPerms,
  presetButtons = false,
  getNewPerms,
}: UserPermissionsEditProps) {
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState<LanguageText>(
    LanguageCheck(
      English,
      French,
      German,
      Dutch,
      Spanish,
      Italian,
      Romanian,
      Portuguese,
      stateLang
    )
  );
  useEffect(() => {
    setLanguage(
      LanguageCheck(
        English,
        French,
        German,
        Dutch,
        Spanish,
        Italian,
        Romanian,
        Portuguese,
        stateLang
      )
    );
  }, [stateLang]);
  const { groupid } = useParams();
  const [newEditeesPerms, setNewEditeesPerms] =
    useState<UserPermissions>(editeesPerms);
  //Gets the user from the redux store so we know which permissions the editor has
  const user = useAppSelector(selectUser);
  //Gets the groups from the redux store so we can get the devices and dashboards in the group
  const groups = useAppSelector(selectGroups);
  //Gets the devices and dashboards from the redux store so we can get the device/dashboard name
  const devices = useAppSelector(selectDevices);
  const dashboards = useAppSelector(selectDashboards);
  //These are the devices and dashboards in the group in the correct format for the react-select component
  const [groupDevices, setGroupDevices] = useState<selectFormat>([]);
  const [groupDashboards, setGroupDashboards] = useState<selectFormat>([]);

  //When the editeesPerms changes, update the newEditeesPerms
  useEffect(() => {
    getNewPerms(newEditeesPerms);
  }, [newEditeesPerms]);

  //When the editeesPerms changes (From the parent), update the newEditeesPerms
  useEffect(() => {
    setNewEditeesPerms(editeesPerms);
  }, [editeesPerms]);

  //When either the users groups or the groupID changes, update the groupDevices
  useEffect(() => {
    //Ensure that we have both a groupID and some groups
    if (groupid && devices.devices) {
      setGroupDevices([]);
      //Map through the devices in the group and add them to the groupDevices in the correct format
      _.map(devices.devices, (device) => {
        if (device.group_id !== groupid) return;
        const deviceSelectFormat = {
          value: device.id,
          label: device.name,
        };
        setGroupDevices((groupDevices) => [
          ...groupDevices,
          deviceSelectFormat,
        ]);
      });
    }
  }, [groups, groupid, devices]);

  useEffect(() => {
    //Ensure that we have both a groupID and some groups
    if (groupid && Object.keys(dashboards.dashboards).length > 0) {
      setGroupDashboards([]);
      //Map through the dashboards in the group and add them to the groupDashboards in the correct format
      _.map(dashboards.dashboards, (dashboard) => {
        if (dashboard.group_id !== groupid) return;
        const dashboardSelectFormat = {
          value: dashboard.id,
          label: dashboard.name,
        };
        setGroupDashboards((groupDashboards) => [
          ...groupDashboards,
          dashboardSelectFormat,
        ]);
      });
    }
  }, [groups, groupid, dashboards]);

  return (
    <>
      {/* Preset buttons visible only when the presetButtons variable is set - This shows 2 buttons that will auto fill the permissions with predefined values*/}
      {presetButtons && (
        <>
          <div className="flex space-x-1 pb-1">
            <div className="w-fit">
              <Button
                onClick={() => {
                  setNewEditeesPerms(adminPreset);
                }}
                label={"Admin"}
                colour="gray"
              />
            </div>
            <div className="w-fit">
              <Button
                onClick={() => {
                  setNewEditeesPerms(memberPreset);
                }}
                label="Member"
                colour="gray"
              />
            </div>
          </div>
        </>
      )}
      {/* Shows the top level switch element which renders each layer within its self */}
      {newEditeesPerms &&
        groupid &&
        devices &&
        dashboards &&
        user.groups[groupid] && (
          <DeepSwitch
            language={language}
            key={JSON.stringify(newEditeesPerms)}
            permissionMap={adminPreset}
            newEditeesPerms={newEditeesPerms}
            getPermissionObject={(value) => {
              setNewEditeesPerms(value);
            }}
            editorPerms={user.groups[groupid].permissions}
            devices={devices}
            dashboards={dashboards}
            groupDevices={groupDevices}
            groupDashboards={groupDashboards}
          />
        )}
    </>
  );
}

type DeepSwitchProps = {
  permissionMap: any;
  getPermissionObject: (value: any) => any;
  newEditeesPerms: any;
  editorPerms: any;
  devices: DeviceState;
  dashboards: DashboardState;
  groupDevices: selectFormat;
  groupDashboards: selectFormat;
  language: LanguageText;
};

//A recessive function that returns a switch for each value in the permissionMap, if the value is an object with at least 1 field,
//it will return a DeepSwitch which in turn will return a switch for each value in the object.
//If the value is an object and there are no values in the object, it will return a select box with the devices or dashboards in the group
const DeepSwitch = ({
  permissionMap,
  getPermissionObject,
  newEditeesPerms,
  editorPerms,
  devices,
  dashboards,
  groupDevices,
  groupDashboards,
  language,
}: DeepSwitchProps) => {
  //find the type of the each value in the map, if it is a boolean, return a switch, if it is an object, return a DeepSwitch
  const [permissionValues, setPermissionValues] =
    useState<any>(newEditeesPerms);
  //when the getPermissionObject function is called, return the permissionValues to the parent and allow the parent to update the state
  useEffect(() => {
    getPermissionObject(permissionValues);
  }, [permissionValues]);
  return (
    <>
      {Object.keys(permissionMap).map((key, index) => {
        // If the value is a boolean, return a switch element
        if (typeof permissionMap[key] === "boolean") {
          //If the user has the permission to edit the permission, return a switch otherwise set the value to false in the permissionValues
          if (editorPerms[key]) {
            //If the key is can_edit_any or can_view_any, do not return a switch as these are edited by it objects parent
            if (!(key == "can_edit_any" || key == "can_view_any")) {
              return (
                <div className="flex flex-row justify-between mb-2" key={index}>
                  <p>
                    {
                      language[
                      `${key}_${permissionValues["can_edit_devices"] ||
                        permissionValues["can_view_devices"]
                        ? "devices"
                        : "dashboards"
                      }` as keyof LanguageText
                      ]
                    }
                    {language[key as keyof LanguageText]}
                  </p>
                  <PermSwitch
                    permissionName={language[key as keyof LanguageText]}
                    defaultValue={permissionValues[key]}
                    onChange={(value) => {
                      setPermissionValues((permissionValues: any) => ({
                        ...permissionValues,
                        [key]: value,
                      }));
                    }}
                  />
                </div>
              );
            }
          } else {
            if (permissionValues[key]) {
              setPermissionValues((permissionValues: any) => ({
                ...permissionValues,
                [key]: false,
              }));
            }
          }
        } else {
          //If the value is an object and has at least 1 field, return a switch that will update the can_view/edit_any field within the object and DeepSwitch which will return a switch for each value in the object
          if (Object.keys(permissionMap[key]).length > 0) {
            if (
              editorPerms[key]["can_view_any"] ||
              editorPerms[key]["can_edit_any"]
            )
              return (
                <div key={index}>
                  <div className="flex flex-row justify-between mb-2">
                    <p>{language[key as keyof LanguageText]}</p>
                    <PermSwitch
                      permissionName={language[key as keyof LanguageText]}
                      defaultValue={getValueofAny(permissionValues, key)}
                      onChange={(value) => {
                        //set the value of newEditeesPerms.key.can_view/edit_any to the value of the switch
                        if (
                          key == "can_edit_dashboards" ||
                          key == "can_edit_devices"
                        ) {
                          setPermissionValues((permissionValues: any) => ({
                            ...permissionValues,
                            [key]: {
                              ...permissionValues[key],
                              ["can_edit_any"]: value,
                            },
                          }));
                        } else if (
                          key == "can_view_dashboards" ||
                          key == "can_view_devices"
                        ) {
                          setPermissionValues((permissionValues: any) => ({
                            ...permissionValues,
                            [key]: {
                              ...permissionValues[key],
                              ["can_view_any"]: value,
                            },
                          }));
                        }
                      }}
                    />
                  </div>
                  <div className="ml-4">
                    {
                      //Dont show the can_view_any or can_edit_any switches
                      (permissionValues[key]["can_view_any"] ||
                        permissionValues[key]["can_edit_any"]) && (
                        <DeepSwitch
                          key={key}
                          permissionMap={permissionMap[key]}
                          newEditeesPerms={permissionValues[key]}
                          getPermissionObject={(permissionObject) => {
                            setPermissionValues((permissionValues: any) => ({
                              ...permissionValues,
                              [key]: permissionObject,
                            }));
                          }}
                          editorPerms={editorPerms[key]}
                          devices={devices}
                          dashboards={dashboards}
                          groupDevices={groupDevices}
                          groupDashboards={groupDashboards}
                          language={language}
                        />
                      )
                    }
                  </div>
                </div>
              );
          } else {
            //If the value is an object and has no fields, return a select box with the devices or dashboards in the group depending on the key
            return (
              <Select
                key={key}
                className="mb-2"
                placeholder={language[key as keyof LanguageText]}
                isMulti
                closeMenuOnSelect={false}
                name={permissionMap[key]}
                defaultValue={Object.keys(permissionValues[key]).map((key) => {
                  if (
                    (permissionValues["can_edit_devices"] ||
                      permissionValues["can_view_devices"]) &&
                    devices.devices[getDeviceById(key, devices.devices)]
                  ) {
                    return {
                      value: key,
                      label:
                        devices.devices[getDeviceById(key, devices.devices)]
                          .name,
                    };
                  } else if (
                    (permissionValues["can_edit_dashboards"] ||
                      permissionValues["can_view_dashboards"]) &&
                    dashboards.dashboards[key]
                  ) {
                    return {
                      value: key,
                      label: dashboards.dashboards[key].name,
                    };
                  }
                })}
                isDisabled={
                  (permissionValues["can_edit_all"] ?? false) ||
                  (permissionValues["can_view_all"] ?? false)
                }
                onChange={(e) => {
                  setPermissionValues(
                    (permissionValues: { [x: string]: any }) => ({
                      ...permissionValues,
                      [key]: e.reduce((acc, curr) => {
                        if (curr) return { ...acc, [curr.value]: true };
                        else return acc;
                      }, {}),
                    })
                  );
                }}
                options={getOptions(
                  groupDevices,
                  groupDashboards,
                  permissionValues
                )}
              />
            );
          }
        }
      })}
    </>
  );
};

const getOptions = (
  groupDevices: selectFormat,
  groupDashboards: selectFormat,
  permissionValues: any
) => {
  if (
    typeof permissionValues["can_view_devices"] === "object" ||
    typeof permissionValues["can_edit_devices"] === "object"
  ) {
    return groupDevices;
  } else if (
    typeof permissionValues["can_view_dashboards"] === "object" ||
    typeof permissionValues["can_edit_dashboards"] === "object"
  ) {
    return groupDashboards;
  } else {
    return [];
  }
};

function getDeviceByEUI(eui: string, devices: Devices) {
  //return the array index of the invited user using a lodash function
  return _.findIndex(devices, function (device) {
    return device.eui == eui;
  });
}

function getDeviceById(id: string, devices: Devices) {
  //return the array index of the invited user using a lodash function
  return _.findIndex(devices, function (device) {
    return device.id == id;
  });
}

const getValueofAny = (userPermissions: any, key: string) => {
  if (key == "can_edit_dashboards" || key == "can_edit_devices") {
    return userPermissions[key]["can_edit_any"];
  } else if (key == "can_view_dashboards" || key == "can_view_devices") {
    return userPermissions[key]["can_view_any"];
  }
};

type PermSwitchProps = {
  permissionName: string;
  defaultValue: boolean;
  onChange: (value: boolean) => void;
};
//A styled switch that takes the permission name (Which gets converted into the users language), default value and onChange function as props
const PermSwitch = ({
  permissionName,
  defaultValue,
  onChange,
}: PermSwitchProps) => {
  const [value, setValue] = useState<boolean>(
    typeof defaultValue === "boolean"
      ? defaultValue
      : defaultValue["can_view_any"]
  );
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  return (
    <Switch
      checked={value}
      key={permissionName}
      onClick={() => {
        onChange(!value);
        setValue((value) => !value);
      }}
      className={`${value ? "bg-green-400" : "bg-red-600"
        } relative inline-flex h-[24px] w-[44px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-dashboards duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
    >
      <span className="sr-only">{permissionName}</span>
      <span
        aria-hidden="true"
        className={`${value ? "translate-x-[20px]" : "translate-x-0"
          } pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
      />
    </Switch>
  );
};
