import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { Icon, LatLng, LatLngTuple } from "leaflet";
import _ from "lodash";
import { useState, useEffect, useRef } from "react";
import { BsFillUnlockFill, BsFillLockFill, BsLifePreserver } from "react-icons/bs";
import { MapContainer, LayersControl, Pane, TileLayer, Marker, Popup, useMapEvents, useMap } from "react-leaflet";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { FormInput } from "../components/FormInput";
import Spinner from "../components/Spinner";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../dictionary/DeviceText";
import { selectLanguage, selectUser } from "../features/user/userSlice";
import { deviceAPI, SaveDevice, useEditDeviceMutation, useGetDeviceQuery } from "../services/deviceAPI";
import { DataTimeToLocale } from "../utils/DateFormating";
import { handleError } from "../utils/ErrorHandling";
import { LanguageCheck } from "../utils/LanguageCheck";


/* gauge imports */
import { UplinkData, useGetLatestDataQuery, useLazyGetDataQuery } from "../services/dataAPI";
/* ********************** */
import { Button } from "../components/Button";
import moment from "moment";
import L from "leaflet";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

type LifeRingType = {
    ringID: string;
    name: string;
    lastContact: string;
    lastActivity: string;
    lat: number;
    lng: number;
};

function LifeRings() {
    const { deviceid } = useParams();
    const {
        data: device,
        isSuccess,
        isError,
        error,
        isFetching,
    } = useGetDeviceQuery(deviceid ?? skipToken);
    const configurations = device?.configuration;
    const gateways = device?.gateways;
    const user = useAppSelector(selectUser);
    const [editPermission, setEditPermission] = useState(false);
    const { BaseLayer } = LayersControl;
    const [lockDragging, setLockDragging] = useState(true);
    const [seed, setSeed] = useState(0);
    const [viewMode, setViewMode] = useState(true);
    const [selectedLifeRing, setSelectedLifeRing] = useState("");
    const [deviceCoordinates, setDeviceCoordinates] = useState<LatLng>(
        new LatLng(0, 0)
    );
    const dispatch = useAppDispatch();
    const [showAlertBox, setShowAlertBox] = useState(true);



    const stateLang = useAppSelector(selectLanguage);
    let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    useEffect(() => {
        setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    }, [stateLang]);

    const [selectedSort, setSelectedSort] = useState("lastActivity");
    const [sortDirection, setSortDirection] = useState("asc");

    /* const { data: latestData } = useGetLatestDataQuery({ "eui": device?.eui ?? "" }, { pollingInterval: 60000 }); */

    const [lifeRings, setLifeRings] = useState<LifeRingType[]>([
        { ringID: "1", name: "LifeRing1", lastContact: getMinutesAgo(1455), lastActivity: getMinutesAgo(1455), lat: 51.509, lng: -0.12 },
        { ringID: "W", name: "LifeRing2", lastContact: getMinutesAgo(2413), lastActivity: getMinutesAgo(3910), lat: 51.511, lng: -0.11 },
        { ringID: "7", name: "LifeRing3", lastContact: getMinutesAgo(1), lastActivity: getMinutesAgo(1), lat: 51.511, lng: -0.1 },
        { ringID: "9", name: "LifeRing4", lastContact: getMinutesAgo(140), lastActivity: getMinutesAgo(140), lat: 51.509, lng: -0.09 },
        { ringID: "Q", name: "LifeRing5", lastContact: getMinutesAgo(24), lastActivity: getMinutesAgo(5893), lat: 51.50822, lng: -0.11 },
        { ringID: "Z", name: "LifeRing6", lastContact: getMinutesAgo(1905), lastActivity: getMinutesAgo(4000), lat: 51.5085, lng: -0.1 },
        { ringID: "J", name: "LifeRing7", lastContact: getMinutesAgo(373), lastActivity: getMinutesAgo(1430), lat: 51.507, lng: -0.09 },
    ])

    const [currentAlert, setCurrentAlert] = useState<LifeRingType>(lifeRings[0]);

    function getMinutesAgo(minutes: number) {
        var time = moment().subtract(minutes, 'minutes').format("YYYY-MM-DDTHH:mm:ss.SSS[+01:00]");
        return time;
    }

    useEffect(() => {
        if (lifeRings) {
            sortLifeRingsByParam(selectedSort);
        }
    }, [selectedSort, sortDirection])

    function sortLifeRingsByParam(param: string) {
        var sortedLifeRings = lifeRings
        if (param == "lastActivity") {
            var offlineRings = _.filter(lifeRings, function (ring) { return moment(ring.lastContact).isBefore(moment().subtract(24, 'hours')) });
            var onlineRings = _.filter(lifeRings, function (ring) { return moment(ring.lastContact).isAfter(moment().subtract(24, 'hours')) });

            sortedLifeRings = _.sortBy(onlineRings, param)
            if (sortDirection === "asc") {
                sortedLifeRings = sortedLifeRings.reverse();
            }
            sortedLifeRings = sortedLifeRings.concat(offlineRings);
        }
        else {
            sortedLifeRings = _.sortBy(lifeRings, param);
            if (sortDirection === "asc") {
                sortedLifeRings = sortedLifeRings.reverse();
            }
        }
        setLifeRings(sortedLifeRings);
    }

    const [
        saveDevice,
        {
            data: saveDeviceResponse,
            isSuccess: isSaveDeviceSuccess,
            isLoading: isSaveDeviceLoading,
            isError: isSaveDeviceError,
            error: saveDeviceError,
        },
    ] = useEditDeviceMutation();

    var gatewayIcon = new Icon({
        iconUrl:
            "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
        shadowUrl:
            "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [12, 41],
        shadowSize: [41, 41],
    });

    function getIcon(colour: string) {
        return L.divIcon({
            className: "custom-icon-marker",
            html: `<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="${colour}" class="bi bi-life-preserver" viewBox="0 0 16 16"> <circle r="8" cx="8" cy="8" style="stroke: rgb(0,0,0); stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke"  cx="0" cy="0" r="35" /> <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm6.43-5.228a7.025 7.025 0 0 1-3.658 3.658l-1.115-2.788a4.015 4.015 0 0 0 1.985-1.985l2.788 1.115zM5.228 14.43a7.025 7.025 0 0 1-3.658-3.658l2.788-1.115a4.015 4.015 0 0 0 1.985 1.985L5.228 14.43zm9.202-9.202-2.788 1.115a4.015 4.015 0 0 0-1.985-1.985l1.115-2.788a7.025 7.025 0 0 1 3.658 3.658zm-8.087-.87a4.015 4.015 0 0 0-1.985 1.985L1.57 5.228A7.025 7.025 0 0 1 5.228 1.57l1.115 2.788zM8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/></svg>`,
            iconSize: [28, 28],
            iconAnchor: [14, 14],
            popupAnchor: [1, -34],
            shadowSize: [41, 41],
        })
    }

    useEffect(() => {
        if (isSuccess) {
            if (user.groups[device.group_id]) {
                setEditPermission(
                    user.groups[device.group_id].permissions.can_edit_devices
                        ?.can_edit_all ??
                    user.groups[device.group_id].permissions.can_edit_devices
                        ?.can_edit_devices?.[device.id] ??
                    false
                );
            } else {
                setEditPermission(false);
            }
        }
    }, [device, user, isSuccess]);

    const LockDraggingButton: React.FC = () => {
        return (
            <button
                className="bg-white pl-[6px] ring-opacity-20 ring-2 ring-gray-900 top-[5rem] left-3 border rounded absolute w-[1.9rem] h-8 z-[700]"
                onClick={() => {
                    setSeed(Math.random());
                    setLockDragging(!lockDragging);
                }}
            >
                {lockDragging ? (
                    <BsFillUnlockFill size={16} />
                ) : (
                    <BsFillLockFill size={16} />
                )}
            </button>
        );
    };

    function MapEvents() {
        const map = useMapEvents({
            baselayerchange: (e) => {
                e.name === "Map" ? setViewMode(true) : setViewMode(false);
            },
            movestart: () => {
                setSelectedLifeRing("");
            },
        });
        return null;
    }

    const lifeRingList: JSX.Element[] = [];

    if (lifeRings !== undefined) {
        for (let lifeRing in lifeRings) {
            lifeRingList.push(
                <LifeRingBox lifeRing={lifeRings[lifeRing]} />
            )
        }
    }

    useEffect(() => {
        if (lifeRings !== undefined) {
            const filteredLifeRings = lifeRings.filter(lifeRing => lifeRing.lat !== 0 && lifeRing.lng !== 0);
            if (filteredLifeRings.length > 0) {
                setDeviceCoordinates(new LatLng(filteredLifeRings[0].lat, filteredLifeRings[0].lng))
            }
        }
    }, [lifeRings]);

    useEffect(() => {
        if (isSaveDeviceError) {
            handleError(saveDeviceError);
        }
    }, [saveDeviceError, isSaveDeviceError]);

    useEffect(() => {
        if (isSaveDeviceSuccess) {
            toast.success(language.editDevice.viewEditDevice.toasts.deviceSaved)
        }
    }, [saveDeviceResponse, isSaveDeviceSuccess, language.editDevice.viewEditDevice.toasts.deviceSaved]);

    const CenterableMarker = ({ center, content, selected, colour }: { center: LatLngTuple, content: JSX.Element, selected: boolean, colour: string; }) => {
        const map = useMap();
        const markerRef = useRef<L.Marker<any>>(null);

        useEffect(() => {
            if (selected) {
                map.setView(center);
                if (markerRef.current !== null) {
                    markerRef.current.openPopup();
                }
            }
        }, [map, center, selected]);

        return (
            center[0] !== 0 && center[1] !== 0 ? (
                <Marker
                    icon={getIcon(colour)}
                    position={center}
                    ref={markerRef}
                >
                    <Popup autoPan={false}>
                        {content}
                    </Popup>
                </Marker>
            ) : <> </>
        );
    };

    const markers = (
        _.map(lifeRings, (lifeRing) => {
            const content: JSX.Element = (<>
                {lifeRing.name}<br />{DataTimeToLocale(new Date(lifeRing.lastActivity ?? 0))}
            </>)
            return (
                <CenterableMarker
                    center={[lifeRing.lat, lifeRing.lng]}
                    content={content}
                    selected={lifeRing.ringID === selectedLifeRing}
                    colour={getColour(lifeRing.lastContact, lifeRing.lastActivity) ?? "#FF0000"}
                />
            );
        })
    )

    const map: JSX.Element = (
        <MapContainer
            key={seed}
            center={deviceCoordinates}
            minZoom={0}
            maxZoom={18}
            zoom={15}
            style={{ height: "100%", width: "100%", zIndex: "0" }}
            dragging={lockDragging}
            scrollWheelZoom={lockDragging}
        >
            <LayersControl position="topright">
                <BaseLayer checked={viewMode} name="Map">
                    <Pane name="map" style={{ zIndex: 0 }}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </Pane>
                </BaseLayer>
                <BaseLayer checked={!viewMode} name="Satellite">
                    <Pane name="satellite" style={{ zIndex: 0 }}>
                        <TileLayer
                            attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                        />
                    </Pane>
                </BaseLayer>
            </LayersControl>
            <LockDraggingButton />
            {markers}
            <MapEvents />
        </MapContainer>
    )

    return (
        <div className="h-full w-full p-4 bg-white rounded-md">
            <div className="h-full w-full flex flex-wrap content-start">
                {showAlertBox && (
                    <div className="w-full h-fit flex-wrap flex space-x-1">
                        <ActivityAlertBox lifeRing={currentAlert} />
                    </div>
                )}
                <div className={`${showAlertBox ? "h-[calc(100%-114px)]" : "h-full"} grid grid-rows-2 grid-cols-1 lg:grid-rows-1 lg:grid-cols-2 lg:space-x-1 w-full pt-1`}>
                    <div className="h-full w-full flex flex-col overflow-clip">
                        <div className="w-full flex justify-between mb-1">
                            <h1 className="font-bold text-xl flex justify-between items-center">
                                Life Rings
                            </h1>
                            <div className="flex space-x-2">
                                <div className="flex items-center">
                                    <label className="text-sm font-semibold whitespace-nowrap text-gray-700 mr-2">Sort by:</label>
                                    <div className="flex items-center rounded-md border border-gray-300 shadow-sm">
                                        <select
                                            className="block w-full py-2 px-3 border-0 border-r border-gray-300 bg-white rounded-l-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            onChange={(e) => {
                                                setSelectedSort(e.target.value);
                                            }}
                                            value={selectedSort}
                                        >
                                            <option value="name">Name</option>
                                            <option value="lastActivity">Last Activity</option>
                                            <option value="lastContact">Last Contact</option>
                                        </select>
                                        <div onClick={() => setSortDirection(sortDirection === "desc" ? "asc" : "desc")} className="block py-2 px-3 bg-white rounded-r-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                            {sortDirection === "desc" ? <FaSortAmountDown size={20} /> : <FaSortAmountUp size={20} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="overflow-y-scroll space-y-3 scrollbar-none h-full mb-2 rounded-md">
                            {lifeRingList}
                        </div>
                    </div>
                    {deviceCoordinates.lat !== 0 && deviceCoordinates.lng !== 0 && (
                        <div className="mr-2 lg:mr-0 h-full w-full bg-gray-100 text-center">
                            {map}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

    function LifeRingBox({ lifeRing }: { lifeRing: LifeRingType }) {
        return (
            <div className="text-black bg-gray-200 rounded-md p-2" onClick={() => { { setSelectedLifeRing(lifeRing.ringID); setShowAlertBox(true); setCurrentAlert(lifeRing) } }}>
                <div className="inline-flex w-full space-x-4">
                    <BsLifePreserver size={40} color={getColour(lifeRing.lastContact, lifeRing.lastActivity)} />
                    <h1 className="text-2xl font-bold"> {lifeRing.name} </h1>
                </div>
                <hr />
                <div className="font-semibold">
                    <div className="inline-flex w-full">
                        Last Activity: {formatDate(lifeRing.lastActivity)}
                    </div>
                    <div className="inline-flex w-full">
                        Last Contact: {formatDate(lifeRing.lastContact)}
                    </div>
                </div>
            </div >
        );
    }

    function ActivityAlertBox({ lifeRing }: { lifeRing: LifeRingType }) {
        var minutesAgo = Math.floor((new Date().getTime() - new Date(lifeRing.lastActivity).getTime()) / 1000 / 60)
        return (
            <>
                {showAlertBox && (
                    <div className="text-black bg-orange-200 rounded-md p-2 w-full h-min pb-2">
                        <div className="inline-flex w-full justify-between">
                            <div className="inline-flex w-full space-x-4">
                                <BsLifePreserver size={40} color="#FF0000" />
                                <h1 className="text-2xl font-bold"> Activity Alert </h1>
                            </div>
                            <button onClick={() => setShowAlertBox(false)} className="text-2xl font-bold"><IoMdClose size={28} /></button>
                        </div>
                        <h1 className="text-xl font-bold"> {lifeRing.name} </h1>
                        <div className="font-semibold">
                            <div className="inline-flex w-full">
                                Last Activity: {formatDate(lifeRing.lastActivity)} ({minutesAgo} minute{minutesAgo === 1 ? "" : "s"} ago)
                            </div>
                        </div>
                    </div >
                )}
            </>
        );
    }
}

export default LifeRings;

function formatDate(date: string) {
    var d = new Date(date);
    var momentDate = moment(d);
    return momentDate.format("DD/MM/YYYY HH:mm:ss");
}

function getColour(lastContact: string, lastActivity: string) {
    var lastContactDate = new Date(lastContact);
    var lastActivityDate = new Date(lastActivity);
    var now = new Date();
    var timeSinceLastContact = Math.floor((now.getTime() - lastContactDate.getTime()) / 1000 / 60 / 60);
    var timeSinceLastActivity = Math.floor((now.getTime() - lastActivityDate.getTime()) / 1000 / 60 / 60);
    if (timeSinceLastContact > 23) {
        return ("#262626");
    } else {
        if (timeSinceLastActivity > 23) {
            return ("#00FF00");
        } else if (timeSinceLastActivity > 12) {
            return ("#FFA500");
        } else {
            return ("#FF0000");
        }
    }
}
