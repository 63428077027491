import {
  createApi,
  fetchBaseQuery,
  skipToken,
} from "@reduxjs/toolkit/query/react";
import moment from "moment";
import { toast } from "react-toastify";
import { store } from "../app/store";
import {
  Dashboard,
  DashboardData,
  Dashboards,
  deleteDashboard,
  NewDashboard,
  setDashboard,
  setDashboards,
  updateFetchTimes,
} from "../features/dashboards/dashboardSlice";
import {
  backfillDashboardData,
  updateDashboardData,
  updateDeviceData,
} from "../features/data/dataSlice";
import { groupAPI } from "./groupAPI";
import { userAPI } from "./userAPI";
import _ from "lodash";

export type DashboardPhenomData = {
  data: DashboardDataPoint[];
  eui: string;
};

export type DashboardDataFetched = {
  dashboard_data_points: DashboardPhenomData[];
  end_time: Date;
  start_time: Date;
};

export type DashboardDataPoint = {
  ts: Date;
  data_points: { [key: string]: { [key: string]: any } };
};

const getLastFetch = (dashboardID: string): string => {
  return (
    store.getState().dashboards?.dashboards?.[dashboardID]?.lastFetch ??
    moment().utc().subtract(7, "days").format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")
  );
};

const getFirstFetch = (dashboardID: string): string => {
  return (
    store.getState().dashboards?.dashboards?.[dashboardID]?.firstFetch ??
    moment().utc().subtract(7, "days").format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")
  );
};
/*
API requests for dashboard related requests
*/
export const dashboardAPI = createApi({
  reducerPath: "dashboardAPI",
  //Set up base query for the API. URL from env. Token taken from state.
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers: any, { getState }: any) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("token", `${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Dashboard", "Dashboards", "DashboardData"],
  endpoints: (builder) => ({
    getDashboards: builder.query({
      query: () => {
        return {
          url: "/dashboards",
        };
      },
      transformResponse: (result: Dashboards) => result,
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setDashboards(data));
        } catch (error) {
          //console.log(error);
        }
      },
      providesTags: ["Dashboards"],
    }),
    getDashboard: builder.query({
      query: (args: { id: string }) => {
        return {
          url: `/dashboard/${args.id}`,
        };
      },
      transformResponse: (result: Dashboard) => result,
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setDashboard(data));
        } catch (error) {
          console.log(error);
        }
      },
      providesTags: ["Dashboard"],
    }),
    saveDashboard: builder.mutation({
      query: (args: { data: DashboardData; id: string; name: string; description: string; }) => {
        return {
          url: `/dashboard/${args.id}/save`,
          method: "PATCH",
          body: { data: args.data, name: args.name, description: args.description },
        };
      },
      transformResponse: (result: { message: string }) => result,
      invalidatesTags: ["Dashboard"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          //toast.success(data.message);
        } catch (error) {
          console.log(error);
        }
      },
    }),
    createDashboard: builder.mutation({
      query: (args: NewDashboard) => {
        return {
          url: `/dashboard/create`,
          method: "POST",
          body: args,
        };
      },
      transformResponse: (result: Dashboard) => result,
      invalidatesTags: ["Dashboards"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          //toast.success("Dashboard created successfully");
          dispatch(setDashboard(data));
          console.log(data);
        } catch (error) {
          console.log(error);
        }
      },
    }),
    getDashboardData: builder.query({
      query: (args: { id: string | undefined }) => {
        return {
          url: `/dashboard/${args.id}/data?start=${getLastFetch(
            args.id ?? ""
          )}&end=${moment().utc().format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")}`,
        };
      },
      transformResponse: (result: DashboardDataFetched) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        //
        try {
          const { data } = await queryFulfilled;
          dispatch(
            updateFetchTimes({
              lastFetch: moment(data.end_time)
                .utc()
                .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
              firstFetch: moment(data.start_time)
                .utc()
                .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
              id: args.id ?? "",
            })
          );
          if (data.dashboard_data_points != null) {
            dispatch(updateDashboardData(data.dashboard_data_points));
          }
        } catch (error) {
          console.log(error);
        }
      },
      providesTags: ["DashboardData"],
    }),
    backFillDashboardData: builder.query({
      query: (args: { id: string | undefined }) => {
        return {
          url: `/dashboard/${args.id}/data?start=${moment(
            getFirstFetch(args.id ?? ""),
            "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
          )
            .subtract(7, "days")
            .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")}&end=${getFirstFetch(
              args.id ?? ""
            )}`,
        };
      },
      transformResponse: (result: DashboardDataFetched) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            updateFetchTimes({
              lastFetch: moment(data.end_time)
                .utc()
                .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
              firstFetch: moment(data.start_time)
                .utc()
                .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
              id: args.id ?? "",
            })
          );
          if (data.dashboard_data_points != null) {
            dispatch(backfillDashboardData(data.dashboard_data_points));
          }
        } catch (error) {
          console.log(error);
        }
      },
      providesTags: ["DashboardData"],
    }),
    deleteDashboard: builder.mutation({
      query: (args: { id: string }) => {
        return {
          url: `/dashboard/${args.id}/delete`,
          method: "DELETE",
        };
      },
      transformResponse: (result: { message: string }) => result,
      invalidatesTags: ["Dashboards"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          //toast.success(data.message);
          dispatch(deleteDashboard(args.id));
          dispatch(userAPI.util.invalidateTags(["User"]));
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
});

export const {
  useGetDashboardsQuery,
  useGetDashboardQuery,
  useSaveDashboardMutation,
  useCreateDashboardMutation,
  useGetDashboardDataQuery,
  useDeleteDashboardMutation,
  useLazyBackFillDashboardDataQuery,
} = dashboardAPI;
