import React, { useEffect, useMemo, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams, } from "react-router-dom";
import { deviceAPI, SaveDevice, useDownlinkResetAllSensorsMutation, useDownlinkResetNodeMutation, useDownlinkToggleSensingMutation, useEditDeviceMutation, useGetDeviceQuery, useUnclaimDeviceMutation, } from "../../services/deviceAPI";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import _ from "lodash";
import { FormInput } from "../../components/FormInput";
import Spinner from "../../components/Spinner";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import AcceptModal from "../../components/modals/AcceptModal";
import { toast } from "react-toastify";
import { MapContainer, TileLayer, Popup, Marker, LayersControl, Pane, useMapEvents, useMap } from "react-leaflet";
import { Icon, LatLng } from "leaflet";
import { handleError } from "../../utils/ErrorHandling";
import { selectLanguage, selectUser } from "../../features/user/userSlice";
import { BsFillLockFill, BsFillUnlockFill, BsHourglassSplit } from "react-icons/bs";
import Skeleton from "react-loading-skeleton";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../dictionary/DeviceText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { MdRefresh } from "react-icons/md";
import { selectGroups } from "../../features/groups/groupSlice";
import { Button } from "../../components/Button";
import { Gateway } from "../../features/devices/deviceSlice";
import { Switch } from "@headlessui/react";

function EditDeviceAdvanced() {

    const stateLang = useAppSelector(selectLanguage);
    let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    useEffect(() => {
        setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    }, [stateLang]);

    const { deviceid } = useParams();
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const groups = useAppSelector(selectGroups);
    const { BaseLayer } = LayersControl;
    const markerRef = useRef(null);
    const navigate = useNavigate();

    const [deviceCoordinates, setDeviceCoordinates] = useState<LatLng>(new LatLng(0, 0));
    const [deviceName, setDeviceName] = useState("");
    const [lastMessageTs, setLastMessageTs] = useState(new Date());
    const [viewMode, setViewMode] = useState(true); //true = map, false = satellite
    const [editPermission, setEditPermission] = useState(false);
    const [unclaimPermission, setUnclaimPermission] = useState(false);
    const [lockDragging, setLockDragging] = useState(false);
    const [unClaimModal, setUnClaimModal] = useState(false);
    const [seed, setSeed] = useState(1);
    const [canMoveMarker, setCanMoveMarker] = useState(false);
    const [geolocationDisabled, setGeolocationDisabled] = useState(false);
    const [minInterval, setMinInterval] = useState(0);
    const [minIntervalError, setMinIntervalError] = useState("");
    const [showDownlinkModal, setShowDownlinkModal] = useState(false);

    const [downlinkMessageType, setDownlinkMessageType] = useState("reset_node");

    const downlinkMessageTypes = [
        /* { optionValue: "parameter", optionName: "Parameter" } , */
        { optionValue: "board_reset", optionName: "Reset Node" },
        { optionValue: "reset_all_sensors", optionName: "Reset All Sensors" },
        { optionValue: "toggle_sensing", optionName: "Toggle Sensing" },
        /* { optionValue: "rejoin_network", optionName: "Rejoin Network" }, */
    ]

    // var lastDownlinkMessageTs = new Date().getTime() - 75600000; 
    var [lastDownlinkMessageTs, setLastDownlinkMessageTs] = useState(new Date().getTime() - 86400001);

    var gatewayIcon = new Icon({
        iconUrl:
            "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
        shadowUrl:
            "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });

    const eventHandlers = useMemo(() => ({
        dragend() {
            const marker = markerRef.current;
            if (marker != null) {
                //ignore the error, it's a false positive
                // @ts-ignore
                setDeviceCoordinates(new LatLng(marker.getLatLng().lat.toFixed(6), marker.getLatLng().lng.toFixed(6)));
            }
        },
    }), []);

    const {
        data: device,
        isSuccess,
        isError,
        error,
        isFetching,
    } = useGetDeviceQuery(deviceid ?? skipToken);

    const [
        downlinkResetNode,
        {
            data: downlinkResetNodeData,
            isSuccess: isDownlinkResetNodeSuccess,
            isError: isDownlinkResetNodeError,
            error: downlinkResetNodeError,
            isLoading: isDownlinkResetNodeLoading,
        },
    ] = useDownlinkResetNodeMutation();

    const [
        downlinkResetAllSensors,
        {
            data: downlinkResetAllSensorsData,
            isSuccess: isDownlinkResetAllSensorsSuccess,
            isError: isDownlinkResetAllSensorsError,
            error: downlinkResetAllSensorsError,
            isLoading: isDownlinkResetAllSensorsLoading,
        },
    ] = useDownlinkResetAllSensorsMutation();

    const [
        downlinkToggleSensing,
        {
            data: downlinkToggleSensingData,
            isSuccess: isDownlinkToggleSensingSuccess,
            isError: isDownlinkToggleSensingError,
            error: downlinkToggleSensingError,
            isLoading: isDownlinkToggleSensingLoading,
        },
    ] = useDownlinkToggleSensingMutation();

    useEffect(() => {
        if (isError) {
            handleError(error);
            navigate("/device");
        }
    }, [error, isError]);

    useEffect(() => {
        if (isSuccess) {
            setDeviceCoordinates(new LatLng(Number.parseFloat(device.lat.toFixed(6)), (Number.parseFloat(device.lng.toFixed(6)))));
            setDeviceName(device.name);
            setLastMessageTs(new Date(device.status.last_message_ts));
            if (user.groups[device.group_id]) {
                setEditPermission(
                    user.groups[device.group_id].permissions.can_edit_devices
                        ?.can_edit_all ??
                    user.groups[device.group_id].permissions.can_edit_devices
                        ?.can_edit_devices?.[device.id] ??
                    false
                );
                setUnclaimPermission(
                    user.groups[device.group_id].permissions.can_unclaim ?? false
                );
            } else {
                setEditPermission(false);
                setUnclaimPermission(false);
            }
        }
    }, [device, user, isSuccess]);

    const [
        saveDevice,
        {
            data: saveDeviceResponse,
            isSuccess: isSaveDeviceSuccess,
            isLoading: isSaveDeviceLoading,
            isError: isSaveDeviceError,
            error: saveDeviceError,
        },
    ] = useEditDeviceMutation();

    const [
        unclaimDevice,
        {
            data: unclaimData,
            isSuccess: isUnclaimSuccess,
            isError: isUnclaimError,
            error: unclaimError,
            isLoading: isUnclaimLoading,
        },
    ] = useUnclaimDeviceMutation();

    useEffect(() => {
        if (isUnclaimSuccess) {
            toast.success(language.editDevice.viewEditDevice.toasts.deviceUnclaimed);
            navigate("/device");
        }
    }, [unclaimData, isUnclaimSuccess, language.editDevice.viewEditDevice.toasts.deviceUnclaimed]);

    useEffect(() => {
        if (isUnclaimError) {
            handleError(unclaimError);
        }
    }, [unclaimError, isUnclaimError]);

    useEffect(() => {
        if (isSaveDeviceError) {
            handleError(saveDeviceError);
        }
    }, [saveDeviceError, isSaveDeviceError]);

    useEffect(() => {
        if (isSaveDeviceSuccess) {
            toast.success(language.editDevice.viewEditDevice.toasts.deviceSaved)
        }
    }, [saveDeviceResponse, isSaveDeviceSuccess, language.editDevice.viewEditDevice.toasts.deviceSaved]);

    async function ChangeDetails(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        var { deviceName } = document.forms[0];
        setDeviceName(deviceName.value);
        if (device) {
            const newUpdatedDevice: SaveDevice = {
                id: device.id,
                updates: [],
            };
            newUpdatedDevice.updates?.push({
                field: "name",
                value: deviceName.value,
            });
            newUpdatedDevice.updates?.push({
                field: "lat",
                value: deviceCoordinates.lat,
            });
            newUpdatedDevice.updates?.push({
                field: "lng",
                value: deviceCoordinates.lng,
            });
            saveDevice(newUpdatedDevice);
        }
        setCanMoveMarker(false);
    }

    function CenterMap({ center }: { center: LatLng }) {
        const map = useMap();
        map.setView(center, map.getZoom());
        return null;
    }

    function MapEvents() {
        const map = useMapEvents({
            baselayerchange: (e) => {
                e.name === "Map" ? setViewMode(true) : setViewMode(false);
            },
        });
        return null;
    }

    const LockDraggingButton: React.FC = () => {
        return (
            <button
                className="bg-white pl-[6px] ring-opacity-20 ring-2 ring-gray-900 top-[5rem] left-3 border rounded absolute w-[1.9rem] h-8 z-[700]"
                onClick={() => {
                    setSeed(Math.random());
                    setLockDragging(!lockDragging);
                }}
            >
                {lockDragging ? (
                    <BsFillUnlockFill size={16} />
                ) : (
                    <BsFillLockFill size={16} />
                )}
            </button>
        );
    };

    const SetLocationAlert: React.FC = () => {
        if ((deviceCoordinates.lat === 0 || deviceCoordinates.lng === 0) && !geolocationDisabled && editPermission) {
            return (
                <div className="bg-white px-[6px] ring-opacity-20 ring-2 ring-gray-900 top-[0.75rem] left-16 border rounded absolute z-[700] text-center"
                    onClick={() => {
                        if (navigator.geolocation) {
                            navigator.geolocation.getCurrentPosition(success, error);
                        } else {
                            setGeolocationDisabled(true);
                            console.log("Geolocation not supported");
                        }

                        function success(position: { coords: { latitude: number; longitude: number; }; }) {
                            const latitude = position.coords.latitude;
                            const longitude = position.coords.longitude;
                            setDeviceCoordinates(new LatLng(latitude, longitude));
                        }

                        function error() {
                            setGeolocationDisabled(true);
                            toast.error("Geolocation is disabled.")
                        }
                    }}
                >
                    {"Device seems to be at (0,0)"}<br />{"Click to set to your current location"}
                </div>
            );
        } else {
            return (
                <></>
            );
        }
    }

    function SendDownLinkMessage() {
        switch (downlinkMessageType) {
            case "parameter":
            case "board_reset":
            case "rejoin_network":
                break;
            case "reset_node":
                downlinkResetNode(deviceid ?? "");
                break;
            case "reset_all_sensors":
                downlinkResetAllSensors(deviceid ?? "");
                break;
            case "toggle_sensing":
                downlinkToggleSensing(deviceid ?? "");
                break;
            default:
                break;
        }
    }

    const Modals: JSX.Element = (
        <>
            {unClaimModal && (
                <AcceptModal
                    onAccept={() => {
                        unclaimDevice({
                            device_id: deviceid ?? "",
                            group_id: device?.group_id ?? "",
                        });
                    }}
                    onCancel={() => {
                        setUnClaimModal(false);
                    }}
                    Title={language.editDevice.viewEditDevice.modals.unclaimModal.title}
                    Body={
                        <>
                            {language.editDevice.viewEditDevice.modals.unclaimModal.phrase1} <b>{(device ?? {}).name}</b>{language.editDevice.viewEditDevice.modals.unclaimModal.phrase2}
                        </>
                    }
                    AcceptButton={language.editDevice.buttons.unclaimDevice}
                    AcceptButtonColour={"red"}
                    CancelButton={language.editDevice.buttons.cancel}
                    loading={isUnclaimLoading}
                />
            )}
            {showDownlinkModal && (
                <AcceptModal
                    onAccept={() => {
                        setShowDownlinkModal(false);
                        SendDownLinkMessage();
                        setLastDownlinkMessageTs(new Date().getTime());
                    }}
                    onCancel={() => {
                        setShowDownlinkModal(false);
                    }}
                    Title={"Send Downlink Message"}
                    Body={
                        <div className="space-y-2">
                            <div>
                                Downlink Messages can only be sent once every 24 hours. Are you sure you want to send this message?
                            </div>
                            <div className="flex flex-col rounded-md bg-gray-200 p-2 text-lg">

                                {downlinkMessageType === "parameter" && (
                                    <div className="w-full">
                                        Minimum Uplink Interval: {minInterval} minutes
                                    </div>
                                )}
                                {downlinkMessageType === "board_reset" && (
                                    <div className="w-full">
                                        {"Board Reset"}
                                    </div>
                                )}
                                {downlinkMessageType === "rejoin_network" && (
                                    <div className="w-full">
                                        {"Rejoin Network"}
                                    </div>
                                )}
                                {downlinkMessageType === "reset_node" && (
                                    <div className="w-full">
                                        {"Reset Node"}
                                    </div>
                                )}
                                {downlinkMessageType === "reset_all_sensors" && (
                                    <div className="w-full">
                                        {"Reset all Sensors"}
                                    </div>
                                )}
                                {downlinkMessageType === "toggle_sensing" && (
                                    <div className="w-full">
                                        {"Toggle Sensing"}
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                    AcceptButton={"Send Message"}
                    AcceptButtonColour={"blue"}
                    CancelButton={"Cancel"}
                    loading={false}
                />
            )}
        </>
    )

    const DownlinkMessages = () => {
        const styledSwitch = (stateVar: boolean, setStateVar: (value: boolean) => void, label: string) => {
            return (
                <div className="flex flex-row justify-between py-1"
                    onClick={() => {
                        setStateVar(!stateVar);
                    }}>
                    <div className="flex items-center text-right font-semibold text-blue-600">{label}</div>
                    <div className="flex items-center">
                        <Switch
                            checked={stateVar}
                            key={label}
                            className={`${stateVar ? "bg-green-400" : "bg-red-600"
                                } relative inline-flex h-[24px] w-[44px] shrink-0 cursor-pointer rounded-full border-2 border-transparent duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                        >
                            <span
                                aria-hidden="true"
                                className={`${stateVar ? "translate-x-[20px]" : "translate-x-0"
                                    } pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                            />
                        </Switch>
                    </div>
                </div>
            )
        }

        function formErrorCheck() {
            let error = false;
            if (minInterval < 3 && downlinkMessageType === "parameter") {
                setMinIntervalError("Minimum interval must be at least 3 minutes");
                error = true;
            } else {
                setMinIntervalError("");
            }
            return error;
        }

        var twentyFourHoursAgo = new Date().getTime() - 86400000;

        return (
            <>
                <div className="mb-1">
                    <label className="text-lg font-semibold">
                        {isFetching ? <Skeleton width={100} height={20} /> : "Downlink Messages"}
                    </label>
                    <FormInput
                        value={downlinkMessageType}
                        type="select"
                        label={"Type"}
                        htmlFor="messagetype"
                        data={downlinkMessageTypes}
                        onChange={(event) => {
                            setDownlinkMessageType(event.target.value);
                        }}
                    />
                </div>
                {downlinkMessageType === "parameter" && (
                    <div>
                        <label htmlFor={"minInterval"} className="text-blue-600 font-semibold">
                            {"Minimum Uplink Interval (Minutes)"}
                        </label>
                        <input
                            disabled={!editPermission}
                            onChange={(e) => {
                                setMinInterval(parseInt(e.target.value) ?? 3);
                            }}
                            step={1}
                            min={3}
                            type={"number"}
                            id={"minInterval"}
                            name={"minInterval"}
                            placeholder={"Minimum Uplink Interval (Minutes)"}
                            className={`w-full p-2 rounded-xl  border-2 min-h-[2.75rem] ${(minIntervalError !== "") ? "border-red-600" : "border-gray-300"
                                }`}
                            value={minInterval ?? 3}
                        >
                        </input>
                        <div>
                            <label className="text-red-600 font-semibold">
                                {minIntervalError}
                            </label>
                        </div>
                    </div>
                )}
                {downlinkMessageType === "board_reset" && (
                    <div className="text-md">
                        {"Send a Board Reset to the device. This will cause the device to restart. Can only be sent once every 24 hours."}
                    </div>
                )}
                {downlinkMessageType === "rejoin_network" && (
                    <div className="text-md">
                        {"Send a Network Rejoin command to the device. This will cause the device to look for available networks. Can only be sent once every 24 hours."}
                    </div>
                )}
                {downlinkMessageType === "reset_node" && (
                    <div className="text-md">
                        {"Resets node. Can only be sent once every 24 hours."}
                    </div>
                )}
                {downlinkMessageType === "reset_all_sensors" && (
                    <div className="text-md">
                        {"Reset all device sensors. Can only be sent once every 24 hours."}
                    </div>
                )}
                {downlinkMessageType === "toggle_sensing" && (
                    <div className="text-md">
                        {"Toggle device sensing. Can only be sent once every 24 hours."}
                    </div>
                )}
                <div className="mt-2">
                    <Button
                        colour={twentyFourHoursAgo < lastDownlinkMessageTs ? "gray" : "blue"}
                        onClick={() => {
                            var formError = formErrorCheck();
                            if (!formError) {
                                setShowDownlinkModal(true);
                            }
                        }}
                        disabled={twentyFourHoursAgo < lastDownlinkMessageTs}
                        label={<>{(twentyFourHoursAgo < lastDownlinkMessageTs) && <BsHourglassSplit size={20} />}<div className="py-[2px] pl-1">{(twentyFourHoursAgo < lastDownlinkMessageTs) ? ("Next message in " + (((twentyFourHoursAgo - lastDownlinkMessageTs) / (-1 * 1000 * 3600)).toFixed(0)) + " hours") : "Send Downlink Message"}</div></>}
                    />
                </div>
            </>
        )
    }

    const Markers = (
        <>
            {!canMoveMarker && (
                _.map(device?.gateways, (gateway: Gateway) => {
                    return (
                        (gateway.lat && gateway.lng) && (
                            (gateway.lat !== 0 && gateway.lng !== 0) && (
                                <Marker
                                    icon={gatewayIcon}
                                    position={new LatLng(gateway.lat, gateway.lng)}
                                >
                                    <Popup autoPan={false}>
                                        {gateway.name + " " + gateway.last_contact_ts}
                                    </Popup>
                                </Marker>
                            )
                        )
                    );
                })
            )}
            <Marker
                position={deviceCoordinates}
                ref={markerRef}
                eventHandlers={eventHandlers}
                draggable={editPermission && canMoveMarker}
            >
                <Popup autoPan={false}>{device?.name}</Popup>
            </Marker>
        </>
    )

    const Map = (
        <>
            <MapContainer
                key={seed}
                center={deviceCoordinates}
                minZoom={0}
                maxZoom={18}
                zoom={13}
                style={{ height: "100%", width: "100%", zIndex: "0" }}
                dragging={lockDragging}
                scrollWheelZoom={lockDragging}
            >
                <LayersControl position="topright">
                    <BaseLayer checked={viewMode} name="Map">
                        <Pane name="map" style={{ zIndex: 0 }}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </Pane>
                    </BaseLayer>
                    <BaseLayer checked={!viewMode} name="Satellite">
                        <Pane name="satellite" style={{ zIndex: 0 }}>
                            <TileLayer
                                attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                            />
                        </Pane>
                    </BaseLayer>
                </LayersControl>
                <LockDraggingButton />
                <SetLocationAlert />
                {Markers}
                <CenterMap center={deviceCoordinates} />
                <MapEvents />
            </MapContainer>
        </>
    )

    const Buttons = (
        <>
            <div className="inline-flex w-full pb-4 lg:pb-0 justify-between">
                <div>
                    {unclaimPermission && (
                        <div className="mt-2">
                            <Button
                                label={language.editDevice.buttons.unclaimDevice}
                                onClick={() => {
                                    setUnClaimModal(true);
                                }}
                                colour="red"
                            />
                        </div>
                    )}
                </div>
                {editPermission && (
                    <div className="inline-flex flex-row-reverse">
                        <div className="w-auto mt-2 ml-2">
                            <Button
                                label={isSaveDeviceLoading ? <Spinner colour="fill-blue-600" /> : language.editDevice.buttons.save}
                                type="submit"
                                colour="green"
                            />
                        </div>
                        <div className="w-auto mt-2">
                            <Button
                                label={language.editDevice.buttons.cancel}
                                onClick={() => {
                                    dispatch(deviceAPI.util.invalidateTags(["Device"]));
                                    setCanMoveMarker(false);
                                    setDeviceName(device?.name ?? deviceName);
                                    setDeviceCoordinates(new LatLng(device?.lat ?? 0, device?.lng ?? 0));
                                }}
                                colour="white"
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    )

    return (
        <>
            {Modals}
            <div className="h-full w-full text-black bg-white rounded-lg overflow-y-scroll scrollbar-thin p-4 pt-1">
                <form
                    className="h-full flex flex-col justify-between"
                    onSubmit={ChangeDetails}
                >
                    <div className="flex flex-col lg:flex-row h-auto lg:space-x-4 space-y-4">
                        <div className="w-full lg:w-[50%]">
                            <h2 className="font-bold text-xl">
                                {!isFetching ? "Advanced" : <Skeleton className="w-30" />}
                            </h2>
                            {DownlinkMessages()}
                            {/* {FieldList} */}
                        </div>
                        <div className="h-[200px] lg:h-auto w-full lg:w-[50%]">
                            {/* {Map} */}
                        </div>
                    </div>
                    {/* {Buttons} */}
                </form>
            </div>
        </>
    );
}

export default EditDeviceAdvanced;
