import _ from "lodash";
import { useEffect, useState } from "react";
import { ChartType, GaugeData } from "..";

import NewRingMenu from "./NewRingMenu";
import { FormInput } from "../../../FormInput";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../../../dictionary/ChartsText";
import { LanguageCheck } from "../../../../utils/LanguageCheck";
import { useAppSelector } from "../../../../app/hooks";
import { selectLanguage } from "../../../../features/user/userSlice";
import { EditChart, EditChartProps } from "../EditChart";

type Props = {
  id: string;
  data: ChartType;
  remove: (id: string) => void;
  updateChart: (data: ChartType) => void;
  setShowChartEdit: (isOpen: boolean) => void;
};

function EditGauge({ id, remove, updateChart, data, setShowChartEdit }: Props) {
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  const [title, setTitle] = useState(data.title.en);
  const [rings, setRings] = useState<GaugeData[]>(data.chartData);
  const [min, setMin] = useState<number>(data.chartData.min);
  const [max, setMax] = useState<number>(data.chartData.max);
  const [loading, setLoading] = useState(false);

  function GetTitleRingsAndMinMaxFromParams() {
    setTitle(data.title.en);
    setRings(data.chartData);
    if (data.chartData !== undefined && data.chartData.length > 0) {
      setMin(data.chartData[0].min);
      setMax(data.chartData[0].max);
    }
  }

  useEffect(() => {
    GetTitleRingsAndMinMaxFromParams();
  }, [data]);

  const addNewRing = ({ label, phenomena, colour, deviceID, ringID, unit, elemID, }: GaugeData) => {
    setRings(_.concat(rings, { label, phenomena, colour, deviceID, unit, ringID, min, max, elemID, }));
  };

  const onRemoveRing = (ringID: string | undefined) => {
    setRings(_.reject(rings, { ringID: ringID }));
  };

  const settingsBox = (
    <div className="p-2 bg-gray-200 overflow-y-scroll scrollbar-none rounded-lg flex flex-col">
      <FormInput
        label={language.charts.gauge.labels.title}
        htmlFor="title"
        value={title !== null ? title : ""}
        onChange={(event) => {
          setTitle(event.target.value);
        }}
      />
      <FormInput
        label={language.charts.gauge.labels.minValue}
        autoComplete="off"
        inputmode="numeric"
        htmlFor="min"
        type="text"
        value={min}
        onKeyDown={(evt) => /^-?\d|-+$/.test(evt.key) ? null : (evt.key === "Backspace") ? null : evt.preventDefault()}
        onChange={(event) => {
          if (/^-?\d+$/.test(event.target.value)) {
            setMin(parseInt(event.target.value));
          }
        }}
      />
      <FormInput
        label={language.charts.gauge.labels.maxValue}
        autoComplete="off"
        inputmode="numeric"
        htmlFor="max"
        type="text"
        value={max}
        onKeyDown={(evt) => /^-?\d|-+$/.test(evt.key) ? null : (evt.key === "Backspace") ? null : evt.preventDefault()}
        onChange={(event) => {
          if (/^-?\d+$/.test(event.target.value)) {
            setMax(parseInt(event.target.value));
          }
        }}
      />
    </div>
  );

  function SaveRingData(ring: GaugeData) {
    setRings(
      _.map(rings, (currentRing: GaugeData) => {
        if (currentRing.ringID === ring.ringID) {
          return ring;
        } else {
          return currentRing;
        }
      })
    );
  }

  const itemList = rings.map((ring, index) => (
    <NewRingMenu
      index={index}
      ring={ring}
      key={index}
      removeRing={onRemoveRing}
      saveRingData={SaveRingData}
    />
  ))

  function onDelete() {
    remove(id);
    setShowChartEdit(false);
  }

  function saveChartSettings() {
    var newRings = rings.map((ring) => {
      var newRing: GaugeData = {
        ...ring,
        min: min,
        max: max,
      };
      return newRing;
    });
    setRings(newRings);
    setLoading(true)
    setTimeout(function () {
      setShowChartEdit(false);
      setLoading(false)
    }, 300);
    updateChart({
      i: id,
      type: "gauge",
      title: { en: title },
      chartData: newRings,
    });
  };

  const EditChartObjects: EditChartProps = ({
    editMapType: language.charts.gauge.editGauge,
    settingsBox: settingsBox,
    itemList: itemList,
    newButton: undefined,
    onDelete: onDelete,
    onSave: saveChartSettings,
    loading: loading,
  })

  return (
    <EditChart {...EditChartObjects} />
  );
};

export default EditGauge;
