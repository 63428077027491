export type LanguageText = {
    createDashboard: {
        createADashboard: string;
        selectAGroup: string;
        formError: {
            noPerms: string;
        };
        labels: {
            dashboardName: string;
            dashboardDescription: string;
            addToAGroup: string;
        };
        buttons: {
            createDashboard: string;
        };
        toasts: {
            createdSuccessfully: string;
            selectAGroup: string;
            noGroupPermission: string;
            noGroups: string;
        };
    };
    dashboardTable: {
        dashboards: string;
        tableElements: {
            headers: {
                dashboardName: string;
                description: string;
                lastUpdated: string;
                group: string;
                deleteDashboard: string;
            };
            buttons: {
                delete: string;
            };
            quickEdit: string;
            searchAllColumns: string;
            goToPage: string;
            show: string;
            loading: string;
            noDashboardsFound: string;
        };
        modals: {
            confirmDelete: {
                title: string;
                phrase1: string;
                thisDashboard: string;
                phrase2: string;
                buttons: {
                    cancel: string;
                    delete: string;
                };
            };
        }
        toasts: {
            deleted: string;
        }
    };
    viewDashboard: {
        buttons: {
            edit: string;
            cancel: string;
            stopEditing: string;
            save: string;
        };
        toasts: {
            saved: string;
        }
    };
};
export const English: LanguageText = {
    createDashboard: {
        createADashboard: "Create a Dashboard",
        selectAGroup: "Select A Group",
        formError: {
            noPerms: "You do not have permission to create any dashboards",
        },
        labels: {
            dashboardName: "Dashboard Name",
            dashboardDescription: "Dashboard Description",
            addToAGroup: "Add to a Group"
        },
        buttons: {
            createDashboard: "Create Dashboard"
        },
        toasts: {
            createdSuccessfully: "Dashboard created successfully",
            selectAGroup: "Please select a group",
            noGroupPermission: "You need to have permission to create dashboards in any group. Contact the person that invited you to get permission.",
            noGroups: "You need to be part of a group to create a dashboard. Either create a new group, or join someone else's."
        }
    },
    dashboardTable: {
        dashboards: "Dashboards",
        tableElements: {
            headers: {
                dashboardName: "Dashboard Name",
                description: "Description",
                lastUpdated: "Last Updated",
                group: "Group",
                deleteDashboard: "Delete Dashboard",
            },
            buttons: {
                delete: "Delete",
            },
            quickEdit: "Quick Edit",
            searchAllColumns: "Search all columns",
            goToPage: "Go to page",
            show: "Show",
            loading: "Loading",
            noDashboardsFound: "No dashboards found"
        },
        modals: {
            confirmDelete: {
                title: "Delete Dashboard",
                phrase1: "Are you sure you want to delete ",
                thisDashboard: "this dashboard",
                phrase2: "? This action cannot be undone.",
                buttons: {
                    cancel: "Cancel",
                    delete: "Delete Dashboard",
                }
            },
        },
        toasts: {
            deleted: "Dashboard deleted."
        }
    },
    viewDashboard: {
        buttons: {
            edit: "Edit",
            cancel: "Cancel",
            stopEditing: "Stop Editing",
            save: "Save",
        },
        toasts: {
            saved: "Dashboard updated successfully"
        }
    }
};
export const French: LanguageText = {
    createDashboard: {
        createADashboard: "Créer un tableau de bord",
        selectAGroup: "Sélectionner un groupe",
        formError: {
            noPerms: "Vous n'avez pas la permission de créer des tableaux de bord",
        },
        labels: {
            dashboardName: "Nom du tableau de bord",
            dashboardDescription: "Description du tableau de bord",
            addToAGroup: "Ajouter à un groupe"
        },
        buttons: {
            createDashboard: "Créer un tableau de bord"
        },
        toasts: {
            createdSuccessfully: "Tableau de bord créé avec succès",
            selectAGroup: "Veuillez sélectionner un groupe",
            noGroupPermission: "Vous devez avoir la permission de créer des tableaux de bord dans n'importe quel groupe. Contactez la personne qui vous a invité pour obtenir cette autorisation.",
            noGroups: "Vous devez faire partie d'un groupe pour créer un tableau de bord. Il faut soit créer un nouveau groupe, soit rejoindre celui de quelqu'un d'autre."
        }
    },
    dashboardTable: {
        dashboards: "Tableaux de bord",
        tableElements: {
            headers: {
                dashboardName: "Nom du tableau de bord",
                description: "Description",
                lastUpdated: "Dernière mise à jour",
                group: "Groupe",
                deleteDashboard: "Supprimer le tableau de bord",
            },
            buttons: {
                delete: "Supprimer",
            },
            quickEdit: "Édition rapide",
            searchAllColumns: "Rechercher dans toutes les colonnes",
            goToPage: "Aller à la page",
            show: "Afficher",
            loading: "Chargement",
            noDashboardsFound: "Aucun tableau de bord trouvé"
        },
        modals: {
            confirmDelete: {
                title: "Supprimer le tableau de bord",
                phrase1: "Êtes-vous sûr de vouloir supprimer ",
                thisDashboard: "ce tableau de bord",
                phrase2: "? Cette action ne peut pas être annulée.",
                buttons: {
                    cancel: "Annuler",
                    delete: "Supprimer le tableau de bord",
                }
            },
        },
        toasts: {
            deleted: "Tableau de bord supprimé."
        }
    },
    viewDashboard: {
        buttons: {
            edit: "Modifier",
            cancel: "Annuler",
            stopEditing: "Arrêter l'édition",
            save: "Sauvegarder",
        },
        toasts: {
            saved: "Tableau de bord mis à jour avec succès"
        }
    }
};
export const German: LanguageText = {
    createDashboard: {
        createADashboard: "Ein Dashboard erstellen",
        selectAGroup: "Eine Gruppe auswählen",
        formError: {
            noPerms: "Sie haben keine Berechtigung, Dashboards zu erstellen",
        },
        labels: {
            dashboardName: "Dashboard-Name",
            dashboardDescription: "Dashboard Beschreibung",
            addToAGroup: "Zu einer Gruppe hinzufügen"
        },
        buttons: {
            createDashboard: "Dashboard erstellen"
        },
        toasts: {
            createdSuccessfully: "Dashboard erfolgreich erstellt",
            selectAGroup: "Bitte wählen Sie eine Gruppe",
            noGroupPermission: "Sie müssen die Erlaubnis haben, Dashboards in jeder Gruppe zu erstellen. Wenden Sie sich an die Person, die Sie eingeladen hat, um die Erlaubnis zu erhalten.",
            noGroups: "Um ein Dashboard zu erstellen, müssen Sie einer Gruppe angehören. Entweder erstellen Sie eine neue Gruppe oder treten einer anderen bei."
        }
    },
    dashboardTable: {
        dashboards: "Dashboards",
        tableElements: {
            headers: {
                dashboardName: "Dashboard-Name",
                description: "Beschreibung",
                lastUpdated: "Zuletzt aktualisiert",
                group: "Gruppe",
                deleteDashboard: "Dashboard löschen",
            },
            buttons: {
                delete: "Löschen",
            },
            quickEdit: "Schnellbearbeitung",
            searchAllColumns: "Alle Spalten durchsuchen",
            goToPage: "Weiter zur Seite",
            show: "Anzeigen",
            loading: "Laden",
            noDashboardsFound: "Keine Dashboards gefunden"
        },
        modals: {
            confirmDelete: {
                title: "Dashboard löschen",
                phrase1: "Sind Sie sicher, dass Sie ",
                thisDashboard: "dieses Dashboard",
                phrase2: " löschen wollen? Diese Aktion kann nicht rückgängig gemacht werden",
                buttons: {
                    cancel: "Abbrechen",
                    delete: "Dashboard löschen",
                }
            },
        },
        toasts: {
            deleted: "Dashboard gelöscht",
        }
    },
    viewDashboard: {
        buttons: {
            edit: "Bearbeiten",
            cancel: "Abbrechen",
            stopEditing: "Bearbeitung beenden",
            save: "Speichern",
        },
        toasts: {
            saved: "Dashboard erfolgreich aktualisiert"
        }
    }
};
export const Dutch: LanguageText = {
    createDashboard: {
        createADashboard: "Maak een dashboard",
        selectAGroup: "Selecteer een groep",
        formError: {
            noPerms: "U heeft geen toestemming om dashboards te maken",
        },
        labels: {
            dashboardName: "Dashboard Naam",
            dashboardDescription: "Dashboard Beschrijving",
            addToAGroup: "Toevoegen aan een groep"
        },
        buttons: {
            createDashboard: "Dashboard maken"
        },
        toasts: {
            createdSuccessfully: "Dashboard succesvol aangemaakt",
            selectAGroup: "Selecteer een groep",
            noGroupPermission: "Je moet toestemming hebben om dashboards te maken in een groep. Neem contact op met de persoon die je heeft uitgenodigd om toestemming te krijgen.",
            noGroups: "Je moet deel uitmaken van een groep om een dashboard te maken. Maak een nieuwe groep aan, of sluit je aan bij die van iemand anders."
        }
    },
    dashboardTable: {
        dashboards: "Dashboards",
        tableElements: {
            headers: {
                dashboardName: "Dashboard Naam",
                description: "Beschrijving",
                lastUpdated: "Laatst bijgewerkt",
                group: "Groep",
                deleteDashboard: "Dashboard verwijderen",
            },
            buttons: {
                delete: "Verwijder",
            },
            quickEdit: "Snelle bewerking",
            searchAllColumns: "Doorzoek alle kolommen",
            goToPage: "Ga naar de pagina",
            show: "Toon",
            loading: "Laden",
            noDashboardsFound: "Geen dashboards gevonden"
        },
        modals: {
            confirmDelete: {
                title: "Dashboard verwijderen",
                phrase1: "Weet je zeker dat je ",
                thisDashboard: "dit dashboard",
                phrase2: " wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.",
                buttons: {
                    cancel: "Annuleren",
                    delete: "Dashboard verwijderen",
                }
            },
        },
        toasts: {
            deleted: "Dashboard verwijderd."
        }
    },
    viewDashboard: {
        buttons: {
            edit: "Bewerk",
            cancel: "Annuleren",
            stopEditing: "Stop bewerken",
            save: "Opslaan",
        },
        toasts: {
            saved: "Dashboard succesvol bijgewerkt"
        }
    }
};
export const Spanish: LanguageText = {
    createDashboard: {
        createADashboard: "Crear un cuadro de mando",
        selectAGroup: "Seleccionar un grupo",
        formError: {
            noPerms: "No tiene permiso para crear ningún cuadro de mando",
        },
        labels: {
            dashboardName: "Nombre del panel",
            dashboardDescription: "Descripción del cuadro de mandos",
            addToAGroup: "Añadir a un grupo"
        },
        buttons: {
            createDashboard: "Crear cuadro de mando"
        },
        toasts: {
            createdSuccessfully: "Cuadro de mando creado con éxito",
            selectAGroup: "Por favor, seleccione un grupo",
            noGroupPermission: "Necesitas tener permiso para crear cuadros de mando en cualquier grupo. Ponte en contacto con la persona que te invitó para obtener permiso",
            noGroups: "Tienes que formar parte de un grupo para crear un cuadro de mando. Crea un grupo nuevo o únete al de otra persona",
        }
    },
    dashboardTable: {
        dashboards: "Cuadros de mando",
        tableElements: {
            headers: {
                dashboardName: "Nombre del panel",
                description: "Descripción",
                lastUpdated: "Última actualización",
                group: "Grupo",
                deleteDashboard: "Borrar panel de control",
            },
            buttons: {
                delete: "Borrar",
            },
            quickEdit: "Edición rápida",
            searchAllColumns: "Buscar en todas las columnas",
            goToPage: "Ir a la página",
            show: "Mostrar",
            loading: "Cargando",
            noDashboardsFound: "No se han encontrado cuadros de mando"
        },
        modals: {
            confirmDelete: {
                title: "Borrar panel de control",
                phrase1: "¿Estás seguro de que quieres borrar ",
                thisDashboard: "este salpicadero",
                phrase2: "? Esta acción no se puede deshacer.",
                buttons: {
                    cancel: "Cancelar",
                    delete: "Borrar panel de control",
                }
            },
        },
        toasts: {
            deleted: "Tablero de mandos borrado",
        }
    },
    viewDashboard: {
        buttons: {
            edit: "Editar",
            cancel: "Cancelar",
            stopEditing: "Dejar de editar",
            save: "Guardar",
        },
        toasts: {
            saved: "Cuadro de mandos actualizado correctamente"
        }
    }
};
export const Italian: LanguageText = {
    createDashboard: {
        createADashboard: "Creare un cruscotto",
        selectAGroup: "Seleziona un gruppo",
        formError: {
            noPerms: "L'utente non ha il permesso di creare alcun dashboard",
        },
        labels: {
            dashboardName: "Nome del cruscotto",
            dashboardDescription: "Descrizione del cruscotto",
            addToAGroup: "Aggiungi a un gruppo"
        },
        buttons: {
            createDashboard: "Crea cruscotto"
        },
        toasts: {
            createdSuccessfully: "Cruscotto creato con successo",
            selectAGroup: "Selezionare un gruppo",
            noGroupPermission: "È necessario avere l'autorizzazione per creare dashboard in qualsiasi gruppo. Contattate la persona che vi ha invitato per ottenere l'autorizzazione",
            noGroups: "Per creare una dashboard è necessario far parte di un gruppo. O si crea un nuovo gruppo o ci si unisce a quello di qualcun altro",
        }
    },
    dashboardTable: {
        dashboards: "Cruscotti",
        tableElements: {
            headers: {
                dashboardName: "Nome del cruscotto",
                description: "Descrizione",
                lastUpdated: "Ultimo aggiornamento",
                group: "Gruppo",
                deleteDashboard: "Elimina cruscotto",
            },
            buttons: {
                delete: "Cancellare",
            },
            quickEdit: "Modifica rapida",
            searchAllColumns: "Cerca in tutte le colonne",
            goToPage: "Vai alla pagina",
            show: "Mostra",
            loading: "Caricamento",
            noDashboardsFound: "Nessun cruscotto trovato"
        },
        modals: {
            confirmDelete: {
                title: "Elimina cruscotto",
                phrase1: "Siete sicuri di voler eliminare ",
                thisDashboard: "questo cruscotto",
                phrase2: "? Questa azione non può essere annullata.",
                buttons: {
                    cancel: "Annulla",
                    delete: "Elimina cruscotto",
                }
            },
        },
        toasts: {
            deleted: "Cruscotto cancellato",
        }
    },
    viewDashboard: {
        buttons: {
            edit: "Modifica",
            cancel: "Annulla",
            stopEditing: "Ferma la modifica",
            save: "Salva",
        },
        toasts: {
            saved: "Cruscotto aggiornato con successo"
        }
    }
};
export const Romanian: LanguageText = {
    createDashboard: {
        createADashboard: "Creați un tablou de bord",
        selectAGroup: "Selectați un grup",
        formError: {
            noPerms: "Nu aveți permisiunea de a crea niciun tablou de bord",
        },
        labels: {
            dashboardName: "Numele tabloului de bord",
            dashboardDescription: "Descrierea tabloului de bord",
            addToAGroup: "Adaugă la un grup"
        },
        buttons: {
            createDashboard: "Creează tabloul de bord"
        },
        toasts: {
            createdSuccessfully: "Tablou de bord creat cu succes",
            selectAGroup: "Vă rugăm să selectați un grup",
            noGroupPermission: "Trebuie să aveți permisiunea de a crea tablouri de bord în orice grup. Contactați persoana care v-a invitat pentru a obține permisiunea.",
            noGroups: "Trebuie să faci parte dintr-un grup pentru a crea un tablou de bord. Fie creați un grup nou, fie vă alăturați grupului altcuiva."
        }
    },
    dashboardTable: {
        dashboards: "Tablouri de bord",
        tableElements: {
            headers: {
                dashboardName: "Numele tabloului de bord",
                description: "Descriere",
                lastUpdated: "Ultima actualizare",
                group: "Grup",
                deleteDashboard: "Ștergeți tabloul de bord",
            },
            buttons: {
                delete: "Ștergeți",
            },
            quickEdit: "Editare rapidă",
            searchAllColumns: "Căutați toate coloanele",
            goToPage: "Mergi la pagina",
            show: "Arată",
            loading: "Încărcare",
            noDashboardsFound: "Nu s-au găsit tablouri de bord"
        },
        modals: {
            confirmDelete: {
                title: "Ștergeți tabloul de bord",
                phrase1: "Sunteți sigur că doriți să ștergeți ",
                thisDashboard: "acest tablou de bord",
                phrase2: "? Această acțiune nu poate fi anulată.",
                buttons: {
                    cancel: "Anulează",
                    delete: "Ștergeți tabloul de bord",
                }
            },
        },
        toasts: {
            deleted: "Tablou de bord șters."
        }
    },
    viewDashboard: {
        buttons: {
            edit: "Editați",
            cancel: "Anulează",
            stopEditing: "Oprește editarea",
            save: "Salvați",
        },
        toasts: {
            saved: "Tabloul de bord actualizat cu succes"
        }
    }
};
export const Portuguese: LanguageText = {
    createDashboard: {
        createADashboard: "Criar um Painel de Controle",
        selectAGroup: "Seleccione um grupo",
        formError: {
            noPerms: "Não tem permissão para criar painéis de controlo",
        },
        labels: {
            dashboardName: "Nome do painel de instrumentos",
            dashboardDescription: "Descrição do painel de instrumentos",
            addToAGroup: "Adicionar a um grupo"
        },
        buttons: {
            createDashboard: "Criar Painel de Controle"
        },
        toasts: {
            createdSuccessfully: "Painel de instrumentos criado com sucesso",
            selectAGroup: "Por favor, seleccione um grupo",
            noGroupPermission: "É necessário ter permissão para criar painéis de instrumentos em qualquer grupo. Contacte a pessoa que o convidou para obter a permissão",
            noGroups: "É preciso fazer parte de um grupo para criar um painel de instrumentos. Ou criar um novo grupo, ou juntar-se ao de outra pessoa",
        }
    },
    dashboardTable: {
        dashboards: "Painéis de instrumentos",
        tableElements: {
            headers: {
                dashboardName: "Nome do painel de instrumentos",
                description: "Descrição",
                lastUpdated: "Última Actualização",
                group: "Grupo",
                deleteDashboard: "Apagar Painel de Controle",
            },
            buttons: {
                delete: "Apagar",
            },
            quickEdit: "Edição rápida",
            searchAllColumns: "Pesquisar todas as colunas",
            goToPage: "Ir para a página",
            show: "Mostrar",
            loading: "Carregamento",
            noDashboardsFound: "Não foram encontrados painéis de bordo"
        },
        modals: {
            confirmDelete: {
                title: "Apagar Painel de Controle",
                phrase1: "Tem a certeza de que quer apagar o ",
                thisDashboard: "este tablier",
                phrase2: "? Esta acção não pode ser desfeita.",
                buttons: {
                    cancel: "Cancelar",
                    delete: "Apagar Painel de Controle",
                }
            },
        },
        toasts: {
            deleted: "Painel de instrumentos apagado",
        }
    },
    viewDashboard: {
        buttons: {
            edit: "Editar",
            cancel: "Cancelar",
            stopEditing: "Parar de editar",
            save: "Salvar",
        },
        toasts: {
            saved: "Painel de controlo actualizado com sucesso"
        }
    }
};