import TermsAndConditions from "../components/TermsAndConditions";

export type LanguageText = {
  login: {
    form: {
      email: string;
      usernameOrEmail: string;
      password: string;
      confirmPassword: string;
      username: string;
      remember: string;
      forgotPassword: string;
    };
    buttons: {
      login: string;
      register: string;
      forgotPassword: string;
      cancel: string;
      notGotAccount: string;
      gotAccount: string;
    };
    toasts: {
      registerUser: string;
      forgotPassword: string;
    };
  };
  termsAndConditions: {
    title: string;
    body: React.ReactNode;
    accept: string;
    cancel: string;
  };
  resetPassword: {
    title: string;
    labels: {
      usernameOrEmail: string;
      newPassword: string;
      confirmNewPassword: string;
    };
    buttons: {
      resetPassword: string;
      cancel: string;
    };
    toasts: {
      resetPassword: string;
    };
  };
  verifyEmail: {
    title: string;
    body: {
      phrase1: string;
      phrase2: string;
    };
    labels: {
      emailCode: string;
    };
    buttons: {
      resendEmail: string;
      accept: string;
      cancel: string;
    };
    toasts: {
      verifyEmail: string;
      newEmailVerified: string;
      resendEmail: string;
    };
  };
  linkVerifyEmail: {
    toasts: {
      invalidCode: string;
      invalidNewEmailCode: string;
    };
    body: {
      ifYouAreNotRedirected: string;
      here: string;
    };
  };
};
export const English: LanguageText = {
  login: {
    form: {
      email: "Email",
      usernameOrEmail: "Username or Email",
      password: "Password",
      confirmPassword: "Confirm Password",
      username: "Username",
      remember: "Remember Me",
      forgotPassword: "Forgotten Password?",
    },
    buttons: {
      login: "Login",
      register: "Register",
      forgotPassword: "Reset Password",
      cancel: "Cancel",
      notGotAccount: "Don't have an account yet? Register here.",
      gotAccount: "Already have an account? Login here.",
    },
    toasts: {
      registerUser: "Account created successfully",
      forgotPassword: "Please check your email to reset your password",
    },
  },
  termsAndConditions: {
    title: "Terms and Conditions",
    body: <TermsAndConditions language={"english"} />,
    accept: "Accept",
    cancel: "Cancel",
  },
  resetPassword: {
    title: "Reset password",
    labels: {
      usernameOrEmail: "Username or Email",
      newPassword: "New Password",
      confirmNewPassword: "Confirm New Password",
    },
    buttons: {
      resetPassword: "Reset Password",
      cancel: "Cancel",
    },
    toasts: {
      resetPassword: "Password reset successfully",
    },
  },
  verifyEmail: {
    title: "Please Verify Your Email",
    body: {
      phrase1:
        "Please verify your email address by clicking the link in the email sent to: ",
      phrase2: " or click the link in the email.",
    },
    labels: {
      emailCode: "Email Verification Code",
    },
    buttons: {
      resendEmail: "Resend Email",
      accept: "Verify",
      cancel: "Logout",
    },
    toasts: {
      verifyEmail: "Email verified successfully",
      newEmailVerified: "New email verified successfully",
      resendEmail: "Email resent successfully",
    },
  },
  linkVerifyEmail: {
    toasts: {
      invalidCode:
        "Your verification code is invalid. Please request a new code or click the link sent to your email",
      invalidNewEmailCode:
        "Your new email verification code is invalid. Please click the link sent to your new email",
    },
    body: {
      ifYouAreNotRedirected:
        "If you are not redirected automatically, please click",
      here: "here",
    },
  },
};
export const French: LanguageText = {
  login: {
    form: {
      email: "Email",
      usernameOrEmail: "Nom d'utilisateur ou courriel",
      password: "Mot de passe",
      confirmPassword: "Confirmer le mot de passe",
      username: "Nom d'utilisateur",
      remember: "Souviens-toi de moi",
      forgotPassword: "Mot de passe oublié",
    },
    buttons: {
      login: "Connexion",
      register: "Enregistrer",
      forgotPassword: "Réinitialiser le mot de passe",
      cancel: "Annuler",
      notGotAccount: "Vous n'avez pas encore de compte ? Inscrivez-vous ici.",
      gotAccount: "Vous avez déjà un compte ? Connectez-vous ici.",
    },
    toasts: {
      registerUser: "Compte créé avec succès",
      forgotPassword:
        "Veuillez vérifier votre email pour réinitialiser votre mot de passe",
    },
  },
  termsAndConditions: {
    title: "Modalités et conditions",
    body: <TermsAndConditions language={"french"} />,
    accept: "Accepter",
    cancel: "Annuler",
  },
  resetPassword: {
    title: "Réinitialiser le mot de passe",
    labels: {
      usernameOrEmail: "Nom d'utilisateur ou courriel",
      newPassword: "Nouveau mot de passe",
      confirmNewPassword: "Confirmer le nouveau mot de passe",
    },
    buttons: {
      resetPassword: "Réinitialiser le mot de passe",
      cancel: "Annuler",
    },
    toasts: {
      resetPassword: "Mot de passe réinitialisé avec succès",
    },
  },
  verifyEmail: {
    title: "Veuillez vérifier votre adresse e-mail",
    body: {
      phrase1:
        "Veuillez vérifier votre adresse e-mail en utilisant le code envoyé à ",
      phrase2: " ou cliquez sur le lien dans l'email.",
    },
    labels: {
      emailCode: "Code de vérification de l'email",
    },
    buttons: {
      resendEmail: "Resend Email",
      accept: "Vérifier",
      cancel: "Déconnexion",
    },
    toasts: {
      verifyEmail: "Email vérifié avec succès",
      newEmailVerified: "Nouvel email vérifié avec succès",
      resendEmail: "Email renvoyé avec succès",
    },
  },
  linkVerifyEmail: {
    toasts: {
      invalidCode:
        "Votre code de vérification est invalide. Veuillez demander un nouveau code ou cliquer sur le lien envoyé à votre email",
      invalidNewEmailCode:
        "Votre nouveau code de vérification de l'email est invalide. Veuillez cliquer sur le lien envoyé à votre nouvel email",
    },
    body: {
      ifYouAreNotRedirected:
        "Si vous n'êtes pas redirigé automatiquement, veuillez cliquer",
      here: "ici",
    },
  },
};
export const German: LanguageText = {
  login: {
    form: {
      email: "E-Mail",
      usernameOrEmail: "Benutzername oder E-Mail",
      password: "Passwort",
      confirmPassword: "Passwort bestätigen",
      username: "Nutzername",
      remember: "Erinnere dich an mich",
      forgotPassword: "Passwort vergessen?",
    },
    buttons: {
      login: "Anmelden",
      register: "Registrieren",
      forgotPassword: "Passwort zurücksetzen",
      cancel: "Abbrechen",
      notGotAccount: "Sie haben noch kein Konto? Registrieren Sie sich hier.",
      gotAccount: "Sie haben bereits ein Konto? Hier anmelden.",
    },
    toasts: {
      registerUser: "Konto erfolgreich erstellt",
      forgotPassword:
        "Bitte prüfen Sie Ihre E-Mail, um Ihr Passwort zurückzusetzen",
    },
  },
  termsAndConditions: {
    title: "Bedingungen und Konditionen",
    body: <TermsAndConditions language={"german"} />,
    accept: "Akzeptieren",
    cancel: "Abbrechen",
  },
  resetPassword: {
    title: "Passwort zurücksetzen",
    labels: {
      usernameOrEmail: "Benutzername oder E-Mail",
      newPassword: "Neues Passwort",
      confirmNewPassword: "Neues Passwort bestätigen",
    },
    buttons: {
      resetPassword: "Passwort zurücksetzen",
      cancel: "Abbrechen",
    },
    toasts: {
      resetPassword: "Passwort erfolgreich zurückgesetzt",
    },
  },
  verifyEmail: {
    title: "Bitte verifizieren Sie Ihre E-Mail",
    body: {
      phrase1: "Bitte verifizieren Sie Ihre E-Mail-Adresse, indem Sie den an  ",
      phrase2:
        " gesendeten Code eingeben oder auf den Link in der E-Mail klicken.",
    },
    labels: {
      emailCode: "E-Mail-Überprüfungscode",
    },
    buttons: {
      resendEmail: "E-Mail erneut senden",
      accept: "Überprüfen",
      cancel: "Abmelden",
    },
    toasts: {
      verifyEmail: "E-Mail erfolgreich verifiziert",
      newEmailVerified: "Neue E-Mail erfolgreich verifiziert",
      resendEmail: "E-Mail erfolgreich erneut gesendet",
    },
  },
  linkVerifyEmail: {
    toasts: {
      invalidCode:
        "Ihr Überprüfungscode ist ungültig. Bitte fordern Sie einen neuen Code an oder klicken Sie auf den Link in der E-Mail, die an Ihre E-Mail-Adresse gesendet wurde",
      invalidNewEmailCode:
        "Ihr neuer E-Mail-Überprüfungscode ist ungültig. Bitte klicken Sie auf den Link in der E-Mail, die an Ihre neue E-Mail-Adresse gesendet wurde",
    },
    body: {
      ifYouAreNotRedirected:
        "Wenn Sie nicht automatisch weitergeleitet werden, klicken Sie bitte",
      here: "hier",
    },
  },
};
export const Dutch: LanguageText = {
  login: {
    form: {
      email: "Email",
      usernameOrEmail: "Gebruikersnaam of e-mail",
      password: "Wachtwoord",
      confirmPassword: "Bevestig wachtwoord",
      username: "Gebruikersnaam",
      remember: "Herinner mij",
      forgotPassword: "Wachtwoord vergeten?",
    },
    buttons: {
      login: "Inloggen",
      register: "Registreer",
      forgotPassword: "Wachtwoord opnieuw instellen",
      cancel: "Annuleren",
      notGotAccount: "Heb je nog geen account? Registreer hier.",
      gotAccount: "Heb je al een account? Log hier in.",
    },
    toasts: {
      registerUser: "Account succesvol aangemaakt",
      forgotPassword:
        "Controleer uw e-mail om uw wachtwoord opnieuw in te stellen.",
    },
  },
  termsAndConditions: {
    title: "Voorwaarden",
    body: <TermsAndConditions language={"dutch"} />,
    accept: "Accepteren",
    cancel: "Annuleren",
  },
  resetPassword: {
    title: "Wachtwoord opnieuw instellen",
    labels: {
      usernameOrEmail: "Gebruikersnaam of e-mail",
      newPassword: "Nieuw wachtwoord",
      confirmNewPassword: "Bevestig nieuw wachtwoord",
    },
    buttons: {
      resetPassword: "Wachtwoord opnieuw instellen",
      cancel: "Annuleren",
    },
    toasts: {
      resetPassword: "Wachtwoord opnieuw ingesteld met succes",
    },
  },
  verifyEmail: {
    title: "Controleer uw e-mail",
    body: {
      phrase1: "Bevestig uw e-mailadres door de code in te voeren die naar ",
      phrase2: " is gestuurd of klik op de link in de e-mail.",
    },
    labels: {
      emailCode: "E-mail Verificatie Code",
    },
    buttons: {
      resendEmail: "E-mail opnieuw versturen",
      accept: "Verify",
      cancel: "Uitloggen",
    },
    toasts: {
      verifyEmail: "E-mail succesvol geverifieerd",
      newEmailVerified: "Nieuwe e-mail succesvol geverifieerd",
      resendEmail: "E-mail succesvol opnieuw verzonden",
    },
  },
  linkVerifyEmail: {
    toasts: {
      invalidCode:
        "Uw verificatiecode is ongeldig. Vraag een nieuwe code aan of klik op de link in de e-mail die naar uw e-mailadres is verzonden",
      invalidNewEmailCode:
        "Uw nieuwe e-mailverificatiecode is ongeldig. Klik op de link in de e-mail die naar uw nieuwe e-mailadres is verzonden",
    },
    body: {
      ifYouAreNotRedirected:
        "Als u niet automatisch wordt doorgestuurd, klik dan",
      here: "hier",
    },
  },
};
export const Spanish: LanguageText = {
  login: {
    form: {
      email: "Email",
      usernameOrEmail: "Nombre de usuario o correo electrónico",
      password: "Contraseña",
      confirmPassword: "Confirmar contraseña",
      username: "Nombre de usuario",
      remember: "Recuérdame",
      forgotPassword: "¿Olvidó su contraseña?",
    },
    buttons: {
      login: "Iniciar sesión",
      register: "Registro",
      forgotPassword: "Restablecer contraseña",
      cancel: "Cancelar",
      notGotAccount: "¿Todavía no tiene una cuenta? Regístrese aquí",
      gotAccount: "¿Ya tiene una cuenta? Inicie sesión aquí",
    },
    toasts: {
      registerUser: "Cuenta creada con éxito",
      forgotPassword:
        "Por favor, compruebe su correo electrónico para restablecer su contraseña",
    },
  },
  termsAndConditions: {
    title: "Condiciones generales",
    body: <TermsAndConditions language={"spanish"} />,
    accept: "Aceptar",
    cancel: "Cancelar",
  },
  resetPassword: {
    title: "Restablecer contraseña",
    labels: {
      usernameOrEmail: "Nombre de usuario o correo electrónico",
      newPassword: "Nueva contraseña",
      confirmNewPassword: "Confirmar nueva contraseña",
    },
    buttons: {
      resetPassword: "Restablecer contraseña",
      cancel: "Cancelar",
    },
    toasts: {
      resetPassword: "Contraseña restablecida con éxito",
    },
  },
  verifyEmail: {
    title: "Por favor, verifique su correo electrónico",
    body: {
      phrase1:
        "Confirme su dirección de correo electrónico introduciendo el código enviado a ",
      phrase2: " o haga clic en el enlace del correo electrónico.",
    },
    labels: {
      emailCode: "Código de verificación de correo electrónico",
    },
    buttons: {
      resendEmail: "Reenviar correo electrónico",
      accept: "Verificar",
      cancel: "Cerrar sesión",
    },
    toasts: {
      verifyEmail: "Correo electrónico verificado correctamente",
      newEmailVerified: "Nuevo correo electrónico verificado correctamente",
      resendEmail: "Correo electrónico reenviado correctamente",
    },
  },
  linkVerifyEmail: {
    toasts: {
      invalidCode:
        "Su código de verificación no es válido. Solicite un nuevo código o haga clic en el enlace del correo electrónico que se envió a su dirección de correo electrónico",
      invalidNewEmailCode:
        "Su código de verificación de correo electrónico nuevo no es válido. Haga clic en el enlace del correo electrónico que se envió a su nuevo correo electrónico",
    },
    body: {
      ifYouAreNotRedirected:
        "Si no se le redirige automáticamente, haga clic en",
      here: "aquí",
    },
  },
};
export const Italian: LanguageText = {
  login: {
    form: {
      email: "Email",
      usernameOrEmail: "Nome utente o e-mail",
      password: "Password",
      confirmPassword: "Conferma la password",
      username: "Nome utente",
      remember: "Ricordati di me",
      forgotPassword: "Password dimenticata?",
    },
    buttons: {
      login: "Accesso",
      register: "Registro",
      forgotPassword: "Reimposta password",
      cancel: "Annulla",
      notGotAccount: "Non hai ancora un account? Registrati qui",
      gotAccount: "Hai già un account? Effettua il login qui",
    },
    toasts: {
      registerUser: "Account creato con successo",
      forgotPassword: "Controlla la tua e-mail per reimpostare la password",
    },
  },
  termsAndConditions: {
    title: "Termini e condizioni",
    body: <TermsAndConditions language={"italian"} />,
    accept: "Accetta",
    cancel: "Annulla",
  },
  resetPassword: {
    title: "Reimpostare la password",
    labels: {
      usernameOrEmail: "Nome utente o e-mail",
      newPassword: "Nuova password",
      confirmNewPassword: "Conferma la nuova password",
    },
    buttons: {
      resetPassword: "Reimposta password",
      cancel: "Annulla",
    },
    toasts: {
      resetPassword: "Resettato la password con successo",
    },
  },
  verifyEmail: {
    title: "Verifica il tuo indirizzo e-mail",
    body: {
      phrase1: "Confermare l'indirizzo e-mail inserendo il codice inviato a ",
      phrase2: " oppure cliccare sul link contenuto nell'e-mail.",
    },
    labels: {
      emailCode: "Codice di verifica e-mail",
    },
    buttons: {
      resendEmail: "Reinvia l'e-mail",
      accept: "Verifica",
      cancel: "Disconnettersi",
    },
    toasts: {
      verifyEmail: "Email verificata con successo",
      newEmailVerified: "Nuova email verificata con successo",
      resendEmail: "Email inviata con successo",
    },
  },
  linkVerifyEmail: {
    toasts: {
      invalidCode:
        "Il tuo codice di verifica non è valido. Richiedi un nuovo codice o fai clic sul link dell'e-mail che è stato inviato al tuo indirizzo e-mail",
      invalidNewEmailCode:
        "Il tuo codice di verifica e-mail non è valido. Fai clic sul link dell'e-mail che è stato inviato al tuo nuovo indirizzo e-mail",
    },
    body: {
      ifYouAreNotRedirected:
        "Se non si viene reindirizzati automaticamente, fare clic",
      here: "qui",
    },
  },
};
export const Romanian: LanguageText = {
  login: {
    form: {
      email: "Email",
      usernameOrEmail: "Nume de utilizator sau e-mail",
      password: "Parola",
      confirmPassword: "Confirmă parola",
      username: "Nume de utilizator",
      remember: "Amintește-ți de mine",
      forgotPassword: "Ați uitat parola?",
    },
    buttons: {
      login: "Autentificare",
      register: "Înregistrare",
      forgotPassword: "Resetați parola",
      cancel: "Anulează",
      notGotAccount: "Nu aveți încă un cont? Înregistrați-vă aici.",
      gotAccount: "Aveți deja un cont? Conectați-vă aici.",
    },
    toasts: {
      registerUser: "Cont creat cu succes",
      forgotPassword:
        "Vă rugăm să vă verificați e-mailul pentru a vă reseta parola",
    },
  },
  termsAndConditions: {
    title: "Termeni și condiții",
    body: <TermsAndConditions language={"romainian"} />,
    accept: "Acceptă",
    cancel: "Anulează",
  },
  resetPassword: {
    title: "Resetează parola",
    labels: {
      usernameOrEmail: "Nume de utilizator sau e-mail",
      newPassword: "Noua parolă",
      confirmNewPassword: "Confirmă noua parolă",
    },
    buttons: {
      resetPassword: "Resetați parola",
      cancel: "Anulează",
    },
    toasts: {
      resetPassword: "Parola resetată cu succes",
    },
  },
  verifyEmail: {
    title: "Vă rugăm să vă verificați e-mailul",
    body: {
      phrase1:
        "Confirmați adresa de e-mail prin introducerea codului trimis la ",
      phrase2: " sau faceți clic pe link-ul din e-mail.",
    },
    labels: {
      emailCode: "Codul de verificare a e-mailului",
    },
    buttons: {
      resendEmail: "Retrimitere e-mail",
      accept: "Verifică",
      cancel: "Deconectați-vă",
    },
    toasts: {
      verifyEmail: "Email verificat cu succes",
      newEmailVerified: "Noul email verificat cu succes",
      resendEmail: "Email trimis cu succes",
    },
  },
  linkVerifyEmail: {
    toasts: {
      invalidCode:
        "Codul dvs. de verificare nu este valid. Solicitați un cod nou sau faceți clic pe link-ul din e-mailul trimis la adresa dvs. de e-mail",
      invalidNewEmailCode:
        "Codul dvs. de verificare al e-mailului nu este valid. Faceți clic pe link-ul din e-mailul trimis la noul dvs. adresa de e-mail",
    },
    body: {
      ifYouAreNotRedirected:
        "Dacă nu sunteți redirecționat automat, vă rugăm să dați click",
      here: "aici",
    },
  },
};
export const Portuguese: LanguageText = {
  login: {
    form: {
      email: "Email",
      usernameOrEmail: "Nome de utilizador ou e-mail",
      password: "Senha",
      confirmPassword: "Confirmar Palavra-passe",
      username: "Nome de utilizador",
      remember: "Lembrar-me",
      forgotPassword: "Esqueceu-se da senha?",
    },
    buttons: {
      login: "Login",
      register: "Registar",
      forgotPassword: "Redefinir Palavra-passe",
      cancel: "Cancelar",
      notGotAccount: "Ainda não tem uma conta? Registe-se aqui",
      gotAccount: "Já tem uma conta? Faça aqui o login",
    },
    toasts: {
      registerUser: "Conta criada com sucesso",
      forgotPassword:
        "Por favor, verifique o seu e-mail para redefinir a sua senha",
    },
  },
  termsAndConditions: {
    title: "Termos e Condições",
    body: <TermsAndConditions language={"portuguese"} />,
    accept: "Aceitar",
    cancel: "Cancelar",
  },
  resetPassword: {
    title: "Redefinir palavra-passe",
    labels: {
      usernameOrEmail: "Nome de utilizador ou e-mail",
      newPassword: "Nova Palavra-passe",
      confirmNewPassword: "Confirmar nova senha",
    },
    buttons: {
      resetPassword: "Redefinir Palavra-passe",
      cancel: "Cancelar",
    },
    toasts: {
      resetPassword: "Reinicialização com sucesso da palavra-passe",
    },
  },
  verifyEmail: {
    title: "Por favor, verifique o seu e-mail",
    body: {
      phrase1:
        "Confirme o seu endereço de correio electrónico introduzindo o código enviado para ",
      phrase2: " ou clique na ligação no correio electrónico.",
    },
    labels: {
      emailCode: "Código de Verificação de Email",
    },
    buttons: {
      resendEmail: "Reenviar e-mail",
      accept: "Verificar",
      cancel: "Logout",
    },
    toasts: {
      verifyEmail: "Email verificado com sucesso",
      newEmailVerified: "Novo email verificado com sucesso",
      resendEmail: "Email enviado com sucesso",
    },
  },
  linkVerifyEmail: {
    toasts: {
      invalidCode:
        "O seu código de verificação não é válido. Solicite um novo código ou clique na ligação no correio electrónico enviado para o seu endereço de correio electrónico",
      invalidNewEmailCode:
        "O seu código de verificação de e-mail não é válido. Clique na ligação no correio electrónico enviado para o seu novo endereço de correio electrónico",
    },
    body: {
      ifYouAreNotRedirected:
        "Se não for redireccionado automaticamente, por favor clique",
      here: "aqui",
    },
  },
};
