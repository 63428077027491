import _ from "lodash";
import { FormEvent, useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { DataState } from "../../features/data/dataSlice";
import { UplinkData } from "../../services/dataAPI";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../dictionary/ChartsText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import InputModal from "../modals/InputModal";
import { selectLanguage } from "../../features/user/userSlice";
import { ChartType } from "./charts";

export function Download(
    nodeData: DataState,
    ChartData: ChartType,
    modal: boolean,
    imgLink: string | undefined,)
    : [JSX.Element, boolean] {

    type LineListEntry = {
        name: string;
        pheno: string;
        config: string;
        device: string;
        checked: boolean;
    }

    const [downloadFormat, setDownloadFormat] = useState("JSON");
    const [seed, setSeed] = useState(Math.random());
    const [internalModal, setInternalModal] = useState(modal);
    const [lineList, setLineList] = useState<LineListEntry[]>([]);


    const stateLang = useAppSelector(selectLanguage);
    let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    useEffect(() => {
        setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    }, [stateLang]);


    useEffect(() => {
        setInternalModal(modal);
    }, [modal]);

    useEffect(() => {
        setLineList([]);
        (ChartData.chartData ?? []).map((a: any) => {
            setLineList((lineList) =>
                [...lineList].concat([{ name: a.label, pheno: a.elemID, config: a.phenomena, device: a.deviceID, checked: true }])
            )
        })
    }, [ChartData.chartData])

    const formatSelection = (
        <>
            <div className="w-full border-b">
                <input
                    type="radio"
                    id="json"
                    name={"JSON"}
                    value={"json"}
                    checked={downloadFormat === "JSON"}
                    onChange={() => {
                        setDownloadFormat("JSON");
                    }}
                />
                <label htmlFor="json" className="text-black font-semibold px-2">
                    JSON
                </label>
                <input
                    type="radio"
                    id="csv"
                    name={"CSV"}
                    value="csv"
                    checked={downloadFormat === "CSV"}
                    onChange={() => {
                        setDownloadFormat("CSV");
                    }}
                />
                <label htmlFor="csv" className="text-black font-semibold px-2">
                    CSV
                </label>
                <input
                    type="radio"
                    id="svg"
                    name={"SVG"}
                    value="csv"
                    checked={downloadFormat === "SVG"}
                    onChange={() => {
                        setDownloadFormat("SVG");
                    }}
                />
                <label htmlFor="svg" className="text-black font-semibold px-2">
                    SVG
                </label>
            </div>
        </>
    )

    const checkBoxes = (
        <>
            {lineList !== undefined && (
                lineList.length > 0 && (
                    lineList.map((a, i) => (
                        <div>
                            <input
                                type="checkbox"
                                id="w"
                                name={"w"}
                                value={"w"}
                                key={seed}
                                checked={a.checked || downloadFormat === "SVG"}
                                disabled={downloadFormat === "SVG"}
                                onChange={() => {
                                    var tempLineList = lineList;
                                    tempLineList[i] = {
                                        ...tempLineList[i],
                                        checked: !a.checked,
                                    };
                                    setLineList(tempLineList);
                                    setSeed(Math.random());
                                }}
                            />
                            <label className="text-black font-semibold px-2">
                                {a.name}
                            </label>
                        </div>
                    ))
                )
            )}
        </>
    )

    let downloadModal = (
        <>
            <form onSubmit={(e) => onSubmit(e)}>
                <div className="bg-white px-4 pt-2 pb-2 sm:p-6 sm:pb-4 sm:pt-5">
                    <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3
                                className="text-lg leading-6 font-medium text-gray-900"
                                id="modal-title"
                            >
                                {language.charts.download.title.phrase1 + " " + ChartData.title.en + " " + language.charts.download.title.phrase2}
                            </h3>
                            <div className="mt-2">
                                <div className="pb-2">
                                    {formatSelection}
                                    <div className={`p-0.5 pl-1.5 mt-0.5 rounded-md ${downloadFormat === "SVG" ? "bg-gray-300" : "bg-white"}`}>
                                        {checkBoxes}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 px-4 pb-3 pt-0 sm:pt-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-md">
                    <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                        {language.charts.download.buttons.accept}
                    </button>
                    <button
                        type="button"
                        onClick={() => setInternalModal(false)}
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-10       0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                        {language.charts.download.buttons.cancel}
                    </button>
                </div>
            </form>
        </>
    );

    return ([downloadModal, internalModal]);

    function onSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();

        var checkedLines = lineList.filter((a) => a.checked);
        var uniqueDevices = (checkedLines.map((a) => a.device)).filter((v, i, a) => a.indexOf(v) === i);
        switch (downloadFormat) {
            case "JSON":
                uniqueDevices.map((g) => {
                    downloadJSON(dataFilter(nodeData.data[g], lineList.filter((a) => a.device === g)))
                })
                break;
            case "CSV":
                uniqueDevices.map((g) => {
                    downloadCSV(dataFilter(nodeData.data[g], lineList.filter((a) => a.device === g)), lineList.filter((a) => a.device === g))
                })
                break;
            case "SVG":
                downloadSVG()
                break;
            default:
                break;
        }

        setInternalModal(false);
    }

    function dataFilter(data: UplinkData[], filteredLineList: LineListEntry[]) {
        return (_.map(data, (value) => {
            return (
                {
                    "ts": (new Date(value["ts"]).toLocaleString("en-GB").replace(",", "")),
                    ...Object.values(_.pick(value["data_points"], filteredLineList.map((line: LineListEntry) => line.config))).reduce((acc, val) => {
                        return {
                            ...acc,
                            ...(_.pickBy(((_.mapKeys(val, (v, k) => (_.filter(filteredLineList, function (o) { return (o.pheno === k) }))[0]?.pheno))), (v, k) => k !== "undefined"))
                        }
                    }, {})
                }
            )
        }));
    }

    function downloadJSON(filtered: { [key: string]: string | number | boolean; }[]) {
        const link = document.createElement("a");
        const fileData = JSON.stringify(filtered);
        const blob = new Blob([fileData], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download =
            ChartData.title.en + " " + Date().substring(4, 24) + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function downloadCSV(filtered: { [key: string]: string | number | boolean; }[], filteredLineList: LineListEntry[]) {
        const link = document.createElement("a");
        const fileData = toCSV(filtered, filteredLineList);
        const blob = new Blob([fileData], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download =
            ChartData.title.en + " " + Date().substring(4, 24) + ".csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function downloadSVG() {
        const link = document.createElement("a");
        var img = new Image();
        if (imgLink !== undefined) {
            img.src = imgLink
        }
        link.href = img.src;
        link.download = ChartData.title.en + " " + Date().substring(4, 24) + ".svg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function toCSV(b: { [key: string]: string | number | boolean; }[], filteredLineList: LineListEntry[]) {
        let csvContent = [
            [
                "ts",
                (filteredLineList.map((line: LineListEntry) => line.name).join(",")),
            ],
            b.map((a: { [key: string]: string | number | boolean; }) => {
                return [
                    a.ts,
                    ([filteredLineList.map((line: LineListEntry) => (line.pheno))].map((c: string[]) => c.map((d: string) => a[d] ?? "")))
                ]
            }).join("\n")
        ].map(e => e).join("\n");
        return csvContent;
    }
}
