import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";
import { clearDashboards } from "../features/dashboards/dashboardSlice";
import {
  Group,
  GroupLimit,
  Groups,
  leaveGroup,
  setGroup,
  setGroups,
  UserPermissions,
} from "../features/groups/groupSlice";
import { dashboardAPI } from "./dashboardAPI";
import { deviceAPI } from "./deviceAPI";
import { userAPI } from "./userAPI";
import { GroupImages, addImages } from "../features/images/imageSlice";
import _ from "lodash";

/*
API requests for group related requests
*/
export const groupAPI = createApi({
  reducerPath: "groupAPI",
  //Set up base query for the API. URL from env. Token taken from state.
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers: any, { getState }: any) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("token", `${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Group", "Groups", "GroupImages"],
  endpoints: (builder) => ({
    getGroups: builder.query({
      query: () => {
        return {
          url: "/groups",
        };
      },
      transformResponse: (result: Groups) => result,
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setGroups(data));
        } catch (error) {
          //console.log(error);
        }
      },
      providesTags: ["Groups"],
    }),
    getGroup: builder.query({
      query: (args: { id: string }) => {
        return {
          url: `/group/${args.id}`,
        };
      },
      transformResponse: (result: Group) => result,
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setGroup(data));
        } catch (error) {
          //console.log(error);
        }
      },
      providesTags: ["Group"],
    }),
    createGroup: builder.mutation({
      query: (args: { name: string }) => {
        return {
          url: "/group/create",
          method: "POST",
          body: args,
        };
      },
      invalidatesTags: ["Groups"],
      transformResponse: (result: { message: string; id: string }) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          //toast.success(data.message);
          dispatch(userAPI.util.invalidateTags(["User"]));
        } catch (error) { }
      },
    }),
    updateGroup: builder.mutation({
      query: (args: { id: string; name: string }) => {
        return {
          url: `/group/${args.id}/update`,
          method: "PUT",
          body: args,
        };
      },
      transformResponse: (result: { message: string }) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          //toast.success(data.message);
        } catch (error) { }
      },
      invalidatesTags: ["Group"],
    }),
    //unimplemented
    deleteGroup: builder.mutation({
      query: (args: { id: string; password: string }) => {
        return {
          url: `/group/${args.id}/delete`,
          method: "DELETE",
          body: { password: args.password },
        };
      },
      invalidatesTags: ["Groups", "Group"],
      transformResponse: (result: { message: string }) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          //toast.success(data.message);
          dispatch(clearDashboards());
          dispatch(userAPI.util.invalidateTags(["User"]));
          dispatch(dashboardAPI.util.invalidateTags(["Dashboards"]));
          dispatch(deviceAPI.util.invalidateTags(["Devices"]));
          dispatch(leaveGroup(args.id));
        } catch (error) { }
      },
    }),
    inviteUserToGroup: builder.mutation({
      query: (args: {
        group_id: string;
        user_email: string;
        permissions: UserPermissions;
      }) => {
        return {
          url: `/group/${args.group_id}/invite`,
          method: "POST",
          body: args,
        };
      },
      transformResponse: (result: { message: string }) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          //toast.success(data.message);
        } catch (error) { }
      },
      invalidatesTags: ["Group"],
    }),
    joinGroup: builder.mutation({
      query: (args: { group_id: string; join_token: string }) => {
        return {
          url: `/group/join`,
          method: "POST",
          body: args,
        };
      },
      transformResponse: (result: { message: string }) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          //toast.success(data.message);
          dispatch(userAPI.util.invalidateTags(["User"]));
          dispatch(dashboardAPI.util.invalidateTags(["Dashboards"]));
          dispatch(deviceAPI.util.invalidateTags(["Devices"]));
        } catch (error) { }
      },
      invalidatesTags: ["Groups"],
    }),
    updateUserInGroup: builder.mutation({
      query: (args: {
        groupID: string;
        userID: string;
        permissions: UserPermissions;
      }) => {
        return {
          url: `/group/${args.groupID}/${args.userID}/updateUser`,
          method: "PUT",
          body: args.permissions,
        };
      },
      transformResponse: (result: { message: string }) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          //toast.success(data.message);
        } catch (error) { }
      },
      invalidatesTags: ["Group"],
    }),
    removeUserFromGroup: builder.mutation({
      query: (args: { groupID: string; userID: string }) => {
        return {
          url: `/group/${args.groupID}/${args.userID}/kick`,
          method: "PATCH",
          body: args,
        };
      },
      invalidatesTags: ["Groups", "Group"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          //toast.success(data.message);
        } catch (error) { }
      },
    }),
    //unimplemented
    updateGroupSubscription: builder.mutation({
      query: (args: { id: string; subscription: boolean }) => {
        return {
          url: `/group/${args.id}/subscription`,
          method: "PUT",
          body: args,
        };
      },
      invalidatesTags: ["Group"],
    }),
    getSubscriptionPlans: builder.query({
      query: () => {
        return {
          url: "/plans/available",
          method: "GET",
        };
      },
      transformResponse: (result: any) => result,
    }),
    userLeaveGroup: builder.mutation({
      query: (args: { groupID: string; userID: string }) => {
        return {
          url: `/group/${args.groupID}/${args.userID}/kick`,
          method: "PATCH",
          body: args,
        };
      },
      invalidatesTags: ["Groups", "Group"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          //toast.success(data.message);
          dispatch(clearDashboards());
          dispatch(userAPI.util.invalidateTags(["User"]));
          dispatch(dashboardAPI.util.invalidateTags(["Dashboards"]));
          dispatch(deviceAPI.util.invalidateTags(["Devices"]));
          dispatch(leaveGroup(args.groupID));
        } catch (error) { }
      },
    }),
    cancelInvitation: builder.mutation({
      query: (args: { id: string; user_email: string }) => {
        return {
          url: `/group/${args.id}/uninvite`,
          method: "PATCH",
          body: args,
        };
      },
      invalidatesTags: ["Group"],
    }),
    groupIDtoName: builder.query({
      query: (args: { id: string }) => {
        return {
          url: `/group/${args.id}/name`,
        };
      },
      transformResponse: (result: { name: string }) => result,
    }),
    updateGroupInvite: builder.mutation({
      query: (args: {
        group_id: string;
        user_email: string;
        permissions: UserPermissions;
      }) => {
        return {
          url: `/group/${args.group_id}/invite`,
          method: "PATCH",
          body: args,
        };
      },
      invalidatesTags: ["Group"],
    }),
    getGroupOnLimit: builder.query({
      query: (groupid: string) => {
        return {
          url: `/group/${groupid}/limit`,
        };
      },
      transformResponse: (group: GroupLimit) => group,
    }),
    transferGroupOwner: builder.mutation({
      query: (args:
        {
          group_id: string;
          user_id: string;
          password: string
        }) => {
        return {
          url: `/group/${args.group_id}/transfer`,
          method: "POST",
          body: args,
        };
      },
      invalidatesTags: ["Group"],
    }),
    uploadImage: builder.mutation({
      query: (args: { group_id: string; file: any; description: string; }) => {
        const formData = new FormData();
        formData.append("image", args.file);
        formData.append("description", args.description);
        return {
          url: `/group/${args.group_id}/image/upload`,
          method: "POST",
          body: formData,
        };
      },
      transformResponse: (result: { message: string }) => result,
      invalidatesTags: ["GroupImages"],
    }),
    getGroupImages: builder.query({
      query: (args: { group_id: string }) => {
        return {
          url: `/group/${args.group_id}/images`,
          method: "GET",
        };
      },
      transformResponse: (result: { images: any }) => result,
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        var returnImages: GroupImages = {}
        try {
          const { data } = await queryFulfilled;
          _.map(Object.entries(data), ([key, value]) => {
            returnImages[key] = {
              id: value.image_id,
              url: value.url,
              name: value.description,
              description: value.description,
            }
          })
        } catch (error) {
        }
        dispatch(addImages({ images: returnImages, group_id: _args.group_id }));
      },
      providesTags: ["GroupImages"]
    }),
    acceptGroupTransfer: builder.mutation({
      query: (args: { group_id: string; token: string; }) => {
        return {
          url: `/group/${args.group_id}/accepttransfer`,
          method: "POST",
          body: JSON.stringify({ "transfer_token": args.token }),
        };
      },
    }),
    deleteImage: builder.mutation({
      query: (args: { group_id: string; image_id: string; }) => {
        return {
          url: `/group/${args.group_id}/${args.image_id}/delete`,
          method: "POST",
        };
      },
      transformResponse: (result: { message: string }) => result,
      invalidatesTags: ["GroupImages"]
    }),
  }),
});

export const {
  useGetGroupsQuery,
  useGetGroupQuery,
  useGroupIDtoNameQuery,
  useCancelInvitationMutation,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useInviteUserToGroupMutation,
  useJoinGroupMutation,
  useRemoveUserFromGroupMutation,
  useUpdateGroupMutation,
  useUpdateGroupSubscriptionMutation,
  useUpdateUserInGroupMutation,
  useGetSubscriptionPlansQuery,
  useUserLeaveGroupMutation,
  useUpdateGroupInviteMutation,
  useGetGroupOnLimitQuery,
  useTransferGroupOwnerMutation,
  useUploadImageMutation,
  useGetGroupImagesQuery,
  useAcceptGroupTransferMutation,
  useDeleteImageMutation,
} = groupAPI;
