import _ from "lodash";
import { useState, useEffect } from "react";
import { BsArrowsMove } from "react-icons/bs";
import { FaMapMarkerAlt, FaSearchLocation } from "react-icons/fa";
import { MdLocationOff, MdGpsFixed } from "react-icons/md";
import { MarkerData } from "..";
import { useAppSelector } from "../../../../app/hooks";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../../../dictionary/ChartsText";
import { selectDevices, PhenomenaConfiguration } from "../../../../features/devices/deviceSlice";
import { selectLanguage } from "../../../../features/user/userSlice";
import { LanguageCheck } from "../../../../utils/LanguageCheck";
import { FormInput } from "../../../FormInput";

export function MarkerBox({
    marker,
    index,
    userDeviceData,
    showCoords,
    mapType,
    updateMarker,
    removeMarker,
    toggleShowUnit,
    makeSelected,
}: {
    marker: MarkerData;
    index: number;
    userDeviceData: {
        optionValue: string;
        optionName: string;
    }[];
    showCoords: boolean;
    mapType: "land" | "image";
    updateMarker: (marker: MarkerData, index: number) => void;
    removeMarker: (index: number) => void;
    toggleShowUnit: (index: number) => void;
    makeSelected: (index: number) => void;
}) {
    const stateLang = useAppSelector(selectLanguage);
    let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    useEffect(() => {
        setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    }, [stateLang]);

    type FormInputData = {
        optionValue: string;
        optionName: string;
    };

    var updatedMarker = { ...marker };
    const [phenomenaSelectData, setPhenomenaSelectData] = useState<FormInputData[]>([]);
    const [selectedPhenomenaElem, setSelectedPhenomenaElem] = useState<string>("");

    let { devices } = useAppSelector(selectDevices);

    function getPhenomenaFromParams() {
        _.map(devices, (device) => {
            if (device.eui === updatedMarker.deviceID) {
                const keys = _.keys(device.configuration);
                var phenom: FormInputData[] = [];
                keys.forEach((key) =>
                    device.configuration[key].forEach((element: PhenomenaConfiguration) =>
                        phenom.push({
                            optionValue: JSON.stringify({
                                phenomena: key,
                                elemID: element.elemID ?? "0",
                            }),
                            optionName: element.description,
                        })
                    )
                );
                setPhenomenaSelectData(
                    [
                        {
                            optionValue: "new",
                            optionName: language.charts.gauge.inputDefaults.selectParameter,
                        },
                    ].concat(phenom)
                );
            }
        });
    }

    function getSelectedPhenomenaFromParams() {
        setSelectedPhenomenaElem(
            updatedMarker.phenomena === "new"
                ? "new"
                : JSON.stringify({
                    phenomena: updatedMarker.phenomena,
                    elemID: updatedMarker.elemID ?? "0",
                })
        );
    }

    useEffect(() => {
        getPhenomenaFromParams();
    }, [updatedMarker.deviceID, userDeviceData]);

    useEffect(() => {
        getSelectedPhenomenaFromParams();
    }, [updatedMarker]);

    function changeDevice(event: React.ChangeEvent<HTMLSelectElement>) {
        updatedMarker.deviceID = event.target.value;
        updatedMarker.phenomena = "new";
        updatedMarker.elemID = "";
        setSelectedPhenomenaElem("new");
        _.values(devices).map((b) => {
            if (b.eui === event.target.value && mapType === "land") {
                updatedMarker.lat = b.lat;
                updatedMarker.lng = b.lng;
            }
        });
        updateMarker(updatedMarker, index);
    }

    function changeLabel(event: React.ChangeEvent<HTMLInputElement>) {
        updatedMarker.label = event.target.value;
        updateMarker(updatedMarker, index);
    }

    function changePhenomena(event: React.ChangeEvent<HTMLSelectElement>) {
        const { phenomena, elemID } = JSON.parse(event.target.value);
        setSelectedPhenomenaElem(
            JSON.stringify({
                phenomena: phenomena,
                elemID: elemID ?? "0",
            })
        );
        updatedMarker.phenomena = phenomena;
        updatedMarker.elemID = elemID ?? "0";
        updateMarker(updatedMarker, index);
    }

    function changeLat(event: React.ChangeEvent<HTMLInputElement>) {
        updatedMarker.lat = parseFloat(event.target.value);
        updateMarker(updatedMarker, index);
    }

    function changeLng(event: React.ChangeEvent<HTMLInputElement>) {
        updatedMarker.lng = parseFloat(event.target.value);
        updateMarker(updatedMarker, index);
    }

    function changeColour(event: React.ChangeEvent<HTMLInputElement>) {
        updatedMarker.colour = event.target.value;
        updateMarker(updatedMarker, index);
    }

    const deviceSelect = (
        marker.deviceID === "new" ?
            <div className="w-full">
                <FormInput
                    label={language.charts.lineChart.labels.device}
                    htmlFor="device"
                    type="select"
                    data={userDeviceData}
                    value={marker.deviceID ?? "new"}
                    onChange={changeDevice}
                />
            </div> :
            <select
                onChange={changeDevice}
                id={"w"}
                className={`w-full border-none outline-none none p-0 text-lg font-bold text-black ${marker.deviceID === "new" ? "bg-white rounded-xl border-2 p-2" : "bg-gray-200"}`}
                value={marker.deviceID ?? "new"}
            >
                {userDeviceData.map((item: { optionValue: string; optionName: string; }, index: number) => (
                    <option
                        key={index}
                        value={item?.optionValue}
                        disabled={item?.optionValue === "new"}
                        hidden={item?.optionValue === "new"}
                    >
                        {item.optionName}
                    </option>
                ))}
            </select>
    )

    const removeMarkerButton = (
        <button
            type="button"
            onClick={(event) => {
                event.stopPropagation();
                removeMarker(index);
            }}
            className="inline-flex"
        >
            <MdLocationOff color="red" size={24} />
        </button>
    )

    const changeLabelInput = (
        <div className="w-full flex justify-between">
            <input
                type="text"
                className={"p-0 text-center w-full text-black font-normal rounded-xl object-center inline-flex border-2 border-gray-300"}
                value={marker.label}
                placeholder={language.charts.map.landMap.labels.markerLabel}
                maxLength={40}
                onChange={changeLabel}
            />
        </div>
    )

    const selectPhenomena = (
        <div className="w-full">
            <FormInput
                disabled={false}
                htmlFor="phenomena"
                type="select"
                label={language.charts.gauge.labels.parameter}
                data={phenomenaSelectData}
                value={selectedPhenomenaElem}
                onChange={changePhenomena}
            />
        </div>
    )

    const toggleShowUnitCheckbox = (
        <div className="w-full flex justify-between items-center">
            <label className="flex items-center">
                <input
                    type="checkbox"
                    className="form-checkbox"
                    checked={marker.showUnit}
                    onChange={(event) => {
                        toggleShowUnit(index)
                    }}
                />
                <span className="ml-2 text-sm text-gray-600">
                    Show Unit (if applicable)
                </span>
            </label>
        </div>
    )

    const latLngInputs = (
        <div className="w-full flex justify-between">
            <div className="w-2/5 flex justify-between items-center">
                {language.charts.map.landMap.labels.lat}:
                <input
                    disabled={marker.deviceID !== "custom"}
                    type={"number"}
                    className={"p-0 text-center w-4/5 text-black font-normal rounded-xl object-center inline-flex border-2 border-gray-300"}
                    value={marker.deviceID === "custom" ? marker.lat : marker.lat !== undefined ? marker.lat.toFixed(4) : ""}
                    placeholder="---"
                    step={0.0001}
                    /* min={-90}
                    max={90} */
                    onChange={changeLat}
                />
            </div>
            <div className="w-2/5 flex justify-between items-center">
                <div>
                    {language.charts.map.landMap.labels.long}:
                </div>
                <input
                    disabled={marker.deviceID !== "custom"}
                    type={"number"}
                    className={"p-0 text-center w-4/5 text-black font-normal rounded-xl object-center inline-flex border-2 border-gray-300"}
                    value={marker.deviceID === "custom" ? marker.lng : marker.lng !== undefined ? marker.lng.toFixed(4) : ""}
                    placeholder="---"
                    step={0.0001}
                    /* min={-90}
                    max={90} */
                    onChange={changeLng}
                />
            </div>
        </div>
    )

    const colourInput = (
        <div className="flex justify-center w-full space-x-1">
            <div className="self-center">
                {language.charts.map.landMap.labels.colour}
            </div>
            <div className="flex self-start justify-center relative">
                <input
                    onChange={changeColour}
                    type={"color"}
                    className={"w-0 h-0 p-6 rounded-xl border-gray-100"}
                    value={updatedMarker.colour}
                />
                <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 32 32" className="absolute self-center justify-center pointer-events-none">
                    <path stroke="#fff" fill={`${updatedMarker.colour}`}
                        d="M 9 20 L 15 32 L 22 20 H 22 C 29 20 30 19 30 10 V 10 C 30 1 29 0 16 0 H 16 C 2 0 1 1 1 10 V 10 C 1 19 2 20 9 20"
                    />
                </svg>
                {/* <FaMapMarkerAlt size={40} color={updatedMarker.colour} className="absolute self-center justify-center pointer-events-none" /> */}
            </div>
        </div>
    )

    const findButton = (
        <div className="flex justify-center w-full space-x-1">
            <div className="self-center">
                {language.charts.map.landMap.labels.find}
            </div>
            <button
                type="button"
                disabled={updatedMarker.deviceID === "new"}
                onClick={(event) => {
                    event.stopPropagation();
                    makeSelected(index);
                }}
                className="inline-flex justify-center rounded-md border border-transparent shadow-sm bg-blue-600 text-base font-medium text-white px-2 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
                <FaSearchLocation size={30} className="self-center justify-center" />
            </button>
        </div>
    )

    return (
        <>
            <div className={"p-2 my-1 space-y-1 flex w-full border-4 bg-gray-200 scrollbar-none rounded-lg flex-col border-gray-200"}>
                <div className="flex">
                    {deviceSelect}
                    {removeMarkerButton}
                </div>
                <div className="my-1 h-px bg-gray-400 "></div>
                {marker.deviceID === "custom" &&
                    changeLabelInput
                }
                {marker.deviceID !== "custom" && marker.deviceID !== "new" &&
                    selectPhenomena
                }
                {marker.deviceID !== "custom" && marker.deviceID !== "new" &&
                    toggleShowUnitCheckbox
                }
                {marker.deviceID !== "new" && mapType === "land" &&
                    latLngInputs
                }
                <div className="flex justify-between w-full">
                    {colourInput}
                    {marker.deviceID !== "new" &&
                        <>
                            {findButton}
                        </>
                    }
                </div>
            </div>
        </>
    );
}