import React, { useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { selectGroups } from "../../features/groups/groupSlice";
import { selectDashboards } from "../../features/dashboards/dashboardSlice";
import { selectNotifications } from "../../features/notifications/notificationSlice";
import NotificationName, {
  NotificationNameRef,
} from "../../components/groupNotifications/NotificationName";
import NotificationRule, {
  NotificationRuleRef,
} from "../../components/groupNotifications/NotificationRule";
import NotificationEmail, {
  NotificationEmailRef,
} from "../../components/groupNotifications/NotificationEmail";
import NotificationConfiguration, {
  NotificationConfigRef,
} from "../../components/groupNotifications/NotificationConfiguration";
import NotificationStatus, {
  NotificationStatusRef,
} from "../../components/groupNotifications/NotificationStatus";
import NotificationDashboard, {
  NotificationDashboardRef,
} from "../../components/groupNotifications/NotificationDashboard";
import { toast } from "react-toastify";
import { useCreateNotificationMutation } from "../../services/groupNotificationAPI";
import { NotificationState } from "../../features/notifications/notificationSlice";
import { convertNotificationRulesArrayIntoMap } from "../../utils/GroupNotificationUtils";
import { LanguageText } from "../../dictionary/GroupNotificationText";
import useGetLanguage from "../../utils/useGetLanguage";

const CreateGroupNotification = () => {
  const language: LanguageText | null = useGetLanguage({
    fileName: "GroupNotificationText",
  });
  const { groupid } = useParams();
  const navigate = useNavigate();

  const notificationNameRef = useRef<NotificationNameRef>(null);
  const notificationRuleRef = useRef<NotificationRuleRef>(null);
  const notificationEmailRef = useRef<NotificationEmailRef>(null);
  const notificationConfigRef = useRef<NotificationConfigRef>(null);
  const notificationStatusRef = useRef<NotificationStatusRef>(null);
  const NotificationDashboardRef = useRef<NotificationDashboardRef>(null);
  const [
    createNotification,
    {
      isLoading,
      isError,
      error: createNotificationError,
      data,
      isSuccess: isCreateSuccess,
    },
  ] = useCreateNotificationMutation();

  const handleCreate = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const errorMessage =
      language?.messages?.toast?.general?.notificationError || "Some fields are required";

    const newNotification: any = {
      group_id: groupid,
    };
    const notificationName = notificationNameRef.current;
    const notificationRule = notificationRuleRef.current;
    const notificationDashboard = NotificationDashboardRef.current;
    const notificationEmail = notificationEmailRef.current;
    const notificationConfig = notificationConfigRef.current;
    const notificationStatus = notificationStatusRef.current;

    if (notificationName) {
      if (!notificationName.validate()) {
        toast.error(errorMessage);
        return;
      }
      const { name } = notificationName.getNotificationName();
      name && (newNotification.name = name);
    }
    if (notificationEmail) {
      if (!notificationEmail.validate()) {
        toast.error(errorMessage);
        return;
      }
      const { subject, body, users, trigger } = notificationEmail.getNotificationEmail();
      newNotification.email = { subject, body, trigger };
      newNotification.users = users;
    }
    if (notificationRule) {
      if (!notificationRule.validate()) {
        toast.error(errorMessage);
        return;
      }
      const { rules } = notificationRule.getNotificationRule();
      rules && (newNotification.rules = rules);
    }

    if (notificationDashboard) {
      if (!notificationDashboard.validate()) {
        toast.error(errorMessage);
        return;
      }
      const { dashboard } = notificationDashboard.getNotificationDashboard();
      dashboard && (newNotification.dashboard = dashboard);
    }

    if (notificationConfig) {
      if (!notificationConfig.validate()) {
        toast.error(errorMessage);
        return;
      }
      const { configuration } = notificationConfig.getNotificationConfig();
      configuration && (newNotification.configuration = configuration);
    }

    if (notificationStatus) {
      if (!notificationStatus.validate()) {
        toast.error(errorMessage);
        return;
      }
      const { status } = notificationStatus.getNotificationStatus();
      status && (newNotification.status = status);
    }

    const notification: NotificationState =
      convertNotificationRulesArrayIntoMap(newNotification);
    notification && (await createNotification({ notification: notification, language }));
  };

  useEffect(() => {
    (isCreateSuccess || isError) && navigate(`/group/${groupid}/notifications`);
  }, [isCreateSuccess, isError, groupid, navigate]);
  return (
    <>
      <div className="bg-gray-100 p-8 w-full">
        <div className="bg-white p-8 rounded-lg shadow-md w-full">
          <NotificationName ref={notificationNameRef} />
          <NotificationRule ref={notificationRuleRef} />
          <hr className="my-4 border-1" />
          <NotificationDashboard ref={NotificationDashboardRef} />
          <hr className="my-4 border-1" />
          <NotificationEmail ref={notificationEmailRef} />
          <hr className="my-4 border-1" />
          <NotificationConfiguration ref={notificationConfigRef} />
          <hr className="my-4 border-1" />
          <NotificationStatus ref={notificationStatusRef} />
          <hr className="my-4 border-1" />
          <div className="flex">
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mr-4 rounded-md shadow-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-400"
              onClick={handleCreate}
            >
              {language?.general?.createNotification || "Create Notification"}
            </button>
            <button
              className="bg-gray-300 hover:bg-gray-500 text-black-700 font-bold py-2 px-4 rounded-md  shadow-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-gray-300"
              onClick={() => navigate(`/group/${groupid}/notifications`)}
            >
              {language?.general?.cancel || "Cancel"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateGroupNotification;
