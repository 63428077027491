import _ from "lodash";
import { useEffect, useState } from "react";
import { ChartScale, ChartType, LineChartData, Scale } from "..";
import { v4 as uuidv4 } from "uuid";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../../../dictionary/ChartsText";
import { LanguageCheck } from "../../../../utils/LanguageCheck";
import { useAppSelector } from "../../../../app/hooks";
import { selectLanguage } from "../../../../features/user/userSlice";
import { EditChart, EditChartProps } from "../EditChart";
import NewTextMenu from "./NewTextMenu";

type Props = {
  id: string;
  data: ChartType;
  remove: (id: string) => void;
  updateChart: (data: ChartType) => void;
  setShowChartEdit: (isOpen: boolean) => void;
};

function EditText({
  id,
  remove,
  updateChart,
  data,
  setShowChartEdit,
}: Props) {

  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  const defaultYAxis: Scale = { min_auto: true, max_auto: true, max: 0, min: 0 };

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(data.title.en);
  const [lines, setLines] = useState<LineChartData[]>(data.chartData ?? []);
  const [chartScale, setChartScale] = useState<ChartScale>(
    (data.chartScale === undefined || (data.chartScale.y_axis === defaultYAxis)) ?
      { y_axis: { min_auto: true, max_auto: true, max: 10, min: 0 } }
      : data.chartScale
  );

  const addNewLine = ({ label, phenomena, colour, lineID, style, connectedNulls, deviceID, smoothed, unit, elemID, }: LineChartData) => {
    setLines(_.concat(lines, { label, phenomena, colour, lineID, style, connectedNulls, deviceID, smoothed, unit, elemID, }));
  };

  const onRemoveLine = (lineID: string | undefined) => {
    setLines(_.reject(lines, { lineID: lineID }));
  };

  const saveLineData = (line: LineChartData) => {
    setLines(
      _.map(lines, (currentLine: LineChartData) => {
        var newLine: LineChartData = {
          lineID: currentLine.lineID,
        };
        if (currentLine.lineID === line.lineID) {
          newLine = line;
        } else {
          newLine = currentLine;
        }
        return newLine;
      })
    );
  }

  function GetTitleAndLinesFromParams() {
    setTitle(data.title.en);
    setLines(data.chartData ?? []);
  }

  function GetBlankRing() {
    return {
      label: "",
      colour: "#" + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0').toUpperCase(),
      phenomena: "new",
      lineID: uuidv4(),
      connectedNulls: true,
      deviceID: "new",
      smoothed: false,
      unit: "",
      elemID: "",
    }
  }

  function CatchBlankData() {
    if (lines.length === 0) {
      addNewLine(GetBlankRing())
    }
  }

  useEffect(() => {
    GetTitleAndLinesFromParams()
  }, [data]);

  useEffect(() => {
    CatchBlankData();
  }, [lines])

  var itemList: JSX.Element[] = (
    lines.map((line, index) => (
      <NewTextMenu
        index={index}
        line={line}
        key={index}
        remove={lines.length !== 1}
        removeLine={onRemoveLine}
        saveLineData={saveLineData}
      />
    ))
  );

  const onDelete = () => {
    remove(id);
    setShowChartEdit(false);
  };

  const saveChartSettings = () => {
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].label == "") {
        lines[i].label = lines[i].elemID;
      }
    }
    setLoading(true)
    setTimeout(function () {
      setShowChartEdit(false);
      setLoading(false)
    }, 300);
    updateChart({
      i: id,
      type: "text",
      title: { en: title },
      chartScale: chartScale,
      chartData: lines,
    });
  };

  const EditChartObjects: EditChartProps = ({
    editMapType: "Edit Text",
    settingsBox: undefined,
    itemList: itemList,
    newButton: undefined,
    onDelete: onDelete,
    onSave: saveChartSettings,
    loading: loading,
  })

  return (
    <EditChart {...EditChartObjects} />
  );
}

export default EditText;
