import { useState, useEffect } from "react";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../dictionary/ChartsText";
import { selectLanguage } from "../../features/user/userSlice";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { ChartScale, Scale } from "./charts";
import { useAppSelector } from "../../app/hooks";
import { FormInput } from "../FormInput";

const ScaleSelections = ({
  chartScale,
  setChartScale,
}: {
  chartScale: ChartScale | undefined;
  setChartScale: (chartScale: ChartScale) => void;
}) => {
  const [xScale, setXScale] = useState<Scale | undefined>(chartScale?.x_axis);
  const [yScale, setYScale] = useState<Scale>(
    chartScale?.y_axis ?? { min_auto: true, max_auto: true, max: 10, min: 0 }
  );

  useEffect(() => {
    if (xScale !== undefined) {
      setChartScale({ x_axis: xScale, y_axis: yScale });
    } else {
      setChartScale({ y_axis: yScale });
    }
  }, [xScale, yScale]);

  if (chartScale === undefined) {
    return <>Error</>;
  } else {
    if (chartScale.x_axis !== undefined) {
      return (
        <>
          {/* <ScaleSelection
            scale={chartScale.x_axis}
            axis="X"
            setScale={setXScale}
          /> */}
          <ScaleSelection
            scale={chartScale.y_axis}
            axis="Y"
            setScale={setYScale}
          />
        </>
      );
    } else {
      return (
        <ScaleSelection
          scale={chartScale.y_axis}
          axis="Y"
          setScale={setYScale}
        />
      );
    }
  }
};

const ScaleSelection = ({
  scale,
  axis,
  setScale,
}: {
  scale: Scale;
  axis: "X" | "Y";
  setScale: (chartScale: Scale) => void;
}) => {
  const [autoScale, setAutoScale] = useState<{
    min_auto: boolean;
    max_auto: boolean;
  }>({ min_auto: scale.min_auto, max_auto: scale.max_auto });
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));

  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);
  useEffect(() => {
    setAutoScale({ min_auto: scale.min_auto, max_auto: scale.max_auto });
  }, [scale]);
  return (
    <>
      <div className="h-2" />
      <div className="flex justify-between flex-col">
        <div className="flex justify-between flex-row">
          <div className="w-[50%] flex flex-row items-center">
            {language.chartScale.labels.min + " " + axis + ":"}
            <div className="w-1" />
            <div className="w-[50%] rounded-xl">
              <FormInput
                label={""}
                autoComplete="off"
                inputmode="numeric"
                htmlFor="min"
                type="text"
                value={scale.min}
                onKeyDown={(evt) => /^-?\d|-+$/.test(evt.key) ? null : (evt.key === "Backspace") ? null : evt.preventDefault()}
                onChange={(event) => {
                  if (/^-?\d+$/.test(event.target.value)) {
                    setScale({ ...scale, min: parseInt(event.target.value) });
                  }
                }}
              />
            </div>
          </div>
          <div className="flex flex-row items-center">
            {language.chartScale.labels.minAutoScale}
            <div className="w-1" />
            <input
              onChange={() => {
                setScale({ ...scale, min_auto: !autoScale.min_auto });
                setAutoScale({ ...autoScale, min_auto: !autoScale.min_auto });
              }}
              checked={autoScale.min_auto}
              type="checkbox"
              className="bg-indigo-50 px-2 py-2 outline-none rounded-md mr-2 hover:cursor-pointer"
            />
          </div>
        </div>
        <div className="flex justify-between flex-row">
          <div className="w-[50%] flex flex-row items-center">
            {language.chartScale.labels.max + " " + axis + ":"}
            <div className="w-1" />
            <div className="w-[50%] rounded-xl">
              <FormInput
                label={""}
                autoComplete="off"
                inputmode="numeric"
                htmlFor="max"
                type="text"
                value={scale.max}
                onKeyDown={(evt) => /^-?\d|-+$/.test(evt.key) ? null : (evt.key === "Backspace") ? null : evt.preventDefault()}
                onChange={(event) => {
                  if (/^-?\d+$/.test(event.target.value)) {
                    setScale({ ...scale, max: parseInt(event.target.value) });
                  }
                }}
              />
            </div>
          </div>
          <div className="flex flex-row items-center">
            {language.chartScale.labels.maxAutoScale}
            <div className="w-1" />
            <input
              onChange={() => {
                setScale({ ...scale, max_auto: !autoScale.max_auto });
                setAutoScale({ ...autoScale, max_auto: !autoScale.max_auto });
              }}
              checked={autoScale.max_auto}
              type="checkbox"
              className="bg-indigo-50 px-2 py-2 outline-none rounded-md mr-2 hover:cursor-pointer"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ScaleSelections;
