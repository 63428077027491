import _, { isObject } from "lodash";
import React, { useEffect, useState } from "react";
import { ChartScale, ChartType, LineChartData, Scale } from "..";
import { FormInput } from "../../../FormInput";
import NewLineMenu from "./NewLineMenu";
import { v4 as uuidv4 } from "uuid";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../../../dictionary/ChartsText";
import { LanguageCheck } from "../../../../utils/LanguageCheck";
import { useAppSelector } from "../../../../app/hooks";
import { selectLanguage } from "../../../../features/user/userSlice";
import ScaleSelections from "../../ChartScale";
import { EditChart, EditChartProps } from "../EditChart";
import { AiOutlineRise } from "react-icons/ai";
import { Button } from "../../../Button";

type Props = {
  id: string;
  data: ChartType;
  remove: (id: string) => void;
  updateChart: (data: ChartType) => void;
  setShowChartEdit: (isOpen: boolean) => void;
};

function EditLineChart({
  id,
  remove,
  updateChart,
  data,
  setShowChartEdit,
}: Props) {

  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  const defaultYAxis: Scale = { min_auto: true, max_auto: true, max: 0, min: 0 };

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(data.title.en);
  const [lines, setLines] = useState<LineChartData[]>(data.chartData ?? []);
  const [chartScale, setChartScale] = useState<ChartScale>(
    (data.chartScale === undefined || (data.chartScale.y_axis === defaultYAxis)) ?
      { y_axis: { min_auto: true, max_auto: true, max: 10, min: 0 } }
      : data.chartScale
  );

  function getBlankLine() {
    return {
      label: "",
      colour: "#" + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0').toUpperCase(),
      phenomena: "new",
      lineID: uuidv4(),
      style: "solid" as "solid" | "dashed" | "dotted",
      connectedNulls: true,
      deviceID: "new",
      smoothed: false,
      unit: "",
      elemID: "",
    }
  }

  const saveChartSettings = () => {
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].label == "") {
        lines[i].label = lines[i].elemID;
      }
    }
    setLoading(true)
    setTimeout(function () {
      setShowChartEdit(false);
      setLoading(false)
    }, 300);
    updateChart({
      i: id,
      type: "line",
      title: { en: title },
      chartScale: chartScale,
      chartData: lines,
    });
  };

  const addNewLine = ({ label, phenomena, colour, lineID, style, connectedNulls, deviceID, smoothed, unit, elemID, }: LineChartData) => {
    setLines(_.concat(lines, { label, phenomena, colour, lineID, style, connectedNulls, deviceID, smoothed, unit, elemID, }));
  };

  const onRemoveLine = (lineID: string | undefined) => {
    setLines(_.reject(lines, { lineID: lineID }));
  };

  function GetTitleAndLinesFromParams() {
    setTitle(data.title.en);
    setLines(data.chartData ?? []);
  }

  useEffect(() => {
    GetTitleAndLinesFromParams()
  }, [data]);

  function CatchBlankLines() {
    if (lines.length === 0) {
      addNewLine(getBlankLine())
    }
  }

  useEffect(() => {
    CatchBlankLines()
  }, [lines])

  const settingsBox: JSX.Element = (
    <div className="p-2 bg-gray-200 overflow-y-scroll scrollbar-none rounded-lg flex flex-col">
      <FormInput
        type="text"
        value={title !== null ? title : ""}
        onChange={(event) => {
          setTitle(event.target.value);
        }}
        htmlFor={"title"}
        label={language.charts.lineChart.labels.graphTitle}
      />
      <ScaleSelections
        chartScale={chartScale}
        setChartScale={setChartScale}
      />
    </div>
  );

  const itemList: JSX.Element[] = (
    lines.map((line, index) => (
      <NewLineMenu
        index={index}
        line={line}
        key={index}
        remove={lines.length !== 1}
        removeLine={onRemoveLine}
        saveLineData={(line: LineChartData) => {
          setLines(
            _.map(lines, (currentLine: LineChartData) => {
              var newLine: LineChartData = {
                lineID: currentLine.lineID,
              };
              if (currentLine.lineID === line.lineID) {
                newLine = line;
              } else {
                newLine = currentLine;
              }
              return newLine;
            })
          );
        }}
      />
    ))
  );

  const newButton = {
    text: language.charts.lineChart.buttons.addNewLine,
    onClick: () =>
      addNewLine({
        label: "",
        colour: "#" + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0').toUpperCase(),
        phenomena: "new",
        lineID: uuidv4(),
        style: "solid",
        connectedNulls: true,
        deviceID: "new",
        smoothed: false,
        unit: "",
        elemID: "",
      }),
    icon: <AiOutlineRise size={40} className="bg-white opacity-0 group-hover:opacity-70 absolute -ml-[6px] -mt-5 h-16 w-[calc(100%+12px)]" />,
  }

  function onDelete() {
    remove(id);
    setShowChartEdit(false);
  }

  const EditChartObjects: EditChartProps = ({
    editMapType: language.charts.lineChart.editLineChart,
    settingsBox: settingsBox,
    itemList: itemList,
    newButton: newButton,
    onDelete: onDelete,
    onSave: saveChartSettings,
    loading: loading,
  })

  return (
    <EditChart {...EditChartObjects} />
  );
}

export default EditLineChart;
