import { toast } from "react-toastify";
import { Group } from "../features/groups/groupSlice";

export function SubscriptionSelectionRedirect(group: Group) {
  if (group) {
    if (group.owner) {
      if (
        group.subscription.renewal_period === "never" &&
        group.subscription.status === "active"
      ) {
        return null;
      }
      switch (group.subscription?.status) {
        case "active":
        case "pending":
        case "trialing":
          return `/group/${group.id}/subscription`;
        case "limit":
          return `/group/${group.id}/limit`;
        case "unselected":
        case "canceled":
        case "expired":
        case "incomplete":
        case "incomplete_expired":
        case "past_due":
        case "unpaid":
        case "paused":
          return null;
      }
    } else {
      toast.error(
        "You must be the owner of this group to view this information.",
        { autoClose: 5000 }
      );
      return `/group/${group.id}`;
    }
  }
}

export function SubscriptionActiveGroupRedirection(group: Group) {
  if (group) {
    if (group.owner) {
      if (group.subscription?.status) {
        switch (group.subscription?.status) {
          case "active":
          case "trialing":
            return null;
          case "limit":
            return `/group/${group.id}/limit`;
          case "pending":
          case "paused":
          case "incomplete":
            return `/group/${group.id}/subscription`;
          case "unselected":
          case "canceled":
          case "expired":
          case "incomplete_expired":
          case "past_due":
          case "unpaid":
          default:
            toast.warning(
              "An active subscription is required to view this group.",
              { autoClose: 6000 }
            );
            return `/group/${group.id}/subscription/start`;
        }
      }
    } else {
      switch (group.subscription?.status) {
        case "active":
        case "trialing":
          return null;
        default:
          toast.warning(
            "An active subscription is required to view this group. Please contact the group owner for more information.",
            { autoClose: 6000 }
          );
          return `/group`;
      }
    }
  }
}
