import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  ChangeEvent,
} from "react";
import { LanguageText } from "../../dictionary/GroupNotificationText";
import useGetLanguage from "../../utils/useGetLanguage";
import { MdOutlineError } from "react-icons/md";
export interface NotificationNameProps {
  name?: string;
}
export interface NotificationNameRef {
  getNotificationName: () => NotificationNameProps;
  validate: () => boolean;
}
const NotificationName = forwardRef(
  ({ name = "" }: NotificationNameProps, ref): JSX.Element => {
    const language: LanguageText | null = useGetLanguage({
      fileName: "GroupNotificationText",
    });
    const [notificationName, setNotificationName] = useState(name);
    const [error, setError] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      getNotificationName: () => ({
        name: notificationName,
      }),
      validate: () => {
        if (!notificationName || notificationName.trim() === "") {
          setError(true);
          return false;
        }
        if (!/^[a-zA-Z0-9 ]+$/.test(notificationName)) {
          setError(true);
          return false;
        }
        setError(false);
        return true;
      },
    }));
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setNotificationName(event.target.value);
      setError(false);
    };

    return (
      <div className="mb-6 bg-gray-100 p-10 rounded-lg mt-2">
        {/* <h3 className="text-lg font-normal mb-3">
          {language?.nameComponent?.heading || "Name"}
        </h3> */}
        <input
          className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
          type="text"
          value={notificationName}
          onChange={handleChange}
          placeholder={language?.nameComponent?.input?.placeholder || "Name"}
          required
        />
        {error && <MdOutlineError className="text-red-500 text-lg mt-2" />}
      </div>
    );
  }
);

export default NotificationName;
