import _ from "lodash";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { DataType } from "../../features/data/dataSlice";
import { UplinkData } from "../../services/dataAPI";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../dictionary/ChartsText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import InputModal from "../modals/InputModal";
import { selectLanguage } from "../../features/user/userSlice";
import useWindowDimensions from "../../utils/ScreenDimensions";

export function Scale(
    firstPointTs: number,
    lastPointTs: number,
    chartOptions: any,
    modal: boolean,
    SetStartAndEndDateFromModal: ({start, end}: {start: any, end: any}) => void,
    )
    : [JSX.Element, boolean, string, string] {

    var { width } = useWindowDimensions();

    const [startDate, setStartDate] = useState<any>("");
    const [endDate, setEndDate] = useState<any>("");
    const [tempStartDate, setTempStartDate] = useState<any>("");
    const [tempEndDate, setTempEndDate] = useState<any>("");
    const [internalModal, setInternalModal] = useState(modal);

    const [initialSet, setInitialSet] = useState(false);

    const stateLang = useAppSelector(selectLanguage);
    let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));

    useEffect(() => {
        setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    }, [stateLang]);

    useEffect(() => {
        setInternalModal(modal);
    }, [modal]);

    useEffect(() => {
        if (firstPointTs === undefined || isNaN(firstPointTs)) {
            firstPointTs = 0 - (new Date().getTimezoneOffset() * 60 * 1000);
        }
    }, [firstPointTs]);

    useEffect(() => {
        if (lastPointTs === undefined || isNaN(lastPointTs)) {
            lastPointTs = 0 - (new Date().getTimezoneOffset() * 60 * 1000);
        }
    }, [lastPointTs]);

    useEffect(() => {
        if (((lastPointTs + (new Date().getTimezoneOffset() * 60 * 1000)) !== 0) && lastPointTs !== undefined && !isNaN(lastPointTs) && !initialSet) {
            setStartDate(new Date(lastPointTs - 24 * 60 * 60 * 1000).toISOString().split(".")[0]);
            setEndDate(new Date(lastPointTs).toISOString().split(".")[0])
            setInitialSet(true)
        }
    }, [lastPointTs]);

    useEffect(() => {
        if (chartOptions !== undefined) {
            if (chartOptions[0] !== undefined && chartOptions[0] !== undefined) {
                if (!isNaN(chartOptions[0].startValue) && !isNaN(chartOptions[0].endValue)) {
                    setTempStartDate(new Date(chartOptions[0].startValue - new Date().getTimezoneOffset() * 60 * 1000).toISOString().split(".")[0]);
                    setTempEndDate(new Date(chartOptions[0].endValue - new Date().getTimezoneOffset() * 60 * 1000).toISOString().split(".")[0])
                }
            }
        }
    }, [chartOptions]);

    const DateEntryBoxes = (
        <>
            <div className="flex flex-wrap space-x-1 items-center justify-center">
                <div className="flex flex-col sm:max-w-[45%]">
                    <label
                        htmlFor="start"
                        className="block text-sm font-medium text-gray-700"
                    >
                        {language.charts.scale.labels.start}
                    </label>
                    <input
                        type="datetime-local"
                        name="start"
                        id="start"
                        className="shadow-sm focus:ring-green-500 focus:border-green-500 mr-0 block sm:text-sm border-gray-300 rounded-md"
                        min={new Date(!isNaN(firstPointTs) ? firstPointTs ?? 0 : 0).toISOString().split(".")[0]}
                        max={new Date(!isNaN(lastPointTs) ? lastPointTs ?? 0 : 0).toISOString().split(".")[0]}
                        step={"any"}
                        value={tempStartDate}
                        onChange={(e) => {
                            setTempStartDate(e.target.value);
                        }}
                    />
                </div>
                {width > 640 && (
                <div className="flex flex-col">
                    <div className="w-0 h-5">
                    </div>
                    <div className="h-[38px] text-center leading-[38px]">
                        -
                    </div>
                </div>
                )}
                <div className="flex flex-col sm:max-w-[45%]">
                    <label
                        htmlFor="start"
                        className="block text-sm font-medium text-gray-700"
                    >
                        {language.charts.scale.labels.end}
                    </label>
                    <input
                        type="datetime-local"
                        name="end"
                        id="end"
                        className="shadow-sm focus:ring-green-500 focus:border-green-500 mr-0 block sm:text-sm border-gray-300 rounded-md"
                        step={"any"}
                        min={new Date(!isNaN(firstPointTs) ? firstPointTs ?? 0 : 0).toISOString().split(".")[0]}
                        max={new Date(!isNaN(lastPointTs) ? lastPointTs ?? 0 : 0).toISOString().split(".")[0]}
                        value={tempEndDate}
                        onChange={(e) => {
                            setTempEndDate(e.target.value);
                        }}
                    />
                </div>
            </div>
        </>
    )

    const PresetButtons = (
        <>
            <div className="inline-flex w-full space-x-0.5 justify-center pt-0.5">
                <button
                    type="button"
                    onClick={() => {
                        setTempStartDate(
                            new Date(
                                (new Date(lastPointTs)).getTime() - 24 * 60 * 60 * 1000
                            ).toISOString().split(".")[0]
                        );
                        setTempEndDate(new Date(lastPointTs).toISOString().split(".")[0]);
                    }}
                    className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-3 py-2 bg-white font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mt-0 ml-3 w-auto text-sm"
                >
                    {language.charts.scale.buttons.twentyFourHours}
                </button>
                <button
                    type="button"
                    onClick={() => {
                        setTempStartDate(
                            ((new Date(lastPointTs)).getTime() - 7 * 24 * 60 * 60 * 1000 > new Date(!isNaN(firstPointTs) ? firstPointTs ?? 0 : 0).getTime()) ?
                                new Date(new Date(lastPointTs).getTime() - 7 * 24 * 60 * 60 * 1000).toISOString().split(".")[0] :
                                new Date(firstPointTs).toISOString().split(".")[0]
                        );
                        setTempEndDate(new Date(lastPointTs).toISOString().split(".")[0]);
                    }}
                    className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-3 py-2 bg-white font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mt-0 ml-3 w-auto text-sm"
                >
                    {language.charts.scale.buttons.sevenDays}
                </button>
                <button
                    type="button"
                    onClick={() => {
                        setTempStartDate((
                            new Date(!isNaN(firstPointTs) ? firstPointTs ?? 0 : 0).toISOString().split(".")[0]
                        ));
                        setTempEndDate(new Date(lastPointTs).toISOString().split(".")[0]);
                    }}
                    className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-3 py-2 bg-white font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mt-0 w-auto text-sm"
                >
                    {language.charts.scale.buttons.max}
                </button>
            </div>
        </>
    )

    let scaleModal = (
        <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:w-full">
            <form onSubmit={(event) => {
                event.preventDefault()
                var { start, end } = document.forms[0]
                setStartDate(new Date(start.value));
                setEndDate(new Date(end.value));
                SetStartAndEndDateFromModal({start: new Date(start.value), end: new Date(end.value)})
                setInternalModal(false)
            }}>
                <div className="bg-white px-4 pt-1 pb-1">
                    <div className="sm:flex w-full">
                        <div className="flex-wrap mt-1 text-center w-full sm:mt-0 sm:text-left">
                            <h3
                                className="text-lg leading-6 font-medium text-gray-900"
                                id="modal-title"
                            >
                                {language.charts.scale.title}
                            </h3>
                            {DateEntryBoxes}
                            {PresetButtons}
                        </div >
                    </div >
                </div >
                <div className="bg-gray-50 px-4 py-2 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                        {language.charts.scale.buttons.save}
                    </button>
                    <button
                        type="button"
                        onClick={() => setInternalModal(false)}
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-10       0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                        {language.charts.scale.buttons.cancel}
                    </button>
                </div>
            </form >
        </div>
    );

    return ([scaleModal, internalModal, startDate, endDate]);
}
