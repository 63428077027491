import React, { useEffect, useRef, useState } from "react";
import {
  MapContainer,
  Popup,
  Marker,
  useMapEvents,
  useMap,
  ImageOverlay,
} from "react-leaflet";

import "leaflet/dist/leaflet.css";
import L, { LatLng, LatLngBoundsExpression, LatLngTuple } from "leaflet";

import iconRetinaUrl from "leaflet/dist/images/marker-icon-2x.png";
import iconUrl from "leaflet/dist/images/marker-icon.png";
import shadowUrl from "leaflet/dist/images/marker-shadow.png";
import { MarkerData, MapData, ChartType, GaugeData } from "..";
import { BsFillLockFill, BsFillUnlockFill } from "react-icons/bs";
import { useAppSelector } from "../../../../app/hooks";
import { selectDevices } from "../../../../features/devices/deviceSlice";
import _ from "lodash";
import { format, I18nSettings } from "fecha";
import Spinner from "../../../Spinner";
import { DataType, selectData } from "../../../../features/data/dataSlice";

type format = (date: Date, format?: string, i18n?: I18nSettings) => string;

const icon = L.Icon.Default as any;
delete icon.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
});

type Props = {
  ChartData: ChartType;
  updateChart: (data: ChartType) => void;
  editingActive: boolean;
};

export default function ImageMap({ ChartData, updateChart, editingActive }: Props) {
  const nodeData = useAppSelector(selectData);
  const [defaultLat, setDefaultLat] = useState(ChartData.chartScale?.defaultLat ?? 0);
  const [defaultLng, setDefaultLng] = useState(ChartData.chartScale?.defaultLng ?? 0);
  const [defaultZoom, setDefaultZoom] = useState(ChartData.chartScale?.defaultZoom ?? 0);
  const [markerSelected, setMarkerSelected] = useState(false);
  const [selectedMarkerLat, setSelectedMarkerLat] = useState(0);
  const [selectedMarkerLng, setSelectedMarkerLng] = useState(0);
  let { devices } = useAppSelector(selectDevices);
  const topLeft: LatLngTuple = [0, 0];
  const bottomRight: LatLngTuple = [0, 0];
  const [bounds, setBounds] = useState<LatLngBoundsExpression>([
    topLeft,
    bottomRight,
  ]);
  const [hideControls, setHideControls] = useState(false);

  var mapDetails: MapData = {
    defaultLng: defaultLng,
    defaultLat: defaultLat,
    defaultZoom: defaultZoom,
    markers: [],
  };

  const [markerList, setMarkerList] = useState<MarkerData[]>(
    ChartData.chartData ?? []
  );
  const markerRef = useRef(null);
  const [lockDragging, setLockDragging] = useState(false);
  const [seed, setSeed] = useState(1);

  const [imageIsLoading, setImageIsLoading] = useState(true);
  const [imageHasError, setImageHasError] = useState(false);
  const imageUrl = ChartData.chartConfig?.imageURL ?? "";

  const loadImage = (imageUrl: string) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setBounds([topLeft, [img.height, img.width]]);
      setImageIsLoading(false);
      setImageHasError(false);
    };
    img.onerror = (err) => {
      setImageHasError(true);
    };
  };

  useEffect(() => {
    setImageIsLoading(true);
    loadImage(imageUrl);
  }, [imageUrl]);

  useEffect(() => {
    setHideControls(ChartData.chartScale?.y_axis.min_auto ?? false);
    setSeed(Math.random());
  }, [ChartData.chartScale?.y_axis.min_auto]);

  const LockDraggingButton: React.FC = () => {
    const map = useMap();
    return (
      hideControls ? (
        <button
          className="bg-white pl-[6px] ring-opacity-20 ring-2 ring-gray-900 top-[5rem] left-3 border rounded absolute w-[1.9rem] h-8 z-[700]"
          onClick={() => {
            if (lockDragging) {
              var templat = map.getCenter().lat;
              var templng = map.getCenter().lng;
              var tempzoom = map.getZoom();
              if (templat && templng && (tempzoom || tempzoom === 0)) {
                var newChartData = _.cloneDeep(ChartData);
                newChartData.chartScale = {
                  ...newChartData.chartScale,
                  y_axis: {
                    min_auto: newChartData.chartScale?.y_axis.min_auto ?? false,
                    max_auto: newChartData.chartScale?.y_axis.max_auto ?? false,
                  },
                  defaultZoom: tempzoom,
                  defaultLat: templat,
                  defaultLng: templng
                };
                updateChart(newChartData);
              }
            }
            setSeed(Math.random());
            setLockDragging(!lockDragging);
          }}
        >
          {lockDragging ? (
            <BsFillUnlockFill size={16} />
          ) : (
            <BsFillLockFill size={16} />
          )}{" "}
        </button>
      ) : null
    )
  };

  useEffect(() => {
    setMarkerList(ChartData.chartData ?? []);
  }, [ChartData.chartData]);

  mapDetails.markers = markerList ?? [];

  function MapEvents() {
    const map = useMapEvents({
      move: () => {
        setDefaultLat(map.getCenter().lat);
        setDefaultLng(map.getCenter().lng);
      },
      zoom: () => {
        setDefaultZoom(map.getZoom());
      },
      load: () => {
        var newMarkerList = _.cloneDeep(markerList);
        newMarkerList.forEach((marker) => {
          marker.selected = false;
        });
        setMarkerList(newMarkerList);
        var newChartData = _.cloneDeep(ChartData);
        newChartData.chartData = newMarkerList;
        updateChart(newChartData);
      },
    });
    return null;
  }

  function CenterMap({ center }: { center: LatLng }) {
    const map = useMap();
    if (markerSelected) {
      map.setView(center, 0);
      setMarkerSelected(false)
    }
    return null;
  }

  function onMarkerSelected(marker: MarkerData) {
    if (marker !== undefined && marker !== null) {
      if (marker.lat !== undefined && marker.lng !== undefined) {
        setMarkerSelected(true)
        setSelectedMarkerLat(marker.lat);
        setSelectedMarkerLng(marker.lng);
      }

      var newMarkerList = _.cloneDeep(markerList);
      newMarkerList.forEach((marker) => {
        marker.selected = false;
      });
      setMarkerList(newMarkerList);
      var newChartData = _.cloneDeep(ChartData);
      newChartData.chartData = newMarkerList;
      updateChart(newChartData);
    }
  };

  function getParamFieldFromMarker(m: MarkerData, field: "description" | "unit"): string {
    var returnField = "";
    _.values(devices).filter(d => d.eui === m.deviceID).map((device) => {
      _.entries(device.configuration).filter(c => c[0] === m.phenomena).map((phenomena) => {
        _.values(phenomena[1]).filter(p => p.elemID === m.elemID).map((elem) => {
          field === "description" ? returnField = elem.description : returnField = elem.unit;
        })
      })
    })
    if (field === "unit") {
      if (returnField === "mm/tip") {
        returnField = "mm";
      } else if (returnField === "V") {
        returnField = ""
      }
    }
    return returnField;
  }

  function getDeviceNameFromEUI(eui: string): string {
    var returnField = "";
    _.values(devices).filter(d => d.eui === eui).map((device) => {
      returnField = device.name;
    })
    return returnField;
  }

  function getIcon(m: MarkerData, markerLabel: number | string | undefined) {
    var growthFactor = -5 + 5 * (markerLabel?.toString().length ?? 1);
    var boxwidth = 25 + 10 * (markerLabel?.toString().length ?? 1);

    console.log(m)
    return ((m.phenomena !== "sw_curr") ? (
      L.divIcon({
        className: "custom-icon-marker",
        html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${boxwidth} 50" class="marker" style="pointer-events: none;">
    <g transform="matrix(1 0 0 1 ${boxwidth * 0.5} 0)">
      <path stroke="#fff" fill="${m.colour}" 
      d="M -7.2 34 L 0 48.4 L 8.4 34 H 8.4 C ${13 + growthFactor} 34 ${14 + growthFactor} 33 ${14 + growthFactor} 20 C ${14 + growthFactor} 4 ${13 + growthFactor} 3 0 3 C ${-12 - growthFactor} 3 ${-13 - growthFactor} 4 ${-13 - growthFactor} 20 C ${-13 - growthFactor} 33 ${-12 - growthFactor} 34 -7.2 34"
      />
    <text filter="url(#solid)" x="0" y="27" fill="black" style="stroke-width: 1px; paint-order: stroke; font: bold 20px sans-serif; stroke-linejoin: round; text-anchor: middle; fill: black; stroke: white;" text-anchor="middle">${markerLabel ?? ""}</text>
    </g>
    </svg>`,
        iconSize: [boxwidth, 50],
        iconAnchor: [boxwidth / 2, 50],
        popupAnchor: [1, -44],
        shadowSize: [41, 41],
      })
    ) :
      L.divIcon({
        className: "custom-icon-marker",
        html: (markerLabel === "0") ? `<svg stroke=${m.colour} fill=${m.colour} stroke-width="0" viewBox="0 0 16 16" height="30" width="30" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V2a1 1 0 0 1 1-1h8zm-2 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></svg>` : `<svg stroke=${m.colour} fill=${m.colour} stroke-width="0" viewBox="0 0 16 16" height="28" width="28" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 15a.5.5 0 0 0 0 1h13a.5.5 0 0 0 0-1H13V2.5A1.5 1.5 0 0 0 11.5 1H11V.5a.5.5 0 0 0-.57-.495l-7 1A.5.5 0 0 0 3 1.5V15H1.5zM11 2h.5a.5.5 0 0 1 .5.5V15h-1V2zm-2.5 8c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z"></path></svg>`,
        iconSize: [30, 30],
        iconAnchor: [15, 15],
        popupAnchor: [1, -15],
        shadowSize: [41, 41],
      })
    )
  }

  function getPopupContent(m: MarkerData, tempLatestValue: string | number | undefined, tempLatestValueTs: Date | undefined) {
    var latestValueTs = tempLatestValueTs?.toString();
    return (
      <>
        {m.deviceID === "new" ? (
          <span>
            No Device Selected
          </span>
        ) : (
          m.phenomena === "new" ? (
            <span>
              {getDeviceNameFromEUI(m.deviceID)} <br />
              No Parameter Selected
            </span>
          ) : (
            tempLatestValue === "?" ? (
              <span>
                {getDeviceNameFromEUI(m.deviceID)} <br />
                <br />
                {getParamFieldFromMarker(m, "description") + ": "} {"No data within time range"} <br />
                {(latestValueTs !== undefined) && (format(new Date(latestValueTs), " DD/MM/YYYY ") + format(new Date(latestValueTs), " HH:mm:ss"))}
              </span>
            ) : (
              <span>
                {getDeviceNameFromEUI(m.deviceID)} <br />
                <br />
                {getParamFieldFromMarker(m, "description") + ": "} {tempLatestValue}{getParamFieldFromMarker(m, "unit")} <br />
                {(latestValueTs !== undefined) && (format(new Date(latestValueTs), " DD/MM/YYYY ") + format(new Date(latestValueTs), " HH:mm:ss"))}
              </span >
            )
          )
        )
        }
      </>
    )
  }

  return (
    <>
      {!imageHasError ?
        !imageIsLoading ? (
          <div className="w-full h-full flex flex-col pb-2">
            {ChartData.title.en &&
              <div className="w-full text-center font-semibold text-lg -mt-1">
                {ChartData.title.en ?? ""}
              </div>
            }
            <div className="w-full grow">
              <MapContainer
                key={seed}
                center={[mapDetails.defaultLat, mapDetails.defaultLng]}
                zoom={mapDetails.defaultZoom ?? 0}
                scrollWheelZoom={lockDragging}
                style={{ height: "100%", width: "100%" }}
                dragging={lockDragging}
                bounds={bounds}
                maxBounds={bounds}
                minZoom={-8}
                maxZoom={10}
                crs={L.CRS.Simple}
                zoomControl={hideControls}
              >
                <LockDraggingButton />
                <ImageOverlay url={imageUrl} bounds={bounds} />
                {mapDetails.markers.map((m, index) => {
                  if (m.selected) {
                    onMarkerSelected(m);
                  }
                  var tempLatestValue: number | string | undefined = undefined;
                  var markerLabel: number | string | undefined = undefined;
                  var tempLatestValueTs: Date | undefined = undefined;
                  if (nodeData) {
                    if (
                      nodeData.data[m.deviceID as keyof DataType] !== undefined
                    ) {
                      let latestValue =
                        nodeData.data[m.deviceID as keyof DataType][
                        nodeData.data[m.deviceID as keyof DataType].length - 1
                        ];
                      tempLatestValue = (!isNaN(Number(latestValue?.data_points?.[m?.phenomena]?.[m?.elemID ?? ""]) * 10) ? (Math.round((Number(latestValue?.data_points?.[m?.phenomena]?.[m?.elemID ?? ""]) * 10)) / 10) : "?");
                      markerLabel = tempLatestValue + (m.showUnit ? getParamFieldFromMarker(m, "unit") : "");
                      tempLatestValueTs = latestValue.ts;
                    }
                  }
                  if (m.phenomena === "new" || (!tempLatestValue && tempLatestValue !== 0)) {
                    tempLatestValue = "?";
                    markerLabel = "?";
                  }
                  return (
                    <Marker
                      key={index}
                      autoPanOnFocus={true}
                      draggable={editingActive}
                      ref={markerRef}
                      icon={getIcon(m, markerLabel)}
                      eventHandlers={{
                        moveend(e) {
                          const { lat, lng } = e.target.getLatLng();
                          var newMarkerList = _.cloneDeep(markerList);
                          newMarkerList.forEach((marker) => {
                            marker.draggable = false;
                          });
                          newMarkerList[index].lat = lat;
                          newMarkerList[index].lng = lng;
                          setMarkerList(newMarkerList);
                          var newChartData = _.cloneDeep(ChartData);
                          newChartData.chartData = newMarkerList;
                          updateChart(newChartData);
                        },
                        click() {
                        },
                      }}
                      position={[m.lat ?? 0, m.lng ?? 0]}
                    >
                      <Popup autoPan={false}>
                        {getPopupContent(m, tempLatestValue, tempLatestValueTs)}
                      </Popup>
                    </Marker>
                  );
                })}
                <CenterMap center={new LatLng(selectedMarkerLat ?? 0, selectedMarkerLng ?? 0)} />
                <MapEvents />
              </MapContainer>
            </div>
          </div>
        ) :
          <div className="h-full flex justify-center items-center space-x-1">
            <Spinner colour="fill-green-600" />
          </div>
        : (
          <div className="h-full flex justify-center items-center space-x-1">
            {imageUrl !== "" && <Spinner colour="fill-green-600" />}
            <div>{imageUrl === "" ? "No Image Selected" : "Image URL could not be found"}</div>
          </div>
        )}
    </>
  );
}
