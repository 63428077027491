import { FaLock } from "react-icons/fa";

type ButtonProps = {
    label: JSX.Element | string;
    type?: "button" | "submit" | "reset";
    disabled?: boolean;
    htmlFor?: string;
    colour?: "red" | "green" | "blue" | "yellow" | "white" | "gray" | "lightgray";
    bgColour?: string;
    hoverColour?: string;
    focusColour?: string;
    textColour?: string;
    border?: string;
    lock?: boolean;
    onClick?: (...args: any) => any;
};


export function Button({
    label,
    type = "button",
    disabled = false,
    htmlFor = "",
    colour,
    bgColour = "red-600",
    hoverColour = "red-700",
    focusColour = "red-500",
    textColour = "white",
    border = "",
    lock = false,
    onClick = () => { },
}: ButtonProps) {

    if (colour) {
        switch (colour) {
            case "red":
                bgColour = "red-600";
                hoverColour = "red-700";
                focusColour = "red-500";
                break;
            case "green":
                bgColour = "green-600";
                hoverColour = "green-700";
                focusColour = "green-500";
                break;
            case "blue":
                bgColour = "blue-600";
                hoverColour = "blue-700";
                focusColour = "blue-500";
                break;
            case "yellow":
                bgColour = "yellow-600";
                hoverColour = "yellow-700";
                focusColour = "yellow-500";
                break;
            case "white":
                bgColour = "white";
                hoverColour = "gray-100";
                focusColour = "gray-200";
                textColour = "gray-700";
                border = "border border-gray-300";
                break;
            case "gray":
                bgColour = "gray-600";
                hoverColour = "gray-700";
                focusColour = "gray-500";
                break;
            case "lightgray":
                bgColour = "gray-300";
                hoverColour = "gray-400";
                focusColour = "gray-200";
                break;
            default:
                bgColour = "red-600";
                hoverColour = "red-700";
                focusColour = "red-500";
                break;
        }
    }

    return (
        <button
            id={htmlFor}
            name={htmlFor}
            disabled={disabled}
            type={type}
            onClick={() => {
                onClick();
            }}
            className={`bg-${bgColour} hover:bg-${hoverColour} focus:ring-${focusColour} text-${textColour} ${border} w-full rounded-md shadow-sm px-4 ${colour === "white" ? "py-[7px]" : "py-2"} text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
            <div className="flex justify-center items-center">{label}{lock && <FaLock size={14} className="mt-0.5 ml-1" />}</div>
        </button>
    );
}
