import { MdAddChart, MdKeyboardArrowDown } from "react-icons/md";
import {
  AiOutlineLineChart,
  AiOutlineBarChart,
  AiOutlineDotChart,
  AiOutlineFieldNumber,
} from "react-icons/ai";
import { TbGauge } from "react-icons/tb";
import {
  IoCalendarSharp,
  IoMap,
  IoImageOutline,
  IoConstructSharp,
} from "react-icons/io5";
import { RiTableLine } from "react-icons/ri";
import { BsCardText } from "react-icons/bs";
import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../dictionary/ChartsText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../features/user/userSlice";
import { useParams } from "react-router-dom";
import { Dashboards, selectDashboards } from "../../features/dashboards/dashboardSlice";
import { selectGroups } from "../../features/groups/groupSlice";
import { FaList } from "react-icons/fa";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type MenuItemProps = {
  active: boolean;
};

type ChartSelectProps = {
  onSelect: (chartType: string) => void;
};
export default function ChartSelect({ onSelect }: ChartSelectProps) {
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));


  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  let { dashboardid } = useParams();
  let { dashboards } = useAppSelector(selectDashboards);
  var groupid = dashboards[dashboardid as keyof Dashboards].group_id;
  let { groups } = useAppSelector(selectGroups);

  function getCanGroupUseImages() {
    if (groups?.[groupid as keyof typeof groups]?.subscription?.plan_id === "649562c4b9b08a7e359d6876") {
      return false;
    } else {
      return true;
    }
  }

  return (
    <Menu
      as="div"
      className="relative inline-block text-left w-fit mr-0 sm:mr-2"
    >
      <Menu.Button className="inline-flex justify-center h-full w-full rounded-md border border-gray-300 shadow-sm py-[3px] px-3 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
        {/* {language.chartSelect.addNewChart} */}
        <MdAddChart size={34} />
        {/* <div className="flex h-full items-center">
          <MdKeyboardArrowDown
            size={22}
            aria-hidden="true"
          />
        </div> */}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-left absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-over-map translate-x-40">
          <div className="py-1">
            <NewItem
              name={language.chartSelect.chartTypes.lineChart}
              icon={<AiOutlineLineChart size={20} />}
              onClick={() => {
                onSelect("line");
              }}
            />
            <NewItem
              name={language.chartSelect.chartTypes.barChart}
              icon={<AiOutlineBarChart size={20} />}
              onClick={() => {
                onSelect("bar");
              }}
            />
            <NewItem
              name={language.chartSelect.chartTypes.scatterChart}
              icon={<AiOutlineDotChart size={20} />}
              onClick={() => {
                onSelect("scatter");
              }}
            />
            <NewItem
              name={"Activity Chart"}
              icon={<AiOutlineDotChart size={20} />}
              onClick={() => {
                onSelect("activity");
              }}
            />
          </div>
          <div className="py-1">
            <NewItem
              name={language.chartSelect.chartTypes.gauge}
              icon={<TbGauge size={20} />}
              onClick={() => {
                onSelect("gauge");
              }}
            />
            <NewItem
              name={language.chartSelect.chartTypes.calendar}
              icon={<IoCalendarSharp size={20} />}
              onClick={() => {
                onSelect("calendar");
              }}
            />
            <NewItem
              name={language.chartSelect.chartTypes.table}
              icon={<RiTableLine size={20} />}
              onClick={() => {
                onSelect("table");
              }}
            />
            <NewItem
              name={language.chartSelect.chartTypes.value}
              icon={<AiOutlineFieldNumber size={20} />}
              onClick={() => {
                onSelect("value");
              }}
            />
            <NewItem
              name={language.chartSelect.chartTypes.text}
              icon={<BsCardText size={20} />}
              onClick={() => {
                onSelect("text");
              }}
            />
            {/* <NewItem
              name={"Status"}
              icon={<FaList size={20} />}
              onClick={() => {
                onSelect("status");
              }}
            /> */}
          </div>
          <div className="py-1">
            <NewItem
              name={language.chartSelect.chartTypes.landMap}
              icon={<IoMap size={20} />}
              onClick={() => {
                onSelect("map");
              }}
            />
            {getCanGroupUseImages() && (
              <NewItem
                name={language.chartSelect.chartTypes.imageMap}
                icon={<IoImageOutline size={20} />}
                onClick={() => {
                  onSelect("imageMap");
                }}
              />
            )}
          </div>
          <div className="py-1">
            <NewItem
              name={language.chartSelect.moreOptionsSoon}
              icon={<IoConstructSharp size={20} />}
              onClick={() => { }}
              disabled={true}
            />
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

type NewItemProps = {
  name: string;
  onClick: () => void;
  icon: React.ReactNode;
  disabled?: boolean;
};
const NewItem = ({ name, icon, onClick, disabled = false }: NewItemProps) => {
  return (
    <Menu.Item>
      {({ active }: MenuItemProps) => (
        <div
          aria-disabled={disabled}
          onClick={onClick}
          className={classNames(
            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
            "px-4 py-2 text-sm cursor-pointer flex flex-row items-center"
          )}
        >
          {icon}
          <div className="ml-2 font-bold">{name}</div>
        </div>
      )}
    </Menu.Item>
  );
};
