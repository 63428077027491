import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { stringify } from "uuid";
import { RootState } from "../../app/store";
import {
  DashboardDataPoint,
  DashboardPhenomData,
} from "../../services/dashboardAPI";
import { UplinkData } from "../../services/dataAPI";

export interface DataState {
  data: DataType;
}

export type DataType = {
  [key: string]: UplinkData[];
};

const initialState: DataState = {
  data: {},
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    updateDeviceData: (state: any, action: PayloadAction<UplinkData[]>) => {
      state.data = {
        ...state.data,
        [action.payload[0].meta.eui]: action.payload,
      };
    },
    updateDashboardData: (
      state: any,
      action: PayloadAction<DashboardPhenomData[]>
    ) => {
      action.payload.forEach((element) => {
        if (state.data[element.eui] === undefined) {
          state.data = {
            ...state.data,
            [element.eui]: element.data,
          };
        } else {
          element.data.forEach((dataPoint) => {
            if (
              Date.parse(new Date(dataPoint.ts ?? 0).toISOString()) >
              Date.parse(
                new Date(
                  state.data[element.eui][state.data[element.eui].length - 1]
                    ?.ts ?? 0
                ).toISOString()
              )
            ) {
              state.data[element.eui].push(dataPoint);
            } else if (state.data[element.eui].length === 0) {
              state.data[element.eui].push(dataPoint);
            }
          });
        }
      });
    },
    backfillDashboardData: (
      state: any,
      action: PayloadAction<DashboardPhenomData[]>
    ) => {
      action.payload.forEach((element) => {
        state.data = {
          ...state.data,
          [element.eui]: [...element.data, ...(state.data[element.eui] ?? [])],
        };
      });
    },
    clearDataData: (state: any) => {
      state.data = {};
    },
  },
});

export const selectData = (state: RootState) => state.data;

export const {
  updateDeviceData,
  clearDataData,
  updateDashboardData,
  backfillDashboardData,
} = dataSlice.actions;

export default dataSlice.reducer;

//I heada back to store.tsx that is found in the folder app
