import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormInput } from "../../components/FormInput";
import Spinner from "../../components/Spinner";
import { useResetPasswordMutation } from "../../services/authAPI";
import { handleError } from "../../utils/ErrorHandling";
import {
  validateConfirmPassword,
  validatePassword,
} from "../../utils/FormValidation";
import { CustomError } from "../../utils/Types";
import { FormErrors } from "./Login";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../dictionary/LoginText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../features/user/userSlice";
import { toast } from "react-toastify";
import { Button } from "../../components/Button";

function ResetPassword() {
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));

  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    if (stateLang === "english" && localStorage.getItem("language") !== null) {
      setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, localStorage.getItem("language")!));
    }
  }, [stateLang]);

  let [formErrors, setFormErrors] = useState<FormErrors>({});
  let [searchParams] = useSearchParams();
  let navigate = useNavigate();

  const [
    ResetPasswordMutation,
    {
      data: resetPasswordData,
      isSuccess: isResetPasswordSuccess,
      isLoading: isResetPasswordLoading,
      isError: isResetPasswordError,
      error: resetPasswordError,
    },
  ] = useResetPasswordMutation();

  useEffect(() => {
    if (isResetPasswordSuccess) {
      navigate("/login");
      toast.success(language.resetPassword.toasts.resetPassword)
    }
  }, [resetPasswordData]);

  useEffect(() => {
    if (isResetPasswordError) {
      const error = handleError(resetPasswordError);
      if (error !== "") {
        setFormErrors({
          formError: error,
        });
      } else {
        setFormErrors({
          formError: "An unknown error has occurred",
        });
      }
    }
  }, [resetPasswordError]);

  async function doResetPassword(event: React.FormEvent<HTMLFormElement>) {
    //prevent the page from reloading on submit
    event.preventDefault();
    //get the form data
    var { newPassword, confirmPassword, resetPasswordToken } =
      document.forms[0];
    //check the form data is valid
    if (validateConfirmPassword(newPassword.value, confirmPassword.value)) {
      setFormErrors({
        ...formErrors,
        confirmPasswordError: validateConfirmPassword(
          newPassword.value,
          confirmPassword.value
        ),
      });
      return;
    }
    if (validatePassword(newPassword.value)) {
      setFormErrors({
        ...formErrors,
        passwordError: validatePassword(newPassword.value),
      });
      return;
    }
    ResetPasswordMutation({
      password: newPassword.value,
      resetPasswordToken: resetPasswordToken.value,
    });
  }

  //reset the form errors to empty when the user changes a value
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    setFormErrors((form) => ({ ...form, [field]: "" }));
  };
  return (
    <div className="h-screen bg-gradient-to-br from-blue-600 to-indigo-600 flex justify-center flex-col items-center w-full">
      <img src="https://www.r-p-r.co.uk/newimages/rprfooter.png" />
      <h1 className="text-center text-6xl font-bold text-white mb-8">
        rpr-IoT
      </h1>
      <div className="bg-white px-10 py-8 rounded-xl shadow-md max-w-sm m-4">
        <h1 className="text-center text-4xl font-semibold text-gray-600 mb-8">
          {language.resetPassword.title}
        </h1>
        <div className="text-xs font-semibold text-center tracking-wide text-red-500 w-full">
          {formErrors.formError}
        </div>
        <>
          {searchParams.get("token") ? (
            <form onSubmit={doResetPassword}>
              <FormInput
                htmlFor="newPassword"
                label={language.resetPassword.labels.newPassword}
                type="password"
                value={undefined}
                autoComplete="new-password"
                required
                error={formErrors.passwordError}
                onChange={(e) => handleInputChange(e, "passwordError")}
              />
              <FormInput
                htmlFor="confirmPassword"
                label={language.resetPassword.labels.confirmNewPassword}
                type="password"
                value={undefined}
                autoComplete="new-password"
                required
                error={formErrors.confirmPasswordError}
                onChange={(e) => handleInputChange(e, "confirmPasswordError")}
              />
              <br />

              <Button
                label={isResetPasswordLoading ? (
                  <Spinner colour="fill-blue-600" />
                ) : (
                  <>{language.resetPassword.buttons.resetPassword}</>
                )}
                type="submit"
                colour="blue"
              />
              <input
                type="hidden"
                name="resetPasswordToken"
                value={searchParams.get("token") ?? ""}
              />
            </form>
          ) : (
            <p className="text-center text-lg">
              {language.resetPassword.buttons.resetPassword}.
            </p>
          )}
        </>
      </div>
    </div>
  );
}

export default ResetPassword;
