export type LanguageText = {
  charts: {
    loadingText: {
      editThisChart: string;
      noDataAvailable: string;
      retrievingData: string;
    }
    tabs: {
      scale: string;
      download: string;
    };
    scale: {
      title: string;
      labels: {
        start: string;
        end: string;
      };
      buttons: {
        twentyFourHours: string;
        sevenDays: string;
        max: string;
        save: string;
        cancel: string;
      };
    }
    download: {
      title: {
        phrase1: string;
        phrase2: string;
      };
      buttons: {
        cancel: string;
        accept: string;
      };
    };
    barChart: {
      editBarChart: string;
      labels: {
        graphTitle: string;
        barName: string;
        device: string;
        parameter: string;
        barColour: string;
      };
      buttons: {
        remove: string;
        addNewBar: string;
        delete: string;
        save: string;
      };
      inputDefaults: {
        selectDevice: string;
        selectParameter: string;
      };
    };
    calendar: {};
    gauge: {
      editGauge: string;
      labels: {
        title: string;
        minValue: string;
        maxValue: string;
        lineName: string;
        device: string;
        parameter: string;
        ringColour: string;
      };
      buttons: {
        delete: string;
        save: string;
      };
      inputDefaults: {
        selectDevice: string;
        selectParameter: string;
      };
      timeFormat: {
        on: string;
        at: string;
      };
    };
    lineChart: {
      editLineChart: string;
      labels: {
        graphTitle: string;
        lineName: string;
        device: string;
        parameter: string;
        lineType: string;
        lineColour: string;
      };
      buttons: {
        remove: string;
        addNewLine: string;
        delete: string;
        save: string;
      };
      lineTypes: {
        solid: string;
        dashed: string;
        dotted: string;
      };
      inputDefaults: {
        selectDevice: string;
        selectParameter: string;
      };
    };
    map: {
      imageMap: {
        imageLink: string;
        showControls: string;
      };
      landMap: {
        editMap: string;
        customMarker: string;
        labels: {
          markerLabel: string;
          lat: string;
          long: string;
          colour: string;
          find: string;
          move: string;
          fix: string;
        };
        buttons: {
          setCenter: string;
          newMarker: string;
          delete: string;
          deleteMap: string;
          save: string;
        };
        setCenter: {
          title: string;
          buttons: {
            save: string;
            cancel: string;
          };
        };
      };
    };
    scatterChart: {
      editScatterGraph: string;
      labels: {
        seriesName: string;
        seriesColour: string;
      };
      buttons: {
        addNewSeries: string;
      };
    };
    table: {};
    text: {};
    value: {};
  };
  chartSelect: {
    addNewChart: string;
    chartTypes: {
      lineChart: string;
      barChart: string;
      scatterChart: string;
      gauge: string;
      calendar: string;
      table: string;
      value: string;
      text: string;
      landMap: string;
      imageMap: string;
    };
    moreOptionsSoon: string;
  };
  chartScale: {
    labels: {
      min: string;
      max: string;
      minAutoScale: string;
      maxAutoScale: string;
    };
  };
};
export const English: LanguageText = {
  charts: {
    loadingText: {
      editThisChart: "Edit this chart to show data",
      noDataAvailable: "No data available",
      retrievingData: "Retrieving Data",
    },
    tabs: {
      scale: "Scale",
      download: "Download",
    },
    scale: {
      title: "Set scale",
      labels: {
        start: "Start",
        end: "End",
      },
      buttons: {
        twentyFourHours: "24 Hours",
        sevenDays: "7 Days",
        max: "Max",
        save: "Save",
        cancel: "Cancel",
      },
    },
    download: {
      title: {
        phrase1: "Download",
        phrase2: "",
      },
      buttons: {
        cancel: "Cancel",
        accept: "Download",
      },
    },
    barChart: {
      editBarChart: "Edit Bar Chart",
      labels: {
        graphTitle: "Graph Title",
        barName: "Bar Name",
        device: "Device",
        parameter: "Parameter",
        barColour: "Bar Colour",
      },
      buttons: {
        remove: "Remove",
        addNewBar: "Add new Bar",
        delete: "Delete",
        save: "Save",
      },
      inputDefaults: {
        selectDevice: "Select A Device",
        selectParameter: "Select Parameter",
      },
    },
    calendar: {},
    gauge: {
      editGauge: "Edit Gauge",
      labels: {
        title: "Title",
        minValue: "Min Value",
        maxValue: "Max Value",
        lineName: "Line Name",
        device: "Device",
        parameter: "Parameter",
        ringColour: "Ring Colour",
      },
      buttons: {
        delete: "Delete",
        save: "Save",
      },
      inputDefaults: {
        selectDevice: "Select Device",
        selectParameter: "Select Parameter",
      },
      timeFormat: {
        on: "on",
        at: "at",
      },
    },
    lineChart: {
      editLineChart: "Edit Line Chart",
      labels: {
        graphTitle: "Graph Title",
        lineName: "Line Name",
        device: "Device",
        parameter: "Parameter",
        lineType: "Line Type",
        lineColour: "Line Colour",
      },
      buttons: {
        remove: "Remove",
        addNewLine: "Add new Line",
        delete: "Delete",
        save: "Save",
      },
      lineTypes: {
        solid: "Solid",
        dashed: "Dashed",
        dotted: "Dotted",
      },
      inputDefaults: {
        selectDevice: "Select A Device",
        selectParameter: "Select Parameter",
      },
    },
    map: {
      imageMap: {
        imageLink: "Image Link",
        showControls: "Show Navigation",
      },
      landMap: {
        editMap: "Edit Map",
        customMarker: "Custom Marker",
        labels: {
          markerLabel: "Marker Label",
          lat: "LAT",
          long: "LONG",
          colour: "Colour",
          find: "Find",
          move: "Move",
          fix: "Fix",
        },
        buttons: {
          setCenter: "Set Center",
          newMarker: "New Marker",
          delete: "Delete",
          deleteMap: "Delete Map",
          save: "Save",
        },
        setCenter: {
          title: "Setting Map Center",
          buttons: {
            save: "Save",
            cancel: "Cancel",
          },
        },
      },
    },
    scatterChart: {
      editScatterGraph: "Edit Scatter Graph",
      labels: {
        seriesName: "Series Name",
        seriesColour: "Series Colour",
      },
      buttons: {
        addNewSeries: "Add New Series",
      },
    },
    table: {},
    text: {},
    value: {},
  },
  chartSelect: {
    addNewChart: "Add new chart",
    chartTypes: {
      lineChart: "Line Chart",
      barChart: "Bar Chart",
      scatterChart: "Scatter Chart",
      gauge: "Gauge",
      calendar: "Calendar",
      table: "Table",
      value: "Value",
      text: "Text",
      landMap: "Land Map",
      imageMap: "Image Map",
    },
    moreOptionsSoon: "More options coming soon",
  },
  chartScale: {
    labels: {
      min: "Min",
      max: "Max",
      minAutoScale: "Min Auto Scale",
      maxAutoScale: "Max Auto Scale",
    },
  },
};
export const French: LanguageText = {
  charts: {
    loadingText: {
      editThisChart: "Editer ce graphique pour montrer les données",
      noDataAvailable: "Aucune donnée disponible",
      retrievingData: "Récupération des données",
    },
    tabs: {
      scale: "Échelle",
      download: "Télécharger",
    },
    scale: {
      title: "Définir l'échelle",
      labels: {
        start: "Début",
        end: "Fin",
      },
      buttons: {
        twentyFourHours: "24 heures",
        sevenDays: "7 jours",
        max: "Max",
        save: "Sauvegarder",
        cancel: "Annuler",
      },
    },
    download: {
      title: {
        phrase1: "Télécharger",
        phrase2: "",
      },
      buttons: {
        cancel: "Annuler",
        accept: "Télécharger",
      },
    },
    barChart: {
      editBarChart: "Editer le diagramme à barres",
      labels: {
        graphTitle: "Titre du graphique",
        barName: "Nom du bar",
        device: "Dispositif",
        parameter: "Paramètre",
        barColour: "Couleur de la barre",
      },
      buttons: {
        remove: "Retirer",
        addNewBar: "Ajouter une nouvelle barre",
        delete: "Supprimer",
        save: "Sauvegarder",
      },
      inputDefaults: {
        selectDevice: "Sélectionner un appareil",
        selectParameter: "Sélectionner les phénomènes",
      },
    },
    calendar: {},
    gauge: {
      editGauge: "Jauge d'édition",
      labels: {
        title: "Titre",
        minValue: "Valeur minimale",
        maxValue: "Valeur maximale",
        lineName: "Nom de la ligne",
        device: "Dispositif",
        parameter: "Paramètre",
        ringColour: "Couleur de l'anneau",
      },
      buttons: {
        delete: "Supprimer",
        save: "Sauvegarder",
      },
      inputDefaults: {
        selectDevice: "Sélectionner le dispositif",
        selectParameter: "Sélectionner les phénomènes",
      },
      timeFormat: {
        on: "le",
        at: "à",
      },
    },
    lineChart: {
      editLineChart: "Modifier le graphique linéaire",
      labels: {
        graphTitle: "Titre du graphique",
        lineName: "Nom de la ligne",
        device: "Dispositif",
        parameter: "Paramètre",
        lineType: "Type de ligne",
        lineColour: "Couleur de la ligne",
      },
      buttons: {
        remove: "Retirer",
        addNewLine: "Ajouter une nouvelle ligne",
        delete: "Supprimer",
        save: "Sauvegarder",
      },
      lineTypes: {
        solid: "Solide",
        dashed: "En tirets",
        dotted: "En pointillés",
      },
      inputDefaults: {
        selectDevice: "Sélectionner un appareil",
        selectParameter: "Sélectionner les phénomènes",
      },
    },
    map: {
      imageMap: {
        imageLink: "Lien vers l'image",
        showControls: "Afficher les contrôles",
      },
      landMap: {
        editMap: "Editer la carte",
        customMarker: "Marqueur personnalisé",
        labels: {
          markerLabel: "Étiquette du marqueur",
          lat: "LAT.",
          long: "LONG.",
          colour: "Couleur",
          find: "Localiser",
          move: "Bouger",
          fix: "Fixer",
        },
        buttons: {
          setCenter: "Définir le centre",
          newMarker: "Nouveau marqueur",
          delete: "Supprimer",
          deleteMap: "Supprimer la carte",
          save: "Sauvegarder",
        },
        setCenter: {
          title: "Définir le centre",
          buttons: {
            save: "Sauvegarder",
            cancel: "Annuler",
          },
        },
      },
    },
    scatterChart: {
      editScatterGraph: "Modifier le graphique de dispersion",
      labels: {
        seriesName: "Nom de la série",
        seriesColour: "Couleur de la série",
      },
      buttons: {
        addNewSeries: "Ajouter une nouvelle série",
      },
    },
    table: {},
    text: {},
    value: {},
  },
  chartSelect: {
    addNewChart: "Ajouter un nouveau graphique",
    chartTypes: {
      lineChart: "Graphique linéaire",
      barChart: "Diagramme à barres",
      scatterChart: "Diagramme de dispersion",
      gauge: "Jauge",
      calendar: "Calendrier",
      table: "Tableau",
      value: "Valeur",
      text: "Texte",
      landMap: "Carte du territoire",
      imageMap: "Carte d'image",
    },
    moreOptionsSoon: "Plus d'options bientôt disponibles",
  },
  chartScale: {
    labels: {
      min: "Min.",
      max: "Max.",
      minAutoScale: "Min Auto échelle",
      maxAutoScale: "Max Auto échelle",
    },
  },
};
export const German: LanguageText = {
  charts: {
    loadingText: {
      editThisChart: "Dieses Diagramm bearbeiten, um Daten anzuzeigen",
      noDataAvailable: "Keine Daten verfügbar",
      retrievingData: "Daten abrufen",
    },
    tabs: {
      scale: "Skala",
      download: "Herunterladen",
    },
    scale: {
      title: "Skala einstellen",
      labels: {
        start: "Anfang",
        end: "Ende",
      },
      buttons: {
        twentyFourHours: "24 Stunden",
        sevenDays: "7 Tage",
        max: "Max",
        save: "Speichern",
        cancel: "Abbrechen",
      },
    },
    download: {
      title: {
        phrase1: "",
        phrase2: "Herunterladen",
      },
      buttons: {
        cancel: "Abbrechen",
        accept: "Herunterladen",
      },
    },
    barChart: {
      editBarChart: "Balkendiagramm bearbeiten",
      labels: {
        graphTitle: "Titel der Grafik",
        barName: "Balkenname",
        device: "Gerät",
        parameter: "Parameter",
        barColour: "Balkenfarbe",
      },
      buttons: {
        remove: "Entfernen",
        addNewBar: "Neue Leiste hinzufügen",
        delete: "Löschen",
        save: "Speichern",
      },
      inputDefaults: {
        selectDevice: "Ein Gerät auswählen",
        selectParameter: "Einen Parameter auswählen",
      },
    },
    calendar: {},
    gauge: {
      editGauge: "Lehre Bearbeiten",
      labels: {
        title: "Titel",
        minValue: "Mindestwert",
        maxValue: "Maximalwert",
        lineName: "Line Name",
        device: "Gerät",
        parameter: "Parameter",
        ringColour: "Ringfarbe",
      },
      buttons: {
        delete: "Löschen",
        save: "Speichern",
      },
      inputDefaults: {
        selectDevice: "Gerät auswählen",
        selectParameter: "Einen Parameter auswählen",
      },
      timeFormat: {
        on: "am",
        at: "um",
      },
    },
    lineChart: {
      editLineChart: "Liniendiagramm bearbeiten",
      labels: {
        graphTitle: "Titel der Grafik",
        lineName: "Zeile Name",
        device: "Gerät",
        parameter: "Parameter",
        lineType: "Zeile Typ",
        lineColour: "Linienfarbe",
      },
      buttons: {
        remove: "Entfernen",
        addNewLine: "Neue Zeile hinzufügen",
        delete: "Löschen",
        save: "Speichern",
      },
      lineTypes: {
        solid: "Solide",
        dashed: "Gestrichelt",
        dotted: 'Gepunktet',
      },
      inputDefaults: {
        selectDevice: "Ein Gerät auswählen",
        selectParameter: "Einen Parameter auswählen",
      },
    },
    map: {
      imageMap: {
        imageLink: "Link zum Bild",
        showControls: "Steuerungen anzeigen",
      },
      landMap: {
        editMap: "Karte bearbeiten",
        customMarker: "Benutzerdefinierte Markierung",
        labels: {
          markerLabel: "Markierungsbeschriftung",
          lat: "Breite",
          long: "Länge",
          colour: "Farbe",
          find: "Finden",
          move: "Bewegen",
          fix: "Fixieren",
        },
        buttons: {
          setCenter: "Zentrum festlegen",
          newMarker: "Neue Markierung",
          delete: "Löschen",
          deleteMap: "Karte löschen",
          save: "Speichern",
        },
        setCenter: {
          title: "Zentrum festlegen",
          buttons: {
            save: "Speichern",
            cancel: "Abbrechen",
          },
        },
      },
    },
    scatterChart: {
      editScatterGraph: "Streudiagramm bearbeiten",
      labels: {
        seriesName: "Serienname",
        seriesColour: "Serienfarbe",
      },
      buttons: {
        addNewSeries: "Neue Serie hinzufügen",
      },
    },
    table: {},
    text: {},
    value: {},
  },
  chartSelect: {
    addNewChart: "Neue Karte hinzufügen",
    chartTypes: {
      lineChart: "Liniendiagramm",
      barChart: "Balkendiagramm",
      scatterChart: "Punktediagramm",
      gauge: "Lehre",
      calendar: "Kalender",
      table: "Tabelle",
      value: "Wert",
      text: "Text",
      landMap: "Landkarte",
      imageMap: "Image Map",
    },
    moreOptionsSoon: "Mehr Optionen in Kürze",
  },
  chartScale: {
    labels: {
      min: "Min",
      max: "Max",
      minAutoScale: "Min Auto-Skala",
      maxAutoScale: "Max Auto-Skala",
    },
  },
};
export const Dutch: LanguageText = {
  charts: {
    loadingText: {
      editThisChart: "Bewerk deze grafiek om gegevens te tonen",
      noDataAvailable: "Geen gegevens beschikbaar",
      retrievingData: "Gegevens ophalen",
    },
    tabs: {
      scale: "Schaal",
      download: "Download",
    },
    scale: {
      title: "Schaal instellen",
      labels: {
        start: "Begin",
        end: "Einde",
      },
      buttons: {
        twentyFourHours: "24 uur",
        sevenDays: "7 dagen",
        max: "Max",
        save: "Opslaan",
        cancel: "Annuleren",
      },
    },
    download: {
      title: {
        phrase1: "Download",
        phrase2: "",
      },
      buttons: {
        cancel: "Annuleren",
        accept: "Download",
      },
    },
    barChart: {
      editBarChart: "Staafdiagram bewerken",
      labels: {
        graphTitle: "Grafiek titel",
        barName: "Bar Naam",
        device: "Apparaat",
        parameter: "Parameter",
        barColour: "Bar Kleur",
      },
      buttons: {
        remove: "Verwijder",
        addNewBar: "Nieuwe balk toevoegen",
        delete: "Verwijder",
        save: "Opslaan",
      },
      inputDefaults: {
        selectDevice: "Selecteer een apparaat",
        selectParameter: "Selecteer een parameter",
      },
    },
    calendar: {},
    gauge: {
      editGauge: "Bewerk meter",
      labels: {
        title: "Titel",
        minValue: "Min Waarde",
        maxValue: "Max Waarde",
        lineName: "regelnaam",
        device: "Apparaat",
        parameter: "Parameter",
        ringColour: "Ring Kleur",
      },
      buttons: {
        delete: "Verwijder",
        save: "Opslaan",
      },
      inputDefaults: {
        selectDevice: "Selecteer apparaat",
        selectParameter: "Selecteer een parameter",
      },
      timeFormat: {
        on: "op",
        at: "om",
      },
    },
    lineChart: {
      editLineChart: "Bewerk lijngrafiek",
      labels: {
        graphTitle: "Grafiek titel",
        lineName: "regelnaam",
        device: "Apparaat",
        parameter: "Parameter",
        lineType: "Lijntype",
        lineColour: "Lijnkleur",
      },
      buttons: {
        remove: "Verwijder",
        addNewLine: "Nieuwe lijn toevoegen",
        delete: "Verwijder",
        save: "Opslaan",
      },
      lineTypes: {
        solid: "Solide",
        dashed: "Dashed",
        dotted: "Gestippeld",
      },
      inputDefaults: {
        selectDevice: "Selecteer een apparaat",
        selectParameter: "Selecteer een parameter",
      },
    },
    map: {
      imageMap: {
        imageLink: "Link naar afbeelding",
        showControls: "Toon bedieningselementen",
      },
      landMap: {
        editMap: "Kaart bewerken",
        customMarker: "Aangepaste marker",
        labels: {
          markerLabel: "Markerlabel",
          lat: "Breedte",
          long: "Lengte",
          colour: "Kleur",
          find: "Vinden",
          move: "Verplaatsen",
          fix: "Fixeren",
        },
        buttons: {
          setCenter: "Centrum instellen",
          newMarker: "Nieuwe Marker",
          delete: "Verwijder",
          deleteMap: "Kaart verwijderen",
          save: "Opslaan",
        },
        setCenter: {
          title: "Centrum instellen",
          buttons: {
            save: "Opslaan",
            cancel: "Annuleren",
          },
        },
      },
    },
    scatterChart: {
      editScatterGraph: "Streudiagram bewerken",
      labels: {
        seriesName: "Serienaam",
        seriesColour: "Seriekleur",
      },
      buttons: {
        addNewSeries: "Nieuwe serie toevoegen",
      },
    },
    table: {},
    text: {},
    value: {},
  },
  chartSelect: {
    addNewChart: "Nieuwe grafiek toevoegen",
    chartTypes: {
      lineChart: "Lijngrafiek",
      barChart: "Staafdiagram",
      scatterChart: "Spreidingsdiagram",
      gauge: "Meter",
      calendar: "Kalender",
      table: "Tafel",
      value: "Waarde",
      text: "Tekst",
      landMap: "Landkaart",
      imageMap: "Beeldkaart",
    },
    moreOptionsSoon: "Binnenkort meer opties",
  },
  chartScale: {
    labels: {
      min: "Min",
      max: "Max",
      minAutoScale: "Min auto-schaal",
      maxAutoScale: "Max auto-schaal",
    },
  },
};
export const Spanish: LanguageText = {
  charts: {
    loadingText: {
      editThisChart: "Editar este gráfico para mostrar datos",
      noDataAvailable: "No hay datos disponibles",
      retrievingData: "Recuperar datos",
    },
    tabs: {
      scale: "Escala",
      download: "Descargar",
    },
    scale: {
      title: "Configurar escala",
      labels: {
        start: "Inicio",
        end: "Fin",
      },
      buttons: {
        twentyFourHours: "24 horas",
        sevenDays: "7 días",
        max: "Máx",
        save: "Guardar",
        cancel: "Cancelar",
      },
    },
    download: {
      title: {
        phrase1: "Descargar",
        phrase2: "",
      },
      buttons: {
        cancel: "Cancelar",
        accept: "Descargar",
      },
    },
    barChart: {
      editBarChart: "Editar gráfico de barras",
      labels: {
        graphTitle: "Título del gráfico",
        barName: "Nombre del bar",
        device: "Dispositivo",
        parameter: "Parámetro",
        barColour: "Color del bar",
      },
      buttons: {
        remove: "Eliminar",
        addNewBar: "Añadir nuevo Bar",
        delete: "Borrar",
        save: "Guardar",
      },
      inputDefaults: {
        selectDevice: "Seleccionar un dispositivo",
        selectParameter: "Seleccione un parámetro",
      },
    },
    calendar: {},
    gauge: {
      editGauge: "Editar medidor",
      labels: {
        title: "Título",
        minValue: "Valor mínimo",
        maxValue: "Valor máximo",
        lineName: "nombre de línea",
        device: "Dispositivo",
        parameter: "Parámetro",
        ringColour: "Color del anillo",
      },
      buttons: {
        delete: "Borrar",
        save: "Guardar",
      },
      inputDefaults: {
        selectDevice: "Seleccionar dispositivo",
        selectParameter: "Seleccione un parámetro",
      },
      timeFormat: {
        on: "el",
        at: "a las",
      },
    },
    lineChart: {
      editLineChart: "Editar gráfico de líneas",
      labels: {
        graphTitle: "Título del gráfico",
        lineName: "nombre de línea",
        device: "Dispositivo",
        parameter: "Parámetro",
        lineType: "Tipo de línea",
        lineColour: "Color de línea",
      },
      buttons: {
        remove: "Eliminar",
        addNewLine: "Añadir nueva línea",
        delete: "Borrar",
        save: "Guardar",
      },
      lineTypes: {
        solid: "Sólido",
        dashed: "Dashed",
        dotted: "Punto",
      },
      inputDefaults: {
        selectDevice: "Seleccionar un dispositivo",
        selectParameter: "Seleccione un parámetro",
      },
    },
    map: {
      imageMap: {
        imageLink: "Enlace de imagen",
        showControls: "Mostrar controles",
      },
      landMap: {
        editMap: "Editar mapa",
        customMarker: "Marcador personalizado",
        labels: {
          markerLabel: "Etiqueta del marcador",
          lat: "Latitud",
          long: "Longitud",
          colour: "Color",
          find: "Encontrar",
          move: "Mover",
          fix: "Fijar",
        },
        buttons: {
          setCenter: "Establecer centro",
          newMarker: "Nuevo marcador",
          delete: "Borrar",
          deleteMap: "Borrar mapa",
          save: "Guardar",
        },
        setCenter: {
          title: "Establecer centro",
          buttons: {
            save: "Guardar",
            cancel: "Cancelar",
          },
        },
      },
    },
    scatterChart: {
      editScatterGraph: "Editar gráfico de dispersión",
      labels: {
        seriesName: "Nombre de la serie",
        seriesColour: "Color de la serie",
      },
      buttons: {
        addNewSeries: "Añadir nueva serie",
      },
    },
    table: {},
    text: {},
    value: {},
  },
  chartSelect: {
    addNewChart: "Añadir nuevo gráfico",
    chartTypes: {
      lineChart: "Gráfico de líneas",
      barChart: "Gráfico de barras",
      scatterChart: "Gráfico de dispersión",
      gauge: "Calibre",
      calendar: "Calendario",
      table: "Mesa",
      value: "Valor",
      text: "texto",
      landMap: "Mapa de la Tierra",
      imageMap: " Mapa de imagen",
    },
    moreOptionsSoon: "Pronto más opciones",
  },
  chartScale: {
    labels: {
      min: "Mín.",
      max: "Máx.",
      minAutoScale: "Min escala auto",
      maxAutoScale: "Max escala auto",
    },
  },
};
export const Italian: LanguageText = {
  charts: {
    loadingText: {
      editThisChart: "Modifica questo grafico per mostrare i dati",
      noDataAvailable: "Nessun dato disponibile",
      retrievingData: "Recupero dei dati",
    },
    tabs: {
      scale: "Scala",
      download: "Scaricare",
    },
    scale: {
      title: "Impostare scala",
      labels: {
        start: "Inizio",
        end: "Fine",
      },
      buttons: {
        twentyFourHours: "24 ore",
        sevenDays: "7 giorni",
        max: "Max",
        save: "Salva",
        cancel: "Annulla",
      },
    },
    download: {
      title: {
        phrase1: "Scaricare",
        phrase2: "",
      },
      buttons: {
        cancel: "Annulla",
        accept: "Scaricare",
      },
    },
    barChart: {
      editBarChart: "Modifica grafico a barre",
      labels: {
        graphTitle: "Titolo del grafico",
        barName: "Nome del bar",
        device: "Dispositivo",
        parameter: "Parametro",
        barColour: "Colore del bar",
      },
      buttons: {
        remove: "Rimuovere",
        addNewBar: "Aggiungi nuova barra",
        delete: "Cancellare",
        save: "Salva",
      },
      inputDefaults: {
        selectDevice: "Seleziona un dispositivo",
        selectParameter: "Selezionare un parametro",
      },
    },
    calendar: {},
    gauge: {
      editGauge: "Modifica indicatore",
      labels: {
        title: "Titolo",
        minValue: "Valore minimo",
        maxValue: "Valore massimo",
        lineName: "nome della linea",
        device: "Dispositivo",
        parameter: "Parametro",
        ringColour: "Colore dell'anello",
      },
      buttons: {
        delete: "Cancellare",
        save: "Salva",
      },
      inputDefaults: {
        selectDevice: "Seleziona dispositivo",
        selectParameter: "Selezionare un parametro",
      },
      timeFormat: {
        on: "il",
        at: "alle",
      },
    },
    lineChart: {
      editLineChart: "Modifica grafico a linee",
      labels: {
        graphTitle: "Titolo del grafico",
        lineName: "nome della linea",
        device: "Dispositivo",
        parameter: "Parametro",
        lineType: "Tipo di linea",
        lineColour: "Colore della linea",
      },
      buttons: {
        remove: "Rimuovere",
        addNewLine: "Aggiungi nuova riga",
        delete: "Cancellare",
        save: "Salva",
      },
      lineTypes: {
        solid: "Solido",
        dashed: "Tratteggiata",
        dotted: "Punteggiata",
      },
      inputDefaults: {
        selectDevice: "Selezionare un dispositivo",
        selectParameter: "Selezionare un parametro",
      },
    },
    map: {
      imageMap: {
        imageLink: "Collegamento immagine",
        showControls: "Mostra controlli",
      },
      landMap: {
        editMap: "Modifica mappa",
        customMarker: "Segnaposto personalizzato",
        labels: {
          markerLabel: "Etichetta del marcatore",
          lat: "Lat",
          long: "Long",
          colour: "Colore",
          find: "Trova",
          move: "Spostare",
          fix: "Fissare",
        },
        buttons: {
          setCenter: "Imposta centro",
          newMarker: "Nuovo marcatore",
          delete: "Cancellare",
          deleteMap: "Cancella mappa",
          save: "Salva",
        },
        setCenter: {
          title: "Imposta centro",
          buttons: {
            save: "Salva",
            cancel: "Annulla",
          },
        },
      },
    },
    scatterChart: {
      editScatterGraph: "Modifica grafico a dispersione",
      labels: {
        seriesName: "Nome della serie",
        seriesColour: "Colore della serie",
      },
      buttons: {
        addNewSeries: "Aggiungi nuova serie",
      },
    },
    table: {},
    text: {},
    value: {},
  },
  chartSelect: {
    addNewChart: "Aggiungi nuovo grafico",
    chartTypes: {
      lineChart: "Grafico a linee",
      barChart: "Grafico a barre",
      scatterChart: "Grafico a dispersione",
      gauge: "Calibro",
      calendar: "Calendario",
      table: "Tavolo",
      value: "Valore",
      text: "testo",
      landMap: "Mappa del territorio",
      imageMap: "Mappa dell'immagine",
    },
    moreOptionsSoon: "Altre opzioni in arrivo",
  },
  chartScale: {
    labels: {
      min: "Min",
      max: "Max",
      minAutoScale: "Min autoscala",
      maxAutoScale: "Max autoscala",
    },
  },
};
export const Romanian: LanguageText = {
  charts: {
    loadingText: {
      editThisChart: "Editați acest grafic pentru a afișa date",
      noDataAvailable: "Nu există date disponibile",
      retrievingData: "Recuperarea datelor",
    },
    tabs: {
      scale: "Scară",
      download: "Descărcați",
    },
    scale: {
      title: "Setați scala",
      labels: {
        start: "Început",
        end: "Sfârșit",
      },
      buttons: {
        twentyFourHours: "24 de ore",
        sevenDays: "7 zile",
        max: "Max",
        save: "Salvați",
        cancel: "Anulați",
      },
    },
    download: {
      title: {
        phrase1: "Descarcă",
        phrase2: "",
      },
      buttons: {
        cancel: "Anulează",
        accept: "Descarcă",
      },
    },
    barChart: {
      editBarChart: "Editează graficul de bare",
      labels: {
        graphTitle: "Titlul graficului",
        barName: "Numele barului",
        device: "Dispozitiv",
        parameter: "Parametru",
        barColour: "Culoarea barei",
      },
      buttons: {
        remove: "Eliminați",
        addNewBar: "Adaugă un nou bar",
        delete: "Ștergeți",
        save: "Salvați",
      },
      inputDefaults: {
        selectDevice: "Selectați un dispozitiv",
        selectParameter: "Selectați un parametru",
      },
    },
    calendar: {},
    gauge: {
      editGauge: "Editare gabarit",
      labels: {
        title: "Titlu",
        minValue: "Valoarea minimă",
        maxValue: "Valoarea maximă",
        lineName: "Numele liniei",
        device: "Dispozitiv",
        parameter: "Parametru",
        ringColour: "Culoarea inelului",
      },
      buttons: {
        delete: "Ștergeți",
        save: "Salvați",
      },
      inputDefaults: {
        selectDevice: "Selectați dispozitivul",
        selectParameter: "Selectați un parametru",
      },
      timeFormat: {
        on: "pe",
        at: "la",
      },
    },
    lineChart: {
      editLineChart: "Editare diagramă cu linii",
      labels: {
        graphTitle: "Titlul graficului",
        lineName: "numele liniei",
        device: "Dispozitiv",
        parameter: "Parametru",
        lineType: "Tip de linie",
        lineColour: "Culoarea liniei",
      },
      buttons: {
        remove: "Eliminați",
        addNewLine: "Adaugă o nouă linie",
        delete: "Ștergeți",
        save: "Salvați",
      },
      lineTypes: {
        solid: "Continuă",
        dashed: "Discontinuă",
        dotted: "Punctată",
      },
      inputDefaults: {
        selectDevice: "Selectați un dispozitiv",
        selectParameter: "Selectați un parametru",
      },
    },
    map: {
      imageMap: {
        imageLink: "Link imagine",
        showControls: "Afișați controalele",
      },
      landMap: {
        editMap: "Editează harta",
        customMarker: "Marcaj personalizat",
        labels: {
          markerLabel: "Eticheta markerului",
          lat: "Lat",
          long: "Lung",
          colour: "Culoare",
          find: "Găsi",
          move: "Mișcare",
          fix: "Fixați",
        },
        buttons: {
          setCenter: "Setați centrul",
          newMarker: "Noul marker",
          delete: "Ștergeți",
          deleteMap: "Șterge harta",
          save: "Salvați",
        },
        setCenter: {
          title: "Setați centrul",
          buttons: {
            save: "Salvați",
            cancel: "Anulare",
          },
        },
      },
    },
    scatterChart: {
      editScatterGraph: "Editați graficul de dispersie",
      labels: {
        seriesName: "Numele seriei",
        seriesColour: "Culoarea seriei",
      },
      buttons: {
        addNewSeries: "Adaugă o nouă serie",
      },
    },
    table: {},
    text: {},
    value: {},
  },
  chartSelect: {
    addNewChart: "Adaugă o nouă diagramă",
    chartTypes: {
      lineChart: "Diagramă cu linii",
      barChart: "Diagramă cu bare",
      scatterChart: "Diagramă de dispersie",
      gauge: "Gabarit",
      calendar: "Calendar",
      table: "Tabelul",
      value: "Valoarea",
      text: "text",
      landMap: "Harta terenurilor",
      imageMap: "Hartă de imagine",
    },
    moreOptionsSoon: "Mai multe opțiuni în curând",
  },
  chartScale: {
    labels: {
      min: "Min",
      max: "Max",
      minAutoScale: "Scară automată min",
      maxAutoScale: "Scară automată max",
    },
  },
};
export const Portuguese: LanguageText = {
  charts: {
    loadingText: {
      editThisChart: "Editar este gráfico para mostrar dados",
      noDataAvailable: "Não há dados disponíveis",
      retrievingData: "Recuperar dados",
    },
    tabs: {
      scale: "Escala",
      download: "Baixar",
    },
    scale: {
      title: "Definir escala",
      labels: {
        start: "Começar",
        end: "Fim",
      },
      buttons: {
        twentyFourHours: "24 horas",
        sevenDays: "7 dias",
        max: "Max",
        save: "Salvar",
        cancel: "Cancelar",
      },
    },
    download: {
      title: {
        phrase1: "Baixar",
        phrase2: "",
      },
      buttons: {
        cancel: "Cancelar",
        accept: "Baixar",
      },
    },
    barChart: {
      editBarChart: "Editar gráfico de barras",
      labels: {
        graphTitle: "Título do gráfico",
        barName: "Nome de Bar",
        device: "Dispositivo",
        parameter: "Parâmetro",
        barColour: "Cor de barra",
      },
      buttons: {
        remove: "Remover",
        addNewBar: "Adicionar nova barra",
        delete: "Apagar",
        save: "Salvar",
      },
      inputDefaults: {
        selectDevice: "Seleccionar um dispositivo",
        selectParameter: "Seleccione um parâmetro",
      },
    },
    calendar: {},
    gauge: {
      editGauge: "Editar Bitola",
      labels: {
        title: "Título",
        minValue: "Valor Mínimo",
        maxValue: "Valor máximo",
        lineName: "nome da linha",
        device: "Dispositivo",
        parameter: "Parâmetro",
        ringColour: "Cor do Anel",
      },
      buttons: {
        delete: "Apagar",
        save: "Salvar",
      },
      inputDefaults: {
        selectDevice: "Seleccionar dispositivo",
        selectParameter: "Seleccione um parâmetro",
      },
      timeFormat: {
        on: "em",
        at: "às",
      },
    },
    lineChart: {
      editLineChart: "Editar Gráfico de Linhas",
      labels: {
        graphTitle: "Título do gráfico",
        lineName: "nome da linha",
        device: "Dispositivo",
        parameter: "Parâmetro",
        lineType: "Tipo de linha",
        lineColour: "Cor da linha",
      },
      buttons: {
        remove: "Remover",
        addNewLine: "Adicionar nova linha",
        delete: "Apagar",
        save: "Salvar",
      },
      lineTypes: {
        solid: "Sólido",
        dashed: "Despedaçado",
        dotted: "Pontilhado",
      },
      inputDefaults: {
        selectDevice: "Seleccionar um dispositivo",
        selectParameter: "Seleccione um parâmetro",
      },
    },
    map: {
      imageMap: {
        imageLink: "Link da imagem",
        showControls: "Mostrar controles",
      },
      landMap: {
        editMap: "Editar Mapa",
        customMarker: "Marcador personalizado",
        labels: {
          markerLabel: "Etiqueta do Marcador",
          lat: "Lat",
          long: "Longo",
          colour: "Cor",
          find: "Encontrar",
          move: "Mover",
          fix: "Fixar",
        },
        buttons: {
          setCenter: "Definir Centro",
          newMarker: "Novo Marcador",
          delete: "Apagar",
          deleteMap: "Apagar Mapa",
          save: "Salvar",
        },
        setCenter: {
          title: "Definir Centro",
          buttons: {
            save: "Salvar",
            cancel: "Cancelar",
          },
        },
      },
    },
    scatterChart: {
      editScatterGraph: "Editar gráfico de dispersão",
      labels: {
        seriesName: "Nome da série",
        seriesColour: "Cor da série",
      },
      buttons: {
        addNewSeries: "Adicionar nova série",
      },
    },
    table: {},
    text: {},
    value: {},
  },
  chartSelect: {
    addNewChart: "Adicionar novo gráfico",
    chartTypes: {
      lineChart: "Gráfico de Linhas",
      barChart: "Gráfico de barras",
      scatterChart: "Quadro de dispersão",
      gauge: "Bitola",
      calendar: "Calendário",
      table: "Mesa",
      value: "Valor",
      text: "texto",
      landMap: "Mapa da terra",
      imageMap: "Mapa de imagens",
    },
    moreOptionsSoon: "Mais opções em breve",
  },
  chartScale: {
    labels: {
      min: "Mín.",
      max: "Máx.",
      minAutoScale: "Balança Automóvel Min",
      maxAutoScale: "Balança Automóvel Máxima",
    },
  },
};