import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { PhenomenaConfiguration } from "../../features/devices/deviceSlice";
import _ from "lodash";
import { FormInput } from "../../components/FormInput";
import Spinner from "../../components/Spinner";
import AcceptModal from "../../components/modals/AcceptModal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  useDeleteGroupMutation,
  useGetGroupQuery,
  useUpdateGroupMutation,
  useUserLeaveGroupMutation,
} from "../../services/groupAPI";
import { handleError } from "../../utils/ErrorHandling";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../dictionary/GroupsText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { selectUser } from "../../features/user/userSlice";
import { useAppSelector } from "../../app/hooks";
import { UserGroup } from "../../services/userAPI";
import { selectLanguage } from "../../features/user/userSlice";
import { toast } from "react-toastify";
import InputModal from "../../components/modals/InputModal";
import { Button } from "../../components/Button";
import GroupManageImages from "./GroupManageImages";
type FormInputData = {
  optionValue: string;
  optionName: string;
};

function ViewEditGroup() {
  const { groupid } = useParams();
  const { username, groups, id: userID } = useAppSelector(selectUser);
  let [groupName, setGroupName] = useState("");
  let [leaveModal, setLeaveModal] = useState(false);
  let [showDeleteModal, setShowDeleteModal] = useState(false);
  let [deletePermission, setDeletePermission] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [invitePermission, setInvitePermission] = useState(false);
  const location = useLocation();
  let userInputData: FormInputData[] = [];
  let deviceInputData: FormInputData[] = [];
  const {
    data: groupInfo,
    isSuccess,
    isError,
    isFetching,
    error,
  } = useGetGroupQuery({ id: groupid ?? "" } ?? skipToken);

  const [
    updateGroup,
    { isLoading: isUpdateGroupLoading, isSuccess: isUpdateGroupSuccess },
  ] = useUpdateGroupMutation();

  const [
    leaveGroup,
    {
      isLoading: isLeaveGroupLoading,
      isSuccess: isLeaveGroupSuccess,
      isError: isLeaveGroupError,
      error: leaveGroupError,
    },
  ] = useUserLeaveGroupMutation();

  const [
    deleteGroup,
    {
      isLoading: isDeleteGroupLoading,
      isSuccess: isDeleteGroupSuccess,
      isError: isDeleteGroupError,
      error: deleteGroupError,
    },
  ] = useDeleteGroupMutation();

  useEffect(() => {
    if (isError) {
      handleError(error);
      navigate("/group");
    }
  }, [error]);

  useEffect(() => {
    if (isDeleteGroupError) {
      handleError(deleteGroupError);
      setShowDeleteModal(false);
    }
  }, [deleteGroupError]);

  useEffect(() => {
    if (isDeleteGroupSuccess) {
      navigate("/group");
      toast.success(language.viewEditGroup.toasts.deleted);
      setLeaveModal(false);
    }
  }, [isDeleteGroupSuccess]);

  useEffect(() => {
    if (isLeaveGroupError) {
      handleError(leaveGroupError);
      setLeaveModal(false);
    }
  }, [leaveGroupError]);

  useEffect(() => {
    if (isUpdateGroupSuccess) {
      toast.success(language.viewEditGroup.toasts.updated);
    }
  }, [isUpdateGroupSuccess]);

  useEffect(() => {
    if (isLeaveGroupSuccess) {
      navigate("/group");
      toast.success(language.viewEditGroup.toasts.left);
      setLeaveModal(false);
    }
  }, [isLeaveGroupSuccess]);

  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));

  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  useEffect(() => {
    if (groupid) {
      setDeletePermission(
        groups?.[groupid as keyof UserGroup]?.permissions.can_delete_group !==
        undefined &&
        groups?.[groupid as keyof UserGroup]?.permissions.can_delete_group !==
        false
      );
      setEditPermission(
        groups?.[groupid as keyof UserGroup]?.permissions.can_edit_group !==
        undefined &&
        groups?.[groupid as keyof UserGroup]?.permissions.can_edit_group !==
        false
      );
      setInvitePermission(
        groups?.[groupid as keyof UserGroup]?.permissions.can_invite_users !==
        undefined &&
        groups?.[groupid as keyof UserGroup]?.permissions.can_invite_users !==
        false
      );
    }
  }, [groupid, location.pathname]);

  //const configurations = Group?.configuration;
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess && groupInfo && groups) {
      setGroupName(groupInfo.name);
      setDeletePermission(
        (groups?.[groupid as keyof UserGroup]?.permissions.can_delete_group !==
          undefined &&
          groups?.[groupid as keyof UserGroup]?.permissions.can_delete_group !==
          false) ??
        false
      );
      setEditPermission(
        groups?.[groupid as keyof UserGroup]?.permissions.can_edit_group !==
        undefined &&
        groups?.[groupid as keyof UserGroup]?.permissions.can_edit_group !==
        false
      );
      setInvitePermission(
        groups?.[groupid as keyof UserGroup]?.permissions.can_invite_users !==
        undefined &&
        groups?.[groupid as keyof UserGroup]?.permissions.can_invite_users !==
        false
      );
    }
  }, [groupInfo, groups]);

  //contents from inside of useEffect
  if (isSuccess && groupInfo) {
    for (const deviceKey in groupInfo.devices) {
      const specificDevices = groupInfo.devices;
      deviceInputData.push({
        optionValue: deviceKey,
        optionName:
          specificDevices[deviceKey as keyof typeof specificDevices].device_eui,
      });
    }
    for (const userKey in groupInfo.users) {
      const specificUsers = groupInfo.users;
      userInputData.push({
        optionValue: userKey,
        optionName:
          specificUsers[userKey as keyof typeof specificUsers].username,
      });
    }
  }

  async function GroupDelete(event: React.FormEvent<HTMLFormElement>) {
    var { modalPassword } = document.forms[0];
    event.preventDefault();
    deleteGroup({ id: groupid ?? "", password: modalPassword.value ?? "" });
  }

  const modals = (
    <>
      {leaveModal && (
        <AcceptModal
          onAccept={() => {
            leaveGroup({ groupID: groupid ?? "", userID: userID ?? "" });
          }}
          onCancel={() => {
            setLeaveModal(false);
          }}
          Title={language.viewEditGroup.modals.leaveModal.title}
          Body={
            <>
              <b>{username}</b>
              {language.viewEditGroup.modals.leaveModal.body.phrase1}
              <b>{groupName}</b>
              {language.viewEditGroup.modals.leaveModal.body.phrase2}
            </>
          }
          AcceptButton={language.viewEditGroup.modals.leaveModal.buttons.accept}
          AcceptButtonColour={"red"}
          CancelButton={language.viewEditGroup.modals.leaveModal.buttons.cancel}
          loading={isLeaveGroupLoading}
        />
      )}
      {showDeleteModal && (
        <InputModal
          onSubmit={GroupDelete}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          Title={language.viewEditGroup.modals.deleteModal.title}
          Loading={isDeleteGroupLoading}
          Body={
            <>
              {language.viewEditGroup.modals.deleteModal.body.phrase1}{" "}
              <b>{groupName}</b>
              {language.viewEditGroup.modals.deleteModal.body.phrase2}
            </>
          }
          Form={[
            {
              htmlFor: "modalPassword",
              label: language.viewEditGroup.modals.deleteModal.labels.password,
              type: "password",
              value: "",
              error: "",
              autoComplete: "current-password",
              onChange: () => { },
            },
          ]}
          AcceptButton={
            language.viewEditGroup.modals.deleteModal.buttons.accept
          }
          CancelButton={
            language.viewEditGroup.modals.deleteModal.buttons.cancel
          }
        />
      )}
    </>
  );

  const groupForms = (
    <>
      <FormInput
        loading={isFetching}
        htmlFor="name"
        label={language.viewEditGroup.labels.groupName}
        value={groupName ?? "Loading..."}
        onChange={(event) => {
          setGroupName(event.target.value);
        }}
        disabled={!editPermission}
      />
    </>
  );

  const buttons = (
    <div className="flex sm:justify-between items-center justify-center sm:flex-row flex-col">
      <div className="flex flex-row">
        <div className="w-auto mt-4 pr-2">
          <Button
            onClick={() => setLeaveModal(true)}
            label={language.viewEditGroup.buttons.leaveGroup}
            colour="red"
          />
        </div>
        {deletePermission && (
          <div className="w-auto mt-4 pr-2">
            <Button
              onClick={() => setShowDeleteModal(true)}
              label={language.viewEditGroup.buttons.deleteGroup}
              colour="red"
              lock={true}
            />
          </div>
        )}
      </div>
      <div className="flex flex-row">
        {invitePermission && (
          <div className="w-auto mt-4 pr-2">
            <Button
              onClick={() => navigate("./invite")}
              label={language.viewEditGroup.buttons.invite}
              colour="white"
            />
          </div>
        )}
        {editPermission && (
          <>
            <div className="w-auto mt-4 pr-2">
              <Button
                onClick={() => setGroupName(groupInfo?.name ?? "")}
                label={language.viewEditGroup.buttons.cancel}
                colour="white"
              />
            </div>
            <div className="w-auto mt-4 pr-2">
              <Button
                onClick={() =>
                  updateGroup({
                    name: groupName,
                    id: groupid ?? "",
                  })
                }
                label={
                  isUpdateGroupLoading ? (
                    <Spinner colour="fill-blue-600" />
                  ) : (
                    language.viewEditGroup.buttons.save
                  )
                }
                colour="green"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );

  return (
    <>
      {modals}
      <>
        <Outlet />
        <div className="font-bold text-xl h-full w-full text-black bg-white rounded-lg p-4">
          {!groupInfo?.name || isFetching ? (
            <Skeleton width={100} height={27} />
          ) : (
            groupInfo?.name
          )}
          <div className="text-black text-base font-normal">
            {groupForms}
            <hr className="my-2" />
            {groupInfo?.subscription?.plan_id === "649562c4b9b08a7e359d6876" ? (
              <div className="mb-1 relative">
                <div className="w-full h-full absolute bg-black opacity-10 z-3 p-2 rounded-md">
                </div>
                <div className="p-2">
                  <h1 className="text-xl font-semibold text-gray-900">
                    {"Images"}
                  </h1>
                  <div className="flex flex-row overflow-x-scroll scrollbar-thin pr-2">
                    <div className="flex flex-col items-center justify-center p-2 bg-white border border-gray-200 rounded-lg shadow-sm">
                      <div className="flex items-center justify-center w-40 h-40 bg-gray-100 rounded-full text-center">
                        {"Subscription needed to upload images."}
                      </div>
                    </div>
                  </div>
                </div>
              </div >
            ) : (
              <GroupManageImages />
            )}
            {buttons}
          </div>
        </div>
      </>
    </>
  );
}

export default ViewEditGroup;
