import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { selectUser } from "../../features/user/userSlice";
import { useGetGroupQuery } from "../../services/groupAPI";
import { useAppSelector } from "../../app/hooks";
import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
} from "../../dictionary/DashboardText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { selectLanguage } from "../../features/user/userSlice";
import useWindowDimensions from "../../utils/ScreenDimensions";
import { Groups, selectGroups } from "../../features/groups/groupSlice";
import moment from "moment";
import { Button } from "../../components/Button";
import { useLazyEditSubscriptionQuery } from "../../services/subscriptionAPI";
import InvoiceTable from "./InvoiceTable";
import { MdAddChart, MdDashboard, MdGroups } from "react-icons/md";
import { GrBeacon } from "react-icons/gr";
import { AiOutlineFieldTime } from "react-icons/ai";

function GroupViewSubscription() {
  const { groupid } = useParams();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(
    LanguageCheck(
      English,
      French,
      German,
      Dutch,
      Spanish,
      Italian,
      Romanian,
      Portuguese,
      stateLang
    )
  );

  useEffect(() => {
    setLanguage(
      LanguageCheck(
        English,
        French,
        German,
        Dutch,
        Spanish,
        Italian,
        Romanian,
        Portuguese,
        stateLang
      )
    );
  }, [stateLang]);

  const [showViewInvoicesModal, setShowViewInvoicesModal] = useState(false);

  const groupInfo =
    useAppSelector(selectGroups).groups[groupid as keyof Groups];

  const [editSubscription, { isError, error, isLoading, isSuccess, data }] =
    useLazyEditSubscriptionQuery();

  useEffect(() => {
    if (isSuccess && data) {
      if (data.url === "free") {
        navigate("/group/" + groupid + "/subscription/start");
        return;
      }
      window.location.href = data.url;
    }
  }, [isSuccess, data]);

  const IconPerkDisplay = ({ icon, tierLevel, perkName }: { icon?: JSX.Element, tierLevel: string | JSX.Element, perkName: string }) => {
    return (
      <div className="border border-black rounded-md px-2 pt-2 pb-1 m-0.5 shadow-md">
        <div className="text-xl font-bold justify-center inline-flex space-x-2">
          <div className="flex items-center w-12">{icon}</div>
          <div className="text-center text-5xl">
            {tierLevel}
          </div>{" "}
          <div className="flex items-end">{perkName}</div>
        </div>
      </div>
    )
  }

  const planNameDisplay = (
    groupInfo?.subscription?.plan_name &&
    <div className="text-xl font-bold justify-start inline-flex space-x-2">
      <div className="text-center text-5xl">{groupInfo.subscription.plan_name}</div>
    </div>
  )

  const memberDisplay = (
    groupInfo?.subscription?.perks?.users &&
    <IconPerkDisplay
      icon={<MdGroups size={48} className="-mb-1" />}
      tierLevel={Object.values(groupInfo.users ?? {}).length + "/" + groupInfo.subscription.perks.users}
      perkName="Members"
    />
  );

  const dashboardDisplay = (
    groupInfo?.subscription?.perks?.dashboards &&
    <IconPerkDisplay
      icon={<MdDashboard size={48} />}
      tierLevel={Object.values(groupInfo.dashboards ?? {}).length + "/" + groupInfo.subscription.perks.dashboards}
      perkName="Dashboards"
    />
  );

  const devicesDisplay = (
    groupInfo?.subscription?.perks?.devices &&
    <IconPerkDisplay
      icon={<GrBeacon size={40} />}
      tierLevel={Object.values(groupInfo.devices ?? {}).length + "/" + groupInfo.subscription.perks.devices}
      perkName="Devices"
    />
  );

  const dataRetentionDisplay = (
    groupInfo?.subscription?.perks?.data_retention &&
    <IconPerkDisplay
      icon={<AiOutlineFieldTime size={48} />}
      tierLevel={formatDayMonthYear({ d: groupInfo.subscription.perks.data_retention.days, m: groupInfo.subscription.perks.data_retention.months, y: groupInfo.subscription.perks.data_retention.years })}
      perkName="Data Retention"
    />
  );

  const dashboardElementsDisplay = (
    groupInfo?.subscription?.perks?.dashboard_elements &&
    <IconPerkDisplay
      icon={<MdAddChart size={48} />}
      tierLevel={groupInfo.subscription.perks.dashboard_elements === -1 ? "Unlimited" : groupInfo.subscription.perks.dashboard_elements.toString()}
      perkName="Dashboard Elements"
    />
  );

  function getDisplayLayout() {
    return (
      <div className="w-full grid grid-cols-1">
        {memberDisplay}
        {dashboardDisplay}
        {devicesDisplay}
        {dataRetentionDisplay}
        {dashboardElementsDisplay}
      </div>
    )
  }

  const priceDisplay = (
    groupInfo?.subscription?.price && (
      <div>
        <div className="w-full text-xl font-bold justify-center inline-flex space-x-2">
          <div className="text-center text-5xl">
            {(groupInfo.subscription.price === 0 || groupInfo.subscription.price === undefined)
              ? "Free"
              : "£" + (groupInfo.subscription.price / 100).toFixed(2)}
          </div>{" "}
          {(groupInfo.subscription.price !== 0 && groupInfo.subscription.price !== undefined) && (
            <div className="flex items-end">
              per{" "}
              {groupInfo.subscription.renewal_period === "month"
                ? "month"
                : "year"}
            </div>
          )}
        </div>{" "}
        <br />
        {(groupInfo.subscription.price !== 0 && groupInfo.subscription.price !== undefined) && (
          <div className="w-full text-lg font-bold justify-center inline-flex space-x-2">
            <div>
              Next Billed on{" "}
              {moment(groupInfo.subscription.renewal_date).format(
                "DD/MM/YYYY HH:mm"
              )}
            </div>
          </div>
        )}
      </div>
    )
  )

  return (
    <div>
      <div className="font-bold text-xl h-full w-full text-black bg-white rounded-lg p-4">
        Subscription
      </div>
      {groupInfo && (
        <>
          <div className="h-full w-full text-black bg-white rounded-lg overflow-y-scroll scrollbar-thin p-4 pt-1">
            <div className="flex flex-col md:flex-row h-auto md:space-x-4 space-y-4">
              <div className="w-full md:w-[50%]">
                <div className="bg-gray-200 border-2 border-gray-700 rounded-md p-4">
                  {planNameDisplay}
                  <hr className="border-gray-600 my-2" />
                  {getDisplayLayout()}
                </div>
              </div>
              <div className="md:h-auto w-full md:w-[50%]">
                {priceDisplay}
              </div>
            </div>
            {/* {Buttons} */}
          </div>
          <hr className="m-2" />
          <div className="w-full flex justify-between">
            <div className="w-fit m-4">
              <Button
                label={"Edit Subscription"}
                colour="blue"
                onClick={() => {
                  editSubscription({ group_id: groupid ?? "" });
                }}
              />
            </div>
            <div className="w-fit m-4">
              <Button
                label={"View Invoices"}
                colour="blue"
                onClick={() => {
                  setShowViewInvoicesModal(true);
                }}
              />
            </div>
          </div>
          {showViewInvoicesModal && (
            <div
              className="relative z-20"
              aria-labelledby="modal-title"
              role="dialog"
              aria-modal="true"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                  <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <h3
                            className="text-lg leading-6 font-medium text-gray-900"
                            id="modal-title"
                          >
                            {""}
                          </h3>
                          <div className="mt-2">
                            <div className="max-h-[400px] sm:max-h-[600px] text-sm text-gray-500 bg-gray-100 scrollbar-thin overflow-y-scroll">{<InvoiceTable />}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <div className="sm:w-auto">
                        <Button
                          onClick={() => setShowViewInvoicesModal(false)}
                          label={"Close"}
                          colour={"blue"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );

  function formatDayMonthYear({ d, m, y }: { d: number, m: number, y: number }) {
    return (
      <>
        {
          d === 0
            ? ""
            : d === 1
              ? d + " day"
              : d + " days"
        }
        {
          m === 0
            ? ""
            : m === 1
              ? m +
              " month"
              : m +
              " months"
        }
        {
          y === 0
            ? ""
            : y === 1
              ? y + " year"
              : y +
              " years"
        }
      </>
    )
  }
};

export default GroupViewSubscription;
