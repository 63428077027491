import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { useVerifyEmailMutation } from "../../services/authAPI";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetGroupQuery } from "../../services/groupAPI";

function GroupIndex() {
  let { groupid } = useParams();
  useGetGroupQuery(groupid ? { id: groupid ?? "" } : skipToken);
  return (
    <div className="h-full w-full">
      <Outlet />
    </div>
  );
}

export default GroupIndex;
