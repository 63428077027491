import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../dictionary/DeviceText";
import { useState, useEffect } from "react";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../features/user/userSlice";
import { LanguageCheck } from "../../utils/LanguageCheck";

function EditDeviceIndex() {

    const navigate = useNavigate();
    const location = useLocation();

    const [tabIndex, setTabIndex] = useState(switchTabFromURL());

    function switchTabFromURL() {
        if (location.pathname.includes("parameters")) {
            return 1;
        } else if (location.pathname.includes("gateways")) {
            return 2;
        } else if (location.pathname.includes("advanced")) {
            return 3;
        } else {
            return 0;
        }
    }

    const stateLang = useAppSelector(selectLanguage);
    let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    useEffect(() => {
        setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    }, [stateLang]);

    return (
        <div className="h-full w-full">
            <Tabs
                forceRenderTabPanel
                /* defaultIndex={switchTabFromURL()} */
                selectedIndex={switchTabFromURL()}
                onSelect={(index) => setTabIndex(index)}
                className="h-full w-full text-black bg-white rounded-lg"
            >
                <TabList className="bg-white rounded  border w-full block overflow-x-scroll whitespace-nowrap overflow-y-hidden scrollbar-thin">
                    <Tab onClick={() => navigate(".")}>
                        {language.editDevice.tabs.overview}
                    </Tab>
                    <Tab onClick={() => navigate("./parameters")}>
                        {language.editDevice.tabs.parameters}
                    </Tab>
                    <Tab onClick={() => navigate("./gateways")}>
                        {language.editDevice.tabs.gateways}
                    </Tab>
                    <Tab onClick={() => navigate("./advanced")}>
                        {"Advanced"}
                    </Tab>
                </TabList>
                <TabPanel>
                    <Outlet />
                </TabPanel >
                <TabPanel>
                    <Outlet />
                </TabPanel>
                <TabPanel>
                    <Outlet />
                </TabPanel>
                <TabPanel>
                    <Outlet />
                </TabPanel>
            </Tabs >
        </div>
    );
}

export default EditDeviceIndex;
