import { useEffect, useState } from "react";
import {
    useDeleteImageMutation,
    useGetGroupImagesQuery,
    useInviteUserToGroupMutation,
} from "../../services/groupAPI";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../features/user/userSlice";
import {
    English,
    French,
    German,
    Dutch,
    Spanish,
    Italian,
    Romanian,
    Portuguese,
} from "../../dictionary/GroupsText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import _ from "lodash";
import { MdAdd } from "react-icons/md";
import GroupObjectModal from "../../components/modals/GroupObjectModal";
import AcceptModal from "../../components/modals/AcceptModal";
import Skeleton from "react-loading-skeleton";
import { useUploadImageMutation } from "../../services/groupAPI";
import { FormInput } from "../../components/FormInput";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ImageType, selectImages } from "../../features/images/imageSlice";

function GroupManageImages() {
    const stateLang = useAppSelector(selectLanguage);
    let [language, setLanguage] = useState(
        LanguageCheck(
            English,
            French,
            German,
            Dutch,
            Spanish,
            Italian,
            Romanian,
            Portuguese,
            stateLang
        )
    );
    useEffect(() => {
        setLanguage(
            LanguageCheck(
                English,
                French,
                German,
                Dutch,
                Spanish,
                Italian,
                Romanian,
                Portuguese,
                stateLang
            )
        );
    }, [stateLang]);

    const [
        uploadImage,
        {
            data: uploadImageData,
            isLoading: isUploadImageLoading,
            isSuccess: isUploadImageSuccess,
            error: uploadImageError
        },
    ] = useUploadImageMutation();

    const [
        deleteImage,
        {
            data: deleteImageData,
            isSuccess: isDeleteImageSuccess,
            isLoading: isDeleteImageLoading,
            error: deleteImageError
        },
    ] = useDeleteImageMutation();

    function GetImages() {
        useGetGroupImagesQuery({ group_id: groupid });
    }

    var groupid = useParams().groupid ?? "";

    const [showEditImageModal, setShowEditImageModal] = useState(false);
    const [showAddImageModal, setShowAddImageModal] = useState(false);

    const [uploadedImage, setUploadedImage] = useState<File | null>(null);
    const [uploadedImageDescription, setUploadedImageDescription] = useState("");
    const [activeImage, setActiveImage] = useState<ImageType | null>(null);
    const [exampleImages, setExampleImages] = useState<ImageType[]>([])

    const stateImages = useAppSelector(selectImages).images[useParams().groupid ?? ""];

    GetImages()

    useEffect(() => {
        if (isDeleteImageSuccess) {
            toast.success("Image Deleted");
        }
    }, [isDeleteImageSuccess])

    useEffect(() => {
        if (isUploadImageSuccess) {
            toast.success("Image Uploaded");
        }
    }, [isUploadImageSuccess])

    useEffect(() => {
        if (stateImages !== undefined) {
            var tempExampleImages: ImageType[] = [];

            _.map(_.entries(stateImages), (image: [string, { image_id: string; description: string; url: string; }]) => {
                tempExampleImages.push({
                    id: image[0],
                    name: image[1].description,
                    description: image[1].description,
                    url: ("https://rpr-iot.fra1.digitaloceanspaces.com/" + "group_images/" + image[1].url),
                });
            });

            setExampleImages(tempExampleImages);
        }
    }, [stateImages]);

    const editImageModal = () => {
        return (
            <GroupObjectModal
                onRemove={() => {
                    if (activeImage !== null && exampleImages.length > 0) {
                        setExampleImages(exampleImages.filter((image) => image.id !== activeImage.id));
                    }
                    deleteImage({ group_id: groupid, image_id: activeImage!.id });
                    setShowEditImageModal(false);
                }}
                onAccept={() => {
                    setShowEditImageModal(false);
                }}
                onCancel={() => {
                    setShowEditImageModal(false);
                }}
                Title={"Edit " + (activeImage?.name ?? "Image")}
                Body={
                    <>
                        {!(_.isEqual(activeImage, null)) && (
                            <div className="flex flex-col items-center justify-center p-2 mr-2 bg-white border border-gray-200 rounded-lg shadow-sm">
                                <div className="flex items-center justify-center bg-gray-100 rounded-full">
                                    <img src={activeImage!.url} alt="" />
                                </div>
                                <div className="mt-2 text-sm font-medium text-gray-900">
                                    {activeImage!.name}
                                </div>
                                {/* <div className="mt-1 text-sm text-gray-500">
                                {image.description}
                            </div> */}
                            </div>
                        )}
                    </>
                }
                AcceptButton="Save"
                CancelButton="Close"
                RemoveButton="Delete"
                loading={false}
            />
        )
    }

    const AddImageModal = () => {
        return (
            <AcceptModal
                onAccept={() => {
                    if (uploadedImage !== null) {
                        uploadImage({ group_id: groupid, file: uploadedImage, description: uploadedImageDescription });
                    }
                    setShowAddImageModal(false);
                }}
                onCancel={() => {
                    setShowAddImageModal(false);
                }}
                Title={"Add new Image"}
                Body={
                    <>
                        <form
                            className="w-full"
                            onClick={() => {
                                let input = document.querySelector(".input-field") as HTMLInputElement;
                                (input !== null && input instanceof HTMLElement) && input.click();
                            }}
                        >
                            <input type="file" accept='image/*' className='input-field' hidden
                                onChange={(e) => {
                                    if (e.target.files !== null && e.target.files[0]) {
                                        setUploadedImage(e.target.files[0]);
                                    }
                                }}
                            />
                            {uploadedImage !== null ? (
                                <div className="flex flex-col items-center justify-center min-w-[300px] min-h-[200px] p-2 mr-2 bg-white border border-gray-200 rounded-lg shadow-sm">
                                    <div className="flex items-center justify-center bg-gray-100 rounded-full">
                                        <img src={URL.createObjectURL(uploadedImage)} alt="" />
                                    </div>
                                </div>
                            ) : (
                                <div className="flex flex-col items-center justify-center min-w-[300px] min-h-[200px] p-2 mr-2 bg-white border border-gray-200 rounded-lg shadow-sm">
                                    <div className="flex items-center justify-center bg-gray-100 rounded-full">
                                        <MdAdd size={48} color="rgb(156, 163, 175)" />
                                    </div>
                                </div>
                            )}
                        </form>
                        <FormInput
                            htmlFor="name"
                            label={"Image Description"}
                            value={uploadedImageDescription}
                            onChange={(event) => {
                                setUploadedImageDescription(event.target.value);
                            }}
                        />
                    </>
                }
                AcceptButton="Upload"
                CancelButton="Cancel"
                loading={false}
            />
        )
    }

    const Modals = () => {
        return (
            <>
                {showEditImageModal && editImageModal()}
                {showAddImageModal && AddImageModal()}
            </>
        )
    }

    return (
        <>
            <div>
                {Modals()}
            </div>
            <div className="mb-1">
                <h1 className="text-xl font-semibold text-gray-900">
                    {"Images"}
                </h1>
                <div className="flex flex-row overflow-x-scroll scrollbar-thin pr-2">
                    {exampleImages.map((image) => (
                        <div className="flex flex-col items-center justify-center p-2 mr-2 bg-white border border-gray-200 rounded-lg shadow-sm" onClick={() => { setActiveImage(image); setShowEditImageModal(true); }}>
                            <div className="flex items-center justify-center w-32 h-32 bg-gray-100 rounded-full">
                                <img src={image.url} alt="" className="w-full h-full object-cover rounded-md" />
                            </div>
                            <div className="mt-2 text-sm font-medium text-gray-900 truncate max-h-5 max-w-[8rem]">
                                {image.name}
                            </div>
                        </div>
                    ))}
                    {isUploadImageLoading && (
                        <div className="flex flex-col items-center justify-center p-2 mr-2 bg-white border border-gray-200 rounded-lg shadow-sm" onClick={() => { setUploadedImage(null); setUploadedImageDescription(""); setShowAddImageModal(true); }}>
                            <div className="flex items-center justify-center w-32 h-32 bg-gray-200 rounded-full animate-pulse">
                            </div>
                            <div className="mt-2 text-sm font-medium text-gray-900 truncate max-h-5 max-w-[8rem]">
                                {"Uploading"}
                            </div>
                        </div>
                    )}
                    {exampleImages.length < 6 ? (
                        <div className="flex flex-col items-center justify-center p-2 bg-white border border-gray-200 rounded-lg shadow-sm" onClick={() => { setUploadedImage(null); setUploadedImageDescription(""); setShowAddImageModal(true); }}>
                            <div className="flex items-center justify-center w-32 h-32 bg-gray-100 rounded-full">
                                <MdAdd size={48} color="rgb(156, 163, 175)" />
                            </div>
                            <div className="mt-2 text-sm font-medium text-gray-900 truncate max-h-5 max-w-[8rem]">
                                {"Add Image"}
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col items-center justify-center p-2 bg-white border border-gray-200 rounded-lg shadow-sm">
                            <div className="flex items-center justify-center w-40 h-40 bg-gray-100 rounded-full text-center">
                                {"Max Images Reached. Upgrade subscription or delete an image to add more."}
                            </div>
                        </div>
                    )}
                </div>
            </div >
        </>
    );
}

export default GroupManageImages;
