import { ColumnDef } from "@tanstack/react-table";
import _ from "lodash";
import { useEffect, useState } from "react";
import { IoMdSettings } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import {
  Dashboard,
  selectDashboards,
} from "../../features/dashboards/dashboardSlice";
import {
  useDeleteDashboardMutation,
  useGetDashboardsQuery,
} from "../../services/dashboardAPI";
import { DataTimeToLocale } from "../../utils/DateFormating";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../dictionary/DashboardText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage, selectUser } from "../../features/user/userSlice";
import { Table } from "../../utils/Table";
import { useGetGroupsQuery } from "../../services/groupAPI";
import { Button } from "../../components/Button";
import { UserGroup } from "../../services/userAPI";
import AcceptModal from "../../components/modals/AcceptModal";
import { selectGroups } from "../../features/groups/groupSlice";

const defaultData: Dashboard[] = [];

function DashboardTable() {
  const { dashboards: dashboardInfo } = useAppSelector(selectDashboards);
  const [
    deleteDashboard,
    {
      isLoading: isDeleteDashboardLoading,
      isSuccess: isDeleteDashboardSuccess,
    },
  ] = useDeleteDashboardMutation();
  const { groups: groupInfo } = useAppSelector(selectGroups);
  const [data, setData] = useState([...defaultData]);
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  const user = useAppSelector(selectUser);
  const [showDeleteDashboardModal, setShowDeleteDashboardModal] =
    useState(false);
  const [deleteDashboardId, setDeleteDashboardId] = useState("");
  const [deleteDashboardName, setDeleteDashboardName] = useState("");

  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  useEffect(() => {
    if (dashboardInfo) {
      const dashboards = _.values(dashboardInfo);
      setData([...dashboards]);
    }
    return () => {};
  }, [dashboardInfo]);

  let navigate = useNavigate();

  const columns: ColumnDef<Dashboard>[] = [
    {
      accessorFn: (row) => row.name,
      id: "dashboardName",
      cell: ({ row, getValue }) => (
        <div className="flex ">
          <IoMdSettings
            title={language.dashboardTable.tableElements.quickEdit}
            size={16}
            className="cursor-pointer hover:fill-black"
            onClick={() => {
              if (data) {
                navigate(`/dashboard/${data[row.index].id}`, {
                  state: row.original,
                });
              }
            }}
          />
          <div className="ml-2">{getValue() as string}</div>
        </div>
      ),
      header: language.dashboardTable.tableElements.headers.dashboardName,
    },

    {
      accessorFn: (row) => row.description,
      id: "dashboardDescription",
      cell: ({ row, getValue }) => getValue(),
      header: language.dashboardTable.tableElements.headers.description,
    },
    /* {
      accessorFn: (row) => DataTimeToLocale(new Date(row.updated_at ?? 0)),
      id: "lastUpdated",
      cell: ({ row, getValue }) => getValue(),
      header: language.dashboardTable.tableElements.headers.lastUpdated,
    }, */

    {
      accessorFn: (row) =>
        _.map(Object.values(groupInfo ?? [])).find(
          (group) => group.id === row.group_id
        )?.name ?? "",
      id: "DashboardGroup",
      cell: ({ row, getValue }) => getValue(),
      header: language.dashboardTable.tableElements.headers.group,
    },

    {
      accessorFn: (row) => row.id,
      id: "delete",
      cell: ({ row, getValue }) => (
        <div className="w-fit ml-2">
          {user.groups[data[row.index].group_id as keyof UserGroup].permissions
            .can_remove_dashboards === true && (
            <Button
              onClick={() => {
                console.log(getValue());
                setDeleteDashboardId(data[row.index].id);
                setShowDeleteDashboardModal(true);
                setDeleteDashboardName(data[row.index].name);
              }}
              label={language.dashboardTable.tableElements.buttons.delete}
              colour="red"
            />
          )}
        </div>
      ),
      header: language.dashboardTable.tableElements.headers.deleteDashboard,
    },
  ];

  var table: JSX.Element = <></>;

  //@ts-ignore
  table = Table(dashboardInfo, columns, language.dashboardTable.dashboards, language.dashboardTable.tableElements.noDashboardsFound, false, "DashboardGroup");

  return (
    <>
      {showDeleteDashboardModal ? (
        <AcceptModal
          onAccept={() => {
            deleteDashboard({ id: deleteDashboardId ?? "" });
          }}
          onCancel={() => {
            setShowDeleteDashboardModal(false);
          }}
          Title={language.dashboardTable.modals.confirmDelete.title}
          Body={
            <>
              {language.dashboardTable.modals.confirmDelete.phrase1}
              {deleteDashboardName ? (
                <b>{deleteDashboardName}</b>
              ) : (
                language.dashboardTable.modals.confirmDelete.thisDashboard
              )}
              {language.dashboardTable.modals.confirmDelete.phrase2}
            </>
          }
          AcceptButton={language.dashboardTable.modals.confirmDelete.buttons.delete}
          AcceptButtonColour={"red"}
          CancelButton={language.dashboardTable.modals.confirmDelete.buttons.cancel}
          loading={isDeleteDashboardLoading}
        />
      ) : null}
      {table}
    </>
  );
}

export default DashboardTable;
