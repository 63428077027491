import { useAcceptGroupTransferMutation } from "../../services/groupAPI";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function GroupTransfer() {

    const navigate = useNavigate();

    const urlParams = new URLSearchParams(window.location.search);
    const groupID = urlParams.get('groupID');
    const token = urlParams.get('token');

    const [
        acceptTransfer,
        {
            isSuccess: isTransferSuccess,
            isLoading: isTransferLoading,
            error: transferError
        },
    ] = useAcceptGroupTransferMutation();

    useEffect(() => {
        if (groupID && token) {
            acceptTransfer({ group_id: groupID, token: token })
        }
    }, [groupID, token])

    useEffect(() => {
        if (isTransferSuccess) {
            toast.success("Group Transferred!")
            navigate(`/group/${groupID}`);
        }
    }, [isTransferSuccess])

    return <></>
}

export default GroupTransfer