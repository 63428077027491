import { ChartType } from ".././index";
import _ from "lodash";
import SingleDeviceTable from "./SingleDeviceTable";
import ComparisonTable from "./ComparisonTable";

type Props = {
  ChartData: ChartType;
  FetchSuccess: boolean;
  FetchError: boolean;
};

function Table({ ChartData, FetchSuccess, FetchError }: Props) {
  return (
    ChartData.chartScale?.y_axis.min_auto === true ? (
      <SingleDeviceTable ChartData={ChartData} FetchSuccess={FetchSuccess} FetchError={FetchError} />
    ) : (
      <ComparisonTable ChartData={ChartData} FetchSuccess={FetchSuccess} FetchError={FetchError} />
    )
  )
}

export default Table;

