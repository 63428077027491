export type LanguageText = {
    table: {
        searchAllColumns: string;
        goToPage: string;
        show: string;
    }
};
export const English: LanguageText = {
    table: {
        searchAllColumns: "Search All Columns",
        goToPage: "Go to Page",
        show: "Show"
    }
};
export const French: LanguageText = {
    table: {
        searchAllColumns: "Rechercher dans toutes les colonnes",
        goToPage: "Aller à la page",
        show: "Afficher",
    }
};
export const German: LanguageText = {
    table: {
        searchAllColumns: "Alle Spalten durchsuchen",
        goToPage: "Weiter zur Seite",
        show: "Anzeigen",
    }
};
export const Dutch: LanguageText = {
    table: {
        searchAllColumns: "Doorzoek alle kolommen",
        goToPage: "Ga naar de pagina",
        show: "Toon",
    }
};
export const Spanish: LanguageText = {
    table: {
        searchAllColumns: "Buscar en todas las columnas",
        goToPage: "Ir a la página",
        show: "Mostrar",
    }
};
export const Italian: LanguageText = {
    table: {
        searchAllColumns: "Cerca in tutte le colonne",
        goToPage: "Vai alla pagina",
        show: "Mostra",
    }
};
export const Romanian: LanguageText = {
    table: {
        searchAllColumns: "Căutați toate coloanele",
        goToPage: "Mergi la pagina",
        show: "Arată",
    }
};
export const Portuguese: LanguageText = {
    table: {
        searchAllColumns: "Pesquisar todas as colunas",
        goToPage: "Ir para a página",
        show: "Mostrar",
    }
};