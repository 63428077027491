import { JSXElementConstructor, Key, ReactElement, ReactFragment, ReactPortal, useEffect, useState } from "react";
import { FormInput } from "../../../FormInput";
import { MarkerData } from "..";
import _, { get, toNumber } from "lodash";
import { ChartType } from "..";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../../../dictionary/ChartsText";
import { LanguageCheck } from "../../../../utils/LanguageCheck";
import { useAppSelector } from "../../../../app/hooks";
import { selectLanguage } from "../../../../features/user/userSlice";
import { useParams } from "react-router";
import { Device, PhenomenaConfiguration, selectDevices } from "../../../../features/devices/deviceSlice";
import { Dashboards, selectDashboards } from "../../../../features/dashboards/dashboardSlice";
import { MdAdd, MdAddLocation, MdGpsFixed, MdLocationOff, MdOutlineDelete } from "react-icons/md";
import { FaMapMarkerAlt, FaSearchLocation } from "react-icons/fa";
import Spinner from "../../../Spinner";
import { BsArrowsMove } from "react-icons/bs";
import { Button } from "../../../Button";
import { EditChart, EditChartProps } from "../EditChart";
import { MarkerBox } from "./NewMarkerMenu";
import { useGetGroupImagesQuery, useUploadImageMutation } from "../../../../services/groupAPI";
import GroupObjectModal from "../../../modals/GroupObjectModal";
import AcceptModal from "../../../modals/AcceptModal";
import { selectImages } from "../../../../features/images/imageSlice";
import { selectGroups } from "../../../../features/groups/groupSlice";
import { toast } from "react-toastify";

type Props = {
  id: string;
  data: ChartType;
  remove: (id: string) => void;
  updateChart: (data: ChartType) => void;
  setShowChartEdit: (isOpen: boolean) => void;
};

function EditImageMap({ id, remove, updateChart, data, setShowChartEdit }: Props) {
  type FormInputData = {
    optionValue: string;
    optionName: string;
  };

  type ImageType = {
    id: string;
    name: string;
    description: string;
    url: string;
  };

  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  const [activeImage, setActiveImage] = useState<ImageType | null>(null);

  const [markerList, setMarkerList] = useState<MarkerData[]>(
    data.chartData ?? []
  );

  const [title, setTitle] = useState(data.title.en ?? "");
  const [imageURL, setImageURL] = useState("");
  const [loading, setLoading] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [userDeviceData, setUserDeviceData] = useState<FormInputData[]>([]);
  const [imgWidth, setImgWidth] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);
  const [showAddImageModal, setShowAddImageModal] = useState(false);
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const [uploadedImageDescription, setUploadedImageDescription] = useState("");
  const [exampleImages, setExampleImages] = useState<ImageType[]>([]);

  let { dashboardid } = useParams();
  let { devices } = useAppSelector(selectDevices);
  let { dashboards } = useAppSelector(selectDashboards);
  let { groups } = useAppSelector(selectGroups);

  var groupid = dashboards[dashboardid as keyof Dashboards].group_id;

  const stateImages = useAppSelector(selectImages).images[groupid];

  function GetImageURLShowControlsAndMarkerListFromParams() {
    setImageURL(data.chartConfig?.imageURL ?? "");
    setShowControls(data.chartScale?.y_axis.min_auto ?? false)
    setMarkerList(data.chartData ?? []);
  }

  function getActiveImageDimensions() {
    const img = new Image();
    img.src = imageURL ?? "";
    img.onload = () => {
      setImgWidth(img.width);
      setImgHeight(img.height);
    };
  }

  function populateDeviceSelect() {
    var tempUserDeviceData: FormInputData[] = [{
      optionValue: "new",
      optionName: language.charts.gauge.inputDefaults.selectDevice,
    }];
    const dashboardDevices: Device[] = _.filter(devices, (device: Device) => {
      return device.group_id === groupid;
    });
    _.map(dashboardDevices, (device: Device) => {
      if (device.eui !== null && device.name !== null) {
        tempUserDeviceData.push({
          optionValue: device.eui,
          optionName: device.name,
        });
      }
    });
    setUserDeviceData(tempUserDeviceData);
  }

  useEffect(() => {
    GetImageURLShowControlsAndMarkerListFromParams();
  }, [data]);

  useEffect(() => {
    getActiveImageDimensions();
  }, [imageURL]);

  useEffect(() => {
    populateDeviceSelect();
  }, [devices, dashboardid, dashboards]);

  const makeSelected = (index: number) => {
    let newMarkerList = [...markerList];
    newMarkerList.slice(0, newMarkerList.length).map((itemT, indexT) => {
      newMarkerList[indexT] = { ...itemT, selected: false };
      if (index === indexT) {
        newMarkerList[indexT] = { ...itemT, selected: true };
      }
    });
    setMarkerList(newMarkerList);
    saveChartSettings({ close: true, chartData: newMarkerList });
  };

  const toggleShowUnit = (index: number) => {
    let newMarkerList = [...markerList];
    newMarkerList.slice(0, newMarkerList.length).map((itemT, indexT) => {
      if (index === indexT) {
        newMarkerList[indexT] = { ...itemT, showUnit: !itemT.showUnit };
      }
    });
    setMarkerList(newMarkerList);
  };

  const updateMarker = ({ deviceID, elemID, lng, lat, label, showUnit, phenomena, colour, draggable, selected, }: MarkerData, index: number) => {
    let newMarkerList = [...markerList];
    newMarkerList.slice(0, newMarkerList.length).map((itemT, indexT) => {
      newMarkerList[indexT] = { ...itemT, selected: false };
      if (index === indexT) {
        newMarkerList[indexT] = { ...itemT, deviceID, elemID, lng, lat, label, showUnit, phenomena, colour, draggable, selected };
      }
    });
    setMarkerList(newMarkerList);
  };

  const addNewMarker = ({ deviceID, elemID, lng, lat, label, showUnit, phenomena, colour, draggable, selected, }: MarkerData) => {
    var newMarkerList = (_.concat(markerList, { deviceID, elemID, lng, lat, label, showUnit, phenomena, colour, draggable, selected, }))
    setMarkerList(newMarkerList)
    saveChartSettings({ close: false, chartData: newMarkerList });
  };

  const removeMarker = (index: number) => {
    setMarkerList(markerList.filter((_, i) => i !== index));
  };

  const [
    uploadImage,
    {
      isLoading: isUploadImageLoading,
      isSuccess: isUploadImageSuccess,
    },
  ] = useUploadImageMutation();

  useEffect(() => {
    if (isUploadImageSuccess) {
      toast.success("Image uploaded!");
    }
  }, [isUploadImageSuccess]);

  function GetImages() {
    useGetGroupImagesQuery({ group_id: groupid });
  }

  GetImages();

  function populateExampleImages() {
    if (stateImages !== undefined) {
      var tempExampleImages: ImageType[] = [];
      _.map(_.entries(stateImages), (image: [string, { image_id: string; description: string; url: string; }]) => {
        tempExampleImages.push({
          id: image[0],
          name: image[1].description,
          description: image[1].description,
          url: ("https://rpr-iot.fra1.digitaloceanspaces.com/" + "group_images/" + image[1].url),
        });
      });
      setExampleImages(tempExampleImages);
    }
  }

  useEffect(() => {
    populateExampleImages();
  }, [stateImages]);

  const AddImageModal = () => {
    return (
      <AcceptModal
        onAccept={() => {
          if (uploadedImage !== null) {
            uploadImage({ group_id: groupid, file: uploadedImage, description: uploadedImageDescription });
          }
          setShowAddImageModal(false);
        }}
        onCancel={() => {
          setShowAddImageModal(false);
        }}
        Title={"Add new Image"}
        Body={
          <>
            <form
              onClick={() => {
                let input = document.querySelector(".input-field") as HTMLInputElement;
                (input !== null && input instanceof HTMLElement) && input.click();
              }}
            >
              <input type="file" accept='image/*' className='input-field' hidden
                onChange={(e) => {
                  if (e.target.files !== null && e.target.files[0]) {
                    setUploadedImage(e.target.files[0]);
                  }
                }}
              />
              {uploadedImage !== null ? (
                <div className="flex flex-col items-center justify-center min-w-[300px] min-h-[200px] p-2 mr-2 bg-white border border-gray-200 rounded-lg shadow-sm">
                  <div className="flex items-center justify-center bg-gray-100 rounded-full">
                    <img src={URL.createObjectURL(uploadedImage)} alt="" />
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center min-w-[300px] min-h-[200px] p-2 mr-2 bg-white border border-gray-200 rounded-lg shadow-sm">
                  <div className="flex items-center justify-center bg-gray-100 rounded-full">
                    <MdAdd size={48} color="rgb(156, 163, 175)" />
                  </div>
                </div>
              )}
            </form>
            <FormInput
              htmlFor="name"
              label={"Image Description"}
              value={uploadedImageDescription}
              onChange={(event) => {
                setUploadedImageDescription(event.target.value);
              }}
            />
          </>
        }
        AcceptButton="Upload"
        CancelButton="Cancel"
        loading={false}
      />
    )
  }

  const Modals = () => {
    return (
      <>
        {showAddImageModal && AddImageModal()}
      </>
    )
  }

  const imageSelectBox = (
    <>
      {getCanGroupUseImages() ? (
        <div className="flex flex-row overflow-x-scroll scrollbar-thin pr-2">
          {exampleImages.map((image) => (
            <div className={`flex flex-col items-center justify-center p-2 mr-2 bg-white border rounded-lg shadow-sm ${activeImage?.id === image.id ? "border-black" : "border-gray-200"}`} onClick={() => { setActiveImage(image); setImageURL(image.url) }}>
              <div className="flex items-center justify-center w-32 h-32 bg-gray-100 rounded-full">
                <img src={image.url} alt="" className="w-full h-full object-cover rounded-md" />
              </div>
              <div className="mt-2 text-sm font-medium text-gray-900 truncate max-h-5 max-w-[8rem]">
                {image.name}
              </div>
            </div>
          ))}
          {isUploadImageLoading && (
            <div className="flex flex-col items-center justify-center p-2 mr-2 bg-white border border-gray-200 rounded-lg shadow-sm" onClick={() => { setUploadedImage(null); setUploadedImageDescription(""); setShowAddImageModal(true); }}>
              <div className="flex items-center justify-center w-32 h-32 bg-gray-200 rounded-full animate-pulse">
              </div>
              <div className="mt-2 text-sm font-medium text-gray-900 truncate max-h-5 max-w-[8rem]">
                {"Uploading"}
              </div>
            </div>
          )}
          {exampleImages.length < 6 ? (
            <div className="flex flex-col items-center justify-center p-2 bg-white border border-gray-200 rounded-lg shadow-sm" onClick={() => { setUploadedImage(null); setShowAddImageModal(true); }}>
              <div className="flex items-center justify-center w-32 h-32 bg-gray-100 rounded-full">
                <MdAdd size={48} color="rgb(156, 163, 175)" />
              </div>
              <div className="mt-2 text-sm font-medium text-gray-900 truncate max-h-5 max-w-[8rem]">
                {"Add Image"}
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center p-2 bg-white border border-gray-200 rounded-lg shadow-sm">
              <div className="flex items-center justify-center w-40 h-40 bg-gray-100 rounded-full text-center">
                {"Max Images Reached. Upgrade subscription or delete an image to add more."}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="mb-1 relative">
          <div className="w-full h-full absolute bg-black opacity-10 z-3 p-2 rounded-md">
          </div>
          <div className="p-2">
            <div className="flex flex-row overflow-x-scroll scrollbar-thin pr-2">
              <div className="flex flex-col items-center justify-center p-2 bg-white border border-gray-200 rounded-lg shadow-sm">
                <div className="flex items-center justify-center w-40 h-40 bg-gray-100 rounded-full text-center">
                  {"Subscription needed to use images."}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

  const settingsBox = (
    <div className="p-2 my-1 space-y-1 flex w-full border-4 bg-gray-200 scrollbar-none rounded-lg flex-col border-gray-200">
      <div>
        {Modals()}
      </div>
      <div>
        <FormInput
          htmlFor="name"
          label={"Title"}
          value={title}
          onChange={(event) => {
            setTitle(event.target.value);
          }}
        />
      </div>
      <div className="mb-1">
        {imageSelectBox}
      </div>
      <div className="my-1 h-px bg-gray-400 "></div>
      <div>
        <div className="flex flex-row items-center space-x-1">
          {language.charts.map.imageMap.showControls}
          <div className="w-1" />
          <input
            onChange={() => {
              setShowControls(!showControls);
            }}
            checked={showControls}
            type="checkbox"
            className="bg-indigo-50 px-2 py-2 outline-none rounded-md mr-2 hover:cursor-pointer"
          />
        </div>
      </div>
    </div>
  );

  const itemList: (JSX.Element | undefined)[] = (
    markerList.slice(0, markerList.length).map((item, index) => {
      if (markerList.length > 0) {
        return (
          <MarkerBox
            marker={item}
            index={index}
            key={index}
            userDeviceData={userDeviceData}
            showCoords={false}
            mapType="image"
            updateMarker={updateMarker}
            removeMarker={removeMarker}
            toggleShowUnit={toggleShowUnit}
            makeSelected={makeSelected}
          />
        );
      }
    })
  );

  const newButton: { text: string; onClick: () => void; icon: JSX.Element; } = {
    text: language.charts.map.landMap.buttons.newMarker,
    onClick: () => {
      addNewMarker({
        deviceID: "new",
        elemID: "0",
        lat: imgWidth / 2,
        lng: imgHeight / 2,
        label: "",
        phenomena: "new",
        colour: pickRandomBrightColour(),
        draggable: false,
        selected: false,
      })
    },
    icon: <MdAddLocation size={40} className="bg-white opacity-0 group-hover:opacity-70 absolute -ml-[6px] -mt-5 h-16 w-[calc(100%+12px)]" />,
  }

  function onDelete() {
    remove(id);
    setShowChartEdit(false);
  }

  function saveChartSettings({ close, chartData }: { close: boolean; chartData: MarkerData[] | undefined }) {
    updateChart({
      i: id,
      type: "imageMap",
      title: { en: title },
      chartData: chartData ? chartData : _.filter(markerList, (item) => item.deviceID !== "new"),
      chartScale: { ...data.chartScale, y_axis: { min_auto: showControls, max_auto: data.chartScale?.y_axis.max_auto ?? false, max: data.chartScale?.y_axis.max ?? 0, min: data.chartScale?.y_axis.min ?? 0 } },
      chartConfig: { ...data.chartConfig, imageURL: imageURL },
    });
    if (close) {
      if (chartData) {
        setShowChartEdit(false);
      } else {
        setLoading(true)
        setTimeout(function () {
          setShowChartEdit(false);
          setLoading(false)
        }, 300)
      }
    }
  };

  const EditChartObjects: EditChartProps = ({
    editMapType: language.charts.map.landMap.editMap,
    settingsBox: settingsBox,
    itemList: itemList,
    newButton: newButton,
    onDelete: onDelete,
    onSave: (chartData) => { saveChartSettings({ close: true, chartData: chartData }) },
    loading: loading,
  })

  return (
    <EditChart {...EditChartObjects} />
  );

  function pickRandomBrightColour(): string {
    const colours = ["#FF0000", "#00FF00", "#0000FF", "#FFFF00", "#00FFFF", "#FF00FF", "#000dff", "#00ff6f", "#ffd000", "#ff5e00", "#00fffb"];
    return colours[Math.floor(Math.random() * colours.length)];
  }

  function getCanGroupUseImages() {
    if (groups?.[groupid as keyof typeof groups]?.subscription?.plan_id === "649562c4b9b08a7e359d6876") {
      return false;
    } else {
      return true;
    }
  }
}

export default EditImageMap;
