import { rankItem } from "@tanstack/match-sorter-utils";
import {
  FilterFn,
  SortingState,
  ColumnFiltersState,
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  flexRender,
} from "@tanstack/react-table";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  MdFirstPage,
  MdNavigateBefore,
  MdNavigateNext,
  MdLastPage,
} from "react-icons/md";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { useAppSelector } from "../app/hooks";
import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
} from "../dictionary/UtilsText";
import { selectLanguage } from "../features/user/userSlice";
import { LanguageCheck } from "../utils/LanguageCheck";
import {
  GroupDevice,
  GroupUser,
  Groups,
  InvitedUser,
  Invoice,
} from "../features/groups/groupSlice";
import { Dashboard } from "../features/dashboards/dashboardSlice";
import { Devices } from "../features/devices/deviceSlice";
import { NotificationState } from "../features/notifications/notificationSlice";

export function Table(
  itemInfo:
    | Groups
    | Devices
    | (GroupUser | InvitedUser)[]
    | { [key: string]: GroupDevice }
    | Dashboard[]
    | Invoice[]
    | undefined
    | NotificationState[],
  columns: ColumnDef<any>[],
  title: string | JSX.Element,
  noResults: string,
  tabs?: boolean,
  defaultSort?: string
): JSX.Element {
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(
    LanguageCheck(
      English,
      French,
      German,
      Dutch,
      Spanish,
      Italian,
      Romanian,
      Portuguese,
      stateLang
    )
  );
  useEffect(() => {
    setLanguage(
      LanguageCheck(
        English,
        French,
        German,
        Dutch,
        Spanish,
        Italian,
        Romanian,
        Portuguese,
        stateLang
      )
    );
  }, [stateLang]);

  const defaultData: any[] = [];
  const [sorting, setSorting] = useState<SortingState>(
    defaultSort
      ? [
          {
            id: defaultSort,
            desc: false,
          },
        ]
      : []
  );
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [data, setData] = useState([...defaultData]);

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta(itemRank);
    return itemRank.passed;
  };

  useEffect(() => {
    if (itemInfo) {
      const items = _.values(itemInfo);
      setData([...items]);
    }
    return () => {};
  }, [itemInfo]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters,
      globalFilter,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  const returnTable = (
    <div className="flex h-full rounded bg-white select-none">
      <div className="relative sm:rounded-lg w-full scrollbar-thin flex justify-between flex-col">
        <div className="flex flex-col">
          <div className="flex justify-between">
            <h1 className="sm:ml-6 ml-2 text-xl font-bold flex items-center">{title}</h1>
            <div>
              <DebouncedInput
                value={globalFilter ?? ""}
                onChange={(value) => setGlobalFilter(String(value))}
                className="p-2 m-2 font-lg border border-block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 w-44"
                placeholder={language.table.searchAllColumns + "..."}
              />
            </div>
          </div>
          <div className={`${tabs === true ? "" : ""} overflow-y-scroll scrollbar-thin `}>
            <table className="w-full text-left text-sm text-gray-500 table-auto">
              <thead className="bg-gray-50 text-xs uppercase text-gray-700 min-h-table-header">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          className="px-6 py-3"
                        >
                          {header.isPlaceholder ? null : (
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? "cursor-pointer select-none flex flex-row"
                                  : "",
                                onClick: header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: <TiArrowSortedUp size={16} />,
                                desc: <TiArrowSortedDown size={16} />,
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.length !== 0 ? (
                  table
                    .getRowModel()
                    .rows //.slice(0, 10)
                    .map((row) => {
                      return (
                        <tr key={row.id} className="hover:bg-gray-50  bg-white ">
                          {row.getVisibleCells().map((cell) => {
                            return (
                              <td key={cell.id} className="px-6 py-4">
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                ) : (
                  <tr>
                    <td colSpan={columns.length} className="text-center">
                      {noResults}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="m-2">
          <div className="h-2" />
          <div className="flex flex-wrap justify-center sm:justify-start">
            <div className="flex items-center gap-2">
              <button
                className="border rounded p-1"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                <MdFirstPage
                  size={24}
                  fill={!table.getCanPreviousPage() ? "grey" : "black"}
                />
              </button>
              <button
                className="border rounded p-1"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                <MdNavigateBefore
                  size={24}
                  fill={!table.getCanPreviousPage() ? "grey" : "black"}
                />
              </button>
              <button
                className="border rounded p-1"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                <MdNavigateNext
                  size={24}
                  fill={!table.getCanNextPage() ? "grey" : "black"}
                />
              </button>
              <button
                className="border rounded p-1"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                <MdLastPage size={24} fill={!table.getCanNextPage() ? "grey" : "black"} />
              </button>
              <span className="flex items-center gap-1">
                <strong>
                  {table.getState().pagination.pageIndex + 1}/{table.getPageCount()}
                </strong>
              </span>
            </div>
            <div className="flex sm:ml-2">
              <span className="flex items-center gap-1">
                {language.table.goToPage}:
                <input
                  type="number"
                  max={table.getPageCount()}
                  min={1}
                  defaultValue={table.getState().pagination.pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    table.setPageIndex(page);
                  }}
                  className="rounded-xl border-1 border-gray-300 w-16 mr-2"
                />
              </span>
              <select
                value={table.getState().pagination.pageSize}
                className="rounded-xl border-1 border-gray-300"
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {language.table.show} {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return returnTable;
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />;
}
