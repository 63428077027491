import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  ChangeEvent,
  MouseEvent,
} from "react";
import { useParams } from "react-router-dom";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { Tooltip } from "flowbite-react";
import { toast } from "react-toastify";
import { LanguageText } from "../../dictionary/GroupNotificationText";
import { useAppSelector } from "../../app/hooks";
import { selectDashboards } from "../../features/dashboards/dashboardSlice";
import useGetLanguage from "../../utils/useGetLanguage";
import { mapObjectToArray } from "../../utils/GroupNotificationUtils";

export interface NotificationDashboardRef {
  getNotificationDashboard: () => { dashboard: string };
  validate: () => boolean;
}

interface NotificationDashboardProps {
  dashboard?: string;
}

const NotificationDashboard = forwardRef(
  ({ dashboard }: NotificationDashboardProps, ref): JSX.Element => {
    const { groupid } = useParams();
    const { dashboards } = useAppSelector(selectDashboards);
    const groupDashboards = mapObjectToArray(
      Object.fromEntries(
        Object.entries(dashboards).filter(([key, value]) => value.group_id === groupid)
      )
    );
    const foundDashboard = groupDashboards.find(
      (groupDashboard) => groupDashboard.id === dashboard
    );

    !foundDashboard && (dashboard = "");

    const language: LanguageText | null = useGetLanguage({
      fileName: "GroupNotificationText",
    });

    const [notificationDashboard, setNotificationDashboard] = useState(dashboard);
    const [error, setError] = useState<boolean>(false);

    const handleDashboardSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;
      value && setNotificationDashboard(value);
    };
    const handleRemoveDashboard = (event: MouseEvent<HTMLButtonElement>) => {
      setNotificationDashboard("");
    };

    useImperativeHandle(ref, () => ({
      getNotificationDashboard: () => ({
        dashboard: notificationDashboard,
      }),
      validate: () => {
        return true;
      },
    }));

    //  console.log("Current Dashboard", notificationDashboard);

    return (
      <div className="relative mb-6 bg-gray-100 p-10 rounded-lg mt-10">
        <h1 className="text-sm font-semibold text-left text-gray-800 leading-tight mb-2">
          Dashboard
        </h1>
        <Tooltip
          content="Associate a Dashboard with notification"
          placement="top-end"
          style="light"
          trigger="hover"
        >
          <BsFillInfoCircleFill
            size={20}
            color="grey"
            className="absolute top-2 left-2 cursor-pointer mb-5"
          />
        </Tooltip>
        <div className="flex items-center w-full">
          <select
            className="border border-gray-300 rounded p-2 mr-4 mb-4 w-1/2 max-w-full max-h-20 overflow-y-auto"
            name="selectInput"
            value={notificationDashboard}
            onChange={handleDashboardSelectChange}
          >
            <option className="w-1/2 max-w-full" value="">
              Select a dashboard
            </option>
            {groupDashboards && groupDashboards.length > 0 ? (
              groupDashboards.map((dashboard: any) => {
                return (
                  <option
                    key={dashboard.id}
                    value={dashboard.id}
                    className="w-1/2 max-w-full"
                  >
                    {`${dashboard.name} | ${dashboard.description}`}
                  </option>
                );
              })
            ) : (
              <option className="w-1/2 max-w-full" value="">
                No dashboard Found
              </option>
            )}
          </select>
          {notificationDashboard && (
            <button
              className="ml-0 mb-4 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-md shadow-lg transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-red-400"
              onClick={handleRemoveDashboard}
            >
              Remove
            </button>
          )}
        </div>
      </div>
    );
  }
);

export default NotificationDashboard;
