import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormInput } from "../../components/FormInput";
import GroupObjectModal from "../../components/modals/GroupObjectModal";
import {
  useInviteUserToGroupMutation,
} from "../../services/groupAPI";
import { validateEmail } from "../../utils/FormValidation";
import { handleError } from "../../utils/ErrorHandling";
import type { UserPermissions } from "../../features/groups/groupSlice";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../features/user/userSlice";
import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
} from "../../dictionary/GroupsText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { toast } from "react-toastify";
import UserPermissionsEdit from "./EditPermissions";
import { memberPreset } from "../../utils/Permissions";

function ViewEditGroup() {
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(
    LanguageCheck(
      English,
      French,
      German,
      Dutch,
      Spanish,
      Italian,
      Romanian,
      Portuguese,
      stateLang
    )
  );
  useEffect(() => {
    setLanguage(
      LanguageCheck(
        English,
        French,
        German,
        Dutch,
        Spanish,
        Italian,
        Romanian,
        Portuguese,
        stateLang
      )
    );
  }, [stateLang]);

  const [emailError, setEmailError] = useState<string | undefined>("");
  const [inviteEmail, setInviteEmail] = useState("");
  const [newUserPerms, setNewUserPerms] = useState<UserPermissions>(memberPreset);
  const { groupid } = useParams();
  const navigate = useNavigate();

  const [
    inviteUserToGroup,
    {
      isError: isInviteError,
      error: inviteError,
      isLoading: isInviteLoading,
      isSuccess: isInviteSuccess,
    },
  ] = useInviteUserToGroupMutation();

  useEffect(() => {
    if (isInviteError) {
      const error = handleError(inviteError);
    }
  }, [isInviteError]);

  useEffect(() => {
    if (isInviteSuccess) {
      toast.success(language.groupInvite.inviteSuccess);
      navigate(-1);
    }
  }, [isInviteSuccess]);

  return (
    <>
      <GroupObjectModal
        onRemove={() => { }}
        onAccept={() => {
          if (validateEmail(inviteEmail) === undefined) {
            inviteUserToGroup({
              user_email: inviteEmail,
              group_id: groupid!,
              permissions: newUserPerms,
            });
          } else {
            setEmailError(validateEmail(inviteEmail));
          }
        }}
        onCancel={() => {
          navigate(-1);
        }}
        loading={isInviteLoading}
        Title={language.groupInvite.inviteUser}
        Body={
          <>
            <div className="mb-1">
              <FormInput
                htmlFor="email"
                label="Email"
                value={inviteEmail}
                error={emailError}
                onChange={(e) => {
                  setInviteEmail(e.target.value);
                  setEmailError("");
                }}
              />
            </div>
            <UserPermissionsEdit
              editeesPerms={memberPreset}
              getNewPerms={(perms) => setNewUserPerms(perms)}
              presetButtons={true}
            />
          </>
        }
        AcceptButton="Invite"
        CancelButton="Cancel"
        RemoveButton="-1"
      />
    </>
  );
}

export default ViewEditGroup;
