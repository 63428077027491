import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppSelector } from "../../app/hooks";
import { FormInput } from "../../components/FormInput";
import Spinner from "../../components/Spinner";
import { selectGroups } from "../../features/groups/groupSlice";
import { selectLanguage, selectUser } from "../../features/user/userSlice";
import { useCreateDashboardMutation } from "../../services/dashboardAPI";
import { handleError } from "../../utils/ErrorHandling";
import {
  validateDashboardDescription,
  validateDashboardName,
} from "../../utils/FormValidation";
import { CustomError } from "../../utils/Types";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../dictionary/DashboardText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { Button } from "../../components/Button";

type FormInputData = {
  optionValue: string;
  optionName: string;
};

type FormErrors = {
  formError?: string | undefined;
  nameError?: string | undefined;
  descriptionError?: string | undefined;
};

function CreateDashboard() {
  const [
    createDash,
    { isLoading, isError, error: createDashError, data, isSuccess },
  ] = useCreateDashboardMutation();
  const navigate = useNavigate();
  let { groups: userGroups, id } = useAppSelector(selectUser);
  let { groups } = useAppSelector(selectGroups);
  let [formErrors, setFormErrors] = useState<FormErrors>({});

  let stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);
  const [formInputData, setFormInputData] = useState<FormInputData[]>([]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(language.createDashboard.toasts.createdSuccessfully);
      navigate("/dashboard/" + data?.id);
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      handleError(createDashError);
    }
  }, [createDashError]);

  async function doCreateDashboard(event: React.FormEvent<HTMLFormElement>) {
    //prevent the page from reloading on submit
    event.preventDefault();
    //Get the form data
    var { dashboardName, dashboardDescription, groupID } = document.forms[0];

    //test if the form data is valid
    if (validateDashboardName(dashboardName.value)) {
      setFormErrors({
        nameError: validateDashboardName(dashboardName.value),
      });
      return;
    }

    if (validateDashboardDescription(dashboardDescription.value)) {
      setFormErrors({
        descriptionError: validateDashboardDescription(
          dashboardDescription.value
        ),
      });
      return;
    }

    if (groupID.value === "") {
      toast.error(language.createDashboard.toasts.selectAGroup);
      return;
    }

    createDash({
      name: dashboardName.value,
      description: dashboardDescription.value,
      group_id: groupID.value,
    });
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    setFormErrors((form) => ({ ...form, [field]: "" }));
  };

  useEffect(() => {
    console.log("userGroups", userGroups);
    if (userGroups) {
      if (Object.keys(userGroups).length > 0) {
        if (CheckGroupsCanCreateDashboardIn()) {
          toast.warning(
            language.createDashboard.toasts.noGroupPermission,
            { autoClose: 6000 }
          );
          navigate("/group/create");
        }
      } else if (id) {
        toast.warning(
          language.createDashboard.toasts.noGroups,
          { autoClose: 6000 }
        );
        navigate("/group/create");
      }
    }
  }, [userGroups]);

  useEffect(() => {
    if (userGroups) {
      let tempFormInputData: FormInputData[] = [];
      tempFormInputData.push({
        optionValue: "new",
        optionName: "--" + language.createDashboard.selectAGroup + "--",
      });
      for (const key in userGroups) {
        if (
          userGroups[key as keyof typeof userGroups].permissions
            .can_add_dashboards
        ) {
          tempFormInputData.push({
            optionValue: key,
            optionName:
              groups?.[key as keyof typeof userGroups]?.name ?? "Loading...",
          });
        }
      }
      setFormInputData(tempFormInputData);
    }
  }, [userGroups]);

  const CheckGroupsCanCreateDashboardIn = () => {
    for (const key in userGroups) {
      if (
        userGroups[key as keyof typeof userGroups].permissions.hasOwnProperty(
          "can_add_dashboards"
        )
      ) {
        if (
          userGroups[key as keyof typeof userGroups].permissions
            .can_add_dashboards
        ) {
          return false;
        }
      }
    }
    return true;
  };

  return (
    <div className="h-full rounded bg-transparent flex justify-center flex-col items-center w-full">
      <div className="bg-white px-10 py-8 rounded-xl w-screen shadow-md max-w-sm m-4">
        <h1 className="text-center md:text-2xl text-lg font-semibold text-gray-600 mb-4">
          {language.createDashboard.createADashboard}
        </h1>
        <form onSubmit={doCreateDashboard}>
          <FormInput
            label={language.createDashboard.labels.dashboardName}
            type="text"
            htmlFor="dashboardName"
            value={""}
            error={formErrors.nameError}
            onChange={(e) => handleInputChange(e, "nameError")}
          />
          <FormInput
            label={language.createDashboard.labels.dashboardDescription}
            type="text"
            htmlFor="dashboardDescription"
            value={""}
            error={formErrors.descriptionError}
            onChange={(e) => handleInputChange(e, "descriptionError")}
          />
          <FormInput
            label={language.createDashboard.labels.addToAGroup}
            type="select"
            htmlFor="groupID"
            data={formInputData}
            value={"new"}
          />
          <div className="text-xs font-semibold text-center tracking-wide text-red-500 w-full">
            {formErrors.formError}
          </div>
          <div className="w-full mt-4">
            <Button
              label={isLoading ? (
                <Spinner colour="fill-blue-600" />
              ) : (
                language.createDashboard.buttons.createDashboard
              )}
              disabled={isLoading}
              type="submit"
              colour="blue"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateDashboard;
