// The hook accepts a `file name` parameter to dynamically load dictionaries
// Example for modules imported from "../dictionary/GroupNotificationText" pass in {fileName: 'GroupNotificationText'} as param

import { useState, useEffect } from "react";
import { LanguageCheck } from "./LanguageCheck";
import { selectLanguage } from "../features/user/userSlice";
import { useAppSelector } from "../app/hooks";
import { LanguageText } from "../dictionary/GroupNotificationText";
interface UseGetLanguageProps {
  fileName: string;
}

const useGetLanguage = ({ fileName }: UseGetLanguageProps) => {
  const stateLang = useAppSelector(selectLanguage);
  const [language, setLanguage] = useState<LanguageText | null>(null);
  const loadDictionaries = async () => {
    try {
      const { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } =
        await import(`../dictionary/${fileName}`);

      setLanguage(
        LanguageCheck(
          English,
          French,
          German,
          Dutch,
          Spanish,
          Italian,
          Romanian,
          Portuguese,
          stateLang
        )
      );
    } catch (error) {
      console.error("Error loading dictionaries:", error);
    }
  };

  useEffect(() => {
    loadDictionaries();
  }, [stateLang, fileName]);

  return language;
};

export default useGetLanguage;
