export type LanguageText = {
  can_claim: string;
  can_unclaim: string;
  can_view_devices: string;
  can_edit_devices: string;
  can_add_dashboards: string;
  can_remove_dashboards: string;
  can_view_dashboards: string;
  can_edit_dashboards: string;
  can_invite_users: string;
  can_remove_users: string;
  can_view_users: string;
  can_edit_users_permissions: string;
  can_edit_group: string;
  can_delete_group: string;
  can_view_all_devices: string;
  can_view_all_dashboards: string;
  can_edit_all_devices: string;
  can_edit_all_dashboards: string;
};

export const English: LanguageText = {
  can_claim: "Can claim devices",
  can_unclaim: "Can unclaim devices",
  can_view_devices: "Can view devices",
  can_edit_devices: "Can edit devices",
  can_add_dashboards: "Can add dashboards",
  can_remove_dashboards: "Can remove dashboards",
  can_view_dashboards: "Can view dashboards",
  can_edit_dashboards: "Can edit dashboards",
  can_invite_users: "Can invite users",
  can_remove_users: "Can remove users",
  can_delete_group: "Can delete the group",
  can_edit_all_dashboards: "Can edit all dashboards",
  can_edit_all_devices: "Can edit all devices",
  can_edit_group: "Can edit the group",
  can_edit_users_permissions: "Can edit users permissions",
  can_view_all_dashboards: "Can view all dashboards",
  can_view_all_devices: "Can view all devices",
  can_view_users: "Can view users",
};
export const French: LanguageText = {
  can_add_dashboards: "Peut ajouter des tableaux de bord",
  can_claim: "Peut revendiquer des appareils",
  can_delete_group: "Peut supprimer le groupe",
  can_edit_all_dashboards: "Peut modifier tous les tableaux de bord",
  can_edit_all_devices: "Peut modifier tous les appareils",
  can_edit_dashboards: "Peut modifier les tableaux de bord",
  can_edit_devices: "Peut modifier les appareils",
  can_edit_group: "Peut modifier le groupe",
  can_edit_users_permissions:
    "Peut modifier les autorisations des utilisateurs",
  can_invite_users: "Peut inviter des utilisateurs",
  can_remove_dashboards: "Peut supprimer des tableaux de bord",
  can_remove_users: "Peut supprimer des utilisateurs",
  can_unclaim: "Peut ne pas revendiquer des appareils",
  can_view_all_dashboards: "Peut voir tous les tableaux de bord",
  can_view_all_devices: "Peut voir tous les appareils",
  can_view_dashboards: "Peut voir les tableaux de bord",
  can_view_devices: "Peut voir les appareils",
  can_view_users: "Peut voir les utilisateurs",
};
//Fill in the other languages based on the name of the constant
//and the name of the object
export const German: LanguageText = {
  can_add_dashboards: "Kann Dashboards hinzufügen",
  can_claim: "Kann Geräte beanspruchen",
  can_delete_group: "Kann die Gruppe löschen",
  can_edit_all_dashboards: "Kann alle Dashboards bearbeiten",
  can_edit_all_devices: "Kann alle Geräte bearbeiten",
  can_edit_dashboards: "Kann Dashboards bearbeiten",
  can_edit_devices: "Kann Geräte bearbeiten",
  can_edit_group: "Kann die Gruppe bearbeiten",
  can_edit_users_permissions: "Kann die Berechtigungen der Benutzer bearbeiten",
  can_invite_users: "Kann Benutzer einladen",
  can_remove_dashboards: "Kann Dashboards entfernen",
  can_remove_users: "Kann Benutzer entfernen",
  can_unclaim: "Kann Geräte nicht beanspruchen",
  can_view_all_dashboards: "Kann alle Dashboards anzeigen",
  can_view_all_devices: "Kann alle Geräte anzeigen",
  can_view_dashboards: "Kann Dashboards anzeigen",
  can_view_devices: "Kann Geräte anzeigen",
  can_view_users: "Kann Benutzer anzeigen",
};
export const Dutch: LanguageText = {
  can_add_dashboards: "Kan dashboards toevoegen",
  can_claim: "Kan apparaten claimen",
  can_delete_group: "Kan de groep verwijderen",
  can_edit_all_dashboards: "Kan alle dashboards bewerken",
  can_edit_all_devices: "Kan alle apparaten bewerken",
  can_edit_dashboards: "Kan dashboards bewerken",
  can_edit_devices: "Kan apparaten bewerken",
  can_edit_group: "Kan de groep bewerken",
  can_edit_users_permissions: "Kan de gebruikersrechten bewerken",
  can_invite_users: "Kan gebruikers uitnodigen",
  can_remove_dashboards: "Kan dashboards verwijderen",
  can_remove_users: "Kan gebruikers verwijderen",
  can_unclaim: "Kan apparaten niet claimen",
  can_view_all_dashboards: "Kan alle dashboards bekijken",
  can_view_all_devices: "Kan alle apparaten bekijken",
  can_view_dashboards: "Kan dashboards bekijken",
  can_view_devices: "Kan apparaten bekijken",
  can_view_users: "Kan gebruikers bekijken",
};
export const Spanish: LanguageText = {
  can_add_dashboards: "Puede agregar paneles",
  can_claim: "Puede reclamar dispositivos",
  can_delete_group: "Puede eliminar el grupo",
  can_edit_all_dashboards: "Puede editar todos los paneles",
  can_edit_all_devices: "Puede editar todos los dispositivos",
  can_edit_dashboards: "Puede editar paneles",
  can_edit_devices: "Puede editar dispositivos",
  can_edit_group: "Puede editar el grupo",
  can_edit_users_permissions: "Puede editar los permisos de los usuarios",
  can_invite_users: "Puede invitar a los usuarios",
  can_remove_dashboards: "Puede eliminar paneles",
  can_remove_users: "Puede eliminar usuarios",
  can_unclaim: "Puede no reclamar dispositivos",
  can_view_all_dashboards: "Puede ver todos los paneles",
  can_view_all_devices: "Puede ver todos los dispositivos",
  can_view_dashboards: "Puede ver paneles",
  can_view_devices: "Puede ver dispositivos",
  can_view_users: "Puede ver usuarios",
};
export const Italian: LanguageText = {
  can_add_dashboards: "Può aggiungere dashboard",
  can_claim: "Può reclamare dispositivi",
  can_delete_group: "Può eliminare il gruppo",
  can_edit_all_dashboards: "Può modificare tutte le dashboard",
  can_edit_all_devices: "Può modificare tutti i dispositivi",
  can_edit_dashboards: "Può modificare le dashboard",
  can_edit_devices: "Può modificare i dispositivi",
  can_edit_group: "Può modificare il gruppo",
  can_edit_users_permissions: "Può modificare i permessi degli utenti",
  can_invite_users: "Può invitare gli utenti",
  can_remove_dashboards: "Può rimuovere le dashboard",
  can_remove_users: "Può rimuovere gli utenti",
  can_unclaim: "Può non reclamare dispositivi",
  can_view_all_dashboards: "Può visualizzare tutte le dashboard",
  can_view_all_devices: "Può visualizzare tutti i dispositivi",
  can_view_dashboards: "Può visualizzare le dashboard",
  can_view_devices: "Può visualizzare i dispositivi",
  can_view_users: "Può visualizzare gli utenti",
};
export const Romanian: LanguageText = {
  can_add_dashboards: "Poate adăuga tablouri de bord",
  can_claim: "Poate revendica dispozitive",
  can_delete_group: "Poate șterge grupul",
  can_edit_all_dashboards: "Poate edita toate tablourile de bord",
  can_edit_all_devices: "Poate edita toate dispozitivele",
  can_edit_dashboards: "Poate edita tablourile de bord",
  can_edit_devices: "Poate edita dispozitivele",
  can_edit_group: "Poate edita grupul",
  can_edit_users_permissions: "Poate edita permisiunile utilizatorilor",
  can_invite_users: "Poate invita utilizatori",
  can_remove_dashboards: "Poate elimina tablourile de bord",
  can_remove_users: "Poate elimina utilizatori",
  can_unclaim: "Poate nu revendica dispozitive",
  can_view_all_dashboards: "Poate vizualiza toate tablourile de bord",
  can_view_all_devices: "Poate vizualiza toate dispozitivele",
  can_view_dashboards: "Poate vizualiza tablourile de bord",
  can_view_devices: "Poate vizualiza dispozitivele",
  can_view_users: "Poate vizualiza utilizatorii",
};
export const Portuguese: LanguageText = {
  can_add_dashboards: "Pode adicionar painéis",
  can_claim: "Pode reivindicar dispositivos",
  can_delete_group: "Pode excluir o grupo",
  can_edit_all_dashboards: "Pode editar todos os painéis",
  can_edit_all_devices: "Pode editar todos os dispositivos",
  can_edit_dashboards: "Pode editar painéis",
  can_edit_devices: "Pode editar dispositivos",
  can_edit_group: "Pode editar o grupo",
  can_edit_users_permissions: "Pode editar as permissões dos usuários",
  can_invite_users: "Pode convidar usuários",
  can_remove_dashboards: "Pode remover painéis",
  can_remove_users: "Pode remover usuários",
  can_unclaim: "Pode não reivindicar dispositivos",
  can_view_all_dashboards: "Pode visualizar todos os painéis",
  can_view_all_devices: "Pode visualizar todos os dispositivos",
  can_view_dashboards: "Pode visualizar painéis",
  can_view_devices: "Pode visualizar dispositivos",
  can_view_users: "Pode visualizar usuários",
};
