import React, { useEffect, useState } from "react";
import { FormInput } from "../../components/FormInput";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import AcceptModal from "../../components/modals/AcceptModal";
import Spinner from "../../components/Spinner";
import {
  validateConfirmPassword,
  validateEmail,
  validatePassword,
  validateUsername,
} from "../../utils/FormValidation";

import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
  LanguageText,
} from "../../dictionary/LoginText";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  useForgottenPasswordMutation,
  useLoginUserMutation,
  useRegisterUserMutation,
} from "../../services/authAPI";
import { invalidateAllCaches } from "../../app/store";
import TermsAndConditions from "../../components/TermsAndConditions";
import { handleError } from "../../utils/ErrorHandling";
import {
  MdAdd,
  MdDashboard,
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
  MdThermostat,
} from "react-icons/md";
import { Tooltip } from "flowbite-react";
import AnimateHeight from "react-animate-height";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { selectLanguage, selectUser } from "../../features/user/userSlice";
import { selectAuth } from "../../features/auth/authSlice";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { Button } from "../../components/Button";

//Errors that can be shown to the user
export type FormErrors = {
  formError?: string | undefined;
  emailError?: string | undefined;
  usernameError?: string | undefined;
  passwordError?: string | undefined;
  confirmPasswordError?: string | undefined;
};

export default function Login() {
  //default state on page load is login
  let [type, setType] = useState("login");
  //by default there are no errors
  let [formErrors, setFormErrors] = useState<FormErrors>({});
  let [showTsCsModal, setShowTsCsModal] = useState(false);
  let [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [recaptchaKey, setRecaptchaKey] = useState<string>();
  let navigate = useNavigate();
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(
    LanguageCheck(
      English,
      French,
      German,
      Dutch,
      Spanish,
      Italian,
      Romanian,
      Portuguese,
      stateLang
    )
  );
  const [isFlagDropdownOpen, setIsFlagDropdownOpen] = useState(false);

  useEffect(() => {
    setLanguage(
      LanguageCheck(
        English,
        French,
        German,
        Dutch,
        Spanish,
        Italian,
        Romanian,
        Portuguese,
        stateLang
      )
    );
    if (
      window.navigator.language !== null &&
      window.navigator.language !== undefined
    ) {
      setLanguage(
        LanguageCheck(
          English,
          French,
          German,
          Dutch,
          Spanish,
          Italian,
          Romanian,
          Portuguese,
          window.navigator.language
        )
      );
    }
    if (stateLang === "English" && localStorage.getItem("language") !== null) {
      setLanguage(
        LanguageCheck(
          English,
          French,
          German,
          Dutch,
          Spanish,
          Italian,
          Romanian,
          Portuguese,
          localStorage.getItem("language")!
        )
      );
    }
  }, [stateLang]);

  const { token } = useAppSelector(selectAuth);
  const reCAPTCHARef = React.useRef<ReCAPTCHA>(null);

  useEffect(() => {
    if (token !== undefined && token !== null) {
      navigate(localStorage.getItem("redirectURL") ?? "/");
    }
  }, [token]);

  useEffect(() => {
    if (localStorage.getItem("language") === null) {
      localStorage.setItem("language", "english");
    }
  }, []);

  //access to the login mutation and any errors from that api request
  const [
    loginUser,
    {
      isSuccess: isLoginSuccess,
      isLoading: isLoginLoading,
      isError: isLoginError,
      error: loginError,
    },
  ] = useLoginUserMutation();

  //access to the register mutation and any errors from that api request
  const [
    registerUser,
    {
      isSuccess: isRegisterSuccess,
      isLoading: isRegisterLoading,
      isError: isRegisterError,
      error: registerError,
    },
  ] = useRegisterUserMutation();

  //access to the forgot password mutation and any errors from that api request
  const [
    forgotPassword,
    {
      isSuccess: isForgotPasswordSuccess,
      isLoading: isForgotPasswordLoading,
      isError: isForgotPasswordError,
      error: forgotPasswordError,
    },
  ] = useForgottenPasswordMutation();

  //If there is a login error, show the error to the user
  useEffect(() => {
    if (isLoginError) {
      const error = handleError(loginError);
      if (error !== "") {
        setFormErrors({
          formError: error,
        });
      } else {
        setFormErrors({
          formError: "An unknown error has occurred",
        });
      }
    }
  }, [isLoginError]);

  //If the login is successful, dispatch the login action and navigate to either home or the page the user was trying to access before logging in
  useEffect(() => {
    if (isLoginSuccess) {
      navigate(localStorage.getItem("redirectURL") ?? "/");
    }
  }, [isLoginSuccess]);

  //If there is a register error, show the error to the user
  useEffect(() => {
    if (isRegisterError) {
      const error = handleError(registerError);
      if (error !== "") {
        setFormErrors({
          formError: error,
        });
        if (reCAPTCHARef.current !== null) {
          reCAPTCHARef.current?.reset();
        }
      } else {
        setFormErrors({
          formError: "An unknown error has occurred",
        });
      }
    }
    setShowTsCsModal(false);
  }, [isRegisterError]);

  //If the register is successful, dispatch the login action and navigate to either home or the page the user was trying to access before logging in
  useEffect(() => {
    if (isRegisterSuccess) {
      toast.success(language.login.toasts.registerUser);
      navigate("/verifyemail");
    }
  }, [isRegisterSuccess]);

  //If there is a forgot password error, show the error to the user
  useEffect(() => {
    if (isForgotPasswordError) {
      const error = handleError(forgotPasswordError);
      if (error) {
        setFormErrors({
          formError: error,
        });
      } else {
        //If there is no error message, show a generic error
        setFormErrors({
          formError: "An unknown error has occurred",
        });
      }
    }
  }, [isForgotPasswordError]);

  //If the forgot password is successful, show the success message to the user
  useEffect(() => {
    if (isForgotPasswordSuccess) {
      setType("login");
      toast.success(language.login.toasts.forgotPassword);
    }
  }, [isForgotPasswordSuccess]);

  async function doLogin(event: React.FormEvent<HTMLFormElement>) {
    //prevent the page from reloading on submit
    event.preventDefault();
    //Get the form data
    var {
      UsernameOrEmail,
      email,
      password,
      remember,
      confirmPassword,
      username,
      type,
    } = document.forms[0];
    //set the loading state to true to show the user something is happening
    //switch on the type of form we are submitting
    switch (type.value) {
      //if the type is login, validate the password
      case "login":
        if (validatePassword(password.value)) {
          //if the password entered isn't strong enough, show the user an error
          return setFormErrors({
            passwordError: validatePassword(password.value),
          });
        }
        //if the password is strong enough, call the login mutation
        invalidateAllCaches();
        await loginUser({
          body: {
            username: UsernameOrEmail.value,
            password: password.value,
          },
          rememberMe: remember.checked,
        });
        break;

      //if the type is register, validate the email, username, password and confirm password
      case "register":
        if (
          validatePassword(password.value) ||
          validateConfirmPassword(password.value, confirmPassword.value) ||
          validateEmail(email.value) ||
          validateUsername(username.value)
        ) {
          //if any of the fields are invalid, show the user the errors
          return setFormErrors({
            emailError: validateEmail(email.value),
            passwordError: validatePassword(password.value),
            confirmPasswordError: validateConfirmPassword(
              password.value,
              confirmPassword.value
            ),
            usernameError: validateUsername(username.value),
          });
        }
        //if all the fields are valid, show the user the terms and conditions modal

        setShowTsCsModal(true);
        break;
      case "forgotPassword":
        //if the type is forgot password, send the inputted email/username to the api
        const usernameOrEmail = UsernameOrEmail.value as string;
        await forgotPassword(usernameOrEmail);
        //if there is some unknown error with the form, show the user an error
        break;
      default:
        return setFormErrors({ formError: "Invalid form type" });
    }
  }

  //if the user accepts the terms and conditions, call the register mutation
  async function acceptTsCs(): Promise<any> {
    setShowTsCsModal(false);
    if (recaptchaKey === "" || recaptchaKey === undefined) {
      setFormErrors({ formError: "Please complete the reCAPTCHA" });
      return;
    }
    //Get the form data
    var { email, password, remember, username } = document.forms[0];
    //call the register mutation
    if (localStorage.getItem("language") !== null) {
      await registerUser({
        body: {
          username: username.value.trim(),
          email: email.value.trim(),
          password: password.value.trim(),
          recaptcha_key: recaptchaKey ?? "",
          language: localStorage.getItem("language")!,
        },
        rememberMe: remember.checked,
      });
    }
  }

  //Allow the user to close the ts and cs modal without accepting
  function closeTsCsModal(): any {
    setShowTsCsModal(false);
  }

  //reset the form errors to empty when the user changes a value
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    setFormErrors((form) => ({ ...form, [field]: "" }));
  };

  const flagDropdown = (
    <div className="pl-[2px] ring-opacity-20 ring-2 ring-gray-900 top-[1rem] right-3 border rounded absolute w-20 h-11 z-[700]">
      <div className="flex flex-row justify-between pb-1">
        <div className="flex flex-row">
          <button
            className="flex items-center"
            type="button"
            onClick={() => setIsFlagDropdownOpen(!isFlagDropdownOpen)}
          >
            {getFlag(language)}
            {isFlagDropdownOpen ? (
              <MdKeyboardArrowDown size={24} />
            ) : (
              <MdKeyboardArrowRight size={24} />
            )}
          </button>
        </div>
      </div>
      <AnimateHeight
        duration={100}
        height={isFlagDropdownOpen ? "auto" : 0}
        className="rounded-lg ml-4 sm:max-h-96 max-h-60 overflow-auto scrollbar-none"
      >
        {[
          { icon: getFlag(English), value: "english" },
          { icon: getFlag(French), value: "french" },
          { icon: getFlag(German), value: "german" },
          { icon: getFlag(Dutch), value: "dutch" },
          { icon: getFlag(Spanish), value: "spanish" },
          { icon: getFlag(Italian), value: "italian" },
          { icon: getFlag(Romanian), value: "romanian" },
          { icon: getFlag(Portuguese), value: "portuguese" },
        ].map((listing, index) => {
          return (
            <button
              value={listing.value}
              onClick={() => {
                setLanguage(
                  LanguageCheck(
                    English,
                    French,
                    German,
                    Dutch,
                    Spanish,
                    Italian,
                    Romanian,
                    Portuguese,
                    listing.value
                  )
                );
                localStorage.setItem("language", listing.value);
                setIsFlagDropdownOpen(false);
              }}
              key={index}
              className="flex flex-row pt-1 pb-1 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 rounded-lg duration-75"
            >
              <span
                className="mx-1 flex-1 truncate relative overflow-hidden whitespace-nowrap text-ellipsis"
                title={""}
              >
                {listing.icon}
              </span>
            </button>
          );
        })}
      </AnimateHeight>
    </div>
  );

  return (
    <div className="h-screen bg-gradient-to-br from-blue-600 to-indigo-600 flex justify-center flex-col items-center w-full overflow-y-auto overflow-x-hidden">
      <>
        {showTsCsModal && (
          <TsCsConsentModal
            acceptTsCs={acceptTsCs}
            closeTsCsModal={closeTsCsModal}
            Language={language}
          />
        )}
      </>
      <>{flagDropdown}</>
      <img
        src="https://www.r-p-r.co.uk/newimages/rprfooter.png"
        alt="rpr logo"
      />
      <h1 className="text-center text-6xl font-bold text-white mb-8">
        rpr-IoT
      </h1>
      <div className="bg-white px-2 sm:px-10 py-8 rounded-xl shadow-md max-w-[90%] sm:max-w-sm m-4">
        <h1 className="text-center text-4xl font-semibold text-gray-600 mb-8">
          <Title type={type} language={language} />
        </h1>
        <div className="text-xs font-semibold text-center tracking-wide text-red-500 w-full">
          {formErrors.formError}
        </div>
        <form onSubmit={doLogin}>
          <div className="">
            {type !== "register" && (
              <FormInput
                htmlFor="UsernameOrEmail"
                label={language.login.form.usernameOrEmail}
                value={undefined}
                autoComplete="username"
                required
                error={formErrors.emailError}
                onChange={(e) => handleInputChange(e, "emailError")}
              />
            )}

            {type === "register" && (
              <FormInput
                htmlFor="email"
                label={language.login.form.email}
                type="email"
                autoComplete="email"
                value={undefined}
                required
                error={formErrors.emailError}
                onChange={(e) => handleInputChange(e, "emailError")}
              />
            )}

            {type === "register" && (
              <FormInput
                htmlFor="username"
                label={language.login.form.username}
                type="text"
                autoComplete="username"
                value={undefined}
                required
                error={formErrors.usernameError}
                onChange={(e) => handleInputChange(e, "usernameError")}
              />
            )}

            {type !== "forgotPassword" && (
              <FormInput
                htmlFor="password"
                label={language.login.form.password}
                type="password"
                value={undefined}
                required
                autoComplete={
                  type === "login" ? "current-password" : "new-password"
                }
                error={formErrors.passwordError}
                onChange={(e) => handleInputChange(e, "passwordError")}
              />
            )}
            {type === "register" && (
              <FormInput
                htmlFor="confirmPassword"
                label={language.login.form.confirmPassword}
                type="password"
                value={undefined}
                autoComplete="new-password"
                required
                error={formErrors.confirmPasswordError}
                onChange={(e) => handleInputChange(e, "confirmPasswordError")}
              />
            )}
            <div
              className={`${
                type === "register" ? "pt-2" : "h-0"
              } overflow-hidden`}
            >
              <ReCAPTCHA
                sitekey="6LcdM-0jAAAAAGCSabXskvglDargOZ9IqVEP7jiG"
                onChange={(e) => setRecaptchaKey(e?.valueOf() ?? "")}
                ref={reCAPTCHARef}
              />
            </div>
            <div className="flex flex-row justify-between pb-1">
              {type !== "forgotPassword" && (
                <div className="text-left">
                  <label
                    htmlFor="remember"
                    className="block mb-1 text-gray-600  "
                  >
                    <input
                      id="remember"
                      type="checkbox"
                      className="bg-indigo-50 px-2 py-2 outline-none rounded-md mr-2 hover:cursor-pointer"
                    />
                    {language.login.form.remember}
                  </label>
                </div>
              )}
              {type === "login" && (
                <div>
                  <label htmlFor="forgotPassword" className="text-gray-600">
                    <button
                      id="forgotPassword"
                      type="button"
                      className="hover:cursor-pointer text-right"
                      onClick={() => {
                        setType("forgotPassword");
                      }}
                    >
                      {language.login.form.forgotPassword}
                    </button>
                  </label>
                </div>
              )}
            </div>
          </div>

          {type === "forgotPassword" && <p></p>}

          <Button
            label={
              isLoginLoading || isRegisterLoading || isForgotPasswordLoading ? (
                <Spinner colour="fill-blue-600" />
              ) : (
                <Title type={type} language={language} />
              )
            }
            type="submit"
            colour="blue"
          />
          <input type="hidden" value={type} id="type" />
        </form>
        <p
          className="block mb-1 text-gray-800 cursor-pointer"
          onClick={() => {
            setType(type === "login" ? "register" : "login");
          }}
        >
          {type === "login" ? (
            <>{language.login.buttons.notGotAccount}</>
          ) : type === "register" ? (
            <>{language.login.buttons.gotAccount}</>
          ) : null}
        </p>
        {type === "forgotPassword" && (
          <div className="text-center">
            <label htmlFor="cancel" className="text-gray-600">
              <button
                id="cancel"
                type="button"
                className="hover:cursor-pointer"
                onClick={() => {
                  setType("login");
                }}
              >
                {language.login.buttons.cancel}
              </button>
            </label>
          </div>
        )}
      </div>
    </div>
  );
}

type TsCsConsentModalProps = {
  acceptTsCs: (...args: any) => any;
  closeTsCsModal: (...args: any) => any;
  Language: LanguageText;
};

const TsCsConsentModal = ({
  acceptTsCs,
  closeTsCsModal,
  Language,
}: TsCsConsentModalProps) => {
  return (
    <AcceptModal
      onAccept={acceptTsCs}
      onCancel={closeTsCsModal}
      Title={Language.termsAndConditions.title}
      Body={Language.termsAndConditions.body}
      AcceptButton={Language.termsAndConditions.accept}
      CancelButton={Language.termsAndConditions.cancel}
    />
  );
};

type TitleProps = {
  type: string;
  language: LanguageText;
};

function getFlag(language: LanguageText) {
  switch (language) {
    case English: {
      return (
        <img
          src={require("../../assets/flag-gb.png")}
          alt="english"
          className="w-12 h-10 rounded-lg"
        />
      );
    }
    case French: {
      return (
        <img
          src={require("../../assets/flag-fr.png")}
          alt="french"
          className="w-12 h-10 rounded-lg"
        />
      );
    }
    case Spanish: {
      return (
        <img
          src={require("../../assets/flag-es.png")}
          alt="spanish"
          className="w-12 h-10 rounded-lg"
        />
      );
    }
    case German: {
      return (
        <img
          src={require("../../assets/flag-de.png")}
          alt="german"
          className="w-12 h-10 rounded-lg"
        />
      );
    }
    case Italian: {
      return (
        <img
          src={require("../../assets/flag-it.png")}
          alt="italian"
          className="w-12 h-10 rounded-lg"
        />
      );
    }
    case Portuguese: {
      return (
        <img
          src={require("../../assets/flag-pt.png")}
          alt="portuguese"
          className="w-12 h-10 rounded-lg"
        />
      );
    }
    case Dutch: {
      return (
        <img
          src={require("../../assets/flag-nl.png")}
          alt="dutch"
          className="w-12 h-10 rounded-lg"
        />
      );
    }
    case Romanian: {
      return (
        <img
          src={require("../../assets/flag-ro.png")}
          alt="romanian"
          className="w-12 h-10 rounded-lg"
        />
      );
    }
    default: {
      return (
        <img
          src={require("../../assets/flag-gb.png")}
          alt="english"
          className="w-12 h-10 rounded-lg"
        />
      );
    }
  }
}

const Title = ({ type, language }: TitleProps) => {
  switch (type) {
    case "login":
      return <>{language.login.buttons.login}</>;
    case "register":
      return <>{language.login.buttons.register}</>;
    case "forgotPassword":
      return <>{language.login.buttons.forgotPassword}</>;
    default:
      return <>{"Error"}</>;
  }
};
