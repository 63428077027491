export type LanguageText = {
    navHeader: {
        settings: string;
        help: string;
        signOut: string
    }
    sideBar: {
        dashboards: string;
        devices: string;
        groups: string;
        collapse: string;
        expand: string;
        createDashboard: string;
        claimDevice: string;
        createGroup: string;
    }
};
export const English: LanguageText = {
    navHeader: {
        settings: "Settings",
        help: "Help",
        signOut: "Sign Out"
    },
    sideBar: {
        dashboards: "Dashboards",
        devices: "Devices",
        groups: "Groups",
        collapse: "Collapse",
        expand: "Expand",
        createDashboard: "Create Dashboard",
        claimDevice: "Claim Device",
        createGroup: "Create Group"
    }
};
export const French: LanguageText={
    navHeader: {
        settings: "Paramètres",
        help: "Aide",
        signOut: "Déconnexion"
    },
    sideBar: {
        dashboards: "Tableaux de bord",
        devices: "Dispositifs",
        groups: "Groupes",
        collapse: "Effondrement",
        expand: "Élargir",
        createDashboard: "Créer un tableau de bord",
        claimDevice: "Dispositif de réclamation",
        createGroup: "Créer un groupe"
    }
};
export const German: LanguageText={
    navHeader: {
        settings: "Einstellungen",
        help: "Hilfe",
        signOut: "Abmelden"
    },
    sideBar: {
        dashboards: "Dashboards",
        devices: "Geräte",
        groups: "Gruppen",
        collapse: "Zusammenbruch",
        expand: "Erweitern",
        createDashboard: "Dashboard erstellen",
        claimDevice: "Gerät beanspruchen",
        createGroup: "Gruppe erstellen"
    }
};
export const Dutch: LanguageText={
    navHeader: {
        settings: "Instellingen",
        help: "Hulp",
        signOut: "Afmelden"
    },
    sideBar: {
        dashboards: "Dashboards",
        devices: "Apparaten",
        groups: "Groepen",
        collapse: "Inklappen",
        expand: "Uitbreiden",
        createDashboard: "Dashboard maken",
        claimDevice: "Claim Apparaat",
        createGroup: "Groep creëren"
    }
};
export const Spanish: LanguageText={
    navHeader: {
        settings: "Ajustes",
        help: "Ayuda",
        signOut: "Cerrar sesión"
    },
    sideBar: {
        dashboards: "Cuadros de mando",
        devices: "Dispositivos",
        groups: "Grupos",
        collapse: "Colapso",
        expand: "Expandir",
        createDashboard: "Crear cuadro de mando",
        claimDevice: "Dispositivo de reclamación",
        createGroup: "Crear grupo"
    }
};
export const Italian: LanguageText={
    navHeader: {
        settings: "Impostazioni",
        help: "Aiuto",
        signOut: "Esci"
    },
    sideBar: {
        dashboards: "Cruscotti",
        devices: "Dispositivi",
        groups: "Gruppi",
        collapse: "Crollo",
        expand: "Espandere",
        createDashboard: "Crea cruscotto",
        claimDevice: "Dispositivo di rivendicazione",
        createGroup: "Crea gruppo"
    }
};
export const Romanian: LanguageText={
    navHeader: {
        settings: "Setări",
        help: "Ajutor",
        signOut: "Ieșire"
    },
    sideBar: {
        dashboards: "Tablouri de bord",
        devices: "Dispozitive",
        groups: "Grupuri",
        collapse: "Prăbușire",
        expand: "Extindeți",
        createDashboard: "Creează tabloul de bord",
        claimDevice: "Dispozitiv de revendicare",
        createGroup: "Creați un grup"
    }
};
export const Portuguese : LanguageText={
    navHeader: {
        settings: "Definições",
        help: "Ajuda",
        signOut: "Sair"
    },
    sideBar: {
        dashboards: "Painéis de instrumentos",
        devices: "Dispositivos",
        groups: "Grupos",
        collapse: "Colapso",
        expand: "Expandir",
        createDashboard: "Criar Painel de Controle",
        claimDevice: "Dispositivo de reclamação",
        createGroup: "Criar Grupo"
    }
};