import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { doLogout } from "../../app/store";
import InputModal from "../../components/modals/InputModal";
import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
  LanguageText,
} from "../../dictionary/LoginText";
import {
  login,
  selectAuth,
  setVerifiedEmail,
} from "../../features/auth/authSlice";
import {
  clearUser,
  selectLanguage,
  selectUser,
  setUser,
} from "../../features/user/userSlice";
import {
  useResendVerificationEmailMutation,
  useVerifyEmailMutation,
} from "../../services/authAPI";
import { useGetUserInfoQuery } from "../../services/userAPI";
import { handleError } from "../../utils/ErrorHandling";
import { CustomError } from "../../utils/Types";
import { LanguageCheck } from "../../utils/LanguageCheck";
import AcceptModal from "../../components/modals/AcceptModal";

function VerifyEmail() {
  const stateLang = useAppSelector(selectLanguage);
  const navigate = useNavigate();
  const location = useLocation();
  let [language, setLanguage] = useState(
    LanguageCheck(
      English,
      French,
      German,
      Dutch,
      Spanish,
      Italian,
      Romanian,
      Portuguese,
      stateLang
    )
  );

  useEffect(() => {
    setLanguage(
      LanguageCheck(
        English,
        French,
        German,
        Dutch,
        Spanish,
        Italian,
        Romanian,
        Portuguese,
        stateLang
      )
    );
  }, [stateLang]);

  const {
    error: getUserInfoError,
    isError: isGetUserInfoError,
    isLoading: isGetUserInfoLoading,
    data: getUserData,
    isSuccess: isGetUserSuccess,
  } = useGetUserInfoQuery("");
  const [resend, setResend] = useState(true);

  const [
    resendVerifyCode,
    {
      data: resendData,
      error: resendError,
      isLoading: resendIsLoading,
      isError: resendIsError,
      isSuccess: resendIsSuccess,
    },
  ] = useResendVerificationEmailMutation();

  //If the user has requested a new verification email, and the request was successful, show a toast
  useEffect(() => {
    if (resendIsSuccess) {
      toast.success(language.verifyEmail.toasts.resendEmail);
    }
  }, [resendIsSuccess]);

  //If a the user has requested a new verification email, and the request failed, show a message
  useEffect(() => {
    if (resendIsError) {
      handleError(resendError);
      setResend(true);
    }
  }, [resendError]);

  //If a the user has registered with an account with the wrong email, they have the option to start again
  function returnToLogin() {
    doLogout();
  }

  useEffect(() => {
    if (isGetUserSuccess) {
      if (getUserData.email_verified !== undefined) {
        if (getUserData.email_verified) {
          navigate("/");
        }
      }
    }
  }, [getUserData]);

  return (
    <VerifyEmailModal
      submitCode={() => {
        resendVerifyCode("");
        setResend(false);
      }}
      changeEmail={returnToLogin}
      Language={language}
      email={getUserData ? getUserData.email : "your email address"}
      loading={isGetUserInfoLoading || resendIsLoading}
      resend={resend}
    />
  );
}

export default VerifyEmail;

type verifyEmailModalProps = {
  submitCode: (...args: any) => any;
  changeEmail: (...args: any) => any;
  Language: LanguageText;
  email: string | null;
  loading: boolean;
  resend: boolean;
};

const VerifyEmailModal = ({
  submitCode,
  changeEmail,
  Language,
  email,
  loading,
  resend,
}: verifyEmailModalProps) => {
  return (
    <AcceptModal
      onAccept={submitCode}
      onCancel={changeEmail}
      Title={Language.verifyEmail.title}
      loading={loading}
      Body={
        <>
          {Language.verifyEmail.body.phrase1}
          <b>{email}</b>
        </>
      }
      AcceptButton={Language.verifyEmail.buttons.resendEmail}
      CancelButton={Language.verifyEmail.buttons.cancel}
      disableAccept={!resend}
    />
  );
};
