import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface DeviceState {
  devices: Devices;
}

export type Device = {
  eui: string;
  lat: number;
  lng: number;
  name: string;
  group_id: string;
  hw_vn: string;
  sw_vn: string;
  images: string[];
  status: Status;
  configuration: DeviceConfiguration;
  id: string;
  gateways: GatewayInfo;
};

export type Devices = Device[];

export type GatewayInfo = {
  [key: string]: Gateway;
};

export type Gateway = {
  lat: number;
  lng: number;
  name: string;
  last_contact_ts: Date;
};

type Status = {
  claimed: boolean;
  last_message_ts: Date;
  online: boolean;
};

export type DeviceConfiguration = {
  [key: string]: PhenomenaConfiguration[];
};

export type PhenomenaConfiguration = {
  ident: number;
  description: string;
  unit: string;
  type: string;
  sensor_sn: string;
  elemID: string;
};

const initialState: DeviceState = {
  devices: [],
};

export const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    addDevices: (state: any, action: PayloadAction<Devices>) => {
      state.devices = action.payload;
    },
    addDevice: (state: any, action: PayloadAction<Device>) => {
      state.devices[action.payload.id] = action.payload;
    },
    //=============== Logout Action ===============
    clearDevices: (state: any) => {
      state.devices = [];
    },
    deleteDevice: (state: any, action: PayloadAction<string>) => {
      delete state.devices[action.payload];
    },
  },
});

export const selectDevices = (state: RootState) => state.devices;

export const { addDevices, clearDevices, addDevice, deleteDevice } =
  deviceSlice.actions;

export default deviceSlice.reducer;

//I heada back to store.tsx that is found in the folder app
