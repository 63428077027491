import { setupListeners } from "@reduxjs/toolkit/dist/query/react";
import _, { map } from "lodash";
import React, { useEffect, useState } from "react";
import { MdLineStyle } from "react-icons/md";
import { useParams } from "react-router-dom";
import { Config } from "tailwindcss";
import { LineChartData } from "..";
import { useAppSelector } from "../../../../app/hooks";
import {
  Dashboards,
  selectDashboards,
} from "../../../../features/dashboards/dashboardSlice";
import {
  Device,
  Devices,
  PhenomenaConfiguration,
  selectDevices,
} from "../../../../features/devices/deviceSlice";
import { useGetDevicesQuery } from "../../../../services/deviceAPI";
import { FormInput } from "../../../FormInput";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../../../dictionary/ChartsText";
import { LanguageCheck } from "../../../../utils/LanguageCheck";
import { selectLanguage } from "../../../../features/user/userSlice";

type Props = {
  line: LineChartData;
  index: number;
  saveLineData: (line: LineChartData) => void;
  removeLine: (index: string) => void;
  remove?: boolean;
};

type FormInputData = {
  optionValue: string;
  optionName: string;
};

function NewBarMenu({
  line: currentLine,
  index,
  saveLineData,
  removeLine,
  remove,
}: Props) {

  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  const [line, setLine] = useState<LineChartData>(currentLine);
  const [phenomenaSelectData, setPhenomenaSelectData] = useState<FormInputData[]>([]);
  const [userDeviceData, setUserDeviceData] = useState<FormInputData[]>([]);
  const [selectedPhenomenaElem, setSelectedPhenomenaElem] = useState<string>("new");

  let { devices } = useAppSelector(selectDevices);
  let { dashboards } = useAppSelector(selectDashboards);
  let { dashboardid } = useParams();

  function GetLineAndPhenomFromParams() {
    setLine(currentLine);
    setSelectedPhenomenaElem(
      currentLine.phenomena == "new"
        ? "new"
        : JSON.stringify({
          phenomena: currentLine.phenomena,
          elemID: currentLine.elemID ?? "0",
        })
    );
  }

  function populateDeviceSelect() {
    var tempUserDeviceData: FormInputData[] = [{
      optionValue: "new",
      optionName: language.charts.gauge.inputDefaults.selectDevice,
    }];
    const dashboardDevices: Device[] = _.filter(devices, (device: Device) => {
      return device.group_id === dashboards[dashboardid as keyof Dashboards].group_id;
    });
    _.map(dashboardDevices, (device: Device) => {
      if (device.eui !== null && device.name !== null) {
        tempUserDeviceData.push({
          optionValue: device.eui,
          optionName: device.name,
        });
      }
    });
    setUserDeviceData(tempUserDeviceData);
  }

  function populatePhenomenaSelect() {
    var currDevice = _.find(devices, (device) => device.eui === line.deviceID)
    if (currDevice !== undefined) {
      var tempPhenomenaSelectData: FormInputData[] = [{
        optionValue: "new",
        optionName: language.charts.gauge.inputDefaults.selectParameter,
      }];
      var currDeviceConfig: [string, PhenomenaConfiguration[]][] = _.entries(currDevice.configuration);

      _.forEach(currDeviceConfig, ([key, value]) => {
        _.forEach(value, (element: PhenomenaConfiguration) => {
          tempPhenomenaSelectData.push({
            optionValue: JSON.stringify({
              phenomena: key,
              elemID: element.elemID ?? "0",
            }),
            optionName: element.description,
          });
        });
      });
      setPhenomenaSelectData(tempPhenomenaSelectData);
    }
  }

  useEffect(() => {
    GetLineAndPhenomFromParams();
  }, [currentLine]);

  useEffect(() => {
    populateDeviceSelect();
  }, [devices]);

  useEffect(() => {
    populatePhenomenaSelect();
  }, [line.deviceID, userDeviceData]);

  function changeLabel(event: React.ChangeEvent<HTMLInputElement>) {
    setLine({ ...line, label: event.target.value });
    saveLineData({ ...line, label: event.target.value });
  }

  function changeDevice(event: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedPhenomenaElem(
      JSON.stringify({
        phenomena: "new",
        elemID: "",
      })
    );
    setLine({ ...line, deviceID: event.target.value, phenomena: "new", elemID: "", unit: "" });
    saveLineData({ ...line, deviceID: event.target.value, phenomena: "new", elemID: "", unit: "" });
  }

  function changePhenomena(event: React.ChangeEvent<HTMLSelectElement>) {
    const { phenomena, elemID } = JSON.parse(event.target.value);
    setSelectedPhenomenaElem(
      JSON.stringify({
        phenomena: phenomena,
        elemID: elemID ?? "0",
      })
    );
    setLine({ ...line, phenomena: phenomena, elemID: elemID });
    saveLineData({ ...line, phenomena: phenomena, elemID: elemID });
  }

  function changeColour(event: React.ChangeEvent<HTMLInputElement>) {
    setLine({ ...line, colour: event.target.value });
    saveLineData({ ...line, colour: event.target.value });
  }

  return (
    <div key={index} className="rounded-md bg-gray-200 p-1 flex flex-col my-2">
      <FormInput
        label={language.charts.barChart.labels.barName}
        htmlFor="lineName"
        value={line.label}
        onChange={changeLabel}
      />
      <FormInput
        label={language.charts.barChart.labels.device}
        htmlFor="device"
        type="select"
        data={userDeviceData}
        value={line.deviceID ?? "new"}
        onChange={changeDevice}
      />
      <FormInput
        label={language.charts.barChart.labels.parameter}
        htmlFor="phenomena"
        type="select"
        data={phenomenaSelectData}
        disabled={line.deviceID == "new"}
        value={selectedPhenomenaElem}
        onChange={changePhenomena}
      />
      <FormInput
        type="color"
        label={language.charts.barChart.labels.barColour}
        htmlFor="lineColor"
        value={line.colour}
        onChange={changeColour}
      />
      {remove ?
        <button onClick={() => removeLine(line.lineID)}>
          {language.charts.barChart.buttons.remove}
        </button>
        : null}
    </div>
  );
}

export default NewBarMenu;
