import Spinner from "../../Spinner";

import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../../dictionary/ChartsText";
import { useEffect, useState } from "react";

import { LanguageCheck } from "../../../utils/LanguageCheck";
import { useAppSelector } from "../../../app/hooks";
import { selectLanguage } from "../../../features/user/userSlice";
import { Button } from "../../Button";

export type EditChartProps = {
    editMapType?: JSX.Element | string;
    settingsBox?: JSX.Element | string;
    itemList?: (JSX.Element | undefined)[];
    newButton?: {
        text: string;
        onClick: () => void;
        icon: JSX.Element;
    }
    onDelete: () => void;
    onSave: (chartData?: any) => void;
    loading: boolean;
};

export function EditChart({
    editMapType,
    settingsBox,
    itemList,
    newButton,
    onDelete,
    onSave,
    loading,
}: EditChartProps) {
    const stateLang = useAppSelector(selectLanguage);
    let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    useEffect(() => {
        setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    }, [stateLang]);
    return (
        <div className="h-[98vh] flex flex-col justify-between">
            <div> {editMapType} </div>
            <div className="my-1 h-full scrollbar-none overflow-y-scroll">
                {settingsBox}
                {itemList}
                {newButton &&
                    <button
                        type="button"
                        onClick={() =>
                            newButton.onClick()
                        }
                        className="group rounded border-dashed border-[6px] flex-wrap content-center justify-center border-gray-400 h-16 w-full">
                        <div className="relative">
                            {newButton.icon}
                            <div className="self-center w-full">{newButton.text}</div>
                        </div>
                    </button>
                }
            </div>
            <div className="block space-y-2">
                <Button
                    label={language.charts.barChart.buttons.delete}
                    colour="red"
                    onClick={() => {
                        onDelete();
                    }}
                />
                <Button
                    label={loading ? <Spinner colour="fill-blue-600 place-self-center" /> : language.charts.lineChart.buttons.save}
                    colour="green"
                    onClick={() => { onSave() }}
                />
            </div>
        </div>
    );
}