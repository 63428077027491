import React from "react";

function P404() {
  function refreshPage() {
    window.location.reload();
    return <></>;

  }

  return refreshPage();
}


export default P404;
