import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AcceptModal from "../../components/modals/AcceptModal";
import { FormInput } from "../../components/FormInput";
import Spinner from "../../components/Spinner";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLanguage, selectUser } from "../../features/user/userSlice";
import { useCreateGroupMutation } from "../../services/groupAPI";
import { toast } from "react-toastify";
import { useGetUserInfoQuery, userAPI } from "../../services/userAPI";
import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
} from "../../dictionary/GroupsText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { handleError } from "../../utils/ErrorHandling";
import { Button } from "../../components/Button";

export default function JoinCreateGroup() {
  const dispatch = useAppDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  let [confirmModal, setConfirmModal] = useState(false);
  const { isFetching: isGetUserInfoFetching, data: userData } =
    useGetUserInfoQuery("");
  let [groupName, setGroupName] = useState("");
  let navigate = useNavigate();
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(
    LanguageCheck(
      English,
      French,
      German,
      Dutch,
      Spanish,
      Italian,
      Romanian,
      Portuguese,
      stateLang
    )
  );

  useEffect(() => {
    setLanguage(
      LanguageCheck(
        English,
        French,
        German,
        Dutch,
        Spanish,
        Italian,
        Romanian,
        Portuguese,
        stateLang
      )
    );
  }, [stateLang]);

  const [
    createGroup,
    {
      data: createGroupData,
      isSuccess: isCreateGroupSuccess,
      isLoading: isCreateGroupLoading,
      isError: isCreateGroupError,
      error: createGroupError,
    },
  ] = useCreateGroupMutation();

  useEffect(() => {
    if (isCreateGroupSuccess) {
      toast.success(language.createGroup.toasts.createdSuccessfully + ".");
      while (isGetUserInfoFetching) {}
      navigate(`/group/${createGroupData?.id}`);
    }
  }, [createGroupData, isGetUserInfoFetching]);

  useEffect(() => {
    if (isCreateGroupError) {
      setConfirmModal(false);
      handleError(createGroupError);
    }
  }, [createGroupError]);

  async function doCreate(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setConfirmModal(true);
    var { groupName } = document.forms[0];
    setGroupName(groupName.value);
  }

  async function accept(): Promise<any> {
    createGroup({ name: groupName });
  }

  function cancel() {
    //console.log("cancel");
    setConfirmModal(false);
  }

  const modals = (
    <>
      {confirmModal && (
        <AcceptModal
          onAccept={accept}
          onCancel={cancel}
          Title={language.createGroup.confirm.title}
          Body={
            <div>
              {language.createGroup.confirm.phrase1} <b>{userData?.username}</b>
              {language.createGroup.confirm.phrase2} <b>{groupName}</b>
              {language.createGroup.confirm.phrase3}
            </div>
          }
          AcceptButton={language.createGroup.confirm.buttons.create}
          CancelButton={language.createGroup.confirm.buttons.cancel}
        />
      )}
    </>
  )

  return (
    <div className="h-full rounded bg-transparent flex justify-center flex-col items-center w-full">
      {modals}
      <div className="bg-white px-10 py-8 rounded-xl w-screen shadow-md max-w-sm m-4">
        <h1 className="text-center md:text-2xl text-lg font-semibold text-gray-600 mb-4">
          {language.createGroup.createAGroup}
        </h1>
        <form onSubmit={doCreate}>
          <FormInput
            htmlFor="groupName"
            required={true}
            label={language.createGroup.createGroup}
            type="text"
            value={""}
            tooltip={language.createGroup.tooltips.groupName}
          />
          <div className="w-full mt-4">
            <Button
              label={
                isCreateGroupLoading ? (
                  <Spinner colour="fill-blue-600" />
                ) : (
                  language.createGroup.createGroup
                )
              }
              disabled={isCreateGroupLoading}
              type="submit"
              colour="blue"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
