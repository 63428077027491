import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GroupObjectModal from "../../components/modals/GroupObjectModal";
import {
  groupAPI,
  useGetGroupOnLimitQuery,
  useGetGroupQuery,
} from "../../services/groupAPI";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import {
  GroupDashboard,
  GroupDevice,
  GroupUser,
  selectGroups,
} from "../../features/groups/groupSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../features/user/userSlice";
import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
} from "../../dictionary/GroupsText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { Switch } from "@headlessui/react";
import _ from "lodash";
import { Devices, selectDevices } from "../../features/devices/deviceSlice";
import { selectDashboards } from "../../features/dashboards/dashboardSlice";
import { Button } from "../../components/Button";
import Spinner from "../../components/Spinner";
import { useKeepOnDowngradeMutation } from "../../services/subscriptionAPI";
import { toast } from "react-toastify";
import { handleError } from "../../utils/ErrorHandling";

export type Consumables = {
  users: { [key: string]: boolean };
  devices: { [key: string]: boolean };
  dashboards: { [key: string]: boolean };
};
type selectFormat = { value: string; label: string }[];

function KeepOnSubscriptionDowngrade() {
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(
    LanguageCheck(
      English,
      French,
      German,
      Dutch,
      Spanish,
      Italian,
      Romanian,
      Portuguese,
      stateLang
    )
  );
  useEffect(() => {
    setLanguage(
      LanguageCheck(
        English,
        French,
        German,
        Dutch,
        Spanish,
        Italian,
        Romanian,
        Portuguese,
        stateLang
      )
    );
  }, [stateLang]);

  const { groupid } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    data: group,
    isFetching: isGetGroupOnLimitFetching,
    isError: isGetGroupOnLimitError,
    isSuccess: isGetGroupOnLimitSuccess,
    error: getGroupOnLimiterror,
  } = useGetGroupOnLimitQuery(groupid ?? "");
  const [selectedConsumables, setSelectedConsumables] = useState<Consumables>({
    users: {},
    devices: {},
    dashboards: {},
  });
  const [keepOnDowngrade, { isLoading, isError, error, isSuccess, data }] =
    useKeepOnDowngradeMutation();

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(groupAPI.util.invalidateTags(["Group"]));
      navigate(`/group/${groupid}`);
      toast.success(data.message);
    }
  }, [isSuccess, navigate, groupid, data]);

  useEffect(() => {
    if (isError && error) {
      let errorMessage = handleError(error);
      toast.error(errorMessage);
    }
  }, [isError, error]);

  useEffect(() => {
    //Initiate the selection to all be false
    if (isGetGroupOnLimitSuccess) {
      if (group.users) {
        setSelectedConsumables({
          users: _.mapValues(group.users, () => false),
          devices: _.mapValues(group.devices, () => false),
          dashboards: _.mapValues(group.dashboards, () => false),
        });
      }
    }
  }, [group, isGetGroupOnLimitSuccess]);

  function setSelectedItemCollection(id: string, value: boolean, key: string) {
    setSelectedConsumables((selectedConsumables) => {
      return {
        ...selectedConsumables,
        [key]: {
          ...selectedConsumables[key as keyof Consumables],
          [id]: value,
        },
      };
    });
  }

  console.log(selectedConsumables);
  console.log(group);

  return (
    <div className="h-full w-full text-black bg-white rounded-lg overflow-y-scroll scrollbar-thin">
      <div className="flex flex-col justify-between pb-2 h-full">
        {isGetGroupOnLimitSuccess &&
        Object.keys(selectedConsumables.users).length > 0 ? (
          <>
            <div>
              <div className="pb-2">
                <h2 className="text-md">
                  {language.subscription.keepOnDowngrade.description}
                </h2>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                <div>
                  <h3 className="text-lg">
                    {language.subscription.keepOnDowngrade.body.titles.users} (
                    {language.subscription.keepOnDowngrade.body.max}{" "}
                    {group.subscription.perks.users})
                  </h3>
                  <SwitchSection
                    itemCollection={_.map(group.users, (user) => {
                      return { value: user.user_id, label: user.username };
                    })}
                    selectedItemCollection={selectedConsumables?.users}
                    setSelectedItemCollection={setSelectedItemCollection}
                    type="users"
                    maxItems={group.subscription.perks.users}
                  />
                </div>
                <div>
                  <h3 className="text-lg">
                    {
                      language.subscription.keepOnDowngrade.body.titles
                        .dashboards
                    }{" "}
                    ({language.subscription.keepOnDowngrade.body.max}{" "}
                    {group.subscription.perks.dashboards})
                  </h3>
                  <SwitchSection
                    itemCollection={_.map(group.dashboards, (dashboard) => {
                      return {
                        value: dashboard.dashboard_id,
                        label: dashboard.name,
                      };
                    })}
                    selectedItemCollection={selectedConsumables?.dashboards}
                    setSelectedItemCollection={setSelectedItemCollection}
                    type="dashboards"
                    maxItems={group.subscription.perks.dashboards}
                  />
                </div>
                <div>
                  <h3 className="text-lg">
                    {language.subscription.keepOnDowngrade.body.titles.devices}{" "}
                    ({language.subscription.keepOnDowngrade.body.max}{" "}
                    {group.subscription.perks.devices})
                  </h3>
                  <SwitchSection
                    itemCollection={_.map(group.devices, (device) => {
                      return {
                        value: device.device_id,
                        label: device.device_name,
                      };
                    })}
                    selectedItemCollection={selectedConsumables?.devices}
                    setSelectedItemCollection={setSelectedItemCollection}
                    type="devices"
                    maxItems={group.subscription.perks.devices}
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex justify-end">
              <div className="w-fit ">
                <Button
                  onClick={() =>
                    keepOnDowngrade({
                      group_id: groupid ?? "",
                      consumables: selectedConsumables,
                    })
                  }
                  label={
                    isLoading ? (
                      <Spinner colour="fill-green-600" />
                    ) : (
                      language.viewEditGroup.buttons.save
                    )
                  }
                  colour="green"
                />
              </div>
            </div>
          </>
        ) : (
          <Spinner colour="fill-rpr-blue" />
        )}
      </div>
    </div>
  );
}

export default KeepOnSubscriptionDowngrade;

type switchSectionProps = {
  itemCollection: selectFormat;
  selectedItemCollection: { [key: string]: boolean };
  setSelectedItemCollection: (id: string, value: boolean, key: string) => void;
  type: string;
  maxItems: number;
};

const SwitchSection = ({
  itemCollection,
  selectedItemCollection,
  setSelectedItemCollection,
  type,
  maxItems,
}: switchSectionProps) => {
  const [capacityReached, setCapacityReached] = useState(false);
  useEffect(() => {
    if (itemCollection) {
      const selectedItems = _.filter(selectedItemCollection, (item) => {
        return item;
      });
      if (selectedItems.length >= maxItems) {
        setCapacityReached(true);
      } else {
        setCapacityReached(false);
      }
    }
  }, [itemCollection, maxItems]);
  return (
    <div className="mr-1">
      {_.map(itemCollection, (item, index) => {
        return (
          <div className="mb-1" key={index}>
            <PermSwitch
              permissionName={item.label}
              defaultValue={selectedItemCollection[item.value]}
              capacityReached={capacityReached}
              onChange={(value) => {
                setSelectedItemCollection(item.value, value, type);
              }}
            />
            <hr />
          </div>
        );
      })}
    </div>
  );
};

type PermSwitchProps = {
  permissionName: string;
  defaultValue: boolean;
  onChange: (value: boolean) => void;
  capacityReached: boolean;
};

const PermSwitch = ({
  permissionName,
  defaultValue,
  onChange,
  capacityReached,
}: PermSwitchProps) => {
  const [value, setValue] = useState<boolean>(defaultValue);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  return (
    <>
      <div
        onClick={() => {
          if ((value === true && capacityReached) || !capacityReached) {
            onChange(!value);
            setValue((value) => !value);
          }
        }}
        className={`py-1 px-1 ${
          value ? "bg-white" : capacityReached ? "bg-gray-300" : "bg-gray-100"
        }`}
      >
        <div className="flex flex-row justify-between">
          <div className="flex items-center">
            <Switch
              checked={value}
              key={permissionName}
              className={`${
                value
                  ? "bg-green-400"
                  : capacityReached
                  ? "bg-red-700"
                  : "bg-red-600"
              } relative inline-flex h-[24px] w-[44px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-dashboards duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span
                aria-hidden="true"
                className={`${
                  value ? "translate-x-[20px]" : "translate-x-0"
                } pointer-events-none inline-block h-[20px] w-[20px] bg-white transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          </div>
          <div className="flex items-center text-right">{permissionName}</div>
        </div>
      </div>
    </>
  );
};
