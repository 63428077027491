import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { RootState } from "../../app/store";
import { ChartType } from "../../components/dashboard/charts";

export type Dashboard = {
  name: string;
  id: string;
  description: string;
  created_at: Date;
  updated_at: Date;
  group_id: string;
  data?: DashboardData;
  lastFetch: string;
  firstFetch: string;
  editing: boolean;
};

export type NewDashboard = {
  name: string;
  description: string;
  group_id: string;
};

export type DashboardData = {
  layouts: Layout[];
  charts: ChartType[];
};

export type Layout = {
  w: number;
  h: number;
  x: number;
  y: number;
  sw?: number;
  sh?: number;
  sx?: number;
  sy?: number;
  i: string;
};

type Chart = {
  i: string;
  type: string;
  title: Language;
  chartData: ChartData[];
};

type Language = {
  en: string;
};

type ChartData = {
  phenomena: string;
  deviceID: string;
  elemID: string;
  label: string;
  colour: string;
  unit: string;
  min?: number;
  max?: number;
  ringID?: string;
  lineID?: string;
  style?: string;
  connectedNulls?: boolean;
  smoothed?: boolean;
};

export type DashboardState = {
  dashboards: Dashboards;
};

export type Dashboards = {
  [key: string]: Dashboard;
};

const initialState: DashboardState = {
  dashboards: {},
};

type UpdateFetchPayload = {
  id: string;
  lastFetch: string;
  firstFetch: string;
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboards: (state: DashboardState, action: PayloadAction<Dashboards>) => {
      for (const key in action.payload) {
        if (
          state.dashboards[action.payload[key].id] === undefined ||
          state.dashboards[action.payload[key].id].editing === false
        ) {
          var lastFetch = moment()
            .subtract(7, "days")
            .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
          var firstFetch = moment()
            .subtract(7, "days")
            .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
          if (state.dashboards[action.payload[key].id] !== undefined) {
            lastFetch = state.dashboards[action.payload[key].id].lastFetch;
            firstFetch = state.dashboards[action.payload[key].id].firstFetch;
          }
          state.dashboards[action.payload[key].id] = {
            ...action.payload[key],
            lastFetch,
            firstFetch,
            editing: false,
          };
        }
      }
    },
    setDashboard: (state: DashboardState, action: PayloadAction<Dashboard>) => {
      var lastFetch = moment().subtract(7, "days").format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
      var firstFetch = moment()
        .subtract(7, "days")
        .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");

      if (state.dashboards[action.payload.id] !== undefined) {
        lastFetch = state.dashboards[action.payload.id].lastFetch;
        firstFetch = state.dashboards[action.payload.id].firstFetch;
      }
      state.dashboards[action.payload.id] = {
        ...action.payload,
        lastFetch,
        firstFetch,
        editing: state.dashboards[action.payload.id]?.editing ?? false,
      };
    },
    clearDashboards: (state: DashboardState) => {
      state.dashboards = {};
    },
    updateFetchTimes: (
      state: DashboardState,
      action: PayloadAction<UpdateFetchPayload>
    ) => {
      //if the latest fetch is later then last fetch, update the last fetch
      if (
        moment(action.payload.lastFetch).isAfter(
          state.dashboards[action.payload.id].lastFetch
        )
      ) {
        state.dashboards[action.payload.id].lastFetch = action.payload.lastFetch;
      }
      //if the first fetch is earlier then first fetch, update the first fetch
      if (
        moment(action.payload.firstFetch).isBefore(
          state.dashboards[action.payload.id].firstFetch
        )
      ) {
        state.dashboards[action.payload.id].firstFetch = action.payload.firstFetch;
      }

      if (state.dashboards[action.payload.id].lastFetch === undefined) {
        state.dashboards[action.payload.id].lastFetch =
          action.payload.lastFetch ??
          moment().subtract(7, "days").format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
      }
      if (state.dashboards[action.payload.id].firstFetch === undefined) {
        state.dashboards[action.payload.id].firstFetch =
          action.payload.firstFetch ??
          moment().subtract(7, "days").format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
      }

      //console.log("last fetch updated to: " + action.payload.lastFetch);
      //console.log("first fetch updated to: " + action.payload.firstFetch);
    },
    editingDashboard: (state: DashboardState, action: PayloadAction<string>) => {
      //set all other dashboards to not editing
      for (const key in state.dashboards) {
        state.dashboards[key].editing = false;
      }
      //set the specified dashboard to editing
      state.dashboards[action.payload].editing = true;
    },
    stopEditingDashboard: (state: DashboardState) => {
      for (const key in state.dashboards) {
        state.dashboards[key].editing = false;
      }
    },
    deleteDashboard: (state: DashboardState, action: PayloadAction<string>) => {
      delete state.dashboards[action.payload];
    },
  },
});

export const selectDashboards = (state: RootState) => state.dashboards;
export const selectLastFetch = (state: RootState, id: string) =>
  state.dashboards.dashboards[id].lastFetch;

export const {
  setDashboards,
  setDashboard,
  clearDashboards,
  updateFetchTimes,
  editingDashboard,
  stopEditingDashboard,
  deleteDashboard,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
