import { useGetGroupUserInfoQuery } from "../services/userAPI";

export const GetGroupUserInfo = ({
  groupid,
  userid,
}: {
  groupid: string;
  userid: string;
}) => {
  const { data, isLoading, error } = useGetGroupUserInfoQuery({
    groupID: groupid || "",
    userID: userid,
  });
  return { data, isLoading, error };
};

export const mapObjectToArray = (mapObject: any) =>
  Object.keys(mapObject).map((key) => {
    return { id: key, ...mapObject[key] };
  });

export const convertNotificationRulesArrayIntoMap = (data: any) => {
  // Create an empty object to hold the transformed rules
  const transformedRules: { [key: string]: any } = {};

  // Iterate over each rule in the rules array
  data.rules.forEach((rule: any) => {
    // Extract the id to be used as the key
    const ruleId: any = rule.id;

    // Copy the rule object without the id property
    const ruleWithoutId = { ...rule };
    delete ruleWithoutId.id;

    // Add the rule to the transformedRules object with the id as the key
    transformedRules[ruleId] = ruleWithoutId;
  });

  // Return the modified data with the rules object
  return {
    ...data,
    rules: transformedRules,
  };
};

export const generateBsonObjectId = () => {
  // Timestamp (4 bytes)
  const timestamp = Math.floor(new Date().getTime() / 1000).toString(16);

  // Random value (5 bytes)
  const randomValue = Math.floor(Math.random() * 0xffffff)
    .toString(16)
    .padStart(5, "0");

  // Machine identifier and process identifier (3 bytes + 2 bytes)
  const machineIdentifier = Math.floor(Math.random() * 0xffffff)
    .toString(16)
    .padStart(6, "0");
  const processIdentifier = Math.floor(Math.random() * 0xffff)
    .toString(16)
    .padStart(4, "0");

  // Counter (3 bytes)
  let counter = Math.floor(Math.random() * 0xffffff)
    .toString(16)
    .padStart(6, "0");

  return timestamp + machineIdentifier + processIdentifier + randomValue + counter;
};

export const convertDaysAndHoursToMinutes = (days: number, hours: number) => {
  const minutesInADay = 1440;
  const minutesInAnHour = 60;
  return days * minutesInADay + hours * minutesInAnHour;
};

export const convertMinutesToDaysAndHours = (min: number) => {
  const minutesInADay = 1440;
  const minutesInAnHour = 60;
  // Calculate days and remaining hours
  const days = Math.floor(min / minutesInADay);
  const hours = Math.floor((min % minutesInADay) / minutesInAnHour);

  return {
    days: days,
    hours: hours,
  };
};
