import { ChartType } from "..";
import MDEditor from "@uiw/react-md-editor";
import { BsCardText } from "react-icons/bs";

type Props = {
  ChartData: ChartType;
  FetchSuccess: boolean;
  FetchError: boolean;
};

function Text({ ChartData }: Props) {
  return (
    <>
      <div className="w-full h-full overflow-scroll scrollbar-none">
        <div>
          {ChartData.title.en}
        </div>
        <div className="h-full">
          {ChartData?.chartData?.[0]?.label ?
            <MDEditor.Markdown source={ChartData.chartData[0].label} />
            :
            <div className="w-full h-full flex items-center justify-center" >
              <div className="relative w-full h-full">
                <div className="absolute w-full h-full flex items-center justify-center opacity-5">
                  <BsCardText className="w-full h-full" />;
                </div>
                <div className="flex h-full w-full justify-center items-center font-semibold">
                  Edit text to add content
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
}

export default Text;
