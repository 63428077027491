import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ChartScale, GaugeData } from "..";
import { useAppSelector } from "../../../../app/hooks";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../../../dictionary/ChartsText";
import { Dashboards, selectDashboards } from "../../../../features/dashboards/dashboardSlice";
import { Device, PhenomenaConfiguration, selectDevices } from "../../../../features/devices/deviceSlice";
import { FormInput } from "../../../FormInput";
import { LanguageCheck } from "../../../../utils/LanguageCheck";
import { selectLanguage } from "../../../../features/user/userSlice";

type Props = {
    ring: GaugeData;
    index: number;
    saveRingData: (ring: GaugeData) => void;
    removeRing: (index: string) => void;
    chartScale: ChartScale;
    setChartScale: (chartScale: ChartScale) => void;
    remove?: boolean;
};

type FormInputData = {
    optionValue: string;
    optionName: string;
};

function NewComparisonTableMenu({
    ring: currentRing,
    index,
    saveRingData,
    chartScale,
    setChartScale,
    removeRing,
    remove,
}: Props) {

    const stateLang = useAppSelector(selectLanguage);
    let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    useEffect(() => {
        setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    }, [stateLang]);

    const [ring, setRing] = useState<GaugeData>(currentRing);
    let { devices } = useAppSelector(selectDevices);
    let { dashboards } = useAppSelector(selectDashboards);
    const [phenomenaSelectData, setPhenomenaSelectData] = useState<FormInputData[]>([]);
    const [userDeviceData, setUserDeviceData] = useState<FormInputData[]>([]);
    const [selectedPhenomenaElem, setSelectedPhenomenaElem] = useState<string>("new");
    let { dashboardid } = useParams();

    function GetRingFromParams() {
        setRing(currentRing);
        setSelectedPhenomenaElem(
            currentRing.phenomena == "new"
                ? "new"
                : JSON.stringify({
                    phenomena: currentRing.phenomena,
                    elemID: currentRing.elemID ?? "0",
                })
        );
    }

    function populatePhenomenaSelect() {
        var currDevice = _.find(devices, (device) => device.eui === ring.deviceID)
        if (currDevice !== undefined) {
            var tempPhenomenaSelectData: FormInputData[] = [{
                optionValue: "new",
                optionName: language.charts.gauge.inputDefaults.selectParameter,
            }];
            var currDeviceConfig: [string, PhenomenaConfiguration[]][] = _.entries(currDevice.configuration);

            _.forEach(currDeviceConfig, ([key, value]) => {
                _.forEach(value, (element: PhenomenaConfiguration) => {
                    tempPhenomenaSelectData.push({
                        optionValue: JSON.stringify({
                            phenomena: key,
                            elemID: element.elemID ?? "0",
                        }),
                        optionName: element.description,
                    });
                });
            });
            setPhenomenaSelectData(tempPhenomenaSelectData);
        }
    }

    function populateDeviceSelect() {
        var tempUserDeviceData: FormInputData[] = [{
            optionValue: "new",
            optionName: language.charts.gauge.inputDefaults.selectDevice,
        }];
        const dashboardDevices: Device[] = _.filter(devices, (device: Device) => {
            return device.group_id === dashboards[dashboardid as keyof Dashboards].group_id;
        });
        _.map(dashboardDevices, (device: Device) => {
            if (device.eui !== null && device.name !== null) {
                tempUserDeviceData.push({
                    optionValue: device.eui,
                    optionName: device.name,
                });
            }
        });
        setUserDeviceData(tempUserDeviceData);
    }

    useEffect(() => {
        GetRingFromParams();
    }, [currentRing]);

    useEffect(() => {
        populatePhenomenaSelect();
    }, [ring.deviceID, userDeviceData]);

    useEffect(() => {
        populateDeviceSelect();
    }, [devices]);

    function changeDevice(event: React.ChangeEvent<HTMLSelectElement>) {
        setSelectedPhenomenaElem(
            JSON.stringify({
                phenomena: "new",
                elemID: "",
            })
        );
        setRing({ ...ring, phenomena: "new", elemID: "", unit: "", deviceID: event.target.value });
        saveRingData({ ...ring, phenomena: "new", elemID: "", unit: "", deviceID: event.target.value });
    }

    function changePhenomena(event: React.ChangeEvent<HTMLSelectElement>) {
        const { phenomena, elemID } = JSON.parse(event.target.value);
        setSelectedPhenomenaElem(
            JSON.stringify({
                phenomena: phenomena,
                elemID: elemID ?? "0",
            })
        );
        var unit: string | undefined = ""
        _.map(devices, (device) => {
            if (device.eui === ring.deviceID) {
                unit = _.find(device.configuration[phenomena], { elemID: elemID })?.unit;
            }
        });
        setRing({ ...ring, phenomena: phenomena, elemID: elemID, unit: unit });
        saveRingData({ ...ring, phenomena: phenomena, elemID: elemID, unit: unit });
    }

    return (
        <div key={index} className="rounded-md bg-gray-200 p-1 flex flex-col my-2">
            <FormInput
                value={ring.deviceID ?? "new"}
                type="select"
                label={language.charts.gauge.labels.device}
                htmlFor="device"
                data={userDeviceData}
                onChange={changeDevice}
            />
            <FormInput
                disabled={ring.deviceID === "new"}
                htmlFor="phenomena"
                type="select"
                label={language.charts.gauge.labels.parameter}
                data={phenomenaSelectData}
                value={selectedPhenomenaElem}
                onChange={changePhenomena}
            />
            {remove &&
                <button onClick={() => removeRing(ring.ringID)}>
                    {language.charts.barChart.buttons.remove}
                </button>
            }
        </div>
    );
}

export default NewComparisonTableMenu;
