import _ from "lodash";
import { useEffect, useState } from "react";
import { LineChartData } from "..";
import { useAppSelector } from "../../../../app/hooks";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../../../dictionary/ChartsText";
import { LanguageCheck } from "../../../../utils/LanguageCheck";
import { selectLanguage } from "../../../../features/user/userSlice";
import MDEditor from "@uiw/react-md-editor";
import sanitizeHtml from "sanitize-html";

type Props = {
  line: LineChartData;
  index: number;
  saveLineData: (line: LineChartData) => void;
  removeLine: (index: string) => void;
  remove?: boolean;
};

function NewTextMenu({ line: currentLine, index, saveLineData }: Props) {

  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  const [line, setLine] = useState<LineChartData>(currentLine);

  const filteredCommands = /(live|edit|fullscreen|preview|comment|image|link|codeBlock|hr|checked-list|unordered-list|ordered-list)/;

  const sanitizeConf = {
    allowedTags: ["i"],
    allowedAttributes: {},
  };

  function GetLineFromParams() {
    setLine(currentLine);
  }

  useEffect(() => {
    GetLineFromParams();
  }, [currentLine]);

  return (
    <div key={index} className="rounded-md bg-gray-200 p-1.5 flex flex-col my-2">
      <div className="space-y-2">
        <div>
          <MDEditor height={200} value={line.label}
            onChange={(e) => {
              if (e || e === "") {
                saveLineData({ ...line, label: (sanitizeHtml(e, sanitizeConf)) })
              }
            }}
            preview="edit"
            commandsFilter={(cmd) => {
              if (cmd.name) {
                if (filteredCommands.test(cmd.name)) {
                  return false
                }
              }
              return (cmd)
            }}
          />
        </div>
        <div>
          <MDEditor.Markdown className="min-h-[200px] max-h-[295px] overflow-y-scroll scrollbar-none" source={line.label} />
        </div>
      </div>
    </div>
  );
}

export default NewTextMenu;
