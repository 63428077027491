import { UserPermissions } from "../features/groups/groupSlice";

export const memberPreset: UserPermissions = {
  can_claim: true,
  can_unclaim: false,
  can_view_devices: {
    can_view_all: true,
    can_view_any: true,
    can_view_devices: {},
  },
  can_edit_devices: {
    can_edit_all: false,
    can_edit_any: false,
    can_edit_devices: {},
  },
  can_add_dashboards: false,
  can_remove_dashboards: false,
  can_view_dashboards: {
    can_view_all: true,
    can_view_any: true,
    can_view_dashboards: {},
  },
  can_edit_dashboards: {
    can_edit_all: false,
    can_edit_any: false,
    can_edit_dashboards: {},
  },
  can_invite_users: false,
  can_remove_users: false,
  can_view_users: true,
  can_edit_users_permissions: false,
  can_edit_group: false,
  can_delete_group: false,
};
export const adminPreset: UserPermissions = {
  can_claim: true,
  can_unclaim: true,
  can_view_devices: {
    can_view_all: true,
    can_view_any: true,
    can_view_devices: {},
  },
  can_edit_devices: {
    can_edit_all: true,
    can_edit_any: true,
    can_edit_devices: {},
  },
  can_add_dashboards: true,
  can_remove_dashboards: true,
  can_view_dashboards: {
    can_view_all: true,
    can_view_any: true,
    can_view_dashboards: {},
  },
  can_edit_dashboards: {
    can_edit_all: true,
    can_edit_any: true,
    can_edit_dashboards: {},
  },
  can_invite_users: true,
  can_remove_users: true,
  can_view_users: true,
  can_edit_users_permissions: true,
  can_edit_group: true,
  can_delete_group: false,
};
