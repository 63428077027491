import { Tooltip } from "flowbite-react";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

type FormInputProps = {
  htmlFor: string;
  label: string;
  type?: string;
  value: any;
  title?: boolean;
  onChange?: (...args: any) => any;
  onKeyDown?: (...args: any) => any;
  inputmode?:
    | "email"
    | "search"
    | "text"
    | "tel"
    | "url"
    | "none"
    | "numeric"
    | "decimal"
    | undefined;
  error?: string;
  required?: boolean;
  tooltip?: string;
  data?: FormInputData[];
  autoComplete?: string;
  disabled?: boolean;
  step?: number;
  loading?: boolean;
  minLength?: number;
  maxLength?: number;
};

type FormInputData = {
  optionValue: string;
  optionName: string;
};

export function FormInput({
  htmlFor,
  label,
  type = "text",
  value = "",
  title = true,
  onChange = () => {},
  onKeyDown = () => {},
  error = "",
  required = false,
  tooltip = "",
  inputmode = undefined,
  data = [],
  autoComplete,
  disabled = false,
  step = 1,
  loading = false,
  minLength = 0,
}: FormInputProps) {
  const [inputValue, setInputValue] = useState(value);
  const [labelValue, setLabelValue] = useState(label);
  const [dataValue, setDataValue] = useState(data);

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value);
      //console.log("value", value);
    }
  }, [value]);

  useEffect(() => {
    if (labelValue !== label) {
      setLabelValue(label);
      //console.log("label", label);
    }
  }, [label]);

  useEffect(() => {
    if (data.length > 0) {
      setDataValue(data);
      //console.log("data", data);
    }
  }, [data]);

  const Label = (label: string, htmlFor: string) => {
    return (
      <label htmlFor={htmlFor} className="text-blue-600 font-semibold">
        {label}
      </label>
    );
  };

  return (
    <div className="">
      {title ? (
        tooltip ? (
          <Tooltip content={tooltip} trigger="hover">
            {Label(labelValue, htmlFor)}
          </Tooltip>
        ) : loading ? (
          <Skeleton width={100} height={20} />
        ) : (
          Label(labelValue, htmlFor)
        )
      ) : null}
      {loading ? (
        <Skeleton width="100%" height={44} />
      ) : type === "select" ? (
        <select
          disabled={disabled}
          onChange={(e) => {
            setInputValue(e.target.value);
            onChange(e);
          }}
          id={htmlFor}
          name={htmlFor}
          placeholder={labelValue}
          className={`w-full p-2 rounded-xl  border-2 ${
            error ? "border-red-600" : "border-gray-300"
          }`}
          value={inputValue}
        >
          {dataValue.map((item, index) => (
            <option
              key={index}
              value={item?.optionValue}
              disabled={item?.optionValue == "new"}
              //selected={item?.optionValue == "new"}
              hidden={item?.optionValue == "new"}
            >
              {item.optionName}
            </option>
          ))}
        </select>
      ) : (
        <input
          disabled={disabled}
          required={required}
          onChange={(e) => {
            setInputValue(e.target.value);
            onChange(e);
          }}
          onKeyDown={onKeyDown}
          step={step}
          type={type}
          inputMode={inputmode}
          id={htmlFor}
          name={htmlFor}
          autoComplete={autoComplete}
          placeholder={labelValue}
          minLength={minLength}
          className={`w-full p-2 rounded-xl  border-2 min-h-[2.75rem] ${
            error ? "border-red-600" : "border-gray-300"
          }
          ${disabled ? "bg-gray-200" : ""}
          `}
          value={inputValue}
        ></input>
      )}
      {error && <p className="text-red-600">{error}</p>}
    </div>
  );
}
