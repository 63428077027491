import { ReactNode, useCallback, useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./dashboard.css";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  LineChart,
  Gauge,
  BarChart,
  ScatterChart,
  Calendar,
  ImageMap,
  Map,
  Value,
  Text,
  Table,
  Status,
  ActivityChart,
  EditBarChart,
  EditScatterChart,
  EditGauge,
  EditCalendar,
  EditTable,
  EditText,
  EditMap,
  EditImageMap,
  EditLineChart,
  ChartType,
  EditValue,
  EditStatus,
  EditActivityChart,
} from "../../components/dashboard/charts";
import ReactTooltip from "react-tooltip";
import ChartSelect from "../../components/dashboard/ChartSelect";
import DashboardBlockEdit from "../../components/dashboard/DashboardBlockEdit";
import EditChartSettings from "../../components/dashboard/EditChartSettings";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAuth } from "../../features/auth/authSlice";
import {
  dashboardAPI,
  useDeleteDashboardMutation,
  useGetDashboardDataQuery,
  useGetDashboardQuery,
  useLazyBackFillDashboardDataQuery,
  useSaveDashboardMutation,
} from "../../services/dashboardAPI";
import { useNavigate, useParams } from "react-router-dom";
import {
  DashboardData,
  Dashboards,
  Layout,
  editingDashboard,
  selectDashboards,
  setDashboard,
  stopEditingDashboard,
} from "../../features/dashboards/dashboardSlice";
import { handleError } from "../../utils/ErrorHandling";
import { selectLanguage, selectUser } from "../../features/user/userSlice";
import { UserGroup } from "../../services/userAPI";
import { FiEdit2 } from "react-icons/fi";
import { MdMoreTime } from "react-icons/md";
import ContentEditable from "react-contenteditable";
import sanitizeHtml from "sanitize-html";
import { useLazyGetDataQuery } from "../../services/dataAPI";
import { DataType, selectData } from "../../features/data/dataSlice";
import AcceptModal from "../../components/modals/AcceptModal";
import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
} from "../../dictionary/DashboardText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { toast } from "react-toastify";
import Spinner from "../../components/Spinner";
import { Button } from "../../components/Button";
import { BsCardText, BsCloudCheck } from "react-icons/bs";
import { BiUndo } from "react-icons/bi";
import { FaList, FaUndo } from "react-icons/fa";
import useWindowDimensions from "../../utils/ScreenDimensions";
import {
  AiOutlineLineChart,
  AiOutlineBarChart,
  AiOutlineDotChart,
  AiOutlineTable,
  AiOutlineFieldNumber,
} from "react-icons/ai";
import { IoCalendarSharp, IoMap, IoImageOutline } from "react-icons/io5";
import { TbGauge } from "react-icons/tb";
import { RiTableLine } from "react-icons/ri";
import Graph from "../../components/dashboard/charts/Graph";
import { selectGroups } from "../../features/groups/groupSlice";
import RenameDashboardModal from "./RenameDashboardModal";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

function ViewDashboard() {
  //get data from useGetDashboardQuery
  const auth = useAppSelector(selectAuth);
  const user = useAppSelector(selectUser);
  const dashboards = useAppSelector(selectDashboards);
  const groups = useAppSelector(selectGroups);
  const navigate = useNavigate();
  const { dashboardid } = useParams();
  const { width } = useWindowDimensions();
  const nodeData = useAppSelector(selectData);
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(
    LanguageCheck(
      English,
      French,
      German,
      Dutch,
      Spanish,
      Italian,
      Romanian,
      Portuguese,
      stateLang
    )
  );

  const [dashboardIsDragging, setDashboardIsDragging] = useState(false);
  useEffect(() => {
    setLanguage(
      LanguageCheck(
        English,
        French,
        German,
        Dutch,
        Spanish,
        Italian,
        Romanian,
        Portuguese,
        stateLang
      )
    );
  }, [stateLang]);

  const [
    getDeviceData,
    {
      isSuccess: isGetDeviceDataSuccess,
      isError: isGetDeviceDataError,
      isFetching: isGetDeviceDataFetching,
      error: getDeviceDataError,
    },
  ] = useLazyGetDataQuery();

  const [
    backFillData,
    {
      isSuccess: isBackFillDataSuccess,
      isError: isBackFillDataError,
      isFetching: isBackFillDataFetching,
      error: backFillDataError,
    },
  ] = useLazyBackFillDashboardDataQuery();

  const {
    isError: isDashboardError,
    isSuccess: isDashboardSuccess,
    error: dashboardError,
    data: dashboardData,
  } = useGetDashboardQuery(
    { id: dashboardid ?? "" },
    {
      skip:
        !dashboardid ||
        !auth.verifiedEmail ||
        !auth.token ||
        dashboards === undefined,
      pollingInterval: 0,
    }
  );

  useEffect(() => {
    if (isGetDeviceDataError) {
      handleError(getDeviceDataError);
    }
  }, [isGetDeviceDataError]);

  useEffect(() => {
    if (isDashboardError) {
      handleError(dashboardError);
      navigate("/dashboard");
    }
  }, [dashboardError]);

  const {
    isError: isDashboardPhenomError,
    isSuccess: isDashboardPhenomSuccess,
    error: dashboardPhenomError,
    data: dashboardPhenomData,
  } = useGetDashboardDataQuery(
    {
      id: dashboardid,
    },
    {
      skip:
        !dashboardid ||
          !auth.verifiedEmail ||
          !auth.token ||
          !isDashboardSuccess ||
          dashboards.dashboards === undefined
          ? true
          : false,
      pollingInterval: 60000,
      refetchOnFocus: true,
    }
  );

  const sanitizeConf = {
    allowedTags: ["i"],
    allowedAttributes: {},
  };

  const sanitize = (name: string) => {
    setDashboardName(sanitizeHtml(name, sanitizeConf));
  };

  const [
    saveDashboard,
    {
      isError: isSaveDashboardError,
      error: saveDashboardError,
      isSuccess: isSaveDashboardSuccess,
      isLoading: isSaveDashboardLoading,
    },
  ] = useSaveDashboardMutation();

  useEffect(() => {
    if (isSaveDashboardSuccess) {
      /* toast.success(language.viewDashboard.toasts.saved); */
    }
  }, [isSaveDashboardSuccess]);

  useEffect(() => {
    if (isSaveDashboardError) {
      handleError(saveDashboardError);
    }
  }, [isSaveDashboardError]);

  const [layout, setLayout] = useState<Layout[]>([]);
  const [smallLayout, setSmallLayout] = useState<Layout[]>([]);
  const [items, setItems] = useState<ChartType[]>([]);
  const [colls, setColls] = useState(5);
  const [breakpoint, setBreakpoint] = useState("lg");
  const [editPermission, setEditPermission] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [showChartEdit, setShowChartEdit] = useState<boolean>(false);
  const [chartEditInfo, setChartEditInfo] = useState<ReactNode>();
  const [dashboardName, setDashboardName] = useState<string>(
    dashboards?.dashboards?.[dashboardid as keyof Dashboards]?.name ??
    "Edit Dashboard Name"
  );
  const [dashboardDescription, setDashboardDescription] = useState<string>(
    dashboards?.dashboards?.[dashboardid as keyof Dashboards]?.description ??
    "Edit Dashboard Description"
  );
  const [maxDashboardElements, setMaxDashboardElements] = useState<number>(0);
  const dispatch = useAppDispatch();
  const [showRenameDashboardModal, setShowRenameDashboardModal] = useState(false);

  useEffect(() => {
    if (Object.keys(dashboards.dashboards).length) {
      if (user.groups) {
        if (
          user.groups[
          dashboards.dashboards[dashboardid as keyof Dashboards]
            .group_id as keyof UserGroup
          ]
        ) {
          const editPerms =
            (user.groups[
              dashboards.dashboards[dashboardid as keyof Dashboards]
                .group_id as keyof UserGroup
            ].permissions.can_edit_dashboards?.can_edit_all !== undefined &&
              user.groups[
                dashboards.dashboards[dashboardid as keyof Dashboards]
                  .group_id as keyof UserGroup
              ].permissions.can_edit_dashboards?.can_edit_all !== false) ||
            user.groups[
              dashboards.dashboards[dashboardid as keyof Dashboards]
                .group_id as keyof UserGroup
            ].permissions.can_edit_dashboards?.can_edit_dashboards?.[
            dashboardid ?? ""
            ];
          setEditPermission(editPerms ?? false);
          setMaxDashboardElements(
            groups?.groups?.[
              dashboards.dashboards[dashboardid as keyof Dashboards]
                .group_id as keyof UserGroup
            ]?.subscription?.perks?.dashboard_elements ?? 0
          );
        }
      }
    }
  }, [user, dashboards, dashboardid]);

  useEffect(() => {
    if (editItem) setEditItem(false);
    if (dashboardIsDragging)
      toast.info(
        "There is a known issue when a dashboard element is clicked but not dragged. Please refresh your page to view the new dashboard if it is not visible.",
        { autoClose: 6000 }
      );
    dispatch(stopEditingDashboard());
  }, [dashboardid]);

  useEffect(() => {
    setLayout(
      dashboards?.dashboards?.[dashboardid as keyof Dashboards]?.data
        ?.layouts ?? []
    );
    if (
      dashboards?.dashboards?.[dashboardid as keyof Dashboards]?.data
        ?.layouts !== undefined &&
      dashboards?.dashboards?.[dashboardid as keyof Dashboards]?.data
        ?.layouts !== null
    ) {
      setSmallLayout(
        dashboards?.dashboards?.[
          dashboardid as keyof Dashboards
        ]?.data?.layouts.map((item: any) => {
          return {
            w: item.sw === 0 ? item.w : item.sw,
            h: item.sh === 0 ? item.h : item.sh,
            x: item.sx ?? item.x,
            y: item.sy ?? item.y,
            i: item.i,
          };
        }) ?? []
      );
    }

    setItems(
      dashboards?.dashboards?.[dashboardid as keyof Dashboards]?.data?.charts ??
      []
    );
    setDashboardName(
      dashboards?.dashboards?.[dashboardid as keyof Dashboards]?.name ??
      "Edit Dashboard Name"
    );
    setDashboardDescription(
      dashboards?.dashboards?.[dashboardid as keyof Dashboards]?.description ??
      "Edit Dashboard Description"
    );
  }, [dashboards, dashboardid]);

  const createElement = (el: ChartType) => {
    const i = el.i;
    const data = _.find(items, { i: i });

    return (
      <div key={i} className="bg-white p-2">
        <DashboardBlockEdit
          onSelect={() => {
            data !== undefined
              ? handleEditBlock(data.type, i)
              : console.log("no data");
          }}
          showEdit={editItem}
          setShowChartEdit={setShowChartEdit}
        />
        {data ? Chart(data) : null}
      </div>
    );
  };

  const onAddItem = (type: string) => {
    if (maxDashboardElements !== -1 && items.length >= maxDashboardElements) {
      toast.warning(
        "You have reached the maximum number of elements for this dashboard. Please remove an element or upgrade your subscription."
      );
      return;
    }
    /*eslint no-console: 0*/
    const id = uuidv4();
    //console.log("adding", id, "type", type);
    let newChartData: any[] = [];
    switch (type) {
      case "line":
      case "bar":
      case "scatter":
      case "gauge":
        newChartData = [
          {
            label: "",
            colour:
              "#" +
              Math.floor(Math.random() * 16777215)
                .toString(16)
                .padStart(6, "0")
                .toUpperCase(),
            phenomena: "new",
            ringID: uuidv4(),
            deviceID: "new",
            unit: "",
            min: 0,
            max: 10,
          },
        ];
        break;
      case "value":
      case "table":
        newChartData = [
          {
            label: "",
            colour: "#000000",
            phenomena: "new",
            ringID: uuidv4(),
            deviceID: "new",
            unit: "",
            min: 0,
            max: 10,
          },
        ];
        break;
      case "calendar":
        newChartData = [
          {
            label: "",
            colour: "#f6efa6,#bf444c",
            phenomena: "new",
            ringID: uuidv4(),
            deviceID: "new",
            unit: "",
            min: 0,
            max: 10,
          },
        ];
        break;
      default:
        break;
    }

    const newData: { layouts: any; charts: any } = {
      layouts: _.concat(layout!, {
        i: id,
        x: 0,
        y: Infinity,
        w: 5,
        h: 5,
        sx: 0,
        sy: Infinity,
        sw: 5,
        sh: 5,
      }),
      charts: _.concat(items!, {
        i: id,
        type: type,
        title: type === "map" ? { en: "7" } : { en: "" },
        chartData: newChartData,
        chartScale: {
          y_axis:
            type === "map"
              ? { min_auto: false, max_auto: true, max: -8240, min: 5100000 }
              : type === "table"
                ? { min_auto: true, max_auto: true, max: 1000, min: 0 }
                : { min_auto: true, max_auto: true, max: 10, min: 0 },
        },
      }),
    };

    const localDashboard = {
      ...dashboards.dashboards[dashboardid as keyof Dashboards],
      data: newData,
    };

    dispatch(setDashboard(localDashboard));
  };

  // We're using the cols coming back from this to calculate where to add new items.
  const onBreakpointChange = (breakpoint: string, cols: number) => {
    setBreakpoint(breakpoint);
    setColls(cols);
  };

  const onLayoutChange = (layout: any) => {
    //onLayoutChange(layout);
    var tempLayouts: Layout[] | undefined = [];

    if (dashboards.dashboards[dashboardid as keyof Dashboards]) {
      tempLayouts =
        dashboards.dashboards[dashboardid as keyof Dashboards].data?.layouts;
    }

    if (editItem) {
      if (tempLayouts) {
        width > 640
          ? (layout = layout.map((item: any) => {
            var currentLayout = _.filter(tempLayouts, function (o) {
              return o.i === item.i;
            })[0];
            return {
              w: item.w,
              h: item.h,
              x: item.x,
              y: item.y,
              sw: currentLayout.sw,
              sh: currentLayout.sh,
              sx: currentLayout.sx,
              sy: currentLayout.sy,
              i: item.i,
            };
          }))
          : (layout = layout.map((item: any) => {
            var currentLayout = _.filter(tempLayouts, function (o) {
              return o.i === item.i;
            })[0];
            return {
              w: currentLayout.w,
              h: currentLayout.h,
              x: currentLayout.x,
              y: currentLayout.y,
              sw: item.w,
              sh: item.h,
              sx: item.x,
              sy: item.y,
              i: item.i,
            };
          }));
      }
      console.log(layout);
      const localDashboard = {
        ...dashboards.dashboards[dashboardid as keyof Dashboards],
        data: {
          layouts: layout,
          charts: items,
        },
      };

      dispatch(setDashboard(localDashboard));
      //setLayout(layout);

      if (localDashboard && dashboardid) {
        saveDashboard({
          data: localDashboard.data,
          id: dashboardid,
          name: dashboardName,
          description: dashboardDescription,
        });
      }
    }
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 0);
  };

  const onRemoveItem = (i: any) => {
    //console.log("removing", i);
    const localDashboard = {
      ...dashboards.dashboards[dashboardid as keyof Dashboards],
      data: {
        layouts: _.reject(layout, { i: i }),
        charts: _.reject(items, { i: i }),
      },
    };
    dispatch(setDashboard(localDashboard));

    if (localDashboard && dashboardid) {
      saveDashboard({
        data: localDashboard.data,
        id: dashboardid,
        name: dashboardName,
        description: dashboardDescription,
      });
    }
    //setItems(_.reject(items, { i: i }));
    //setLayout(_.reject(layout, { i: i }));
  };

  const updateChart = (data: ChartType) => {
    if (data.chartData !== undefined) {
      if (data.chartData !== null) {
        data.chartData.forEach((element: any) => {
          console.log(element.deviceID);
          if (element.deviceID !== "custom" && element.deviceID !== "new") {
            if (
              nodeData.data[element.deviceID as keyof DataType] === undefined
            ) {
              getDeviceData({ eui: element.deviceID });
            } else if (
              nodeData.data[element.deviceID as keyof DataType].length <= 1
            ) {
              getDeviceData({ eui: element.deviceID });
            }
          }
        });
      }

      const localDashboard = {
        ...dashboards.dashboards[dashboardid as keyof Dashboards],
        data: {
          layouts:
            dashboards.dashboards[dashboardid as keyof Dashboards].data
              ?.layouts ?? [],
          charts: _.map(items, (item: ChartType) => {
            var newItem: ChartType = {
              i: "",
              title: {
                en: "",
              },
              chartData: undefined,
              type: "",
              chartScale: {
                y_axis: { min_auto: true, max_auto: true, max: 10, min: 0 },
              },
            };
            if (item.i === data.i) {
              newItem.i = data.i;
              newItem.type = data.type;
              newItem.title.en = data.title.en;
              newItem.chartData = data.chartData;
              newItem.chartScale = data.chartScale;
              newItem.chartConfig = data.chartConfig;
            } else {
              newItem = item;
            }

            return newItem;
          }),
        },
      };
      dispatch(setDashboard(localDashboard));

      if (localDashboard && dashboardid) {
        saveDashboard({
          data: localDashboard.data,
          id: dashboardid,
          name: dashboardName,
          description: dashboardDescription,
        });
      }

      console.log(localDashboard);
    }
  };

  const handleEditBlock = (type: string, i: string) => {
    const Editdata = _.find(items, { i: i });
    setEditItem(true);
    setChartEditInfo(ChartEdit(Editdata, i));
    return; //console.log("edit", i, type, Editdata);
  };

  const handleResize = useCallback(
    (
      l: any,
      oldLayoutItem: { h: number; w: number },
      layoutItem: { h: number; w: number },
      placeholder: { h: number; w: number }
    ) => {
      const heightDiff = layoutItem.h - oldLayoutItem.h;
      const widthDiff = layoutItem.w - oldLayoutItem.w;
      const changeCoef = oldLayoutItem.w / oldLayoutItem.h;
      if (Math.abs(heightDiff) < Math.abs(widthDiff)) {
        layoutItem.h = layoutItem.w / changeCoef;
        placeholder.h = layoutItem.w / changeCoef;
      } else {
        layoutItem.w = layoutItem.h * changeCoef;
        placeholder.w = layoutItem.h * changeCoef;
      }
    },
    []
  );
  const [tooltip, showTooltip] = useState(true);

  function Chart(data: ChartType) {
    //console.log("chart", data);
    if (
      !(data.type === "text" || data.type === "map" || data.type === "imageMap")
    ) {
      if (data.chartData !== undefined && data.chartData !== null) {
        if (data.chartData[0] !== undefined && data.chartData[0] !== null) {
          if (
            data.chartData[0].deviceID === undefined ||
            data.chartData[0].deviceID === null ||
            data.chartData[0].deviceID === "new"
          ) {
            return (
              <MissingChartData
                missing={"device and parameter"}
                chartType={data.type}
              />
            );
          }
          if (
            data.chartData[0].phenomena === undefined ||
            data.chartData[0].phenomena === null ||
            data.chartData[0].phenomena === "new"
          ) {
            return (
              <MissingChartData missing={"parameter"} chartType={data.type} />
            );
          }
        }
      }
    }
    switch (data.type) {
      case "line":
      case "bar":
      case "scatter":
        return (
          <Graph
            Type={data.type}
            ChartData={data}
            FetchSuccess={
              (isDashboardPhenomSuccess || isGetDeviceDataSuccess) &&
              !isGetDeviceDataFetching
            }
            FetchError={
              (isDashboardPhenomSuccess || isGetDeviceDataSuccess) &&
              !isGetDeviceDataFetching
            }
            updateChart={updateChart}
          />
        );
      case "gauge":
        return (
          <Gauge
            ChartData={data}
            FetchSuccess={
              (isDashboardPhenomSuccess || isGetDeviceDataSuccess) &&
              !isGetDeviceDataFetching
            }
            FetchError={
              (isDashboardPhenomSuccess || isGetDeviceDataSuccess) &&
              !isGetDeviceDataFetching
            }
          />
        );
      case "calendar":
        return (
          <Calendar
            ChartData={data}
            FetchSuccess={
              (isDashboardPhenomSuccess || isGetDeviceDataSuccess) &&
              !isGetDeviceDataFetching
            }
            FetchError={
              (isDashboardPhenomSuccess || isGetDeviceDataSuccess) &&
              !isGetDeviceDataFetching
            }
          />
        );
      case "table":
        return (
          <Table
            ChartData={data}
            FetchSuccess={
              (isDashboardPhenomSuccess || isGetDeviceDataSuccess) &&
              !isGetDeviceDataFetching
            }
            FetchError={
              (isDashboardPhenomSuccess || isGetDeviceDataSuccess) &&
              !isGetDeviceDataFetching
            }
          />
        );
      case "value":
        return (
          <Value
            ChartData={data}
            FetchSuccess={
              (isDashboardPhenomSuccess || isGetDeviceDataSuccess) &&
              !isGetDeviceDataFetching
            }
            FetchError={
              (isDashboardPhenomSuccess || isGetDeviceDataSuccess) &&
              !isGetDeviceDataFetching
            }
          />
        );
      case "text":
        return (
          <Text
            ChartData={data}
            FetchSuccess={
              (isDashboardPhenomSuccess || isGetDeviceDataSuccess) &&
              !isGetDeviceDataFetching
            }
            FetchError={
              (isDashboardPhenomSuccess || isGetDeviceDataSuccess) &&
              !isGetDeviceDataFetching
            }
          />
        );
      case "status":
        return (
          <Status
            ChartData={data}
            FetchSuccess={
              (isDashboardPhenomSuccess || isGetDeviceDataSuccess) &&
              !isGetDeviceDataFetching
            }
            FetchError={
              (isDashboardPhenomSuccess || isGetDeviceDataSuccess) &&
              !isGetDeviceDataFetching
            }
          />
        );
      case "activity":
        return (
          <ActivityChart
            ChartData={data}
            FetchSuccess={
              (isDashboardPhenomSuccess || isGetDeviceDataSuccess) &&
              !isGetDeviceDataFetching
            }
            FetchError={
              (isDashboardPhenomSuccess || isGetDeviceDataSuccess) &&
              !isGetDeviceDataFetching
            }
          />
        )
      case "map":
        return <Map ChartData={data} updateChart={updateChart} />;
      case "imageMap":
        return <ImageMap ChartData={data} updateChart={updateChart} editingActive={editItem} />;
      default:
        return <div>Chart</div>;
    }
  }

  function ChartEdit(Editdata: ChartType | undefined, id: string) {
    if (Editdata) {
      switch (Editdata.type) {
        case "line":
          return (
            <EditLineChart
              id={id}
              remove={onRemoveItem}
              updateChart={updateChart}
              data={Editdata}
              setShowChartEdit={setShowChartEdit}
            />
          );
        case "bar":
          return (
            <EditBarChart
              id={id}
              remove={onRemoveItem}
              updateChart={updateChart}
              data={Editdata}
              setShowChartEdit={setShowChartEdit}
            />
          );
        case "scatter":
          return (
            <EditScatterChart
              id={id}
              remove={onRemoveItem}
              updateChart={updateChart}
              data={Editdata}
              setShowChartEdit={setShowChartEdit}
            />
          );
        case "activity":
          return (
            <EditActivityChart
              id={id}
              remove={onRemoveItem}
              updateChart={updateChart}
              data={Editdata}
              setShowChartEdit={setShowChartEdit}
            />
          );
        case "gauge":
          return (
            <EditGauge
              id={id}
              remove={onRemoveItem}
              updateChart={updateChart}
              data={Editdata}
              setShowChartEdit={setShowChartEdit}
            />
          );
        case "calendar":
          return (
            <EditCalendar
              id={id}
              remove={onRemoveItem}
              updateChart={updateChart}
              data={Editdata}
              setShowChartEdit={setShowChartEdit}
            />
          );
        case "table":
          return (
            <EditTable
              id={id}
              remove={onRemoveItem}
              updateChart={updateChart}
              data={Editdata}
              setShowChartEdit={setShowChartEdit}
            />
          );
        case "value":
          return (
            <EditValue
              id={id}
              remove={onRemoveItem}
              updateChart={updateChart}
              data={Editdata}
              setShowChartEdit={setShowChartEdit}
            />
          );
        case "status":
          return (
            <EditStatus
              id={id}
              remove={onRemoveItem}
              updateChart={updateChart}
              data={Editdata}
              setShowChartEdit={setShowChartEdit}
            />
          );
        case "text":
          return (
            <EditText
              id={id}
              remove={onRemoveItem}
              updateChart={updateChart}
              data={Editdata}
              setShowChartEdit={setShowChartEdit}
            />
          );
        case "map":
          return (
            <EditMap
              id={id}
              remove={onRemoveItem}
              updateChart={updateChart}
              data={Editdata}
              setShowChartEdit={setShowChartEdit}
            />
          );
        case "imageMap":
          return (
            <EditImageMap
              id={id}
              remove={onRemoveItem}
              updateChart={updateChart}
              data={Editdata}
              setShowChartEdit={setShowChartEdit}
            />
          );
        default:
          return <div>Default - {id}</div>;
      }
    }
  }

  var [dashboardRevert, setDashboardRevert] = useState<{
    data: DashboardData;
    id: string;
    name: string;
    description: string;
  }>();

  const modals = (
    <>
      {showRenameDashboardModal && (
        <RenameDashboardModal setInternalModal={setShowRenameDashboardModal} setDashboardName={setDashboardName} setDashboardDescription={setDashboardDescription} dashboardName={dashboardName} dashboardDescription={dashboardDescription} />
      )}
    </>
  )

  return (
    <>
      <div className="w-full h-full bg-gray-200 rounded-sm p-1">
        <div className="justify-between rounded-t py-0.5 bg-gray-200 flex items-center min-w-[315px]">
          {modals}
          <div className="relative inline-block sm:mr-2 align-middle select-none w-full">
            {!editItem ? (
              <div className="inline-flex w-full pl-2 justify-center sm:justify-between sm:flex-row items-center">
                <div className="flex items-center">
                  <div
                    className="m-1 sm:ml-2 ml-0 text-2xl font-bold flex items-center w-min font-sans whitespace-nowrap overflow-hidden"
                  >
                    {dashboardName}
                  </div>
                  {isBackFillDataFetching ? (
                    <Spinner colour="fill-rpr-blue mx-2" />
                  ) : (
                    <MdMoreTime
                      size={32}
                      className="mx-2 cursor-pointer"
                      onClick={() =>
                        backFillData({
                          id: dashboardid,
                        })
                      }
                    />
                  )}
                </div>

                {editPermission ? (
                  <div className="w-auto p-0.5">
                    <Button
                      label={language.viewDashboard.buttons.edit}
                      onClick={() => {
                        setEditItem(true);
                        const localDashboard = {
                          ...dashboards.dashboards[
                          dashboardid as keyof Dashboards
                          ],
                        };
                        if (localDashboard.data && dashboardid) {
                          setDashboardRevert({
                            data: localDashboard.data,
                            id: dashboardid,
                            name: sanitizeHtml(dashboardName, sanitizeConf),
                            description: sanitizeHtml(dashboardDescription, sanitizeConf),
                          });
                        }
                        dispatch(editingDashboard(dashboardid ?? ""));
                      }}
                      colour="blue"
                    />
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="flex w-full justify-between flex-col sm:flex-row items-center">
                <div className="flex items-center">
                  <div className="flex items-center pl-2" onClick={() => { if (editPermission && editItem) { setShowRenameDashboardModal(true) } }}>
                    <div
                      className="m-1 sm:ml-2 ml-0 text-2xl font-bold flex items-center w-min font-sans whitespace-nowrap overflow-hidden"
                    >
                      {dashboardName}
                    </div>
                    {editPermission && editItem && <FiEdit2 size={24} />}
                  </div>
                  {isBackFillDataFetching ? (
                    <Spinner colour="fill-rpr-blue" />
                  ) : (
                    <MdMoreTime
                      size={32}
                      className="ml-2 cursor-pointer"
                      onClick={() =>
                        backFillData({
                          id: dashboardid,
                        })
                      }
                    />
                  )}
                </div>

                <div className="inline-flex space-x-1">
                  <ChartSelect
                    onSelect={(type) => {
                      onAddItem(type);
                    }}
                  />
                  <div className="w-auto ml-2">
                    <Button
                      label={<>{<FaUndo size={24} className="m-0.5" />}</>}
                      onClick={() => {
                        setEditItem(false);
                        if (dashboardRevert) {
                          saveDashboard(dashboardRevert);
                        }
                      }}
                      colour="white"
                    />
                  </div>
                  <div className="w-auto">
                    <Button
                      colour="blue"
                      onClick={() => {
                        setEditItem(false);
                        /* dispatch(dashboardAPI.util.invalidateTags(["Dashboard"])); */
                        const localDashboard = {
                          ...dashboards.dashboards[
                          dashboardid as keyof Dashboards
                          ],
                        };
                        if (
                          localDashboard.data &&
                          dashboardid &&
                          dashboardName &&
                          dashboardDescription &&
                          editItem
                        ) {
                          saveDashboard({
                            data: localDashboard.data,
                            id: dashboardid,
                            name: sanitizeHtml(dashboardName, sanitizeConf),
                            description: sanitizeHtml(dashboardDescription, sanitizeConf),
                          });
                        }
                        dispatch(stopEditingDashboard());
                      }}
                      label={
                        <>
                          {language.viewDashboard.buttons.stopEditing}
                          {isSaveDashboardLoading ? (
                            <Spinner colour="fill-blue-600 place-self-center ml-1 mt-1" />
                          ) : (
                            <BsCloudCheck size={24} className="mt-1 ml-1" />
                          )}
                        </>
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className={`select-none bg-gray-200 sm:h-[calc(100%-52px)] ${(editPermission && editItem) ? "h-[calc(100%-92px)]" : "h-[calc(100%-52px)]"} overflow-y-auto scrollbar-none rounded-b`}
        >
          {/* <div className="flex sm:flex-row justify-between items-center scrollbar-none flex-col min-h-[54px] flex-wrap">
          <div className="flex items-center">
            <ContentEditable
              className="m-2 sm:ml-4 ml-0 text-2xl font-bold flex items-center w-min font-sans whitespace-nowrap overflow-hidden"
              tagName="pre"
              html={dashboardName} // innerHTML of the editable div
              disabled={!(editPermission && editItem)} // use true to disable edition
              onChange={(e) => {
                sanitize(e.target.value);
              }} // handle innerHTML change
            />
            {editPermission && editItem && <FiEdit2 size={20} />}
          </div>
        </div> */}
          <ResponsiveReactGridLayout
            onLayoutChange={onLayoutChange}
            onBreakpointChange={onBreakpointChange}
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 615, xxs: 0 }} //615 is for a 639px or less display, 24px taken off for side padding of main container
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            rowHeight={50}
            layouts={{ lg: layout, xxs: smallLayout || [] }}
            draggableHandle=".draggable"
            //compactType={null}
            measureBeforeMount={true} //Required to ensure graphs know their dimensions before being mounted
            isDraggable={editItem}
            isResizable={editItem}
            onDragStart={() => {
              setDashboardIsDragging(true);
            }}
            onDragStop={() => {
              setDashboardIsDragging(false);
            }}
          //onResize={handleResize}
          >
            {_.map(items, (el) => createElement(el))}
          </ResponsiveReactGridLayout>
          {tooltip && <ReactTooltip />}
          <EditChartSettings
            chartEditInfo={chartEditInfo}
            showChartEdit={showChartEdit}
            setShowChartEdit={setShowChartEdit}
          />
        </div>
      </div >
    </>
  );
}

export default ViewDashboard;

export const MissingChartData = ({
  missing,
  chartType,
}: {
  missing: "device and parameter" | "device" | "parameter";
  chartType: string;
}) => {
  return (
    <>
      <div className="w-full h-full flex items-center justify-center">
        <div className="relative w-full h-full">
          <div className="absolute w-full h-full flex items-center justify-center opacity-10">
            {GetIcon(chartType)}
          </div>
          <div className="flex h-full w-full px-6 justify-center items-center text-center font-semibold">
            {`Select a ${missing} to show data`}
          </div>
        </div>
      </div>
    </>
  );
};

function GetIcon(type: string) {
  switch (type) {
    case "line":
      return <AiOutlineLineChart className="w-full h-full" />;
    case "bar":
      return <AiOutlineBarChart className="w-full h-full" />;
    case "scatter":
      return <AiOutlineDotChart className="w-full h-full" />;
    case "gauge":
      return <TbGauge className="w-full h-full" />;
    case "calendar":
      return <IoCalendarSharp className="w-full h-full" />;
    case "table":
      return <RiTableLine className="w-full h-full" />;
    case "text":
      return <BsCardText className="w-full h-full" />;
    case "value":
      return <AiOutlineFieldNumber className="w-full h-full" />;
    case "status":
      return <FaList className="w-full h-full" />;
    case "map":
      return <IoMap className="w-full h-full" />;
    case "imageMap":
      return <IoImageOutline className="w-full h-full" />;
    default:
      return <AiOutlineLineChart className="w-full h-full" />;
  }
}
