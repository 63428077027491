import React from "react";
import CreateGroup from "./CreateGroup";

function InitialCreateGroup() {
  return (
    <div className="flex flex-col items-center h-screen bg-rpr-blue">
      <div className=" text-center max-w-md text-white">
        Thank you for creating an account. To get started you need to be part of
        a group. If you are joining to view data from devices that have already
        been set up please request the person setting up the devices to invite
        you to their group. If you want to start a new group, please enter the
        name below.
        <br />
        <br />
        <br />
        <b>Needs more styling and input about if this is what you want</b>
      </div>
      <CreateGroup />
    </div>
  );
}

export default InitialCreateGroup;
