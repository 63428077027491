import React, { useEffect, useState } from "react";
import { MdDashboard, MdThermostat } from "react-icons/md";
import { Link } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../dictionary/QuickAccessText";
import { selectLanguage } from "../features/user/userSlice";
import { LanguageCheck } from "../utils/LanguageCheck";

type QuickAccessCardProps = {
  type: string;
  title: string;
  description?: string;
  ID: string;
  lastContact?: Date;
};

function QuickAccessCard({
  type,
  title,
  description,
  ID,
  lastContact,
}: QuickAccessCardProps) {
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));

  
  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  return (
    <div className="rounded border shadow-lg p-2 w-full">
      <div className="flex flex-col justify-between h-full">
        <div>
          <h3 className="text-lg">{title}</h3>

          {lastContact && (
            <p className="text-md">
              {new Date(lastContact ?? 0).getTime() === -62135596800000
                ? language.offline
                : (Date.parse(new Date(lastContact ?? 0).toISOString()) >
                  Date.parse(new Date().toISOString()) - 3 * 60 * 60 * 1000
                    ? language.online
                    : language.offline) +
                  " " +
                  new Date(lastContact ?? 0).toLocaleString()}
            </p>
          )}
          <p className="text-sm">{description}</p>
        </div>
        <Link
          to={`${type}/${ID}`}
          className="rounded flex justify-self-end text-white bg-rpr-blue px-1.5 pb-1 pt-0.5 mt-2"
        >
          {type == "dashboard" ? (
            <MdDashboard size={24} />
          ) : (
            <MdThermostat size={24} />
          )}
          <span className="ml-1 flex-1 text-left">
            {type === "dashboard"
              ? language.goToDashboard
              : type === "device"
              ? language.goToDevice
              : null}
          </span>
        </Link>
      </div>
    </div>
  );
}

export default QuickAccessCard;
