import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  setNotifications,
  removeNotification,
} from "../features/notifications/notificationSlice";
import { toast } from "react-toastify";
import { LanguageText } from "../dictionary/GroupNotificationText";

export const groupNotificationAPI = createApi({
  reducerPath: "groupNotificationAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers: any, { getState }: any) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("token", `${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Notifications"],
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: (groupID) => {
        return {
          url: `/notifications/${groupID}`,
          method: "GET",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setNotifications(data));
        } catch (error) {
          console.log("SOMETHING WENT WRONG FETCHING NOTIFICATIONS. TRY AGAIN LATER");
        }
      },
      providesTags: ["Notifications"],
    }),
    createNotification: builder.mutation({
      query: (args: {
        notification: { [key: string]: any };
        language: LanguageText | null;
      }) => {
        return {
          url: "/notification/create",
          method: "POST",
          body: args.notification,
        };
      },
      invalidatesTags: ["Notifications"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const language = args.language;
        try {
          const { data } = await queryFulfilled;
          toast.success(
            language?.messages?.toast?.createNotification?.success || data.message
          );
        } catch (error: any) {
          const { code: errorCode, message: errorMessage } = error?.error?.data?.error;
          errorCode === "subscription error"
            ? toast.error(language?.messages?.toast?.createNotification?.maxNotification)
            : toast.error(
                language?.messages?.toast?.createNotification?.error || errorMessage
              );
        }
      },
    }),
    updateNotification: builder.mutation({
      query: (args: {
        notification: { [key: string]: any };
        language: LanguageText | null;
      }) => {
        const { id: notificationId } = args.notification;
        return {
          url: `notification/${notificationId}/update`,
          method: "PUT",
          body: args.notification,
        };
      },
      invalidatesTags: ["Notifications"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const language = args.language;
        try {
          const { data } = await queryFulfilled;
          toast.success(
            language?.messages?.toast?.updateNotification?.success || data.message
          );
        } catch (error: any) {
          const errorMessage =
            error?.error?.data?.error?.message || error.message || "An error occurred";
          toast.error(
            language?.messages?.toast?.updateNotification?.error || errorMessage
          );
        }
      },
    }),
    acknowledge: builder.mutation({
      query: (args: { notificationId: string; language: LanguageText | null }) => {
        const { notificationId } = args;
        return {
          url: `/notification/${notificationId}/ack`,
          method: "PUT",
        };
      },
      invalidatesTags: ["Notifications"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const { language } = args;
        try {
          const { meta } = await queryFulfilled;
          const statusCode = meta?.response?.status;
          statusCode === 200 &&
            toast.success(
              language?.messages?.toast?.updateNotification?.success ||
                "Notification successfully updated"
            );
        } catch (error) {
          toast.error(
            language?.messages?.toast?.updateNotification?.error ||
              "Something went wrong. Please try again later"
          );
        }
      },
    }),
    deleteNotification: builder.mutation({
      query: (args: { notificationID: string; language: LanguageText | null }) => {
        return {
          url: `notification/${args.notificationID}/delete`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Notifications"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const language = args.language;
        try {
          const { data } = await queryFulfilled;
          dispatch(removeNotification(args.notificationID));
          toast.success(
            language?.messages?.toast?.deleteNotification?.success || data.message
          );
        } catch (error: any) {
          const errorMessage =
            error?.error?.data?.error?.message || error.message || "An error occurred";
          toast.error(
            language?.messages?.toast?.deleteNotification?.error || errorMessage
          );
        }
      },
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useCreateNotificationMutation,
  useUpdateNotificationMutation,
  useAcknowledgeMutation,
  useDeleteNotificationMutation,
} = groupNotificationAPI;
