import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface AuthState {
  token: string | null;
  rememberMe: boolean | null;
  verifiedEmail: boolean | null;
}

const initialState: AuthState = {
  token: null,
  rememberMe: null,
  verifiedEmail: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (
      state: any,
      action: PayloadAction<{
        token: string | null;
        rememberMe: boolean | null;
        verifiedEmail: boolean | null;
      }>
    ) => {
      //console.log("loginSlice: " + JSON.stringify(action.payload));
      if (action.payload.rememberMe) {
        localStorage.setItem(
          "auth",
          JSON.stringify({
            token: action.payload.token,
            verifiedEmail: action.payload.verifiedEmail,
          })
        );
      } else {
        sessionStorage.setItem(
          "auth",
          JSON.stringify({
            token: action.payload.token,
            verifiedEmail: action.payload.verifiedEmail,
          })
        );
      }
      if (action.payload.token) {
        //console.log("loginSliceToken: " + JSON.stringify(action.payload.token));
        state.token = action.payload.token;
        //console.log("loginSliceStateToken: " + JSON.stringify(state.token));
      }
      if (action.payload.verifiedEmail) {
        console.log(
          "loginSliceEmail: " + JSON.stringify(action.payload.verifiedEmail)
        );
        state.verifiedEmail = action.payload.verifiedEmail;
        console.log(
          "loginSliceStateEmail: " + JSON.stringify(state.verifiedEmail)
        );
      }
    },
    //=============== Logout Action ===============
    clearAuth: (state: any) => {
      localStorage.setItem("auth", JSON.stringify({ undefined }));
      sessionStorage.setItem("auth", JSON.stringify({ undefined }));
      state.token = null;
      state.rememberMe = null;
      state.verifiedEmail = false;
    },
    setVerifiedEmail: (state: any, action: PayloadAction<boolean>) => {
      state.verifiedEmail = action.payload;
      console.log("setVerifiedEmail: " + JSON.stringify(action.payload));
      if (localStorage.getItem("auth") !== null) {
        localStorage.setItem(
          "auth",
          JSON.stringify({
            token: state.token,
            verifiedEmail: state.verifiedEmail,
          })
        );
      } else {
        sessionStorage.setItem(
          "auth",
          JSON.stringify({
            token: state.token,
            verifiedEmail: state.verifiedEmail,
          })
        );
      }
    },
    setToken: (state: any, action: PayloadAction<string>) => {
      state.token = action.payload;
      console.log("setToken: " + JSON.stringify(action.payload));
      if (localStorage.getItem("auth") !== null) {
        localStorage.setItem(
          "auth",
          JSON.stringify({
            token: state.token,
            verifiedEmail: state.verifiedEmail,
          })
        );
      } else {
        sessionStorage.setItem(
          "auth",
          JSON.stringify({
            token: state.token,
            verifiedEmail: state.verifiedEmail,
          })
        );
      }
    },
  },
});

export const selectAuth = (state: RootState) => state.auth;

export const { login, clearAuth, setVerifiedEmail, setToken } =
  authSlice.actions;

export default authSlice.reducer;
