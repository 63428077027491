export type LanguageText = {
    claim: {
        claimANewDevice: string;
        selectAGroup: string;
        labels: {
            deviceID: string;
            referenceCode: string;
            group: string;
        };
        buttons: {
            claimDevice: string;
            cancel: string;
            confirm: string;
        };
        confirm: {
            title: string;
            phrase1: string;
            phrase2: string;
            phrase3: string;
            refCode: string;
        };
        toasts: {
            noGroupPermission: string;
            noGroups: string;
            claimed: string;
        }
    };
    deviceTable: {
        devices: string;
        tableElements: {
            headers: {
                deviceName: string;
                deviceID: string;
                group: string;
                deviceLocation: string;
                deviceStatus: string;
                claimDate: string;
                deviceEUI: string;
            };
            quickEdit: string;
            searchAllColumns: string;
            goToPage: string;
            show: string;
            loading: string;
            noDevicesFound: string;
            online: string;
            offline: string;
        };
    };
    editDevice: {
        viewEditDevice: {
            editDevice: string;
            device: string;
            modals: {
                unclaimModal: {
                    title: string;
                    phrase1: string;
                    phrase2: string;
                };
            };
            labels: {
                deviceName: string;
                group: string;
                deviceLatitude: string;
                deviceLongitude: string;
                deviceID: string;
                lastSeen: string;
                softwareVersion: string;
            };
            toasts: {
                deviceSaved: string;
                deviceUnclaimed: string;
            };
            invalidFields: {
                groupNameUnknown: string;
                deviceNeverSeen: string;
                softwareVersionUnknown: string;
            };
            buttons: {
                move: string;
                fix: string;
            };
        },
        editDeviceParameters: {
            deviceParameters: string;
            sensors: string;
            buttons: {
                deleteData: string;
            }
            modals: {
                thisDevice: string;
                buttons: {
                    cancel: string;
                    accept: string;
                }
                title: {
                    phrase1: string;
                    phrase2: string;
                }
                deleteData: {
                    labels: {
                        all: string;
                        before: string;
                        since: string;
                        between: string;
                    }
                }
                deleteDataConfirm: {
                    body: {
                        phrase1: string;
                        phrase2: string;
                        phrase3: string;
                        phrase4: string;
                        phrase5: string;
                    }
                }
            }
        },
        editDeviceGateways: {
            gateways: string;
            lastContact: string;
            noGateways: string;
            noValidGateways: string;
            labels: {
                sensorName: string;
            }
        },
        tabs: {
            overview: string;
            parameters: string;
            gateways: string;
        }
        buttons: {
            save: string;
            cancel: string;
            unclaimDevice: string;
        };
        downloadModal: {
            title: {
                phrase1: string;
                phrase2: string;
            }
            labels: {
                advanced: string;
                dateFormat: string;
                dataTitles: string;
            }
            buttons: {
                download: string;
                cancel: string;
            }
            formDefaults: {
                dataTitles: {
                    custom: string;
                    config: string;
                }
            }
        }
    };
};
export const English: LanguageText = {
    claim: {
        claimANewDevice: "Claim a new device",
        selectAGroup: "Select A Group",
        labels: {
            deviceID: "Device ID",
            referenceCode: "Reference Code",
            group: "Group"
        },
        buttons: {
            claimDevice: "Claim Device",
            cancel: "Cancel",
            confirm: "Confirm"
        },
        confirm: {
            title: "Claim Device",
            phrase1: "Are you sure you would like to add device ",
            phrase2: " to group ",
            phrase3: "? ",
            refCode: "ref code ",
        },
        toasts: {
            noGroupPermission: "You need to have permission to claim a device in a group. Contact the person that invited you to get permission.",
            noGroups: "You need to be part of a group to claim a device. Either create a new group, or join someone elses.",
            claimed: "Device claimed"
        }
    },
    deviceTable: {
        devices: "Devices",
        tableElements: {
            headers: {
                deviceName: "Device Name",
                deviceID: "Device ID",
                group: "Group",
                deviceLocation: "Device Location",
                deviceStatus: "Device Status",
                claimDate: "Claim Date",
                deviceEUI: "Device EUI"
            },
            quickEdit: "Quick Edit",
            searchAllColumns: "Search all columns",
            goToPage: "Go to page",
            loading: "Loading",
            show: "Show",
            noDevicesFound: "No devices found",
            online: "Online",
            offline: "Offline"
        }
    },
    editDevice: {
        viewEditDevice: {
            editDevice: "Edit Device",
            device: "Device",
            modals: {
                unclaimModal: {
                    title: "Unclaim Device",
                    phrase1: "Are you sure you would like to unclaim device ",
                    phrase2: "? This action cannot be undone.",
                },
            },
            labels: {
                deviceName: "Device Name",
                group: "Group",
                deviceLatitude: "Device Latitude",
                deviceLongitude: "Device Longitude",
                deviceID: "Device ID",
                lastSeen: "Last Seen",
                softwareVersion: "Software Version",
            },
            toasts: {
                deviceSaved: "Device Updated",
                deviceUnclaimed: "Device Unclaimed"
            },
            invalidFields: {
                groupNameUnknown: "Unknown",
                deviceNeverSeen: "Never",
                softwareVersionUnknown: "Unknown",
            },
            buttons: {
                move: "Move",
                fix: "Fix",
            },
        },
        editDeviceParameters: {
            deviceParameters: "Device Parameters",
            sensors: "Sensors",
            buttons: {
                deleteData: "Delete Data"
            },
            modals: {
                thisDevice: "this device",
                buttons: {
                    cancel: "Cancel",
                    accept: "Delete Data",
                },
                title: {
                    phrase1: "Delete data from ",
                    phrase2: "",
                },
                deleteData: {
                    labels: {
                        all: "All",
                        before: "Before",
                        since: "Since",
                        between: "Between",
                    }
                },
                deleteDataConfirm: {
                    body: {
                        phrase1: "All",
                        phrase2: "data from",
                        phrase3: "",
                        phrase4: "",
                        phrase5: "will be deleted. Are you sure? This action cannot be undone.",
                    }
                }
            }
        },
        editDeviceGateways: {
            gateways: "Gateways",
            lastContact: "Last Contact",
            noGateways: "No gateways within the last 30 days",
            noValidGateways: "No gateways with valid coordinates found",
            labels: {
                sensorName: "Sensor Name",
            }
        },
        tabs: {
            overview: "Overview",
            parameters: "Parameters",
            gateways: "Gateways",
        },
        buttons: {
            save: "Save",
            cancel: "Cancel",
            unclaimDevice: "Unclaim Device"
        },
        downloadModal: {
            title: {
                phrase1: "Download data from",
                phrase2: "",
            },
            labels: {
                advanced: "Advanced",
                dateFormat: "Date Format",
                dataTitles: "Data Titles",
            },
            buttons: {
                download: "Download",
                cancel: "Cancel",
            },
            formDefaults: {
                dataTitles: {
                    custom: "Custom",
                    config: "Config",
                },
            },
        },
    }
};
export const French: LanguageText = {
    claim: {
        claimANewDevice: "Réclamer un nouveau dispositif",
        selectAGroup: "Sélectionner un groupe",
        labels: {
            deviceID: "ID du dispositif",
            referenceCode: "Code de référence",
            group: "Groupe"
        },
        buttons: {
            claimDevice: "Dispositif de réclamation",
            cancel: "Annuler",
            confirm: "Confirmer"
        },
        confirm: {
            title: "Dispositif de réclamation",
            phrase1: "Êtes-vous sûr de vouloir ajouter le dispositif ",
            phrase2: " au groupe ",
            phrase3: "? ",
            refCode: "code de référence ",
        },
        toasts: {
            noGroupPermission: "Vous devez avoir la permission de réclamer un appareil dans un groupe. Contactez la personne qui vous a invité pour obtenir la permission. ",
            noGroups: "Vous devez faire partie d'un groupe pour réclamer un appareil. Soit vous créez un nouveau groupe, soit vous rejoignez celui de quelqu'un d'autre.",
            claimed: "Dispositif réclamé"
        }
    },
    deviceTable: {
        devices: "Dispositifs",
        tableElements: {
            headers: {
                deviceName: "Nom du dispositif",
                deviceID: "ID du dispositif",
                group: "Groupe",
                deviceLocation: "Emplacement du dispositif",
                deviceStatus: "État du dispositif",
                claimDate: "La date réclamée",
                deviceEUI: "ID du dispositif"
            },
            quickEdit: "Édition rapide",
            searchAllColumns: "Rechercher dans toutes les colonnes",
            goToPage: "Aller à la page",
            loading: "Chargement",
            show: "Afficher",
            noDevicesFound: "Aucun appareil trouvé",
            online: "En ligne",
            offline: "Hors ligne"
        }
    },
    editDevice: {
        viewEditDevice: {
            editDevice: "Modifier le dispositif",
            device: "Dispositif",
            modals: {
                unclaimModal: {
                    title: "Désapproprier l'appareil",
                    phrase1: "Êtes-vous sûr de vouloir annuler le dispositif ",
                    phrase2: "? Cette action ne peut être annulée.",
                },
            },
            labels: {
                deviceName: "Nom du dispositif",
                group: "Groupe",
                deviceLatitude: "Latitude du dispositif",
                deviceLongitude: "Longitude du dispositif",
                deviceID: "ID du dispositif",
                lastSeen: "Dernière connexion",
                softwareVersion: "Version du logiciel",
            },
            toasts: {
                deviceSaved: "Dispositif sauvegardé",
                deviceUnclaimed: "Dispositif non réclamé"
            },
            invalidFields: {
                groupNameUnknown: "Inconnu",
                deviceNeverSeen: "Jamais",
                softwareVersionUnknown: "Inconnu",
            },
            buttons: {
                move: "Bouger",
                fix: "Fixer",
            },
        },
        editDeviceParameters: {
            deviceParameters: "Paramètres du dispositif",
            sensors: "Capteurs",
            buttons: {
                deleteData: "Supprimer les données"
            },
            modals: {
                thisDevice: "ce dispositif",
                buttons: {
                    cancel: "Annuler",
                    accept: "Supprimer les données",
                },
                title: {
                    phrase1: "Supprimer les données de ",
                    phrase2: "",
                },
                deleteData: {
                    labels: {
                        all: "Tout",
                        before: "Avant",
                        since: "Depuis",
                        between: "Entre",
                    }
                },
                deleteDataConfirm: {
                    body: {
                        phrase1: "Toutes ",
                        phrase2: "les données de ",
                        phrase3: "",
                        phrase4: " au ",
                        phrase5: " seront supprimées. En êtes-vous sûr ? Cette action ne peut pas être annulée.",

                    },
                }
            }
        },
        editDeviceGateways: {
            gateways: "Passerelles",
            lastContact: "Dernier contact",
            noGateways: "Aucune passerelle au cours des 30 derniers jours.",
            noValidGateways: "Aucune passerelle avec des coordonnées valides trouvée",
            labels: {
                sensorName: "Nom du capteur",
            }
        },
        tabs: {
            overview: "Aperçu",
            parameters: "Paramètres",
            gateways: "Passerelles",
        },
        buttons: {
            save: "Sauvegarder",
            cancel: "Annuler",
            unclaimDevice: "Désapproprier l'appareil"
        },
        downloadModal: {
            title: {
                phrase1: "Télécharger les données de",
                phrase2: "",
            },
            labels: {
                advanced: "Avancée",
                dateFormat: "Format de date",
                dataTitles: "Titres de données",
            },
            buttons: {
                download: "Télécharger",
                cancel: "Annuler",
            },
            formDefaults: {
                dataTitles: {
                    custom: "Personnalisé",
                    config: "Config",
                },
            },
        },
    }
};
export const German: LanguageText = {
    claim: {
        claimANewDevice: "Beantragen Sie ein neues Gerät",
        selectAGroup: "Eine Gruppe auswählen",
        labels: {
            deviceID: "Geräte-ID",
            referenceCode: "Referenzcode",
            group: "Gruppe"
        },
        buttons: {
            claimDevice: "Gerät beanspruchen",
            cancel: "Abbrechen",
            confirm: "Bestätigen"
        },
        confirm: {
            title: "Gerät beanspruchen",
            phrase1: "Sind Sie sicher, dass Sie das Gerät ",
            phrase2: " zur Gruppe ",
            phrase3: " hinzufügen möchten? ",
            refCode: "Referenzcode ",
        },
        toasts: {
            noGroupPermission: "Sie benötigen eine Erlaubnis, um ein Gerät in einer Gruppe zu beanspruchen. Wenden Sie sich an die Person, die Sie eingeladen hat, um die Erlaubnis zu erhalten.",
            noGroups: "Sie müssen Teil einer Gruppe sein, um ein Gerät zu beanspruchen. Entweder erstellen Sie eine neue Gruppe oder treten Sie einer anderen bei.",
            claimed: "Gerät beansprucht"
        }
    },
    deviceTable: {
        devices: "Geräte",
        tableElements: {
            headers: {
                deviceName: "Gerätename",
                deviceID: "Geräte-ID",
                group: "Gruppe",
                deviceLocation: "Gerätestandort",
                deviceStatus: "Gerätestatus",
                claimDate: "Claim Date",
                deviceEUI: "Gerät ID"
            },
            quickEdit: "Schnellbearbeitung",
            searchAllColumns: "Alle Spalten durchsuchen",
            goToPage: "Weiter zur Seite",
            loading: "Laden",
            show: "Anzeigen",
            noDevicesFound: "Keine Geräte gefunden",
            online: "Online",
            offline: "Offline"
        }
    },
    editDevice: {
        viewEditDevice: {
            editDevice: "Gerät bearbeiten",
            device: "Gerät",
            modals: {
                unclaimModal: {
                    title: "Gerät abmelden",
                    phrase1: "Sind Sie sicher, dass Sie das Gerät ",
                    phrase2: " abmelden möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
                },
            },
            labels: {
                deviceName: "Gerätename",
                group: "Gruppe",
                deviceLatitude: "Geräte-Breitengrad",
                deviceLongitude: "Geräte-Längengrad",
                deviceID: "Geräte-ID",
                lastSeen: "Zuletzt gesehen",
                softwareVersion: "Software-Version",
            },
            toasts: {
                deviceSaved: "Gerät gespeichert",
                deviceUnclaimed: "Gerät wurde nicht in Anspruch genommen"
            },
            invalidFields: {
                groupNameUnknown: "Unbekannt",
                deviceNeverSeen: "Niemals",
                softwareVersionUnknown: "Unbekannt",
            },
            buttons: {
                move: "Bewegen",
                fix: "Fixieren",
            },
        },
        editDeviceParameters: {
            deviceParameters: "Geräteparameter",
            sensors: "Sensoren",
            buttons: {
                deleteData: "Daten löschen"
            },
            modals: {
                thisDevice: "dieses Gerät",
                buttons: {
                    cancel: "Abbrechen",
                    accept: "Daten löschen",
                },
                title: {
                    phrase1: "Daten aus ",
                    phrase2: " löschen",
                },
                deleteData: {
                    labels: {
                        all: "Alle",
                        before: "Vor",
                        since: "Seit",
                        between: "Zwischen",
                    },
                },
                deleteDataConfirm: {
                    body: {
                        phrase1: "Alle ",
                        phrase2: "Daten von ",
                        phrase3: "",
                        phrase4: "",
                        phrase5: "werden gelöscht. Bist du sicher? Diese Aktion kann nicht rückgängig gemacht werden.",
                    },
                }
            }
        },
        editDeviceGateways: {
            gateways: "Gateways",
            lastContact: "Letzter Kontakt",
            noGateways: "Keine Gateways in den letzten 30 Tagen.",
            noValidGateways: "Keine Gateways mit gültigen Koordinaten gefunden",
            labels: {
                sensorName: "Sensorname",
            }
        },
        tabs: {
            overview: "Überblick",
            parameters: "Parameter",
            gateways: "Gateways",
        },
        buttons: {
            save: "Speichern",
            cancel: "Abbrechen",
            unclaimDevice: "Gerät abmelden"
        },
        downloadModal: {
            title: {
                phrase1: "Daten von",
                phrase2: "herunterladen",
            },
            labels: {
                advanced: "Erweitert",
                dateFormat: "Datumsformat",
                dataTitles: "Datentitel",
            },
            buttons: {
                download: "Herunterladen",
                cancel: "Abbrechen",
            },
            formDefaults: {
                dataTitles: {
                    custom: "Benutzerdefiniert",
                    config: "Config",
                },
            },
        },
    }
};
export const Dutch: LanguageText = {
    claim: {
        claimANewDevice: "Claim een nieuw apparaat",
        selectAGroup: "Selecteer een groep",
        labels: {
            deviceID: "Apparaat-id",
            referenceCode: "Referentiecode",
            group: "Groep"
        },
        buttons: {
            claimDevice: "Claim Apparaat",
            cancel: "Annuleren",
            confirm: "Bevestig"
        },
        confirm: {
            title: "Claim Apparaat",
            phrase1: "Weet u zeker dat u apparaat ",
            phrase2: " wilt toevoegen aan groep ",
            phrase3: "? ",
            refCode: "referentiecode ",
        },
        toasts: {
            noGroupPermission: "Je moet toestemming hebben om een apparaat in een groep te claimen. Neem contact op met de persoon die je heeft uitgenodigd om toestemming te krijgen.",
            noGroups: "Je moet deel uitmaken van een groep om een apparaat te claimen. Maak een nieuwe groep aan, of sluit je aan bij iemand anders.",
            claimed: "Apparaat geclaimd"
        }
    },
    deviceTable: {
        devices: "Apparaten",
        tableElements: {
            headers: {
                deviceName: "Naam apparaat",
                deviceID: "Apparaat-ID",
                group: "Groep",
                deviceLocation: "Apparaatlocatie",
                deviceStatus: "Apparaatstatus",
                claimDate: "Claim Datum",
                deviceEUI: "Apparaat-ID"
            },
            quickEdit: "Snelle Bewerking",
            searchAllColumns: "Doorzoek alle kolommen",
            goToPage: "Ga naar de pagina",
            loading: "Laden",
            show: "Toon",
            noDevicesFound: "Geen apparaten gevonden",
            online: "Online",
            offline: "Offline"
        }
    },
    editDevice: {
        viewEditDevice: {
            editDevice: "Bewerk Apparaat",
            device: "Apparaat",
            modals: {
                unclaimModal: {
                    title: "Dit apparaat vrijgeven",
                    phrase1: "Weet u zeker dat u het apparaat ",
                    phrase2: " wilt afmelden? Deze actie kan niet ongedaan worden gemaakt.",
                },
            },
            labels: {
                deviceName: "Apparaatnaam",
                group: "Groep",
                deviceLatitude: "Apparaatbreedte",
                deviceLongitude: "Apparaatlengte",
                deviceID: "Apparaat-ID",
                lastSeen: "Laatst gezien",
                softwareVersion: "Softwareversie",
            },
            toasts: {
                deviceSaved: "Apparaat opgeslagen",
                deviceUnclaimed: "Apparaat werd niet opgeëist"
            },
            invalidFields: {
                groupNameUnknown: "Onbekend",
                deviceNeverSeen: "Nooit",
                softwareVersionUnknown: "Onbekend",
            },
            buttons: {
                move: "Verplaatsen",
                fix: "Vastzetten",
            },
        },
        editDeviceParameters: {
            deviceParameters: "Apparaatparameters",
            sensors: "Sensoren",
            buttons: {
                deleteData: "Verwijder gegevens"
            },
            modals: {
                thisDevice: "dit apparaat",
                buttons: {
                    cancel: "Annuleren",
                    accept: "Verwijder gegevens",
                },
                title: {
                    phrase1: "Gegevens verwijderen uit ",
                    phrase2: "",
                },
                deleteData: {
                    labels: {
                        all: "Alle",
                        before: "Voor",
                        since: "Sinds",
                        between: "Tussen",
                    },
                },
                deleteDataConfirm: {
                    body: {
                        phrase1: "Alle ",
                        phrase2: "gegevens van ",
                        phrase3: "",
                        phrase4: "",
                        phrase5: " worden verwijderd. Weet u het zeker? Deze actie kan niet ongedaan worden gemaakt.",
                    },
                },
            },
        },
        editDeviceGateways: {
            gateways: "Gateways",
            lastContact: "Laatste Contact",
            noGateways: "Geen gateways in de laatste 30 dagen.",
            noValidGateways: "Geen gateways met geldige coördinaten gevonden",
            labels: {
                sensorName: "Sensornaam",
            }
        },
        tabs: {
            overview: "Overzicht",
            parameters: "Parameters",
            gateways: "Gateways",
        },
        buttons: {
            save: "Opslaan",
            cancel: "Annuleren",
            unclaimDevice: "Apparaat vrijgeven"
        },
        downloadModal: {
            title: {
                phrase1: "Gegevens van",
                phrase2: "downloaden",
            },
            labels: {
                advanced: "Geavanceerd",
                dateFormat: "Datumnotatie",
                dataTitles: "Gegevenstitels",
            },
            buttons: {
                download: "Downloaden",
                cancel: "Annuleren",
            },
            formDefaults: {
                dataTitles: {
                    custom: "Aangepast",
                    config: "Config",
                },
            },
        },
    }
};
export const Spanish: LanguageText = {
    claim: {
        claimANewDevice: "Reclamar un nuevo dispositivo",
        selectAGroup: "Seleccionar un grupo",
        labels: {
            deviceID: "ID de dispositivo",
            referenceCode: "Código de referencia",
            group: "Grupo"
        },
        buttons: {
            claimDevice: "Dispositivo de reclamación",
            cancel: "Cancelar",
            confirm: "Confirmar"
        },
        confirm: {
            title: "Dispositivo de reclamación",
            phrase1: "¿Está seguro de que desea añadir el dispositivo ",
            phrase2: " al grupo ",
            phrase3: "? ",
            refCode: "código de referencia ",
        },
        toasts: {
            noGroupPermission: "Necesitas tener permiso para reclamar un dispositivo en un grupo. Ponte en contacto con la persona que te invitó para obtener permiso",
            noGroups: "Tienes que formar parte de un grupo para reclamar un dispositivo. Crea un grupo nuevo o únete a otro",
            claimed: "Dispositivo reclamado"
        }
    },
    deviceTable: {
        devices: "Dispositivos",
        tableElements: {
            headers: {
                deviceName: "Nombre del dispositivo",
                deviceID: "ID de dispositivo",
                group: "Grupo",
                deviceLocation: "Localización del dispositivo",
                deviceStatus: "Estado del dispositivo",
                claimDate: "Fecha de reclamación",
                deviceEUI: "Dispositivo EUI"
            },
            quickEdit: "Edición rápida",
            searchAllColumns: "Buscar en todas las columnas",
            goToPage: "Ir a la página",
            loading: "Cargando",
            show: "Mostrar",
            noDevicesFound: "No se han encontrado dispositivos",
            online: "En línea",
            offline: "Fuera de línea"
        }
    },
    editDevice: {
        viewEditDevice: {
            editDevice: "Editar dispositivo",
            device: "Dispositivo",
            modals: {
                unclaimModal: {
                    title: "Dispositivo Unclaim",
                    phrase1: "¿Está seguro de que desea anular la reclamación del dispositivo ",
                    phrase2: "? Esta acción no se puede deshacer.",
                },
            },
            labels: {
                deviceName: "Nombre del dispositivo",
                group: "Grupo",
                deviceLatitude: "Latitud del dispositivo",
                deviceLongitude: "Longitud del dispositivo",
                deviceID: "ID de dispositivo",
                lastSeen: "Visto por última vez",
                softwareVersion: "Versión del software",
            },
            toasts: {
                deviceSaved: "Dispositivo guardado",
                deviceUnclaimed: "Dispositivo no reclamado"
            },
            invalidFields: {
                groupNameUnknown: "Desconocido",
                deviceNeverSeen: "Nunca",
                softwareVersionUnknown: "Desconocido",
            },
            buttons: {
                move: "Mover",
                fix: "Fijar",
            },
        },
        editDeviceParameters: {
            deviceParameters: "Parámetros del dispositivo",
            sensors: "Sensores",
            buttons: {
                deleteData: "Eliminar datos"
            },
            modals: {
                thisDevice: "este dispositivo",
                buttons: {
                    cancel: "Cancelar",
                    accept: "Eliminar datos",
                },
                title: {
                    phrase1: "Borrar datos de ",
                    phrase2: "",
                },
                deleteData: {
                    labels: {
                        all: "Todo",
                        before: "Antes",
                        since: "Desde",
                        between: "Entre",
                    },
                },
                deleteDataConfirm: {
                    body: {
                        phrase1: "Todos ",
                        phrase2: "los datos de ",
                        phrase3: "",
                        phrase4: " al ",
                        phrase5: "serán eliminados. ¿Estás seguro de que quieres continuar? Esta acción no se puede deshacer.",
                    },
                },
            },
        },
        editDeviceGateways: {
            gateways: "Pasarelas",
            lastContact: "Último contacto",
            noGateways: "No hay pasarelas en los últimos 30 días.",
            noValidGateways: "No se han encontrado pasarelas con coordenadas válidas",
            labels: {
                sensorName: "Nombre del sensor",
            }
        },
        tabs: {
            overview: "Visión de conjunto",
            parameters: "Parámetros",
            gateways: "Pasarelas",
        },
        buttons: {
            save: "Guardar",
            cancel: "Cancelar",
            unclaimDevice: "Dispositivo Unclaim"
        },
        downloadModal: {
            title: {
                phrase1: "Descargar datos de",
                phrase2: "",
            },
            labels: {
                advanced: "Avanzado",
                dateFormat: "Formato de fecha",
                dataTitles: "Títulos de datos",
            },
            buttons: {
                download: "Descargar",
                cancel: "Cancelar",
            },
            formDefaults: {
                dataTitles: {
                    custom: "Personalizado",
                    config: "Config",
                },
            },
        },
    }
};
export const Italian: LanguageText = {
    claim: {
        claimANewDevice: "Richiedi un nuovo dispositivo",
        selectAGroup: "Seleziona un gruppo",
        labels: {
            deviceID: "ID dispositivo",
            referenceCode: "Codice di riferimento",
            group: "Gruppo"
        },
        buttons: {
            claimDevice: "Dispositivo di rivendicazione",
            cancel: "Annulla",
            confirm: "Confermare"
        },
        confirm: {
            title: "Dispositivo di rivendicazione",
            phrase1: "Siete sicuri di voler aggiungere il dispositivo ",
            phrase2: " al gruppo ",
            phrase3: "? ",
            refCode: "codice di riferimento",
        },
        toasts: {
            noGroupPermission: "È necessario avere l'autorizzazione per richiedere un dispositivo in un gruppo. Contatta la persona che ti ha invitato per ottenere l'autorizzazione",
            noGroups: "Per rivendicare un dispositivo è necessario far parte di un gruppo. Crea un nuovo gruppo o unisciti a un altro",
            claimed: "Dispositivo rivendicato"
        }
    },
    deviceTable: {
        devices: "Dispositivi",
        tableElements: {
            headers: {
                deviceName: "Nome dispositivo",
                deviceID: "ID dispositivo",
                group: "Gruppo",
                deviceLocation: "Posizione del dispositivo",
                deviceStatus: "Stato del dispositivo",
                claimDate: "Data del reclamo",
                deviceEUI: "EUI del dispositivo"
            },
            quickEdit: "Modifica rapida",
            searchAllColumns: "Cerca in tutte le colonne",
            goToPage: "Vai alla pagina",
            loading: "Caricamento",
            show: "Mostra",
            noDevicesFound: "Nessun dispositivo trovato",
            online: "Online",
            offline: "Offline"
        }
    },
    editDevice: {
        viewEditDevice: {
            editDevice: "Modifica dispositivo",
            device: "Dispositivo",
            modals: {
                unclaimModal: {
                    title: "Disconoscimento del dispositivo",
                    phrase1: "Sei sicuro di voler annullare il dispositivo ",
                    phrase2: "? Questa azione non può essere annullata.",
                },
            },
            labels: {
                deviceName: "Nome dispositivo",
                group: "Gruppo",
                deviceLatitude: "Latitudine del dispositivo",
                deviceLongitude: "Longitudine del dispositivo",
                deviceID: "ID dispositivo",
                lastSeen: "Ultimo visto",
                softwareVersion: "Versione del software",
            },
            toasts: {
                deviceSaved: "Dispositivo salvato",
                deviceUnclaimed: "Dispositivo non reclamato"
            },
            invalidFields: {
                groupNameUnknown: "Sconosciuto",
                deviceNeverSeen: "Mai",
                softwareVersionUnknown: "Sconosciuto",
            },
            buttons: {
                move: "Spostare",
                fix: "Fissare",
            },
        },
        editDeviceParameters: {
            deviceParameters: "Parametri del dispositivo",
            sensors: "Sensori",
            buttons: {
                deleteData: "Elimina dati"
            },
            modals: {
                thisDevice: "questo dispositivo",
                buttons: {
                    cancel: "Annulla",
                    accept: "Elimina dati",
                },
                title: {
                    phrase1: "Cancellare i dati da ",
                    phrase2: "",
                },
                deleteData: {
                    labels: {
                        all: "Tutto",
                        before: "Prima",
                        since: "Dalla",
                        between: "Tra",
                    },
                },
                deleteDataConfirm: {
                    body: {
                        phrase1: "Tutti ",
                        phrase2: "i dati di ",
                        phrase3: "",
                        phrase4: " del ",
                        phrase5: " saranno eliminati. È sicuro? Questa azione non può essere annullata.",
                    },
                },
            },
        },
        editDeviceGateways: {
            gateways: "Gateway",
            lastContact: "Ultimo contatto",
            noGateways: "Nessuna gateway negli ultimi 30 giorni.",
            noValidGateways: "Nessuna gateway con coordinate valide trovata",
            labels: {
                sensorName: "Nome del sensore",
            }
        },
        tabs: {
            overview: "Panoramica",
            parameters: "Parametri",
            gateways: "Gateway",
        },
        buttons: {
            save: "Salva",
            cancel: "Annulla",
            unclaimDevice: "Disconoscimento del dispositivo"
        },
        downloadModal: {
            title: {
                phrase1: "Scarica i dati da",
                phrase2: "",
            },
            labels: {
                advanced: "Avanzato",
                dateFormat: "Formato data",
                dataTitles: "Titoli dei dati",
            },
            buttons: {
                download: "Scarica",
                cancel: "Annulla",
            },
            formDefaults: {
                dataTitles: {
                    custom: "Personalizzato",
                    config: "Config",
                },
            },
        },
    }
};
export const Romanian: LanguageText = {
    claim: {
        claimANewDevice: "Revendicați un nou dispozitiv",
        selectAGroup: "Selectați un grup",
        labels: {
            deviceID: "ID-ul dispozitivului",
            referenceCode: "Cod de referință",
            group: "Grup"
        },
        buttons: {
            claimDevice: "Dispozitiv de revendicare",
            cancel: "Anulează",
            confirm: "Confirmă"
        },
        confirm: {
            title: "Dispozitiv de revendicare",
            phrase1: "Sunteți sigur că doriți să adăugați dispozitivul ",
            phrase2: " la grupul ",
            phrase3: "? ",
            refCode: "cod de referință ",
        },
        toasts: {
            noGroupPermission: "Trebuie să aveți permisiunea de a revendica un dispozitiv în cadrul unui grup. Contactați persoana care v-a invitat pentru a obține permisiunea.",
            noGroups: "Trebuie să faci parte dintr-un grup pentru a revendica un dispozitiv. Fie creați un grup nou, fie vă alăturați unui alt grup.",
            claimed: "Dispozitiv revendicat"
        }
    },
    deviceTable: {
        devices: "Dispozitive",
        tableElements: {
            headers: {
                deviceName: "Numele dispozitivului",
                deviceID: "ID-ul dispozitivului",
                group: "Grup",
                deviceLocation: "Locația dispozitivului",
                deviceStatus: "Starea dispozitivului",
                claimDate: "Data revendicării",
                deviceEUI: "ID-ul dispozitivului"
            },
            quickEdit: "Editare rapidă",
            searchAllColumns: "Căutați toate coloanele",
            goToPage: "Mergi la pagina",
            loading: "Încărcare",
            show: "Arată",
            noDevicesFound: "Nu s-au găsit dispozitive",
            online: "Online",
            offline: "Offline"
        }
    },
    editDevice: {
        viewEditDevice: {
            editDevice: "Editare dispozitiv",
            device: "Dispozitiv",
            modals: {
                unclaimModal: {
                    title: "Dezangajeze acest dispozitiv",
                    phrase1: "Sunteți sigur că doriți să anulați revendicarea dispozitivului ",
                    phrase2: "? Această acțiune nu poate fi anulată.",
                },
            },
            labels: {
                deviceName: "Numele dispozitivului",
                group: "Grup",
                deviceLatitude: "Latitudinea dispozitivului",
                deviceLongitude: "Longitudine dispozitiv",
                deviceID: "ID-ul dispozitivului",
                lastSeen: "Ultima vizualizare",
                softwareVersion: "Versiunea software-ului",
            },
            toasts: {
                deviceSaved: "Dispozitiv salvat",
                deviceUnclaimed: "Dispozitivul a fost nerevendicat"
            },
            invalidFields: {
                groupNameUnknown: "Necunoscut",
                deviceNeverSeen: "Niciodată",
                softwareVersionUnknown: "Necunoscut",
            },
            buttons: {
                move: "Mișcare",
                fix: "Fixați",
            },
        },
        editDeviceParameters: {
            deviceParameters: "Parametrii dispozitivului",
            sensors: "Senzori",
            buttons: {
                deleteData: "Eliminați datele"
            },
            modals: {
                thisDevice: "acest dispozitiv",
                title: {
                    phrase1: "Ștergeți datele din ",
                    phrase2: "",
                },
                buttons: {
                    cancel: "Annulla",
                    accept: "Eliminați datele",
                },
                deleteData: {
                    labels: {
                        all: "toate",
                        before: "înainte ",
                        since: "după ",
                        between: "între ",
                    },
                },
                deleteDataConfirm: {
                    body: {
                        phrase1: "Toate ",
                        phrase2: "datele din ",
                        phrase3: "de",
                        phrase4: "",
                        phrase5: " vor fi șterse. Sunteți sigur? Această acțiune nu poate fi anulată.",
                    },
                },
            },
        },
        editDeviceGateways: {
            gateways: "Gateway",
            lastContact: "Ultimul contact",
            noGateways: "Nicio gateway în ultimele 30 de zile.",
            noValidGateways: "Nu s-a găsit nicio gateway cu coordonate valide",
            labels: {
                sensorName: "Numele senzorului",
            }
        },
        tabs: {
            overview: "Prezentare generală",
            parameters: "Parametri",
            gateways: "Gateway",
        },
        buttons: {
            save: "Salvați",
            cancel: "Anulează",
            unclaimDevice: "dezangajeze acest dispozitiv"
        },
        downloadModal: {
            title: {
                phrase1: "Descărcați datele de la",
                phrase2: "",
            },
            labels: {
                advanced: "Avansat",
                dateFormat: "Formatul datei",
                dataTitles: "Titluri de date",
            },
            buttons: {
                download: "Descarcă",
                cancel: "Anulează",
            },
            formDefaults: {
                dataTitles: {
                    custom: "Personalizat",
                    config: "Config",
                },
            },
        },
    }
};
export const Portuguese: LanguageText = {
    claim: {
        claimANewDevice: "Reclamar um novo dispositivo",
        selectAGroup: "Seleccione um grupo",
        labels: {
            deviceID: "ID do dispositivo",
            referenceCode: "Código de referência",
            group: "Grupo"
        },
        buttons: {
            claimDevice: "Dispositivo de reclamação",
            cancel: "Cancelar",
            confirm: "Confirmar"
        },
        confirm: {
            title: "Dispositivo de reclamação",
            phrase1: "Tem a certeza que gostaria de adicionar o dispositivo ",
            phrase2: " ao grupo ",
            phrase3: "? ",
            refCode: "código de referência ",
        },
        toasts: {
            noGroupPermission: "É preciso ter permissão para reclamar um dispositivo num grupo. Contacte a pessoa que o convidou para obter a permissão",
            noGroups: "É preciso fazer parte de um grupo para reclamar um dispositivo. Ou criar um novo grupo, ou juntar-se a alguém elses",
            claimed: "Dispositivo reclamado"
        }
    },
    deviceTable: {
        devices: "Dispositivos",
        tableElements: {
            headers: {
                deviceName: "Nome do dispositivo",
                deviceID: "ID do dispositivo",
                group: "Grupo",
                deviceLocation: "Localização do dispositivo",
                deviceStatus: "Estado do dispositivo",
                claimDate: "Data de reclamação",
                deviceEUI: "Dispositivo EUI"
            },
            quickEdit: "Edição rápida",
            searchAllColumns: "Pesquisar todas as colunas",
            goToPage: "Ir para a página",
            loading: "Carregamento",
            show: "Mostrar",
            noDevicesFound: "Nenhum dispositivo encontrado",
            online: "Online",
            offline: "Offline"
        }
    },
    editDevice: {
        viewEditDevice: {
            editDevice: "Dispositivo de edição",
            device: "Dispositivo",
            modals: {
                unclaimModal: {
                    title: "Dispositivo de Desclassificação",
                    phrase1: "Tem a certeza de que gostaria de desactivar o dispositivo ",
                    phrase2: "? Esta acção não pode ser desfeita.",
                },
            },
            labels: {
                deviceName: "Nome do dispositivo",
                group: "Grupo",
                deviceLatitude: "Latitude do dispositivo",
                deviceLongitude: "Longitude do dispositivo",
                deviceID: "ID do dispositivo",
                lastSeen: "Visto pela última vez",
                softwareVersion: "Versão do software",
            },
            toasts: {
                deviceSaved: "Dispositivo Guardado",
                deviceUnclaimed: "Dispositivo não reclamado"
            },
            invalidFields: {
                groupNameUnknown: "Desconhecido",
                deviceNeverSeen: "Nunca",
                softwareVersionUnknown: "Desconhecido",
            },
            buttons: {
                move: "Mover",
                fix: "Fixar",
            },
        },
        editDeviceParameters: {
            deviceParameters: "Parâmetros do dispositivo",
            sensors: "Sensores",
            buttons: {
                deleteData: "Apagar dados"
            },
            modals: {
                thisDevice: "este dispositivo",
                title: {
                    phrase1: "Apagar dados do ",
                    phrase2: "",
                },
                buttons: {
                    cancel: "Cancelar",
                    accept: "Apagar dados",
                },
                deleteData: {
                    labels: {
                        all: "Tudo",
                        before: "Antes",
                        since: "Desde",
                        between: "Entre",
                    },
                },
                deleteDataConfirm: {
                    body: {
                        phrase1: "Todos ",
                        phrase2: "os dados do ",
                        phrase3: "",
                        phrase4: "de",
                        phrase5: " serão apagados. Tem a certeza? Esta acção não pode ser desfeita.",
                    },
                },
            },
        },
        editDeviceGateways: {
            gateways: "Gateway",
            lastContact: "Último contacto",
            noGateways: "Nenhuma gateway nos últimos 30 dias.",
            noValidGateways: "Nenhuma gateway com coordenadas válidas encontrada",
            labels: {
                sensorName: "Nome do sensor",
            }
        },
        tabs: {
            overview: "Visão geral",
            parameters: "Parâmetros",
            gateways: "Gateway",
        },
        buttons: {
            save: "Salvar",
            cancel: "Cancelar",
            unclaimDevice: "Dispositivo de Desclassificação"
        },
        downloadModal: {
            title: {
                phrase1: "Descarregar dados de",
                phrase2: "",
            },
            labels: {
                advanced: "Avançado",
                dateFormat: "Formato de data",
                dataTitles: "Títulos de dados",
            },
            buttons: {
                download: "Baixar",
                cancel: "Cancelar",
            },
            formDefaults: {
                dataTitles: {
                    custom: "Personalizado",
                    config: "Config",
                },
            },
        },
    }
};
