import _ from "lodash";
import { useState, useEffect } from "react";
import AnimateHeight from "react-animate-height";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import { useAppSelector } from "../../app/hooks";
import { FormInput } from "../../components/FormInput";
import InputModal from "../../components/modals/InputModal";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../dictionary/DeviceText";
import { selectData, DataType } from "../../features/data/dataSlice";
import { selectLanguage } from "../../features/user/userSlice";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { Device } from "../../features/devices/deviceSlice";
import { UplinkData } from "../../services/dataAPI";
import sanitizeHtml from "sanitize-html";

type LineListEntry = {
    name: string;
    pheno: string | number;
    config: string;
    checked: boolean;
}

export type DownloadModalProps = {
    setInternalModal: (value: boolean) => void;
    setDashboardName: (value: string) => void;
    setDashboardDescription: (value: string) => void;
    dashboardName: string;
    dashboardDescription: string;
};

function RenameDashboardModal({
    setInternalModal,
    setDashboardName,
    setDashboardDescription,
    dashboardName,
    dashboardDescription,
}: DownloadModalProps) {

    const stateLang = useAppSelector(selectLanguage);
    let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    useEffect(() => {
        setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    }, [stateLang]);

    const [tempName, setTempName] = useState(dashboardName);
    const [tempDescription, setTempDescription] = useState(dashboardDescription);

    const [nameError, setNameError] = useState<string>();
    const [descriptionError, setDescriptionError] = useState<string>();

    const sanitizeConf = {
        allowedTags: ["i"],
        allowedAttributes: {},
    };

    const sanitize = (name: string) => {
        return (sanitizeHtml(name, sanitizeConf));
    };


    return (
        <InputModal
            onSubmit={async (e) => {
                e.preventDefault();
                if(tempName === "") {
                    setNameError("Name cannot be empty");
                    return;
                }
                if(tempDescription === "") {
                    setDescriptionError("Description cannot be empty");
                    return;
                }
                setDashboardName(tempName);
                setDashboardDescription(tempDescription);
                setInternalModal(false);
            }}
            onCancel={() => setInternalModal(false)}
            Title={"Edit " + dashboardName}
            Loading={false}
            Body={
                <div className="w-full">
                    <div className="w-full">
                        <FormInput
                            label={"Name"}
                            value={tempName}
                            htmlFor="name"
                            error={nameError}
                            onChange={(e) => setTempName(sanitize(e.target.value))}
                        />
                    </div>
                    <div className="w-full">
                        <FormInput
                            label={"Description"}
                            value={tempDescription}
                            htmlFor="description"
                            error={descriptionError}
                            onChange={(e) => setTempDescription(sanitize(e.target.value))}
                        />
                    </div>
                </div>
            }
            Form={[]}
            AcceptButton={"Submit"}
            CancelButton={"Cancel"}
        />
    );
}

export default RenameDashboardModal;
