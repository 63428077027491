import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { Icon, LatLng, LatLngBoundsExpression, LatLngTuple } from "leaflet";
import _ from "lodash";
import { useState, useEffect, useRef } from "react";
import { BsFillUnlockFill, BsFillLockFill, BsLifePreserver, BsFillDoorClosedFill, BsFillDoorOpenFill, BsDoorClosedFill, BsDoorOpenFill } from "react-icons/bs";
import { MapContainer, LayersControl, Pane, TileLayer, Marker, Popup, useMapEvents, useMap, ImageOverlay } from "react-leaflet";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { FormInput } from "../components/FormInput";
import Spinner from "../components/Spinner";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../dictionary/DeviceText";
import { selectLanguage, selectUser } from "../features/user/userSlice";
import { deviceAPI, SaveDevice, useEditDeviceMutation, useGetDeviceQuery } from "../services/deviceAPI";
import { DataTimeToLocale } from "../utils/DateFormating";
import { handleError } from "../utils/ErrorHandling";
import { LanguageCheck } from "../utils/LanguageCheck";
import ReactECharts from "echarts-for-react";


/* gauge imports */
import { UplinkData, useGetLatestDataQuery, useLazyGetDataQuery } from "../services/dataAPI";
/* ********************** */
import { Button } from "../components/Button";
import moment from "moment";
import L from "leaflet";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import echarts from "echarts/types/dist/echarts";

type LifeRingType = {
    ringID: string;
    name: string;
    lastContact: string;
    lastActivity: string;
    lastState: boolean;
    lat: number;
    lng: number;
};

function LifeRings() {
    const { deviceid } = useParams();
    const {
        data: device,
        isSuccess,
        isError,
        error,
        isFetching,
    } = useGetDeviceQuery(deviceid ?? skipToken);
    const configurations = device?.configuration;
    const gateways = device?.gateways;
    const user = useAppSelector(selectUser);
    const [editPermission, setEditPermission] = useState(false);
    const { BaseLayer } = LayersControl;
    const [lockDragging, setLockDragging] = useState(true);
    const [seed, setSeed] = useState(0);
    const [viewMode, setViewMode] = useState(true);
    const [selectedLifeRing, setSelectedLifeRing] = useState("");
    const [deviceCoordinates, setDeviceCoordinates] = useState<LatLng>(
        new LatLng(0, 0)
    );
    const dispatch = useAppDispatch();
    const [showAlertBox, setShowAlertBox] = useState(true);

    const topLeft: LatLngTuple = [0, 0];
    const bottomRight: LatLngTuple = [0, 0];
    const [bounds, setBounds] = useState<LatLngBoundsExpression>([
        topLeft,
        bottomRight,
    ]);

    const chartRef = useRef<ReactECharts>(null);

    const imageUrl = "https://i.pinimg.com/originals/6e/af/fd/6eaffdbb5def9fa8d538a55597f3e6a8.png"

    const loadImage = (imageUrl: string) => {
        const img = new Image();
        img.src = imageUrl;

        img.onload = () => {
            setBounds([topLeft, [img.height, img.width]]);
        };
        img.onerror = (err) => {
        };
    };

    useEffect(() => {
        loadImage(imageUrl);
    }, [imageUrl]);


    const stateLang = useAppSelector(selectLanguage);
    let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    useEffect(() => {
        setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    }, [stateLang]);

    const [selectedSort, setSelectedSort] = useState("lastActivity");
    const [sortDirection, setSortDirection] = useState("asc");

    /* const { data: latestData } = useGetLatestDataQuery({ "eui": device?.eui ?? "" }, { pollingInterval: 60000 }); */

    const [lifeRings, setLifeRings] = useState<LifeRingType[]>([
        { ringID: "0080e115005af8ce", name: "0080e115005af8ce", lastContact: getMinutesAgo(1455), lastActivity: getMinutesAgo(1455), lastState: true, lat: 51.509, lng: -0.12 },
        { ringID: "0080e11505046436", name: "0080e11505046436", lastContact: getMinutesAgo(2413), lastActivity: getMinutesAgo(3910), lastState: false, lat: 51.511, lng: -0.11 },
        { ringID: "0080e1150504643f", name: "0080e1150504643f", lastContact: getMinutesAgo(1), lastActivity: getMinutesAgo(1), lastState: true, lat: 51.511, lng: -0.1 },
        /* { ringID: "9", name: "LifeRing4", lastContact: getMinutesAgo(140), lastActivity: getMinutesAgo(140), lat: 51.509, lng: -0.09 },
        { ringID: "Q", name: "LifeRing5", lastContact: getMinutesAgo(24), lastActivity: getMinutesAgo(5893), lat: 51.50822, lng: -0.11 },
        { ringID: "Z", name: "LifeRing6", lastContact: getMinutesAgo(1905), lastActivity: getMinutesAgo(4000), lat: 51.5085, lng: -0.1 },
        { ringID: "J", name: "LifeRing7", lastContact: getMinutesAgo(373), lastActivity: getMinutesAgo(1430), lat: 51.507, lng: -0.09 }, */
    ])

    const [currentAlert, setCurrentAlert] = useState<LifeRingType>(lifeRings[0]);

    function getMinutesAgo(minutes: number) {
        var time = moment().subtract(minutes, 'minutes').format("YYYY-MM-DDTHH:mm:ss.SSS[+01:00]");
        return time;
    }

    useEffect(() => {
        if (lifeRings) {
            sortLifeRingsByParam(selectedSort);
        }
    }, [selectedSort, sortDirection])

    function sortLifeRingsByParam(param: string) {
        var sortedLifeRings = lifeRings
        if (param == "lastActivity") {
            var offlineRings = _.filter(lifeRings, function (ring) { return moment(ring.lastContact).isBefore(moment().subtract(24, 'hours')) });
            var onlineRings = _.filter(lifeRings, function (ring) { return moment(ring.lastContact).isAfter(moment().subtract(24, 'hours')) });

            sortedLifeRings = _.sortBy(onlineRings, param)
            if (sortDirection === "asc") {
                sortedLifeRings = sortedLifeRings.reverse();
            }
            sortedLifeRings = sortedLifeRings.concat(offlineRings);
        }
        else {
            sortedLifeRings = _.sortBy(lifeRings, param);
            if (sortDirection === "asc") {
                sortedLifeRings = sortedLifeRings.reverse();
            }
        }
        setLifeRings(sortedLifeRings);
    }

    const [
        saveDevice,
        {
            data: saveDeviceResponse,
            isSuccess: isSaveDeviceSuccess,
            isLoading: isSaveDeviceLoading,
            isError: isSaveDeviceError,
            error: saveDeviceError,
        },
    ] = useEditDeviceMutation();

    var gatewayIcon = new Icon({
        iconUrl:
            "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
        shadowUrl:
            "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [12, 41],
        shadowSize: [41, 41],
    });

    function getIcon(colour: string, state: boolean) {
        return L.divIcon({
            className: "custom-icon-marker",
            html: state ? `<svg stroke=${colour} fill=${colour} stroke-width="0" viewBox="0 0 16 16" height="28" width="28" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V2a1 1 0 0 1 1-1h8zm-2 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></svg>` : `<svg stroke=${colour} fill=${colour} stroke-width="0" viewBox="0 0 16 16" height="28" width="28" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 15a.5.5 0 0 0 0 1h13a.5.5 0 0 0 0-1H13V2.5A1.5 1.5 0 0 0 11.5 1H11V.5a.5.5 0 0 0-.57-.495l-7 1A.5.5 0 0 0 3 1.5V15H1.5zM11 2h.5a.5.5 0 0 1 .5.5V15h-1V2zm-2.5 8c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z"></path></svg>`,
            iconSize: [28, 28],
            iconAnchor: [14, 14],
            popupAnchor: [1, -34],
            shadowSize: [41, 41],
        })
    }

    useEffect(() => {
        if (isSuccess) {
            if (user.groups[device.group_id]) {
                setEditPermission(
                    user.groups[device.group_id].permissions.can_edit_devices
                        ?.can_edit_all ??
                    user.groups[device.group_id].permissions.can_edit_devices
                        ?.can_edit_devices?.[device.id] ??
                    false
                );
            } else {
                setEditPermission(false);
            }
        }
    }, [device, user, isSuccess]);

    const LockDraggingButton: React.FC = () => {
        return (
            <button
                className="bg-white pl-[6px] ring-opacity-20 ring-2 ring-gray-900 top-[5rem] left-3 border rounded absolute w-[1.9rem] h-8 z-[700]"
                onClick={() => {
                    setSeed(Math.random());
                    setLockDragging(!lockDragging);
                }}
            >
                {lockDragging ? (
                    <BsFillUnlockFill size={16} />
                ) : (
                    <BsFillLockFill size={16} />
                )}
            </button>
        );
    };

    function MapEvents() {
        const map = useMapEvents({
            baselayerchange: (e) => {
                e.name === "Map" ? setViewMode(true) : setViewMode(false);
            },
            movestart: () => {
                setSelectedLifeRing("");
            },
        });
        return null;
    }

    const lifeRingList: JSX.Element[] = [];

    if (lifeRings !== undefined) {
        for (let lifeRing in lifeRings) {
            lifeRingList.push(
                <LifeRingBox lifeRing={lifeRings[lifeRing]} />
            )
        }
    }

    useEffect(() => {
        if (lifeRings !== undefined) {
            const filteredLifeRings = lifeRings.filter(lifeRing => lifeRing.lat !== 0 && lifeRing.lng !== 0);
            if (filteredLifeRings.length > 0) {
                setDeviceCoordinates(new LatLng(filteredLifeRings[0].lat, filteredLifeRings[0].lng))
            }
        }
    }, [lifeRings]);

    useEffect(() => {
        if (isSaveDeviceError) {
            handleError(saveDeviceError);
        }
    }, [saveDeviceError, isSaveDeviceError]);

    useEffect(() => {
        if (isSaveDeviceSuccess) {
            toast.success(language.editDevice.viewEditDevice.toasts.deviceSaved)
        }
    }, [saveDeviceResponse, isSaveDeviceSuccess, language.editDevice.viewEditDevice.toasts.deviceSaved]);

    const CenterableMarker = ({ center, content, selected, colour, state }: { center: LatLngTuple, content: JSX.Element, selected: boolean, colour: string; state: boolean; }) => {
        const map = useMap();
        const markerRef = useRef<L.Marker<any>>(null);

        useEffect(() => {
            if (selected) {
                map.setView(center);
                if (markerRef.current !== null) {
                    markerRef.current.openPopup();
                }
            }
        }, [map, center, selected]);

        return (
            center[0] !== 0 && center[1] !== 0 ? (
                <Marker
                    icon={getIcon(colour, state)}
                    position={center}
                    ref={markerRef}
                >
                    <Popup autoPan={false}>
                        {content}
                    </Popup>
                </Marker>
            ) : <> </>
        );
    };

    const markers = (
        _.map(lifeRings, (lifeRing) => {
            const content: JSX.Element = (<>
                {lifeRing.name}<br />{DataTimeToLocale(new Date(lifeRing.lastActivity ?? 0))}
            </>)
            return (
                <CenterableMarker
                    center={[lifeRing.lat, lifeRing.lng]}
                    content={content}
                    selected={lifeRing.ringID === selectedLifeRing}
                    colour={getColour(lifeRing.lastContact, lifeRing.lastActivity) ?? "#FF0000"}
                    state={lifeRing.lastState}
                />
            );
        })
    )

    const map: JSX.Element = (
        <MapContainer
            key={seed}
            center={deviceCoordinates}
            minZoom={0}
            maxZoom={18}
            bounds={bounds}
            maxBounds={bounds}
            zoom={15}
            style={{ height: "100%", width: "100%", zIndex: "0" }}
            dragging={lockDragging}
            scrollWheelZoom={lockDragging}
        >
            {/*  <LayersControl position="topright">
                <BaseLayer checked={viewMode} name="Map">
                    <Pane name="map" style={{ zIndex: 0 }}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </Pane>
                </BaseLayer>
                <BaseLayer checked={!viewMode} name="Satellite">
                    <Pane name="satellite" style={{ zIndex: 0 }}>
                        <TileLayer
                            attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                        />
                    </Pane>
                </BaseLayer>
            </LayersControl> */}
            <LockDraggingButton />
            <ImageOverlay url={imageUrl} bounds={bounds} />
            {markers}
            <MapEvents />
        </MapContainer>
    )

    const openDoorIcon = (
        "path://M1.5 15a.5.5 0 0 0 0 1h13a.5.5 0 0 0 0-1H13V2.5A1.5 1.5 0 0 0 11.5 1H11V.5a.5.5 0 0 0-.57-.495l-7 1A.5.5 0 0 0 3 1.5V15H1.5zM11 2h.5a.5.5 0 0 1 .5.5V15h-1V2zm-2.5 8c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z"
    )

    const closedDoorIcon = (
        "path://M12 1a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V2a1 1 0 0 1 1-1h8zm-2 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
    )

    const N = () => {
        var date = new Date().getTime() - Math.round(1000 * (Math.random()));

        var axisData: any[] = [];
        for (var i = 0; i < 14; i++) {
            axisData.push([]);
        }
        const data = axisData.map(function (item, i) {
            date = Math.round(date + Math.random() * 1000 * 60 * 60 * i)
            return [date, (Math.round(i % 2) + 1)]
        });
        const links = data.map(function (item, i) {
            return {
                source: i,
                target: i + 1
            };
        });
        links.pop();
        var option = {
            title: {
                text: 'Demo Timeline'
            },
            tooltip: {
                position: 'inside',
                formatter: function (params: any) {
                    if (params.dataType === 'node') {
                        return `${formatDate(params.data[0])} <br />
                        ${params.data[1] === 1 ? 'Closed' : 'Open'}`
                    } else if (params.dataType === 'edge') {
                        var source = data[params.data.source];
                        var target = data[params.data.target];

                        var sourceDate = new Date(source[0]).toLocaleString();
                        var targetDate = new Date(target[0]).toLocaleString();

                        return `${sourceDate} - ${targetDate} <br />
                        ${source[1] === 1 ? 'Closed' : 'Open'}`
                    }
                }
            },
            xAxis: {
                type: 'time',
                boundaryGap: false,
                data: axisData
            },
            yAxis: {
                type: 'value',
                min: 0.5,
                max: 2.5,

                splitLine: {
                    interval: 2,
                },

                axisLabel: {
                    formatter: function (val: number) {
                        if (val === 1) {
                            return 'Closed';
                        } else if (val === 2) {
                            return 'Open';
                        }
                        return '';
                    }
                },
            },
            series: [
                {
                    type: 'graph',
                    layout: 'none',
                    coordinateSystem: 'cartesian2d',
                    symbolSize: 40,
                    symbol: function (data: number[]) {
                        return data[1] === 1 ? closedDoorIcon : openDoorIcon;
                    },
                    label: {
                        show: true
                    },
                    connectNulls: true,
                    edgeSymbol: ['circle', 'line'],
                    edgeSymbolSize: [4, 10],
                    data: data,
                    links: links,
                    lineStyle: {
                        color: '#2f4554'
                    }
                }
            ],
            dataZoom: [{
                type: 'inside'
            }, {
                type: 'slider'
            }]
        };

        return (
            <div className="h-full w-full">
                <ReactECharts
                    style={{ height: "100%", width: "100%" }}
                    opts={{ renderer: "svg" }}
                    option={option}
                    ref={chartRef}
                />
            </div>
        );

    };

    return (
        <div className="h-full w-full p-4 bg-white rounded-md">
            <div className="h-full w-full flex flex-wrap content-start">
                {/* {showAlertBox && (
                    <div className="w-full h-fit flex-wrap flex space-x-1">
                        <ActivityAlertBox lifeRing={currentAlert} />
                    </div>
                )}
                <div className={`${showAlertBox ? "h-[calc(100%-114px)]" : "h-full"} grid grid-rows-2 grid-cols-1 lg:grid-rows-1 lg:grid-cols-2 lg:space-x-1 w-full pt-1`}>
                    <div className="h-full w-full flex flex-col overflow-clip">
                        <div className="w-full flex justify-between mb-1">
                            <h1 className="font-bold text-xl flex justify-between items-center">
                                Demo2
                            </h1>
                            <div>
                                <N />
                            </div>
                            {/* <div className="flex space-x-2">
                                <div className="flex items-center">
                                    <label className="text-sm font-semibold whitespace-nowrap text-gray-700 mr-2">Sort by:</label>
                                    <div className="flex items-center rounded-md border border-gray-300 shadow-sm">
                                        <select
                                            className="block w-full py-2 px-3 border-0 border-r border-gray-300 bg-white rounded-l-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            onChange={(e) => {
                                                setSelectedSort(e.target.value);
                                            }}
                                            value={selectedSort}
                                        >
                                            <option value="name">Name</option>
                                            <option value="lastActivity">Last Activity</option>
                                            <option value="lastContact">Last Contact</option>
                                        </select>
                                        <div onClick={() => setSortDirection(sortDirection === "desc" ? "asc" : "desc")} className="block py-2 px-3 bg-white rounded-r-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                            {sortDirection === "desc" ? <FaSortAmountDown size={20} /> : <FaSortAmountUp size={20} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="overflow-y-scroll space-y-3 scrollbar-none h-full mb-2 rounded-md">
                            {lifeRingList}
                        </div>
                    </div>
                    {deviceCoordinates.lat !== 0 && deviceCoordinates.lng !== 0 && (
                        <div className="mr-2 lg:mr-0 h-full w-full bg-gray-100 text-center">
                            {map}
                        </div>
                    )}
                </div> */}
                <div className="h-full w-full">
                    <N />
                </div>
            </div>
        </div>
    );

    function LifeRingBox({ lifeRing }: { lifeRing: LifeRingType }) {
        return (
            <div className="text-black bg-gray-200 rounded-md p-2" onClick={() => { { setSelectedLifeRing(lifeRing.ringID); setShowAlertBox(true); setCurrentAlert(lifeRing) } }}>
                <div className="inline-flex w-full space-x-4">
                    {lifeRing.lastState ? <BsDoorClosedFill size={40} color={getColour(lifeRing.lastContact, lifeRing.lastActivity)} /> : <BsDoorOpenFill size={40} color={getColour(lifeRing.lastContact, lifeRing.lastActivity)} />}
                    <h1 className="text-2xl font-bold"> {lifeRing.name} </h1>
                </div>
                <hr />
                <div className="font-semibold">
                    <div className="inline-flex w-full">
                        Last Activity: {formatDate(lifeRing.lastActivity)}
                    </div>
                    <div className="inline-flex w-full">
                        Last Contact: {formatDate(lifeRing.lastContact)}
                    </div>
                </div>
            </div >
        );
    }

    function ActivityAlertBox({ lifeRing }: { lifeRing: LifeRingType }) {
        var minutesAgo = Math.floor((new Date().getTime() - new Date(lifeRing.lastActivity).getTime()) / 1000 / 60)
        return (
            <>
                {showAlertBox && (
                    <div className="text-black bg-orange-200 rounded-md p-2 w-full h-min pb-2">
                        <div className="inline-flex w-full justify-between">
                            <div className="inline-flex w-full space-x-4">
                                <BsLifePreserver size={40} color="#FF0000" />
                                <h1 className="text-2xl font-bold"> Activity Alert </h1>
                            </div>
                            <button onClick={() => setShowAlertBox(false)} className="text-2xl font-bold"><IoMdClose size={28} /></button>
                        </div>
                        <h1 className="text-xl font-bold"> {lifeRing.name} </h1>
                        <div className="font-semibold">
                            <div className="inline-flex w-full">
                                Last Activity: {formatDate(lifeRing.lastActivity)} ({minutesAgo} minute{minutesAgo === 1 ? "" : "s"} ago)
                            </div>
                        </div>
                    </div >
                )}
            </>
        );
    }
}

export default LifeRings;

function formatDate(date: string) {
    var d = new Date(date);
    var momentDate = moment(d);
    return momentDate.format("DD/MM/YYYY HH:mm:ss");
}

function getColour(lastContact: string, lastActivity: string) {
    var lastContactDate = new Date(lastContact);
    var lastActivityDate = new Date(lastActivity);
    var now = new Date();
    var timeSinceLastContact = Math.floor((now.getTime() - lastContactDate.getTime()) / 1000 / 60 / 60);
    var timeSinceLastActivity = Math.floor((now.getTime() - lastActivityDate.getTime()) / 1000 / 60 / 60);
    if (timeSinceLastContact > 23) {
        return ("#262626");
    } else {
        if (timeSinceLastActivity > 23) {
            return ("#00FF00");
        } else if (timeSinceLastActivity > 12) {
            return ("#FFA500");
        } else {
            return ("#FF0000");
        }
    }
}
