export type LanguageText = {
    goToDashboard: string;
    goToDevice: string;
    online: string;
    offline: string;
};
export const English: LanguageText = {
    goToDashboard: "Go to dashboard",
    goToDevice: "Go to device",
    online: "Online",
    offline: "Offline"
};
export const French: LanguageText={
    goToDashboard: "Aller au tableau de bord",
    goToDevice: "Aller à l'appareil",
    online: "En ligne",
    offline: "Hors ligne"
};
export const German: LanguageText={
    goToDashboard: "Gehe zu Dashboard",
    goToDevice: "Gehe zu Gerät",
    online: "Online",
    offline: "Offline"
};
export const Dutch: LanguageText={
    goToDashboard: "Ga naar het dashboard",
    goToDevice: "Ga naar het apparaat",
    online: "Online",
    offline: "Offline"
};
export const Spanish: LanguageText={
    goToDashboard: "Ir al salpicadero",
    goToDevice: "Ir al dispositivo",
    online: "En línea",
    offline: "Fuera de línea"
};
export const Italian: LanguageText={
    goToDashboard: "Vai al cruscotto",
    goToDevice: "Vai al dispositivo",
    online: "Online",
    offline: "Offline"
};
export const Romanian: LanguageText={
    goToDashboard: "Du-te la tabloul de bord",
    goToDevice: "Du-te la dispozitiv",
    online: "Online",
    offline: "Offline"
};
export const Portuguese : LanguageText={
    goToDashboard: "Go to dashboard",
    goToDevice: "Ir para o dispositivo",
    online: "Online",
    offline: "Offline"
};