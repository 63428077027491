import React from "react";
interface DotSampleProps {
  isActive: boolean;
}

const DotStatus = ({ isActive }: DotSampleProps) => {
  // console.log("is active", isActive);
  return (
    // Dot status

    // const dotSample = (
    //   <div
    //     className="ml-2 h-2 w-2 rounded-full bg-green-600 animate-pulse shadow-lg"
    //     style={{
    //       boxShadow: "0 0 0 0 #69ffa8",
    //       animation: "pulse 1.5s infinite ease-in-out",
    //     }}
    //   ></div>
    // );
    isActive ? (
      <div
        className="ml-2 h-2 w-2 rounded-full bg-green-600 animate-pulse shadow-lg"
        style={{
          boxShadow: "0 0 0 0 #69ffa8",
          animation: "pulse 1.5s infinite ease-in-out",
        }}
      ></div>
    ) : (
      <div
        className="ml-2 h-2 w-2 rounded-full bg-red-400 shadow-lg"
        style={{
          boxShadow: "0 0 0 0 #69ffa8",
        }}
      ></div>
    )
  );
};

export default DotStatus;
