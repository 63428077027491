import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  ChangeEvent,
} from "react";

import { Configuration } from "../../features/notifications/notificationSlice";
import {
  convertDaysAndHoursToMinutes,
  convertMinutesToDaysAndHours,
} from "../../utils/GroupNotificationUtils";

import useGetLanguage from "../../utils/useGetLanguage";
import { LanguageText } from "../../dictionary/GroupNotificationText";
export interface NotificationConfigProps {
  configuration?: Configuration;
}

export interface NotificationConfigRef {
  getNotificationConfig: () => NotificationConfigProps;
  validate: () => boolean;
}
const NotificationConfiguration = forwardRef(
  (
    {
      configuration = {
        repeat_interval: 0,
        hold_on: 0,
        hold_off: 0,
        assert_on: false,
        latch_on: false,
      },
    }: NotificationConfigProps,
    ref
  ): JSX.Element => {
    const [error, setError] = useState("");
    const [notificationConfig, setNotificationConfig] =
      useState<Configuration>(configuration);

    const language: LanguageText | null = useGetLanguage({
      fileName: "GroupNotificationText",
    });

    useImperativeHandle(ref, () => ({
      getNotificationConfig: () => ({
        configuration: notificationConfig,
      }),
      validate: () => {
        // Handle validation here
        return true;
      },
    }));

    const [isRepeatInterval, setIsRepeatInterval] = useState<boolean>(
      (notificationConfig.repeat_interval > 0 ? true : false) || false
    );

    const [numOfDays, setNumOfDays] = useState<number | string>(
      convertMinutesToDaysAndHours(notificationConfig.repeat_interval).days || ""
    );
    const [numOfHours, setNumOfHours] = useState<number | string>(
      convertMinutesToDaysAndHours(notificationConfig.repeat_interval).hours || ""
    );

    const handleIsRepeatIntervalChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;
      if (checked === false) {
        setNumOfDays("");
        setNumOfHours("");
        setNotificationConfig((prev) => ({
          ...prev,
          repeat_interval: 0,
        }));
      }
      setIsRepeatInterval(checked);
    };

    const handleNumOfDaysChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setNumOfDays(value);
      setNotificationConfig((prev) => ({
        ...prev,
        repeat_interval: convertDaysAndHoursToMinutes(Number(value), Number(numOfHours)),
      }));
    };
    const handleNumOfHoursChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setNumOfHours(value);
      setNotificationConfig((prev) => ({
        ...prev,
        repeat_interval: convertDaysAndHoursToMinutes(Number(numOfDays), Number(value)),
      }));
    };

    const handleLatchOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, type, value, checked } = event.target;
      setNotificationConfig((prev) => ({
        ...prev,
        latch_on: checked,
      }));
    };
    return (
      <div className="mb-6 bg-gray-100 p-5 rounded-lg mt-2">
        <h1 className="text-sm font-semibold text-left text-gray-800 leading-tight mb-2">
          {language?.configurationComponent?.heading || "Configuration"}
        </h1>
        {/* <div className="w-full flex items-center space-y-4 sm:space-y-0 sm:space-x-4 py-2">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              name="isRepeatInterval"
              checked={isRepeatInterval}
              onChange={handleIsRepeatIntervalChange}
              className="form-checkbox h-5 w-5 text-blue-600 focus:ring-blue-500 rounded"
            />
            <span>{language?.configurationComponent?.repeat || "Repeat"}</span>
          </label>
          {isRepeatInterval && (
            <>
              <label className="flex items-center space-x-2">
                <span>{language?.configurationComponent?.in || "In"}</span>
                <input
                  type="number"
                  name="numOfDays"
                  value={numOfDays}
                  onChange={handleNumOfDaysChange}
                  className="border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 w-20 sm:w-25"
                />
                <span>{language?.configurationComponent?.days || "Days"}</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="number"
                  name="numOfHours"
                  value={numOfHours}
                  onChange={handleNumOfHoursChange}
                  className="border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 w-20 sm:w-25"
                />
                <span>{language?.configurationComponent?.hours || "Hours"}</span>
              </label>
            </>
          )}
        </div> */}
        <div className="w-full flex items-center py-2">
          <input
            type="checkbox"
            name="latch_on"
            checked={notificationConfig.latch_on}
            onChange={handleLatchOnChange}
            className="form-checkbox h-5 w-5 text-blue-600 focus:ring-blue-500 rounded mr-2"
          />
          <label className="text-gray-700">
            {/* {language?.configurationComponent?.allowRetriggering || "Allow Retriggering"} */}
            Require Acknowledgment
          </label>
        </div>
      </div>
    );
  }
);

export default NotificationConfiguration;
