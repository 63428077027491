import ReactECharts from "echarts-for-react";
import { ChartType, GaugeData } from "..";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { DataType, selectData } from "../../../../features/data/dataSlice";
import { v4 as uuidv4 } from "uuid";
import _, { get } from "lodash";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, } from "../../../../dictionary/ChartsText";
import { LanguageCheck } from "../../../../utils/LanguageCheck";
import { selectLanguage } from "../../../../features/user/userSlice";
import moment from "moment";

type Props = {
  ChartData: ChartType;
  FetchSuccess: boolean;
  FetchError: boolean;
};

function Gauge({ ChartData, FetchSuccess, FetchError }: Props) {

  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  const [chartData, setChartData] = useState<GaugeData[]>(ChartData.chartData ?? []);
  const [dataValue, setDataValue] = useState<number>();
  const [readingTime, setReadingTime] = useState<string>();

  const chartRef = useRef<ReactECharts>(null);
  const nodeData = useAppSelector(selectData);

  function GetBlankRing() {
    return {
      label: "",
      colour:
        "#" +
        Math.floor(Math.random() * 16777215)
          .toString(16)
          .padStart(6, "0")
          .toUpperCase(),
      phenomena: "new",
      ringID: uuidv4(),
      deviceID: "new",
      unit: "",
      min: 0,
      max: 10,
    }
  }

  function CatchEmptyChartData() {
    if (chartData.length === 0) {
      setChartData(() => [
        ...ChartData.chartData,
        GetBlankRing(),
      ]);
    } else {
      setChartData(ChartData.chartData ?? []);
    }
  }

  function GetDataValueAndReadingTime() {
    if (nodeData) {
      if (chartData !== undefined && chartData.length > 0) {
        if (
          nodeData.data[chartData[0].deviceID as keyof DataType] !== undefined
        ) {
          let latestValue =
            nodeData.data[chartData[0].deviceID as keyof DataType][
            nodeData.data[chartData[0].deviceID as keyof DataType].length - 1
            ];
          if (chartData[0].phenomena !== undefined && chartData[0].elemID !== undefined) {
            if (latestValue.data_points[chartData[0].phenomena] !== undefined) {
              setDataValue(
                latestValue.data_points[chartData[0].phenomena][chartData[0].elemID]
              );
            }
          }
          setReadingTime(
            moment(new Date(latestValue.ts))
              .local()
              .format(
                "[" +
                language.charts.gauge.timeFormat.on +
                "] DD/MM/YYYY [" +
                language.charts.gauge.timeFormat.at +
                "] HH:mm:ss"
              )
          );
        }
      }
    }
  }

  function InitiateGauge() {
    if (chartRef && chartRef.current && nodeData) {
      chartRef.current?.getEchartsInstance().showLoading("default", {
        text: language.charts.loadingText.retrievingData + "...",
        color: "#000099",
        textColor: "#000",
        maskColor: "rgba(255, 255, 255, 0.8)",
        zlevel: 0,
        fontSize: 12,
        showSpinner: true,
        spinnerRadius: 10,
        lineWidth: 5,
        fontWeight: "normal",
        fontStyle: "normal",
        fontFamily: "sans-serif",
      });
      if (chartData !== undefined && chartData.length > 0) {
        if (dataValue !== undefined) {
          chartRef.current?.getEchartsInstance().hideLoading();
        }
        const series = [
          {
            data: [
              {
                value: CalculateValue(
                  dataValue ?? 0,
                  chartData[0].max ?? 20,
                  chartData[0].min ?? 0
                ),
                title: {
                  offsetCenter: ["0", "-25"],
                },
                detail: {
                  valueAnimation: true,
                  offsetCenter: ["0", "0"],
                },
              },
            ],
            detail: {
              width: "auto",
              height: 14,
              fontSize: 14,
              fontWeight: "bold",
              borderRadius: 20,
              borderWidth: 1,
              formatter: dataValue + "",
              color: chartData[0].colour,
              borderColor: chartData[0].colour,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 1,
                borderColor: "#464646",
                color: chartData[0].colour,
              },
            },
            axisLabel: {
              show: true,
              formatter: function (value: number) {
                return CalculateTicks(
                  chartData[0].max ?? 0,
                  chartData[0].min ?? 0,
                  value
                );
              },
              distance: 10,
            },
            type: "gauge",
            startAngle: 225,
            endAngle: -45,
            pointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 15,
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
            },
            axisTick: {
              show: false,
            },
            title: {
              fontSize: 14,
            },
          },
        ];
        chartRef.current?.getEchartsInstance().setOption({
          series: series,
        });
        if (dataValue === undefined) {
          if (FetchSuccess) {
            chartRef.current?.getEchartsInstance().showLoading("default", {
              text:
                chartData[0].phenomena === "new"
                  ? language.charts.loadingText.editThisChart
                  : language.charts.loadingText.noDataAvailable,
              textColor: "#000",
              maskColor: "rgba(255, 255, 255, 0.8)",
              showSpinner: false,
            });
          } else if (FetchError) {
            chartRef.current?.getEchartsInstance().showLoading("default", {
              text: "Error retrieving data",
              textColor: "#000",
              maskColor: "rgba(255, 255, 255, 0.8)",
              showSpinner: false,
            });
          }
        }
      }
    }
  }

  useEffect(() => {
    CatchEmptyChartData();
  }, [ChartData.chartData]);

  useEffect(() => {
    GetDataValueAndReadingTime();
  }, [ChartData, chartData, nodeData]);

  useEffect(() => {
    InitiateGauge();
  }, [nodeData, chartData, dataValue, FetchError]);

  const options = {};
  return (
    <>
      <p className="text-center font-bold">{ChartData.title.en}</p>
      <p className="text-center font-bold">{readingTime}</p>
      <ReactECharts
        style={{ height: "calc(100% - 2rem)", width: "100%" }}
        opts={{ renderer: "svg" }}
        option={options}
        ref={chartRef}
      />
    </>
  );
}

export default Gauge;

function CalculateValue(value: number, max: number, min: number) {
  return ((value - min) / (max - min)) * 100;
}

function CalculateTicks(max: number, min: number, value: number) {
  //console.log(max, min, value, (max + min) / 2);
  max = parseInt(max.toString());
  min = parseInt(min.toString());
  value = parseInt(value.toString());
  switch (value) {
    case 100:
      return max;
    case 0:
      return min;
    case 50:
      return max + min == 0 ? 0 : (max + min) / 2;
    default:
      return;
  }
}
