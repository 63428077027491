import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { LatLng, LatLngTuple } from "leaflet";
import _ from "lodash";
import { useState, useEffect, useRef } from "react";
import { BsLifePreserver, BsDoorClosedFill, BsDoorOpenFill } from "react-icons/bs";
import { Marker, Popup, useMap } from "react-leaflet";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../../app/hooks";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../../../dictionary/DeviceText";
import { selectLanguage, selectUser } from "../../../../features/user/userSlice";
import { useGetDeviceQuery } from "../../../../services/deviceAPI";
import { DataTimeToLocale } from "../../../../utils/DateFormating";
import { LanguageCheck } from "../../../../utils/LanguageCheck";

import moment from "moment";
import L from "leaflet";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { ChartType } from "..";
import { DataState, selectData } from "../../../../features/data/dataSlice";

type LifeRingType = {
    ringID: string;
    name: string;
    lastContact: string;
    lastActivity: string;
    lastState: boolean;
    lat: number;
    lng: number;
};

type Props = {
    ChartData: ChartType;
    FetchSuccess: boolean;
    FetchError: boolean;
};

function Status({ ChartData, FetchSuccess, FetchError }: Props) {
    const { deviceid } = useParams();
    const {
        data: device,
        isSuccess,
        isError,
        error,
        isFetching,
    } = useGetDeviceQuery(deviceid ?? skipToken);
    const user = useAppSelector(selectUser);
    const [editPermission, setEditPermission] = useState(false);
    const [selectedLifeRing, setSelectedLifeRing] = useState("");
    const [deviceCoordinates, setDeviceCoordinates] = useState<LatLng>(
        new LatLng(0, 0)
    );
    const [showAlertBox, setShowAlertBox] = useState(true);

    var nodeData = useAppSelector(selectData);

    const [stateData, setStateData] = useState<any[]>([])

    const stateLang = useAppSelector(selectLanguage);
    let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    useEffect(() => {
        setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    }, [stateLang]);

    const [selectedSort, setSelectedSort] = useState("lastActivity");
    const [sortDirection, setSortDirection] = useState("asc");

    /* const { data: latestData } = useGetLatestDataQuery({ "eui": device?.eui ?? "" }, { pollingInterval: 60000 }); */

    const [lifeRings, setLifeRings] = useState<LifeRingType[]>([
        { ringID: "0080e115005af8ce", name: "0080e115005af8ce", lastContact: getMinutesAgo(1455), lastActivity: getMinutesAgo(1455), lastState: true, lat: 51.509, lng: -0.12 },
        { ringID: "0080e11505046436", name: "0080e11505046436", lastContact: getMinutesAgo(2413), lastActivity: getMinutesAgo(3910), lastState: false, lat: 51.511, lng: -0.11 },
        { ringID: "0080e1150504643f", name: "0080e1150504643f", lastContact: getMinutesAgo(1), lastActivity: getMinutesAgo(1), lastState: true, lat: 51.511, lng: -0.1 },
        /* { ringID: "9", name: "LifeRing4", lastContact: getMinutesAgo(140), lastActivity: getMinutesAgo(140), lat: 51.509, lng: -0.09 },
        { ringID: "Q", name: "LifeRing5", lastContact: getMinutesAgo(24), lastActivity: getMinutesAgo(5893), lat: 51.50822, lng: -0.11 },
        { ringID: "Z", name: "LifeRing6", lastContact: getMinutesAgo(1905), lastActivity: getMinutesAgo(4000), lat: 51.5085, lng: -0.1 },
        { ringID: "J", name: "LifeRing7", lastContact: getMinutesAgo(373), lastActivity: getMinutesAgo(1430), lat: 51.507, lng: -0.09 }, */
    ])

    function populateStateData(dater: DataState) {
        if (ChartData.chartData !== null) {
            setStateData(
                ChartData.chartData.map((ring: { deviceID: string; }) => {
                    return ({
                        [ring.deviceID]: {
                            deviceID: ring.deviceID,
                            lastContact: getMinutesAgo(1455),
                            lastActivity: getMinutesAgo(1455),
                        }
                    })
                })
            )
        }
    }

    useEffect(() => {
        populateStateData(nodeData)
    }, [nodeData])

    console.log(stateData)

   /*  useEffect(() => {
        if (ChartData.chartData !== null) {
            setLifeRings(
                ChartData.chartData.map((ring: { deviceID: string; }) => {
                    return {
                        ringID: ring.deviceID,
                        name: ring.deviceID,
                        lastContact: stateData[ring.deviceID as keyof typeof stateData]?.lastContact ?? "",
                        lastActivity: stateData[ring.deviceID as keyof typeof stateData]?.lastContact ?? "",
                        lastState: false,
                        lat: 51.511,
                        lng: -0.1
                    };
                })
            )
        }
    }, [ChartData]) */

    useEffect(() => {
        if (ChartData.chartData !== null) {
            setLifeRings(
                stateData.map((ring: { deviceID: string; }) => {
                    return {
                        ringID: ring.deviceID,
                        name: ring.deviceID,
                        lastContact: stateData[ring.deviceID as keyof typeof stateData]?.lastContact ?? "",
                        lastActivity: stateData[ring.deviceID as keyof typeof stateData]?.lastContact ?? "",
                        lastState: false,
                        lat: 51.511,
                        lng: -0.1
                    };
                })
            )
        }
    }, [stateData]) 

    const [currentAlert, setCurrentAlert] = useState<LifeRingType>(lifeRings[0]);

    function getMinutesAgo(minutes: number) {
        var time = moment().subtract(minutes, 'minutes').format("YYYY-MM-DDTHH:mm:ss.SSS[+01:00]");
        return time;
    }

    useEffect(() => {
        if (lifeRings) {
            sortLifeRingsByParam(selectedSort);
        }
    }, [selectedSort, sortDirection])

    function sortLifeRingsByParam(param: string) {
        var sortedLifeRings = lifeRings
        if (param == "lastActivity") {
            var offlineRings = _.filter(lifeRings, function (ring) { return moment(ring.lastContact).isBefore(moment().subtract(24, 'hours')) });
            var onlineRings = _.filter(lifeRings, function (ring) { return moment(ring.lastContact).isAfter(moment().subtract(24, 'hours')) });

            sortedLifeRings = _.sortBy(onlineRings, param)
            if (sortDirection === "asc") {
                sortedLifeRings = sortedLifeRings.reverse();
            }
            sortedLifeRings = sortedLifeRings.concat(offlineRings);
        }
        else {
            sortedLifeRings = _.sortBy(lifeRings, param);
            if (sortDirection === "asc") {
                sortedLifeRings = sortedLifeRings.reverse();
            }
        }
        setLifeRings(sortedLifeRings);
    }

    function getIcon(colour: string, state: boolean) {
        return L.divIcon({
            className: "custom-icon-marker",
            html: state ? `<svg stroke=${colour} fill=${colour} stroke-width="0" viewBox="0 0 16 16" height="28" width="28" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V2a1 1 0 0 1 1-1h8zm-2 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></svg>` : `<svg stroke=${colour} fill=${colour} stroke-width="0" viewBox="0 0 16 16" height="28" width="28" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 15a.5.5 0 0 0 0 1h13a.5.5 0 0 0 0-1H13V2.5A1.5 1.5 0 0 0 11.5 1H11V.5a.5.5 0 0 0-.57-.495l-7 1A.5.5 0 0 0 3 1.5V15H1.5zM11 2h.5a.5.5 0 0 1 .5.5V15h-1V2zm-2.5 8c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z"></path></svg>`,
            iconSize: [28, 28],
            iconAnchor: [14, 14],
            popupAnchor: [1, -34],
            shadowSize: [41, 41],
        })
    }

    useEffect(() => {
        if (isSuccess) {
            if (user.groups[device.group_id]) {
                setEditPermission(
                    user.groups[device.group_id].permissions.can_edit_devices
                        ?.can_edit_all ??
                    user.groups[device.group_id].permissions.can_edit_devices
                        ?.can_edit_devices?.[device.id] ??
                    false
                );
            } else {
                setEditPermission(false);
            }
        }
    }, [device, user, isSuccess]);

    const lifeRingList: JSX.Element[] = [];

    if (lifeRings !== undefined) {
        for (let lifeRing in lifeRings) {
            lifeRingList.push(
                <LifeRingBox lifeRing={lifeRings[lifeRing]} />
            )
        }
    }

    useEffect(() => {
        if (lifeRings !== undefined) {
            const filteredLifeRings = lifeRings.filter(lifeRing => lifeRing.lat !== 0 && lifeRing.lng !== 0);
            if (filteredLifeRings.length > 0) {
                setDeviceCoordinates(new LatLng(filteredLifeRings[0].lat, filteredLifeRings[0].lng))
            }
        }
    }, [lifeRings]);


    //console.log(ChartData)

    return (
        <div className="h-full w-full p-4 bg-white rounded-md">
            <div className="h-full w-full flex flex-wrap content-start">
                <div className="h-full w-full flex pb-2 flex-col overflow-clip">
                    <div className="w-full flex justify-between mb-1">
                        <div className="flex space-x-2">
                            <div className="flex items-center">
                                <label className="text-sm font-semibold whitespace-nowrap text-gray-700 mr-2">Sort by:</label>
                                <div className="flex items-center rounded-md border border-gray-300 shadow-sm">
                                    <select
                                        className="block w-full py-2 px-3 border-0 border-r border-gray-300 bg-white rounded-l-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        onChange={(e) => {
                                            setSelectedSort(e.target.value);
                                        }}
                                        value={selectedSort}
                                    >
                                        <option value="name">Name</option>
                                        <option value="lastActivity">Last Activity</option>
                                        <option value="lastContact">Last Contact</option>
                                    </select>
                                    <div onClick={() => setSortDirection(sortDirection === "desc" ? "asc" : "desc")} className="block py-2 px-3 bg-white rounded-r-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                        {sortDirection === "desc" ? <FaSortAmountDown size={20} /> : <FaSortAmountUp size={20} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="overflow-y-scroll space-y-3 scrollbar-none h-full mb-2 rounded-md">
                        {lifeRingList}
                    </div>
                </div>
            </div>
        </div>
    );

    function LifeRingBox({ lifeRing }: { lifeRing: LifeRingType }) {
        return (
            <div className="text-black bg-gray-200 rounded-md p-2" onClick={() => { { setSelectedLifeRing(lifeRing.ringID); setShowAlertBox(true); setCurrentAlert(lifeRing) } }}>
                <div className="inline-flex w-full space-x-4">
                    {lifeRing.lastState ? <BsDoorClosedFill size={40} color={getColour(lifeRing.lastContact, lifeRing.lastActivity)} /> : <BsDoorOpenFill size={40} color={getColour(lifeRing.lastContact, lifeRing.lastActivity)} />}
                    <h1 className="text-2xl font-bold"> {lifeRing.name} </h1>
                </div>
                <hr />
                <div className="font-semibold">
                    <div className="inline-flex w-full">
                        Last Activity: {formatDate(lifeRing.lastActivity)}
                    </div>
                    <div className="inline-flex w-full">
                        Last Contact: {formatDate(lifeRing.lastContact)}
                    </div>
                </div>
            </div >
        );
    }
}

export default Status;

function formatDate(date: string) {
    var d = new Date(date);
    var momentDate = moment(d);
    return momentDate.format("DD/MM/YYYY HH:mm:ss");
}

function getColour(lastContact: string, lastActivity: string) {
    var lastContactDate = new Date(lastContact);
    var lastActivityDate = new Date(lastActivity);
    var now = new Date();
    var timeSinceLastContact = Math.floor((now.getTime() - lastContactDate.getTime()) / 1000 / 60 / 60);
    var timeSinceLastActivity = Math.floor((now.getTime() - lastActivityDate.getTime()) / 1000 / 60 / 60);
    if (timeSinceLastContact > 23) {
        return ("#262626");
    } else {
        if (timeSinceLastActivity > 23) {
            return ("#00FF00");
        } else if (timeSinceLastActivity > 12) {
            return ("#FFA500");
        } else {
            return ("#FF0000");
        }
    }
}
