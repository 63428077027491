import ReactECharts from "echarts-for-react";
import { useAppSelector } from "../../../app/hooks";
import { ReactElement, useEffect, useRef, useState } from "react";
import { ChartType, LineChartData } from "./index";
import _ from "lodash";
import { DataState, DataType, selectData } from "../../../features/data/dataSlice";
import { UplinkData } from "../../../services/dataAPI";
import useWindowDimensions, {
  mdBreakPoint,
} from "../../../utils/ScreenDimensions";
import { BsFillLockFill, BsFillUnlockFill } from "react-icons/bs";
import { VscSettings } from "react-icons/vsc";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../../dictionary/ChartsText";
import { selectLanguage } from "../../../features/user/userSlice";
import { LanguageCheck } from "../../../utils/LanguageCheck";
import { Download } from "../Download";
import { TabList, Tab, Tabs, TabPanel } from "react-tabs";
import { Scale } from "../Scale";

type Props = {
  ChartData: ChartType;
  FetchSuccess: boolean;
  FetchError: boolean;
  Type: "line" | "bar" | "scatter";
  HideLock?: boolean;
  HideSettings?: boolean;
  propNodeData?: DataState;
  updateChart?: (data: ChartType) => void;
};

function Graph({ ChartData, FetchSuccess, FetchError, Type, HideLock, HideSettings, propNodeData, updateChart }: Props) {

  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  const [dataAvailable, setDataAvailable] = useState(false);
  const [lines, setLines] = useState<LineChartData[]>(ChartData.chartData ?? []);
  const [imgLink, setImgLink] = useState<string | undefined>("");
  const [zoomLock, setZoomLock] = useState(true);
  const [modal, setModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [startDate, setStartDate] = useState<string>(new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toISOString().split(".")[0]);
  const [endDate, setEndDate] = useState<string>(new Date(new Date().getTime()).toISOString().split(".")[0]);
  const [firstPointTs, setFirstPointTs] = useState<number>(0);
  const [lastPointTs, setLastPointTs] = useState<number>(0);
  const [scaleOptions, setScaleOptions] = useState<any>([]);
  const [seed, setSeed] = useState<number>(0);
  const [initalSet, setInitialSet] = useState<boolean>(false);


  var scalePanel: ReactElement = <></>;
  var downloadPanel: ReactElement = <></>;

  const { width } = useWindowDimensions();
  const chartRef = useRef<ReactECharts>(null);
  var nodeData = useAppSelector(selectData);

  const timeSeriesData = (
    phenomena: string | undefined,
    elemID: string | undefined,
    deviceid: string | undefined
  ) => {
    if (nodeData.data[deviceid as keyof DataType] !== undefined) {
      setDataAvailable(true);
      var arr = _.values(nodeData.data[deviceid as keyof DataType]);
      var data = arr.map((element: UplinkData, index: number) => {
        if (typeof element.ts === "string" && phenomena !== undefined) {
          const date = new Date(element.ts);
          var newData: (number | Date)[] = [];
          if (phenomena !== undefined && elemID !== undefined) {
            if (
              element?.data_points?.[phenomena]?.[elemID] !== undefined &&
              element?.data_points?.[phenomena]?.[elemID] !== null
            ) {
              if (!isNaN(Number(element.data_points[phenomena][elemID]))) {
                newData = [date, _.round(Number(element.data_points[phenomena][elemID]), 1)];
              }
            }
          }
          return newData;
        }
      });
      setFirstPointTs(data[0] !== undefined ? Number(data[0][0]) : 0);
      setLastPointTs(data[data.length - 1] !== undefined ? Number(data[data.length - 1]![0]) : 0);
      return data.filter((n) => n?.length ?? 0 > 0);
    }
  };

  function PropNodeDataOverride() {
    if (propNodeData !== undefined) {
      nodeData = propNodeData;
    }
  }

  function LockZoomFromHideLockParam() {
    if (HideLock) {
      setZoomLock(false);
    }
  }

  function GetLinesAndRetrievingDataLoading() {
    setLines(ChartData.chartData ?? []);
    if (chartRef && chartRef.current) {
      chartRef.current?.getEchartsInstance().showLoading("default", {
        text: (lines[0] ? lines[0].deviceID !== "new" : false)
          ? language.charts.loadingText.retrievingData + "..."
          : language.charts.loadingText.editThisChart,
        color: "#000099",
        textColor: "#000",
        maskColor: "rgba(255, 255, 255, 0.8)",
        showSpinner: (lines[0] ? lines[0].deviceID !== "new" : false)
          ? true
          : false,
      });
    }
  }

  function GetScaleOptionsFromChart() {
    if (chartRef && chartRef.current) {
      setScaleOptions(chartRef.current?.getEchartsInstance().getOption().dataZoom);
    }
  }

  function SetDataZoomAndDataAvailableLoading() {
    if (chartRef && chartRef.current && nodeData) {
      if (!dataAvailable) {
        chartRef.current?.getEchartsInstance().showLoading("default", {
          text: (lines[0] ? lines[0].deviceID !== "new" : false)
            ? language.charts.loadingText.retrievingData + "..."
            : language.charts.loadingText.editThisChart,
          color: "#000099",
          textColor: "#000",
          maskColor: "rgba(255, 255, 255, 0.8)",
          showSpinner: (lines[0] ? lines[0].deviceID !== "new" : false)
            ? true
            : false,
        });
      }
      chartRef.current?.getEchartsInstance().setOption({
        dataZoom:
          width < mdBreakPoint
            ? [
              {
                brushSelect: !zoomLock,
                zoomLock: zoomLock,
                disabled: zoomLock,
                type: "inside",
                startValue: startDate,
                endValue: endDate,
                top: "82%",
                handleSize: "170%",
                moveHandleSize: 16,
                handleIcon:
                  "M 0 1.628 L 0 2 M 0 0 L 0 0.375 M -0.2 0.375 L 0.2 0.375 A 0.2 0.1 0 0 1 0.4 0.475 L 0.4 1.525 A 0.2 0.1 0 0 1 0.2 1.625 L -0.2 1.625 A 0.2 0.1 0 0 1 -0.4 1.525 L -0.4 0.475 A 0.2 0.1 0 0 1 -0.2 0.375 Z",
              },
              {
                brushSelect: !zoomLock,
                zoomLock: zoomLock,
                disabled: zoomLock,
                startValue: startDate,
                endValue: endDate,
                top: "82%",
                handleSize: "170%",
                moveHandleSize: 16,
                handleIcon:
                  "M 0 1.628 L 0 2 M 0 0 L 0 0.375 M -0.2 0.375 L 0.2 0.375 A 0.2 0.1 0 0 1 0.4 0.475 L 0.4 1.525 A 0.2 0.1 0 0 1 0.2 1.625 L -0.2 1.625 A 0.2 0.1 0 0 1 -0.4 1.525 L -0.4 0.475 A 0.2 0.1 0 0 1 -0.2 0.375 Z",
              },
            ]
            : [
              {
                brushSelect: !zoomLock,
                zoomLock: zoomLock,
                disabled: zoomLock,
                type: "inside",
                startValue: startDate,
                endValue: endDate,
              },
              {
                brushSelect: !zoomLock,
                zoomLock: zoomLock,
                disabled: zoomLock,
                startValue: startDate,
                endValue: endDate,
              },
            ],
      });
    }
  }

  function SetSeriesAndFetchErrorLoading() {
    if (chartRef && chartRef.current && nodeData) {
      if (lines.length > 0) {
        const series = lines.map((line) => {
          if (line !== undefined) {
            return {
              name: line.label,
              type: Type,
              symbolSize: (Type === "scatter" ? 2 : undefined),
              symbol: (Type === "scatter" ? undefined : "none"),
              sampling: "lttb",
              smooth: line.smoothed ?? false,
              connectNulls: line.connectedNulls ?? true,
              lineStyle: {
                color: line.colour,
                type: line.style,
              },
              itemStyle: {
                color: line.colour,
              },
              data: timeSeriesData(line.phenomena, line.elemID, line.deviceID),
            };
          }
        });
        chartRef.current?.getEchartsInstance().setOption({
          ...chartRef.current?.getEchartsInstance().getOption(), series: series,
        }, true);
        if (nodeData.data[lines[0].deviceID as keyof DataType] !== undefined) {
          chartRef.current?.getEchartsInstance().hideLoading();
        } else {
          if (FetchError && lines[0].deviceID !== "new") {
            chartRef.current?.getEchartsInstance().showLoading("default", {
              text: "Error retrieving data",
              textColor: "#000",
              maskColor: "rgba(255, 255, 255, 0.8)",
              showSpinner: false,
            });
          }
        }
      }
    }
  }

  useEffect(() => {
    PropNodeDataOverride();
  }, [propNodeData]);

  useEffect(() => {
    LockZoomFromHideLockParam();
  }, [HideLock]);

  useEffect(() => {
    GetLinesAndRetrievingDataLoading();
  }, [ChartData.chartData]);

  useEffect(() => {
    GetScaleOptionsFromChart();
  }, [ChartData]);

  useEffect(() => {
    SetDataZoomAndDataAvailableLoading();
  }, [seed, startDate, endDate]);

  useEffect(() => {
    SetSeriesAndFetchErrorLoading();
  }, [nodeData, lines, FetchSuccess, FetchError]);

  const chart_scale =
    ChartData.chartScale === undefined ||
      (ChartData.chartScale.y_axis.min_auto === false &&
        ChartData.chartScale.y_axis.max_auto === false &&
        ChartData.chartScale.y_axis.max === 0 &&
        ChartData.chartScale.y_axis.max === 0)
      ? { y_axis: { min_auto: true, max_auto: true, max: 10, min: 0 } }
      : ChartData.chartScale;
  ;

  const options = {
    title: {
      text: ChartData.title.en,
    },
    legend: { type: "scroll", top: 30 },
    grid:
      width < mdBreakPoint
        ? {
          left: "3%",
          right: "4%",
          bottom: "20%",
          containLabel: true,
        }
        : {
          left: "3%",
          right: "4%",
          bottom: 50,
          containLabel: true,
        },
    tooltip: {
      trigger: "axis",
    },
    animation: false,
    toolbox: {
      top: 30,
      feature: {
        //restore: {},
        //saveAsImage: {},
      },
    },
    xAxis: {
      type: "time",
      boundaryGap: false,
      alignTicks: "value",
      minorTick: { show: true },
      splitLine: { show: true },
    },
    yAxis: [
      {
        type: "value",
        axisLabel: {
          formatter: "{value}",
        },
        scale: chart_scale.y_axis.min_auto || chart_scale.y_axis.max_auto,
        min: chart_scale.y_axis.min_auto ? undefined : chart_scale.y_axis.min,
        max: chart_scale.y_axis.max_auto ? undefined : chart_scale.y_axis.max,
      },
    ],
    dataZoom: [],
  };

  let tempModal1 = modal;
  let tempModal2 = modal;
  var tempStartDate = "";
  var tempEndDate = "";
  var timeOffset = new Date().getTimezoneOffset() * 60 * 1000;

  [scalePanel, tempModal1, tempStartDate, tempEndDate] = Scale((firstPointTs - timeOffset), (lastPointTs - timeOffset), scaleOptions, modal, SetStartAndEndDateFromModal);

  [downloadPanel, tempModal2] = Download(nodeData, ChartData, modal, imgLink);

  function CloseModalOnButtonPress() {
    if (tempModal1 === false || tempModal2 === false) {
      setModal(false)
    } else {
      setModal(true)
    }
  }

  function SetStartAndEndDateFromModal({ start, end }: { start: string, end: string }) {
    if (start !== "" && end !== "") {
      setStartDate(start)
      setEndDate(end)
    }
  }

  useEffect(() => {
    CloseModalOnButtonPress()
  }, [tempModal1, tempModal2]);

  function getInitalStartAndEndDate() {
    if (initalSet === false) {
      if (firstPointTs && lastPointTs && ChartData.chartScale?.x_axis?.min && ChartData.chartScale?.x_axis?.max) {
        var timespanms = lastPointTs - firstPointTs;
        var firstpointoffset = timespanms * ChartData.chartScale?.x_axis?.min * 0.01;
        var lastpointoffset = timespanms * ChartData.chartScale?.x_axis?.max * 0.01;
        var start = firstpointoffset + firstPointTs;
        var end = lastpointoffset + firstPointTs;
        setStartDate(new Date(start).toISOString().split(".")[0])
        setEndDate(new Date(end).toISOString().split(".")[0])
        setInitialSet(true);
      }
    }
  }

  useEffect(() => {
    getInitalStartAndEndDate();
  }, [firstPointTs, lastPointTs, ChartData.chartScale?.x_axis?.min, ChartData.chartScale?.x_axis?.max, initalSet]);

  function SettingsButtonPressed() {
    setImgLink(chartRef.current?.getEchartsInstance().getDataURL());
    setScaleOptions(chartRef.current?.getEchartsInstance().getOption().dataZoom);
    setModal(true);
  }

  function LockButtonPressed() {
    setStartDate((chartRef.current?.getEchartsInstance().getOption() as any).dataZoom[0].startValue);
    setEndDate((chartRef.current?.getEchartsInstance().getOption() as any).dataZoom[0].endValue);
    if (zoomLock) {
      console.log(chartRef.current?.getEchartsInstance().getOption())
      var tempstart = Math.round((chartRef.current?.getEchartsInstance().getOption() as any).dataZoom[0].start)
      var tempend = Math.round((chartRef.current?.getEchartsInstance().getOption() as any).dataZoom[0].end);
      if (tempstart && tempend && updateChart) {
        var newChartData = _.cloneDeep(ChartData);
        newChartData.chartScale = {
          ...newChartData.chartScale,
          y_axis: {
            min_auto: newChartData.chartScale?.y_axis.min_auto ?? false,
            max_auto: newChartData.chartScale?.y_axis.max_auto ?? false,
          },
          x_axis: {
            min: tempstart,
            max: tempend,
            min_auto: newChartData.chartScale?.x_axis?.min_auto ?? false,
            max_auto: newChartData.chartScale?.x_axis?.max_auto ?? false,
          },
        };
        updateChart(newChartData);
      }
    }
    setSeed(Math.random())
    setZoomLock(!zoomLock);
  }

  return (
    <>
      {modal && (
        <div
          className="relative z-20"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          <div className="fixed z-10 inset-0 overflow-y-auto mt-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center">
              <Tabs
                forceRenderTabPanel
                selectedIndex={tabIndex}
                onSelect={(index) => setTabIndex(index)}
                className="w-full text-black bg-white rounded-lg justify-self-center"
              >
                <TabList className="bg-white rounded border w-full block overflow-x-scroll whitespace-nowrap overflow-y-hidden scrollbar-thin">
                  <Tab >
                    {language.charts.tabs.scale}
                  </Tab>
                  <Tab >
                    {language.charts.tabs.download}
                  </Tab>
                </TabList>
                <TabPanel>
                  {scalePanel}
                </TabPanel >
                <TabPanel>
                  {downloadPanel}
                </TabPanel>
              </Tabs >
            </div>
          </div>
        </div>
      )}
      {!HideSettings && (
        <button
          className="top-[2.5rem] right-[2rem] rounded absolute w-[1rem] h-8 z-[700]"
          disabled={!dataAvailable || modal}
          onClick={SettingsButtonPressed}
        >
          <VscSettings size={20} opacity={modal ? 0 : dataAvailable ? 1 : 0.2} />
        </button>
      )}
      {!HideLock && (
        <button
          className="bottom-[0.6rem] left-[0.4rem] rounded absolute w-[1rem] h-8 z-[700]"
          disabled={!dataAvailable || modal}
          onClick={LockButtonPressed}
        >
          {zoomLock ? (
            <BsFillLockFill size={16} opacity={modal ? 0 : dataAvailable ? 1 : 0.2} />
          ) : (
            <BsFillUnlockFill size={16} opacity={modal ? 0 : dataAvailable ? 1 : 0.2} />
          )}{" "}
        </button>
      )}
      <ReactECharts
        style={{ height: "100%", width: "100%" }}
        opts={{ renderer: "svg" }}
        option={options}
        ref={chartRef}
      />
    </>
  );
}

export default Graph;
