import { ReactNode } from "react";
import Spinner from "../Spinner";
import { Button } from "../Button";

type Props = {
  onRemove: (...args: any) => any;
  onAccept: (...args: any) => any;
  onCancel: (...args: any) => any;
  Title: string;
  Body: ReactNode | string;
  AcceptButton: string;
  CancelButton: string;
  RemoveButton?: string;
  loading?: boolean;
};

function GroupObjectModal({
  onAccept,
  onCancel,
  onRemove,
  Title,
  Body,
  AcceptButton,
  CancelButton,
  RemoveButton,
  loading = false,
}: Props) {
  return (
    <div
      className="relative z-20"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-title"
                  >
                    {Title}
                  </h3>
                  <div className="mt-2">
                    <div className="text-sm text-gray-500">{Body}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 inline-flex px-4 py-3 sm:px-6 sm:flex sm:flex-row">
              {RemoveButton === undefined || RemoveButton === "-1" ? (
                <button onClick={() => undefined}></button>
              ) : (
                <div className="sm:w-auto mr-auto">
                  <Button
                    label={RemoveButton}
                    colour="gray"
                    onClick={() =>
                      onRemove()
                    }
                  />
                </div>
              )}
              <div className="sm:w-auto">
                <Button
                  label={loading ? <Spinner colour="fill-green-600" /> : AcceptButton}
                  colour="gray"
                  onClick={() =>
                    onAccept()
                  }
                />
              </div>
              <div className="sm:w-auto ml-2">
                <Button
                  label={CancelButton}
                  colour="white"
                  onClick={() =>
                    onCancel()
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupObjectModal;
