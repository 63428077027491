import { ReactNode } from "react";
import { IoMdClose } from "react-icons/io";
import Spinner from "../Spinner";
import { Button } from "../Button";

type Props = {
  onAccept: (...args: any) => any;
  onCancel?: (...args: any) => any;
  showCancel?: boolean;
  Title: string;
  Body: ReactNode | string;
  AcceptButton: string;
  AcceptButtonColour?: "red" | "green" | "blue" | "yellow" | "white" | "gray" | "lightgray";
  CancelButton: string;
  loading?: boolean;
  closeButton?: boolean;
  onClose?: (...args: any) => any;
  disableAccept?: boolean;
};

function AcceptModal({
  onAccept,
  onCancel,
  showCancel = true,
  Title,
  Body,
  AcceptButton,
  AcceptButtonColour = "green",
  CancelButton,
  loading = false,
  closeButton = false,
  onClose,
  disableAccept = false,
}: Props) {
  return (
    <div
      className="relative z-20"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                {closeButton && (
                  <>
                    <button
                      className="flex text-4xl text-black items-center cursor-pointer right-2 top-2 fixed z-[1001]"
                      onClick={() => (onClose ? onClose() : null)}
                    >
                      <IoMdClose size={28} />
                    </button>
                  </>
                )}
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-title"
                  >
                    {Title}
                  </h3>
                  {!loading ? (
                    <div className="mt-2">
                      <div className="text-sm text-gray-500">{Body}</div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {!loading ? (
              <>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <div className="sm:w-auto">
                    <Button
                      onClick={() => onAccept()}
                      disabled={disableAccept}
                      label={AcceptButton}
                      colour={AcceptButtonColour}
                    />
                  </div>
                  {showCancel ? (
                    <div className="sm:w-auto mr-2">
                      <Button
                        onClick={() => (onCancel ? onCancel() : null)}
                        label={CancelButton}
                        colour="white"
                      />
                    </div>
                  ) : null}
                </div>
              </>
            ) : (
              <div className="w-full flex justify-center h-10">
                <Spinner colour={"fill-green-800"} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcceptModal;
