import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "../../app/hooks";
import { doLogout } from "../../app/store";
import { FormInput } from "../../components/FormInput";
import {
  selectLanguage,
  selectUser,
} from "../../features/user/userSlice";
import {
  useChangePasswordMutation,
} from "../../services/authAPI";
import {
  useDeleteUserMutation,
  useUpdateUserMutation,
  useUpdateUserSettingsMutation,
} from "../../services/userAPI";
import { handleError } from "../../utils/ErrorHandling";
import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
} from "../../dictionary/UserText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { validateEmail, validateUsername } from "../../utils/FormValidation";
import InputModal from "../../components/modals/InputModal";
import { Button } from "../../components/Button";

type FormErrors = {
  userDetailsFormError?: string | undefined;
  emailError?: string | undefined;
  phoneNumberError?: string | undefined;
  usernameError?: string | undefined;
  changePasswordFormError?: string | undefined;
  oldPasswordError?: string | undefined;
  newPasswordError?: string | undefined;
  confirmNewPasswordError?: string | undefined;
};

function Settings() {
  const { username, email, phone_number, id } = useAppSelector(selectUser);
  let [formErrors, setFormErrors] = useState<FormErrors>({});

  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  let languages = [
    { optionName: "English", optionValue: "english" },
    { optionName: "Français", optionValue: "french" },
    { optionName: "Deutsch", optionValue: "german" },
    { optionName: "Nederlands", optionValue: "dutch" },
    { optionName: "Español", optionValue: "spanish" },
    { optionName: "Italiano", optionValue: "italian" },
    { optionName: "Română", optionValue: "romanian" },
    { optionName: "Português", optionValue: "portuguese" },
  ];

  const [
    updateUserSettings,
    {
      error: updateUserSettingsError,
      isError: isUpdateUserSettingsError,
      isSuccess: isUpdateUserSettingsSuccess,
    },
  ] = useUpdateUserSettingsMutation();

  const [changeDetailsModal, setChangeDetailsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  var [changeDetailsModalProps, setChangeDetailsModalProps] = useState({
    username: username,
    email: email,
    phone_number: phone_number,
    id: id,
  });

  const [
    changePassword,
    {
      data: changePasswordData,
      error: changePasswordError,
      isError: isChangePasswordError,
      isSuccess: isChangePasswordSuccess,
    },
  ] = useChangePasswordMutation();

  const [
    deleteUser,
    {
      isSuccess: isDeleteUserSuccess,
      isLoading: isDeleteUserLoading,
      isError: isDeleteUserError,
      error: deleteUserError,
    },
  ] = useDeleteUserMutation();

  const [
    updateUserDetails,
    {
      data: updateUserDetailsData,
      error: updateUserDetailsError,
      isError: isUpdateUserDetailsError,
      isSuccess: isUpdateUserDetailsSuccess,
      isLoading: isUpdateUserDetailsLoading,
    },
  ] = useUpdateUserMutation();

  useEffect(() => {
    if (isDeleteUserSuccess) {
      setShowDeleteModal(false);
      toast.success(language.settings.toasts.deleteUser);
      doLogout();
    }
  }, [isDeleteUserSuccess, language.settings.toasts.deleteUser]);

  useEffect(() => {
    if (isUpdateUserSettingsSuccess) {
      toast.success(language.settings.toasts.updateUserSettings);
    }
  }, [isUpdateUserSettingsSuccess]);

  useEffect(() => {
    if (isUpdateUserDetailsSuccess) {
      toast.success(language.settings.toasts.updateUser);
      toast.info(language.settings.toasts.updateUserCheckEmail, {
        autoClose: 6000,
      });
      setChangeDetailsModal(false);
    }
  }, [isUpdateUserDetailsSuccess, language.settings.toasts.updateUser, language.settings.toasts.updateUserCheckEmail]);

  useEffect(() => {
    if (isChangePasswordSuccess) {
      toast.success(language.settings.toasts.changePassword);
    }
  }, [isChangePasswordSuccess, language.settings.toasts.changePassword]);

  useEffect(() => {
    if (isUpdateUserSettingsError) {
      handleError(updateUserSettingsError);
    }
  }, [isUpdateUserSettingsError, updateUserSettingsError]);

  useEffect(() => {
    if (isDeleteUserError) {
      setShowDeleteModal(false);
      handleError(deleteUserError);
    }
  }, [isDeleteUserError, deleteUserError]);

  useEffect(() => {
    if (isChangePasswordError) {
      const error = handleError(changePasswordError);
      switch (error) {
        case "Password incorrect":
          setFormErrors({ ...formErrors, oldPasswordError: error });
          break;
        default:
          setFormErrors({ ...formErrors, changePasswordFormError: error });
      }
    }
  }, [isChangePasswordError, changePasswordError]);

  useEffect(() => {
    if (isUpdateUserDetailsError) {
      const error = handleError(updateUserDetailsError);
      toast.error(error);
      switch (error) {
        case "Email already exists":
          setFormErrors({ ...formErrors, emailError: error });
          break;
        case "Username already exists":
          setFormErrors({ ...formErrors, usernameError: error });
          break;
        case "Email and Username already exists":
          setFormErrors({ ...formErrors, userDetailsFormError: error });
          break;
        default:
          setFormErrors({ ...formErrors, userDetailsFormError: error });
      }
      setChangeDetailsModal(false);
    }
  }, [isUpdateUserDetailsError, updateUserDetailsError, formErrors]);

  async function UsernameOrEmailChange(
    event: React.FormEvent<HTMLFormElement>
  ) {
    event.preventDefault();
    var { username, email, phoneNumber } = document.forms[0];
    if (validateUsername(username.value) !== undefined) {
      setFormErrors({
        ...formErrors,
        usernameError: validateUsername(username.value),
      });
      return;
    }
    if (validateEmail(email.value) !== undefined) {
      setFormErrors({ ...formErrors, emailError: validateEmail(email.value) });
      return;
    }
    setChangeDetailsModalProps({
      username: username.value,
      email: email.value,
      phone_number: phone_number,
      id: id,
    });
    setChangeDetailsModal(true);
  }

  async function ChangeContact(event: React.FormEvent<HTMLFormElement>) {
    var { modalPassword } = document.forms[0];
    event.preventDefault();
    updateUserDetails({
      username: changeDetailsModalProps.username ?? "",
      email: changeDetailsModalProps.email ?? "",
      phone_number: changeDetailsModalProps.phone_number?.toString() ?? "",
      id: changeDetailsModalProps.id ?? "",
      password: modalPassword.value ?? "",
    });
  }

  async function ChangeLanguage(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    var { languageselect } = document.forms[1];
    console.log(languageselect.value)
    localStorage.setItem("language", languageselect.value);
    updateUserSettings({ language: languageselect.value });
  }

  async function ChangePassword(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    var { oldPassword, newPassword, confirmNewPassword } = document.forms[2];
    if (newPassword.value !== confirmNewPassword.value) {
      setFormErrors({
        ...formErrors,
        newPasswordError: "New passwords do not match",
      });
      return;
    }
    changePassword({
      newPassword: newPassword.value,
      oldPassword: oldPassword.value,
    });
  }

  async function UserDelete(event: React.FormEvent<HTMLFormElement>) {
    var { modalPassword } = document.forms[0];
    event.preventDefault();
    console.log(modalPassword.value);
    deleteUser({ id: id ?? "", password: modalPassword.value ?? "" });
  }

  function clearFormErrors() {
    setFormErrors({});
  }

  const Modals = (
    <>
      {showDeleteModal && (
        <InputModal
          onSubmit={UserDelete}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          Title={language.settings.modals.deleteModal.title}
          Loading={isDeleteUserLoading}
          Body={
            <>
              <b>{username}</b>
              {language.settings.modals.deleteModal.body.phrase1}
            </>
          }
          Form={[
            {
              htmlFor: "modalPassword",
              label: language.settings.modals.deleteModal.labels.password,
              type: "password",
              value: "",
              error: "",
              autoComplete: "current-password",
              onChange: () => {
                clearFormErrors();
              },
            },
          ]}
          AcceptButton={language.settings.modals.deleteModal.buttons.accept}
          CancelButton={language.settings.modals.deleteModal.buttons.cancel}
        />
      )}
      {changeDetailsModal && (
        <InputModal
          onSubmit={ChangeContact}
          onCancel={() => {
            setChangeDetailsModal(false);
          }}
          Title={language.settings.modals.changeDetailsModal.title}
          Loading={isUpdateUserDetailsLoading}
          Body={
            language.settings.modals.changeDetailsModal.body.passwordRequired
          }
          Form={[
            {
              htmlFor: "modalPassword",
              label:
                language.settings.modals.changeDetailsModal.labels.password,
              type: "password",
              value: "",
              error: "",
              autoComplete: "current-password",
              onChange: () => {
                clearFormErrors();
              },
            },
          ]}
          AcceptButton={
            language.settings.modals.changeDetailsModal.buttons.accept
          }
          CancelButton={
            language.settings.modals.changeDetailsModal.buttons.cancel
          }
        />
      )}
    </>
  );

  const UsernameEmailSection = (
    <>
      <form onSubmit={UsernameOrEmailChange}>
        <h3>{language.settings.changeYourDetails}</h3>
        <FormInput
          htmlFor="username"
          label={language.settings.labels.username}
          value={username}
          error={formErrors.usernameError}
          autoComplete="username"
          onChange={() => {
            clearFormErrors();
          }}
        />
        <FormInput
          htmlFor="email"
          label={language.settings.labels.emailAddress}
          value={email}
          type="email"
          error={formErrors.emailError}
          autoComplete="email"
          onChange={() => {
            clearFormErrors();
          }}
        />
        {/* <FormInput
              htmlFor="phoneNumber"
              label="Mobile Number"
              value={phoneNumber}
              type="tel"
              error={formErrors.phoneNumberError}
              autoComplete="tel"
              disabled={true}
            /> */}
        <div className="text-xs font-semibold text-center tracking-wide text-red-500 w-full">
          {formErrors.userDetailsFormError}
        </div>
        <div className="flex flex-row-reverse justify-between sm:w-auto pt-2">
          <div className="w-fit">
            <Button
              label={language.settings.buttons.save}
              type="submit"
              htmlFor="submitusernameemail"
              colour="green"
              lock={true}
            />
          </div>
        </div>
      </form>
    </>
  );

  const ChangeLanguageField = (
    <>
      <FormInput
        label={language.settings.labels.language}
        htmlFor="languageselect"
        type="select"
        data={languages}
        value={stateLang.toLowerCase() ?? "english"}
      />
    </>
  )

  const ChangeTimeSettingField = (
    <>
      <FormInput
        label={"Time Format"}
        htmlFor="timeselect"
        type="select"
        data={[
          { optionName: "Local", optionValue: "local" },
          { optionName: "UTC", optionValue: "utc" },
        ]}
        value={"local"}
      />
    </>
  )

  const LanguageSection = (
    <>
      <form onSubmit={ChangeLanguage}>
        {"Language and Time"}
        {ChangeLanguageField}
        {ChangeTimeSettingField}
        <div className="flex flex-row-reverse justify-between sm:w-auto pt-2">
          <div className="w-fit">
            <Button
              label={language.settings.buttons.save}
              type="submit"
              htmlFor="submitlanguage"
              colour="green"
            />
          </div>
        </div>
      </form>
    </>
  );

  const ChangePasswordSection = (
    <>
      <form onSubmit={ChangePassword}>
        <h3>{language.settings.changeYourPassword}</h3>
        <FormInput
          htmlFor="oldPassword"
          label={language.settings.labels.oldPassword}
          type="password"
          value=""
          error={formErrors.oldPasswordError}
          autoComplete="current-password"
          onChange={() => {
            clearFormErrors();
          }}
        />
        <FormInput
          htmlFor="newPassword"
          label={language.settings.labels.newPassword}
          type="password"
          minLength={5}
          value=""
          error={formErrors.newPasswordError}
          autoComplete="new-password"
          onChange={() => {
            clearFormErrors();
          }}
        />
        <FormInput
          htmlFor="confirmNewPassword"
          label={language.settings.labels.confirmNewPassword}
          value=""
          type="password"
          minLength={5}
          error={formErrors.confirmNewPasswordError}
          autoComplete="new-password"
          onChange={() => {
            clearFormErrors();
          }}
        />
        <div className="text-xs font-semibold text-center tracking-wide text-red-500 w-full">
          {formErrors.changePasswordFormError}
        </div>
        <div className="flex flex-row-reverse justify-between sm:w-auto pt-2">
          <div className="w-fit">
            <Button
              label={language.settings.buttons.change}
              type="submit"
              htmlFor="submitpassword"
              colour="green"
            />
          </div>
        </div>
      </form>
    </>
  );

  const Buttons = (
    <>
      <div className="flex flex-row-reverse justify-between sm:w-auto pt-2">
        <div className="w-fit">
          <Button
            onClick={() => {
              setShowDeleteModal(true);
            }}
            label={language.settings.buttons.deleteUser}
            type="submit"
            colour="red"
            lock={true}
          />
        </div>
      </div>
    </>
  );

  return (
    <>
      {Modals}
      <div className="h-full w-full text-black bg-white rounded-lg p-4 overflow-y-scroll scrollbar-thin space-y-4 justify-between flex flex-col">
        <div className="flex flex-col">
          {UsernameEmailSection}
          <div className="w-full h-[2px] bg-gray-500 my-4" />
          {LanguageSection}
          <div className="w-full h-[2px] bg-gray-500 my-4" />
          {ChangePasswordSection}
        </div>
        {Buttons}
      </div>
    </>
  );
}

export default Settings;
