import { ReactNode, useEffect, useState } from "react";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../dictionary/InfoHelpText";
import { LanguageCheck } from "../utils/LanguageCheck";
import { selectLanguage } from "../features/user/userSlice";
import { useAppSelector } from "../app/hooks";
import { Button } from "../components/Button";
import { MdEmail, MdPhone } from "react-icons/md";
import { FiFacebook, FiInstagram, FiLinkedin, FiRss, FiTwitter, FiYoutube } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import Spinner from "../components/Spinner";

function Help() {

  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  const [showTsAndCsModal, setShowTsAndCsModal] = useState(false);

  const Modals = (
    <>
      {showTsAndCsModal && (
        <ScrollableModal
          onAccept={() => {
            setShowTsAndCsModal(false);
          }}
          onCancel={() => {
            setShowTsAndCsModal(false);
          }}
          showCancel={false}
          Title={language.help.termsAndConditions.title}
          Body={language.help.termsAndConditions.body}
          AcceptButton={language.help.termsAndConditions.close}
          CancelButton={""}
        />
      )}
    </>
  )

  const TermsAndConditionsSection = (
    <>
      <div>
        <div>
          <h2 className="text-xl font-bold flex items-center">
            {language.help.termsAndConditions.title}
          </h2>
        </div>
        <div className="w-full">
          <Button
            colour="white"
            onClick={() => {
              setShowTsAndCsModal(true);
            }}
            label={language.help.termsAndConditions.title}
          />
        </div>
      </div>
    </>
  )

  const ExternalLinksSection = (
    <>
      <div className="w-full bg-rpr-blue inline-flex space-x-2 items-center">
        <div className="w-fit">
          <a href="https://www.r-p-r.co.uk/" target="_blank" rel="noreferrer">
            <img src="https://www.r-p-r.co.uk/newimages/rprfooter.png" alt="website" />
          </a>
        </div>
        <div className="flex flex-row space-x-2">
          <div>
            <a href="https://www.facebook.com/rprweatherfile" target="_blank" rel="noreferrer">
              <FiFacebook color="white" size={30} className="mt-1" />
            </a>
          </div>
          <div>
            <a href="https://twitter.com/rprweatherfile" target="_blank" rel="noreferrer">
              <FiTwitter color="white" size={30} className="mt-1" />
            </a>
          </div>
          <div>
            <a href="https://www.linkedin.com/company/rprltd" target="_blank" rel="noreferrer">
              <FiLinkedin color="white" size={30} className="mt-1" />
            </a>
          </div>
          <div>
            <a href="https://www.instagram.com/richardpaulrussellltd/" target="_blank" rel="noreferrer">
              <FiInstagram color="white" size={30} className="mt-1" />
            </a>
          </div>
          <div>
            <a href="https://www.youtube.com/channel/UCk__JfZxQ3tJAsByMw-H7hA/feed" target="_blank" rel="noreferrer">
              <FiYoutube color="white" size={30} className="mt-1" />
            </a>
          </div>
          <div>
            <a href="https://r-p-r.co.uk/?page_id=12255" target="_blank" rel="noreferrer">
              <FiRss color="white" size={30} className="mt-1" />
            </a>
          </div>
        </div>
      </div>
    </>
  )

  const ContactUsSection = (
    <>
      <div className="space-y-4">
        <div>
          Richard Paul Russell Ltd <br />
          The Lodge <br />
          Unit 1 Barnes Farm Business Park <br />
          Barnes Lane, Milford on Sea <br />
          SO41 0AP
        </div>
        <div>
          <div className="w-full inline-flex space-x-2">
            <MdEmail size={18} className="mt-1" /><div>sales@r-p-r.co.uk</div>
          </div>
          <div className="w-full inline-flex space-x-2">
            <MdPhone size={18} className="mt-1" /><div>+44 (0) 1590 641223</div>
          </div>
        </div>
        {ExternalLinksSection}
        <div>
          Registered in England. Company registration number 2746689. VAT no. GB580260358
        </div>
      </div>
    </>
  )

  return (
    <div className="h-full w-full p-2 rounded bg-white shadow-md select-none">
      {Modals}
      <div className="w-full">
        <div>
          <h1 className="text-3xl font-bold flex items-center">
            {language.help.titles.help}
          </h1>
        </div>
        {TermsAndConditionsSection}
        <hr className="my-2" />
        <div>
          <div>
            <h2 className="text-xl font-bold flex items-center">
              {language.help.titles.contactUs}
            </h2>
          </div>
          {ContactUsSection}
        </div>
      </div>
    </div>
  );
}

export default Help;


type ScrollableModalProps = {
  onAccept: (...args: any) => any;
  onCancel?: (...args: any) => any;
  showCancel?: boolean;
  Title: string;
  Body: ReactNode | string;
  AcceptButton: string;
  AcceptButtonColour?: "red" | "green" | "blue" | "yellow" | "white" | "gray" | "lightgray";
  CancelButton: string;
  loading?: boolean;
  closeButton?: boolean;
  onClose?: (...args: any) => any;
  disableAccept?: boolean;
};

function ScrollableModal({
  onAccept,
  onCancel,
  showCancel = true,
  Title,
  Body,
  AcceptButton,
  AcceptButtonColour = "green",
  CancelButton,
  loading = false,
  closeButton = false,
  onClose,
  disableAccept = false,
}: ScrollableModalProps) {
  return (
    <div
      className="relative z-20"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                {closeButton && (
                  <>
                    <button
                      className="flex text-4xl text-black items-center cursor-pointer right-2 top-2 fixed z-[1001]"
                      onClick={() => (onClose ? onClose() : null)}
                    >
                      <IoMdClose size={28} />
                    </button>
                  </>
                )}
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-title"
                  >
                    {Title}
                  </h3>
                  {!loading ? (
                    <div className="mt-2">
                      <div className="max-h-[400px] sm:max-h-[600px] text-sm text-gray-500 bg-gray-100 scrollbar-thin overflow-y-scroll">{Body}</div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {!loading ? (
              <>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <div className="sm:w-auto">
                    <Button
                      onClick={() => onAccept()}
                      disabled={disableAccept}
                      label={AcceptButton}
                      colour={AcceptButtonColour}
                    />
                  </div>
                  {showCancel ? (
                    <div className="sm:w-auto mr-2">
                      <Button
                        onClick={() => (onCancel ? onCancel() : null)}
                        label={CancelButton}
                        colour="white"
                      />
                    </div>
                  ) : null}
                </div>
              </>
            ) : (
              <div className="w-full flex justify-center h-10">
                <Spinner colour={"fill-green-800"} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
