import _ from "lodash";
import { useEffect, useState } from "react";
import { ChartScale, Scale, ChartType, GaugeData } from "..";
import { v4 as uuidv4 } from "uuid";
import NewRingMenu from "./NewActivityChartMenu";
import { FormInput } from "../../../FormInput";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../../../dictionary/ChartsText";
import { LanguageCheck } from "../../../../utils/LanguageCheck";
import { useAppSelector } from "../../../../app/hooks";
import { selectLanguage } from "../../../../features/user/userSlice";
import { EditChart, EditChartProps } from "../EditChart";
import { FaList } from "react-icons/fa";

type Props = {
    id: string;
    data: ChartType;
    remove: (id: string) => void;
    updateChart: (data: ChartType) => void;
    setShowChartEdit: (isOpen: boolean) => void;
};

function EditActivityChart({ id, remove, updateChart, data, setShowChartEdit }: Props) {

    const defaultYAxis: Scale = { min_auto: true, max_auto: true, max: 0, min: 0 };

    const [loading, setLoading] = useState<boolean>(false);
    const [showControls, setShowControls] = useState<boolean>(false);

    const [title, setTitle] = useState(data.title.en);
    const [rings, setRings] = useState<GaugeData[]>(data.chartData);
    const [chartScale, setChartScale] = useState<ChartScale>(
        (data.chartScale === undefined || (data.chartScale.y_axis === defaultYAxis)) ?
            { y_axis: { min_auto: true, max_auto: true, max: 10, min: 0 } }
            : data.chartScale
    );


    const stateLang = useAppSelector(selectLanguage);
    let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    useEffect(() => {
        setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    }, [stateLang]);

    const addNewRing = ({ ringID, deviceID, phenomena, unit, elemID, colour, data, label, min, max, }: GaugeData) => {
        setRings(_.concat(rings, { ringID, deviceID, phenomena, unit, elemID, colour, data, label, min, max, }));
    };

    function getBlankRing() {
        return {
            ringID: uuidv4(),
            deviceID: "new",
            phenomena: "",
            unit: "",
            elemID: "",
            colour: "",
            data: [],
            label: "",
            min: 0,
            max: 0,
        };
    }

    function GetTitleAndCatchBlankData() {
        setTitle(data.title.en);
        if (data.chartData !== undefined && data.chartData !== null && data.chartData.length > 0) {
            setRings(data.chartData);
        } else {
            setRings([getBlankRing()]);
        }
    }

    function GetChartScaleFromParams() {
        if (data.chartScale !== undefined && data.chartScale !== null) {
            setChartScale(data.chartScale)
        }
    }

    function GetShowControlsFromChartScale() {
        if (chartScale.y_axis.max_auto !== undefined) {
            setShowControls(chartScale.y_axis.max_auto)
        }
    }

    function CatchBlankRings() {
        if (rings === undefined || rings === null) {
            setRings([getBlankRing()]);
        } else {
            if (!(rings.length > 0)) {
                setRings([getBlankRing()]);
            }
        }
    }

    useEffect(() => {
        GetTitleAndCatchBlankData();
    }, [data]);

    useEffect(() => {
        GetChartScaleFromParams();
    }, [data.chartScale])

    useEffect(() => {
        GetShowControlsFromChartScale();
    }, [chartScale])

    useEffect(() => {
        CatchBlankRings();
    }, [rings]);

    const saveRingData = (ring: GaugeData) => {
        setRings(
            _.map(rings, (currentRing: GaugeData) => {
                if (currentRing.ringID === ring.ringID) {
                    return ring;
                } else {
                    return currentRing;
                }
            })
        );
    }

    const onRemoveRing = (ringID: string | undefined) => {
        setRings(_.reject(rings, { ringID: ringID }));
    };

    var settingsBox = (
        <div className="p-2 bg-gray-200 overflow-y-scroll scrollbar-none rounded-lg flex flex-col">
            <div>
                <FormInput
                    label={language.charts.gauge.labels.title}
                    htmlFor="title"
                    value={title !== null ? title : ""}
                    onChange={(event) => {
                        setTitle(event.target.value);
                    }}
                />
            </div>
            <div className="w-full flex items-left space-x-1">
                <div>
                    {"Show timestamp"}
                </div>
                <div className="flex justify-center items-center">
                    <input
                        onChange={() => {
                            setShowControls(!showControls);
                            setChartScale({
                                ...chartScale,
                                y_axis: {
                                    ...chartScale.y_axis,
                                    max_auto: !showControls,
                                },
                            });
                        }}
                        checked={showControls}
                        type="checkbox"
                        className="bg-indigo-50 px-2 py-2 outline-none rounded-md mr-2 hover:cursor-pointer"
                    />
                </div>
            </div>
        </div>
    );

    var itemList = (rings ?? [getBlankRing()]).map((ring, index) => (
        <NewRingMenu
            index={index}
            ring={ring}
            key={index}
            removeRing={onRemoveRing}
            chartScale={chartScale}
            setChartScale={setChartScale}
            saveRingData={saveRingData}
        />
    ))

    const onDelete = () => {
        remove(id);
        setShowChartEdit(false);
    };

    const saveChartSettings = () => {
        var newRings = rings.map((ring) => {
            var newRing: GaugeData = {
                ...ring,
                max: 0,
                min: 0,
            };
            return newRing;
        });
        setRings(newRings);
        setLoading(true)
        setTimeout(function () {
            setShowChartEdit(false);
            setLoading(false)
        }, 300);
        updateChart({
            i: id,
            type: "activity",
            title: { en: title },
            chartData: newRings,
            chartScale: chartScale,
        });
    };

    var newButton = undefined

    const EditChartObjects: EditChartProps = ({
        editMapType: "Edit Status",
        settingsBox: settingsBox,
        itemList: itemList,
        newButton: newButton,
        onDelete: onDelete,
        onSave: saveChartSettings,
        loading: loading,
    })

    return (
        <EditChart {...EditChartObjects} />
    );
};

export default EditActivityChart;
