import { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { useVerifyEmailMutation } from "../../services/authAPI";
import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
} from "../../dictionary/LoginText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { selectLanguage } from "../../features/user/userSlice";
import { useAppSelector } from "../../app/hooks";

function VerifyNewEmail() {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(
    LanguageCheck(
      English,
      French,
      German,
      Dutch,
      Spanish,
      Italian,
      Romanian,
      Portuguese,
      stateLang
    )
  );

  useEffect(() => {
    setLanguage(
      LanguageCheck(
        English,
        French,
        German,
        Dutch,
        Spanish,
        Italian,
        Romanian,
        Portuguese,
        stateLang
      )
    );
  }, [stateLang]);

  //Setting up the 2 queries
  const [
    sendVerifyCode,
    {
      data: verifyCodeData,
      error: verifyCodeError,
      isError: verifyCodeIsError,
      isSuccess: verifyCodeIsSuccess,
      isLoading: verifyCodeIsLoading,
    },
  ] = useVerifyEmailMutation();

  //If the user has entered the correct code for their email, redirect them to the login page
  useEffect(() => {
    if (verifyCodeData !== undefined) {
      if (verifyCodeIsSuccess && verifyCodeData.verifiedEmail) {
        navigate("/");
        toast.success(language.verifyEmail.toasts.newEmailVerified);
      }
    }
  }, [verifyCodeIsSuccess]);

  //If the user has entered the wrong code for their email, show an error on the page
  useEffect(() => {
    if (verifyCodeIsError) {
      toast.error(language.linkVerifyEmail.toasts.invalidNewEmailCode, {
        autoClose: 6000,
      });
      navigate("/user/settings");
    }
  }, [verifyCodeIsError]);

  useEffect(() => {
    if (!verifyCodeIsLoading && !verifyCodeIsError && !verifyCodeIsSuccess) {
      if (searchParams.get("token") !== null) {
        sendVerifyCode(searchParams.get("token") ?? "");
      } else {
        toast.error(language.linkVerifyEmail.toasts.invalidNewEmailCode, {
          autoClose: 6000,
        });
        navigate("/user/settings");
      }
    }
  }, []);

  return (
    <div className="w-full h-screen flex items-center justify-center">
      {language.linkVerifyEmail.body.ifYouAreNotRedirected}&nbsp;
      <Link className="text-blue-600 hover:text-blue-700 underline" to="/">
        {language.linkVerifyEmail.body.here}
      </Link>
    </div>
  );
}

export default VerifyNewEmail;
