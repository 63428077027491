import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AcceptModal from "../../components/modals/AcceptModal";
import { FormInput } from "../../components/FormInput";
import Spinner from "../../components/Spinner";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../features/user/userSlice";
import { useClaimDeviceMutation } from "../../services/deviceAPI";
import { handleError } from "../../utils/ErrorHandling";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../dictionary/DeviceText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { selectGroups } from "../../features/groups/groupSlice";
import { toast } from "react-toastify";
import { useGetUserInfoQuery } from "../../services/userAPI";

type FormInputData = {
  optionValue: string;
  optionName: string;
};

export default function Claim() {

  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  useEffect(() => {
    setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
  }, [stateLang]);

  let [searchParams, setSearchParams] = useSearchParams();
  let [showConfirmModal, setShowConfirmModal] = useState(false);
  let navigate = useNavigate();
  let [deviceID, setDeviceID] = useState("");
  let [groupID, setGroupID] = useState("");
  let [groupName, setGroupName] = useState("");
  //let { groups: userGroups, id } = useAppSelector(selectUser);
  const {
    error: getUserInfoError,
    isError: isGetUserInfoError,
    data: userInfo,
    isFetching: isUserInfoFetching,
  } = useGetUserInfoQuery("");
  const userGroups = userInfo?.groups;
  const id = userInfo?.id;
  let { groups } = useAppSelector(selectGroups);
  let [refCodeFull, setRefCodeFull] = useState("");
  const location = useLocation();
  const [error, setError] = useState("");
  const [claimDisabled, setClaimDisabled] = useState(true);
  const [groupList, setGroupList] = useState<FormInputData[]>([]);

  const [
    claimDevice,
    {
      data: claimData,
      isSuccess: isClaimSuccess,
      isLoading: isClaimLoading,
      isError: isClaimError,
      error: claimError,
    },
  ] = useClaimDeviceMutation();

  useEffect(() => {
    if (isClaimSuccess) {
      setShowConfirmModal(false);
      toast.success(language.claim.toasts.claimed);
      localStorage.removeItem("redirectURL");
      navigate("/device/" + claimData?.deviceID);
    }
  }, [claimData, isClaimSuccess, language.claim.toasts.claimed]);

  useEffect(() => {
    if (isClaimError) {
      setShowConfirmModal(false);
      setClaimDisabled(false);
      setError(handleError(claimError));
    }
  }, [claimError, isClaimError]);

  useEffect(() => {
    if (userGroups && !isUserInfoFetching) {
      if (Object.keys(userGroups).length > 0) {
        if (CheckGroupsCanClaimIn()) {
          toast.warning(language.claim.toasts.noGroupPermission, {
            autoClose: 6000,
          });
          localStorage.setItem(
            "redirectURL",
            location.pathname + location.search
          );
          navigate("/group/create");
        }
      } else if (id) {
        toast.warning(language.claim.toasts.noGroups, { autoClose: 6000 });
        localStorage.setItem(
          "redirectURL",
          location.pathname + location.search
        );
        navigate("/group/create");
      }
    }
  }, [userGroups, isUserInfoFetching, language.claim.toasts.noGroupPermission, language.claim.toasts.noGroups, location.pathname, location.search, id]);

  useEffect(() => {
    if (userGroups) {
      let tempFormInputData: FormInputData[] = [];
      tempFormInputData.push({
        optionValue: "new",
        optionName: "--" + language.claim.selectAGroup + "--",
      });
      for (const key in userGroups) {
        if (
          userGroups[key as keyof typeof userGroups].permissions.hasOwnProperty(
            "can_claim"
          )
        ) {
          if (
            userGroups[key as keyof typeof userGroups].permissions.can_claim
          ) {
            tempFormInputData.push({
              optionValue: key,
              optionName:
                groups?.[key as keyof typeof userGroups]?.name ?? "Loading...",
            });
          }
        }
      }
      setGroupList(tempFormInputData);
    }
  }, [userGroups, groups, language.claim.selectAGroup]);

  const CheckGroupsCanClaimIn = () => {
    for (const key in userGroups) {
      if (
        userGroups[key as keyof typeof userGroups].permissions.hasOwnProperty(
          "can_claim"
        )
      ) {
        if (userGroups[key as keyof typeof userGroups].permissions.can_claim) {
          return false;
        }
      }
    }
    return true;
  };

  async function doClaim(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setClaimDisabled(true);
    setShowConfirmModal(true);
    var { deviceID } = document.forms[0];

    setDeviceID(
      deviceID.value
        .match(/[0-9A-Fa-f]/g ?? [])
        .join("")
        .toLowerCase()
    );

    if (/^[0-9A-Fa-f]{6}$/.test(OTP)) {
      setRefCodeFull(
        OTP.substring(0, 3) + "-" + OTP.substring(3, 6)
      );
    }
  }

  async function accept(): Promise<any> {
    claimDevice({
      device_id: deviceID,
      group_id: groupID,
      claim_token: refCodeFull,
    });
  }

  function cancel() {
    //console.log("cancel");
    setShowConfirmModal(false);
    setClaimDisabled(false);
  }

  const inputRef = useRef<HTMLInputElement>(null);

  const OTPField = (): [JSX.Element, string] => {
    let currentOTPIndex: number = 0;
    const [activeOTPIndex, setActiveOTPIndex] = useState<number>(-1);


    useEffect(() => {
      inputRef.current?.focus();
    }, [activeOTPIndex]);
    const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));

    var urlClaimToken = (searchParams.get("claim_token") && (searchParams.get("claim_token")!.substring(0, 3) + (searchParams.get("claim_token")!.substring(4, 7) ?? "")));

    useEffect(() => {
      if (urlClaimToken) {
        if (urlClaimToken.length === 6) {
          setOtp(urlClaimToken.split(""));
        }
      }
    }, [urlClaimToken]);

    return ([
      <div className="flex justify-center items-center space-x-1 w-fit">
        {otp.map((_, index) => {
          return (
            <React.Fragment key={index}>
              <input
                ref={index === activeOTPIndex ? inputRef : null}
                value={otp[index]}
                type="text"
                placeholder="X"
                className="w-8 h-11 border-2 p-0 rounded-lg bg-transparent outline-none text-center font-semibold text-xl spin-button-none border-gray-300 focus:border-gray-700 focus:text-gray-700 text-gray-800 transition"
                onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                  const { value } = target;
                  const newOTP: string[] = [...otp]
                  newOTP[currentOTPIndex] = value.substring(value.length - 1).toLocaleUpperCase();

                  if (!value) setActiveOTPIndex(currentOTPIndex - 1)
                  else setActiveOTPIndex(currentOTPIndex + 1);
                  setOtp(newOTP);
                }}
                onKeyDown={(e/* { key }: React.KeyboardEvent<HTMLInputElement> */) => {
                  currentOTPIndex = index;
                  if (e.key === "Backspace" || e.key === "ArrowLeft") {
                    setActiveOTPIndex(currentOTPIndex - 1);
                  } else if (e.key === "ArrowRight" || e.key === "Tab") {
                    setActiveOTPIndex(currentOTPIndex + 1);
                  }

                  if (e.key === "v" && (!e.ctrlKey && !e.metaKey)) {
                    console.log(e.ctrlKey || e.metaKey)
                    e.preventDefault();
                  }

                  if (e.key === "Delete") {
                    setOtp([...otp.slice(0, index), "", ...otp.slice(index + 1)])
                  }

                  if (e.key === "Backspace") {
                    setOtp([...otp.slice(0, index), "", ...otp.slice(index + 1)])
                  }

                  if (!/^[0-9A-Fa-fv]$/.test(e.key)) {
                    e.preventDefault();
                  }

                }}
                onPaste={(e) => {
                  e.preventDefault()
                  if (!/^[0-9A-Fa-f]{3}-[0-9A-Fa-f]{3}$/.test(e.clipboardData.getData("text"))) {
                    if (/^[0-9A-Fa-f]{6}$/.test(e.clipboardData.getData("text"))) {
                      setOtp(e.clipboardData.getData("text").split(""));
                    }
                  } else {
                    setOtp(e.clipboardData.getData("text").split("-").join("").split(""));
                  }
                }
                }
              />
              {index === 2 && (
                <span className="rounded-md w-3 py-0.5 bg-gray-700" />
              )}
            </React.Fragment>
          );
        })}
      </div>
      , otp.join("")]);
  };

  var [OTPFieldElement, OTP] = OTPField();

  return (
    <div className="h-full rounded bg-transparent flex justify-center flex-col items-center w-full">
      {showConfirmModal && (
        <AcceptModal
          onAccept={accept}
          onCancel={cancel}
          Title={language.claim.confirm.title}
          Body={
            <div>
              {language.claim.confirm.phrase1}
              <b>{deviceID}</b>
              {language.claim.confirm.phrase2}
              <b>{groupName}</b>
              {language.claim.confirm.phrase3}({language.claim.confirm.refCode}
              {refCodeFull})
            </div>
          }
          AcceptButton={language.claim.buttons.confirm}
          CancelButton={language.claim.buttons.cancel}
        />
      )}

      <div className="bg-white px-10 py-8 rounded-xl w-screen shadow-md max-w-sm m-4">
        <h1 className="text-center text-2xl font-semibold text-gray-600 mb-4">
          {language.claim.claimANewDevice}
        </h1>
        <form onSubmit={doClaim}>
          <div className="">
            <FormInput
              htmlFor="deviceID"
              required={true}
              label={language.claim.labels.deviceID}
              type="text"
              value={searchParams.get("eui") ?? undefined}
              tooltip="Auto fill this value by scanning the QR code on the device"
            />
            <p className="text-blue-600 font-semibold">
              {language.claim.labels.referenceCode}
            </p>
            <p className="w-full">
              {OTPFieldElement}
            </p>
            <FormInput
              htmlFor="groupID"
              label={language.claim.labels.group}
              required={true}
              type="select"
              data={groupList}
              value={"new"}
              onChange={(event) => {
                setGroupID(event.target.value);
                setClaimDisabled(false);
                setGroupName(
                  groupList.filter(
                    (e) => e.optionValue === event.target.value
                  )[0].optionName
                );
              }}
            />
          </div>
          <div className="text-xs font-semibold text-center tracking-wide text-red-500 w-full"> <></>
            {error}
          </div>
          <button
            disabled={claimDisabled}
            type="submit"
            className={`mt-4 w-full ${claimDisabled ? "bg-gray-700" : "bg-rpr-blue cursor-pointer"
              } text-indigo-100 py-2 rounded-md text-lg tracking-wide flex items-center justify-center`}
          >
            {isClaimLoading ? (
              <Spinner colour="fill-blue-600" />
            ) : (
              language.claim.buttons.claimDevice
            )}
          </button>
        </form>
      </div>
    </div>
  );
}
