/* 
Checks an email against a regex to see if it is valid, returns an error message if it is not
*/
export const validateEmail = (email: string): string | undefined => {
  var validRegex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  if (!email.length || !validRegex.test(email)) {
    return "Please enter a valid email address";
  }
};

/*
Checks a password to ensure its more than 5 characters long to see if it is valid, returns an error message if it is not
*/
export const validatePassword = (password: string): string | undefined => {
  if (password.length < 5) {
    return "Please enter a password that is at least 5 characters long";
  }
};

/*
Checks 2 passwords to see if they are the same, returns an error message if it is not
*/
export const validateConfirmPassword = (
  password: string,
  confirmPassword: string
): string | undefined => {
  if (password !== confirmPassword) {
    return "Passwords do not match";
  }
};

/*
Checks a username to ensure its more than 5 characters long to see if it is valid, returns an error message if it is not
*/
export const validateUsername = (username: string): string | undefined => {
  if (username.length < 5) {
    return "Please enter a username with 5 or more characters";
  }
  var validRegex = /@/i;
  if (validRegex.test(username)) {
    return "Username cannot contain @ Symbols";
  }
};

export const validateDashboardName = (name: string): string | undefined => {
  if (name.length >= 32) {
    return "Maximum length is 32 characters";
  } else if (name.length < 1) {
    return "Please enter a name";
  }
};

export const validateDashboardDescription = (
  description: string
): string | undefined => {
  if (description.length > 200) {
    return "Maximum length is 200 characters";
  } else if (description.length < 1) {
    return "Please enter a description";
  }
};
