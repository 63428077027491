import { SerializedError } from "@reduxjs/toolkit/dist/createAsyncThunk";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch } from "../app/hooks";
import {
  CustomError,
  CustomErrorInternal,
  ERROR_ACTIONS,
  ERROR_TYPES,
} from "./Types";
import { doLogout, AcceptedLatestTsCs } from "../app/store";
import { setTermsAndConditionsUpdated } from "../features/user/userSlice";

export function handleError(
  error: FetchBaseQueryError | SerializedError | undefined,
  nonUserToken?: boolean
): string {
  if ((error as FetchBaseQueryError).data) {
    if (((error as FetchBaseQueryError).data as CustomError)?.error) {
      let errorVal = ((error as FetchBaseQueryError).data as CustomError).error;
      console.log(errorVal);
      return completeAction(errorVal) || "";
    } else if ((error as FetchBaseQueryError).data == "404 page not found") {
      toast.error(
        "An issue connecting to the server has occurred, please try again later",
        { autoClose: 6000 }
      );
    }
  }
  return "Something went wrong";
}

function completeAction(error: CustomErrorInternal): string | null {
  switch (error.action) {
    case ERROR_ACTIONS.LOGOUT:
      switch (error.code) {
        case "email not verified":
          toast.warn(
            error.message + " Please verify your email to try again.",
            { autoClose: 6000 }
          );
          break;
        case "new version of terms and conditions":
          AcceptedLatestTsCs(false);
          toast.warn(
            error.message +
              " Please accept the new terms and conditions to try again.",
            { autoClose: 6000 }
          );
          break;
        default:
          toast.warn(error.message + " Please login to try again.", {
            autoClose: 6000,
          });
          doLogout();
      }

      break;
    case ERROR_ACTIONS.TOAST:
      switch (error.severity) {
        case "error":
          toast.error(error.message, { autoClose: 6000 });
          break;
        case "warning":
          toast.warn(error.message, { autoClose: 6000 });
          break;
        case "info":
          toast.info(error.message, { autoClose: 6000 });
          break;
        default:
          toast(error.message, { autoClose: 6000 });
      }
      break;
    case ERROR_ACTIONS.FORM_ERROR:
      return error.message;
    case ERROR_ACTIONS.INPUT_ERROR:
      return error.message;
    case ERROR_ACTIONS.NONE:
      break;
    default:
      break;
  }
  return null;
}
