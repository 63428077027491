import _ from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ChartScale, GaugeData } from "..";
import { useAppSelector } from "../../../../app/hooks";
import { English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese } from "../../../../dictionary/ChartsText";
import {
    Dashboards,
    selectDashboards,
} from "../../../../features/dashboards/dashboardSlice";
import {
    Device,
    PhenomenaConfiguration,
    selectDevices,
} from "../../../../features/devices/deviceSlice";
import { FormInput } from "../../../FormInput";
import { LanguageCheck } from "../../../../utils/LanguageCheck";
import { selectLanguage } from "../../../../features/user/userSlice";

type Props = {
    ring: GaugeData;
    index: number;
    saveRingData: (ring: GaugeData) => void;
    removeRing: (index: string) => void;
    chartScale: ChartScale;
    setChartScale: (chartScale: ChartScale) => void;
};

type FormInputData = {
    optionValue: string;
    optionName: string;
};

function NewStatusMenu({
    ring: currentRing,
    index,
    saveRingData,
    chartScale,
    setChartScale,
    removeRing,
}: Props) {

    const stateLang = useAppSelector(selectLanguage);
    let [language, setLanguage] = useState(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    useEffect(() => {
        setLanguage(LanguageCheck(English, French, German, Dutch, Spanish, Italian, Romanian, Portuguese, stateLang));
    }, [stateLang]);


    const [showUnit, setShowUnit] = useState<boolean>(chartScale.y_axis.min_auto ?? true);
    const [selectedPhenomenaElem, setSelectedPhenomenaElem] = useState<string>("new");

    const [ring, setRing] = useState<GaugeData>(currentRing);
    const [phenomenaSelectData, setPhenomenaSelectData] = useState<FormInputData[]>([]);
    const [userDeviceData, setUserDeviceData] = useState<FormInputData[]>([]);


    let { devices } = useAppSelector(selectDevices);
    let { dashboards } = useAppSelector(selectDashboards);
    let { dashboardid } = useParams();

    function getRingFromParams() {
        setRing(currentRing);
        setSelectedPhenomenaElem(
            currentRing.phenomena === "new"
                ? "new"
                : JSON.stringify({
                    phenomena: currentRing.phenomena,
                    elemID: currentRing.elemID ?? "0",
                })
        );
    }

    function populateDeviceSelect() {
        var tempUserDeviceData: FormInputData[] = [{
            optionValue: "new",
            optionName: language.charts.gauge.inputDefaults.selectDevice,
        }];
        const dashboardDevices: Device[] = _.filter(devices, (device: Device) => {
            return device.group_id === dashboards[dashboardid as keyof Dashboards].group_id;
        });
        _.map(dashboardDevices, (device: Device) => {
            if (device.eui !== null && device.name !== null) {


                //map through device.configuration, if any of the keys are "sw_curr" then add to list
                var currDeviceConfig: [string, PhenomenaConfiguration[]][] = _.entries(device.configuration);
                var hasSWCurr = false;
                _.forEach(currDeviceConfig, ([key, value]) => {
                    _.forEach(value, (element: PhenomenaConfiguration) => {
                        if (key === "sw_curr") {
                            hasSWCurr = true;
                        }
                    });
                });

                if (hasSWCurr) {
                    tempUserDeviceData.push({
                        optionValue: device.eui,
                        optionName: device.name,
                    });
                }
            }
        });
        setUserDeviceData(tempUserDeviceData);
    }

    function populatePhenomenaSelect() {
        var currDevice = _.find(devices, (device) => device.eui === ring.deviceID)
        if (currDevice !== undefined) {
            var tempPhenomenaSelectData: FormInputData[] = [{
                optionValue: "new",
                optionName: language.charts.gauge.inputDefaults.selectParameter,
            }];
            var currDeviceConfig: [string, PhenomenaConfiguration[]][] = _.entries(currDevice.configuration);

            _.forEach(currDeviceConfig, ([key, value]) => {
                _.forEach(value, (element: PhenomenaConfiguration) => {
                    tempPhenomenaSelectData.push({
                        optionValue: JSON.stringify({
                            phenomena: key,
                            elemID: element.elemID ?? "0",
                        }),
                        optionName: element.description,
                    });
                });
            });
            setPhenomenaSelectData(tempPhenomenaSelectData);
        }
    }

    useEffect(() => {
        getRingFromParams();
    }, [currentRing]);

    useEffect(() => {
        populateDeviceSelect();
    }, [devices, dashboards, dashboardid]);

    useEffect(() => {
        populatePhenomenaSelect();
    }, [ring.deviceID, devices]);

    return (
        <div key={index} className="rounded-md bg-gray-200 p-1 flex flex-col my-2">
            <FormInput
                value={ring.deviceID ?? "new"}
                type="select"
                label={language.charts.gauge.labels.device}
                htmlFor="device"
                data={userDeviceData}
                onChange={(event) => {
                    setSelectedPhenomenaElem(
                        JSON.stringify({
                            phenomena: "sw_curr",
                            elemID: "sw_curr0",
                        })
                    );
                    setRing({ ...ring, phenomena: "sw_curr", elemID: "sw_curr0", unit: "", deviceID: event.target.value });
                    saveRingData({ ...ring, phenomena: "sw_curr", elemID: "sw_curr0", unit: "", deviceID: event.target.value });
                }}
            />
            {/* <button onClick={() => removeRing(ring.ringID)}>
                {language.charts.lineChart.buttons.remove}
            </button> */}
        </div>
    );
}

export default NewStatusMenu;

function DemoBox({ colour }: { colour: string | undefined }): JSX.Element {
    return (
        <div className="w-[40%] br-1 h-full border-4 border-gray-300 rounded-lg bg-white">
            <div className="w-full h-full flex items-center justify-center">
                <svg className="w-full h-full"><text fontFamily="sans-serif" fontSize={140} fontStyle="normal" fontWeight="700" textAnchor="middle" dominantBaseline="middle" ><tspan x="50%" y="62%" fill={colour} >8</tspan></text></svg>
            </div>
        </div>
    )
}
